define('fund-figure-app/models/asset-depreciation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    assetCategory: _emberData.default.attr('string'),
    condition: _emberData.default.attr(),
    mileage: _emberData.default.attr(),
    assetModel: _emberData.default.attr(),
    year: _emberData.default.attr()
  });
});