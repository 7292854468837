define("fund-figure-app/components/ember-plaid-min", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({ globalMessaging: Ember.inject.service(), connectedUser: Ember.inject.service(), actions: {
      processPlaidToken: function processPlaidToken(e, n, o) {
        if (console.log(e, n, o), this.get("update")) return console.log("only update so dont exchange"), void $.get({ url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_updatedAccount?bank_account_id=" + JSON.stringify(o), json: !0 }).done(function (e) {
          console.log(e), e.error ? s.actionNotification(e.error, !1) : e.success && s.actionNotification(e.success, !0);
        });var t = this.get("connectedUser.fiscalYear.municipality.id"),
            c = this.get("connectedUser.currentFund.id"),
            s = this.get("globalMessaging");t.then(function (o) {
          o.id;$.get({ url: "https://us-central1-aacc-603.cloudfunctions.net/plaidOnRequestAccessToken?token=" + JSON.stringify(e) + "&municipality=" + t + "&municipalityFund=" + c + "&institution=" + JSON.stringify(n.institution.name), json: !0 }).done(function (e) {
            console.log(e), e.error ? s.actionNotification(e.error, !1) : e.success && s.actionNotification(e.success, !0);
          });
        });
      },
      updatePlaid: function updatePlaid(e, n, o) {
        console.log(e, n, o), $.get({ url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_updatedAccount?bank_account_id=" + JSON.stringify(o), json: !0 }).done(function (e) {
          console.log(e), e.error ? sendMessage.actionNotification(e.error, !1) : e.success && sendMessage.actionNotification(e.success, !0);
        });
      },
      getAccounts: function getAccounts() {
        $.get({ url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_addWebHookManuelly", json: !0 }).done(function (e) {
          console.log(e), e.error ? self.setProperties({ errorMessage: e.error }) : e.success && sendMessage.actionNotification(e.success), console.log(e);
        });
      }
    } });
});