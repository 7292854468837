define('fund-figure-app/models/card', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    address_city: _emberData.default.attr('string'), //null,
    address_country: _emberData.default.attr('string'), //null,
    address_line1: _emberData.default.attr('string'), //null,
    address_line1_check: _emberData.default.attr('string'), //null,
    address_line2: _emberData.default.attr('string'), //null,
    address_state: _emberData.default.attr('string'), //null,
    address_zip: _emberData.default.attr('string'), //null,
    address_zip_check: _emberData.default.attr('string'), //null,
    brand: _emberData.default.attr('string'), //Visa,
    country: _emberData.default.attr('string'), //US,
    customer: _emberData.default.belongsTo('municipalPaymentInfo', { async: true, inverse: null }), //cus_CHVMmuuI4Re0l9,
    cvc_check: _emberData.default.attr('string'), //null,
    dynamic_last4: _emberData.default.attr('string'), //null,
    exp_month: _emberData.default.attr('string'), //8,
    exp_year: _emberData.default.attr('string'), //2019,
    fingerprint: _emberData.default.attr('string'), //3pEmM1Fk4Xhj9HAi,
    funding: _emberData.default.attr('string'), //credit,
    last4: _emberData.default.attr('string'), //4242,
    metadata: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'), //null,
    tokenization_method: _emberData.default.attr('string') //null
  });
});