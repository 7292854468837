define('fund-figure-app/components/account-handler/create-with-pin/progress-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    newUser: Ember.inject.service(),
    classNames: ['flex-grow1']
  });
});