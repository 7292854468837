define('fund-figure-app/routes/dashboard/feedback-messages', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		connectedUser: Ember.inject.service(),
		model: function model() {
			var user = this.get('connectedUser.currentAccount.id');
			console.log('model', user);
			if (!user) {
				return;
			}
			return this.store.query('feedback', {
				orderBy: 'municipalUser',
				equalTo: user
			});
		}
	});
});