define('fund-figure-app/components/dashboard/billing/payment-info', ['exports', 'fund-figure-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    stripev3: Ember.inject.service(),
    attemptAddPayment: false,

    actions: {
      toggleAddCard: function toggleAddCard() {
        this.toggleProperty('addCard');
      },
      submitCard: function submitCard(stripeElement, paymentInfo_id) {
        var _this = this;
        var customer_id = paymentInfo_id;
        var ifSandbox = 's';
        var global_message = this.get('globalMessaging');
        global_message.actionNotification('Verifying card info', 'success');
        var stripe = this.get('stripev3');
        if (_environment.default.environment === 'development') {
          ifSandbox = 'sandboxS';
        }
        stripe.createToken(stripeElement).then(function (token) {
          _this.set('attemptAddPayment', false);
          global_message.actionNotification('Saving card access', 'success');
          $.get({
            url: 'https://us-central1-aacc-603.cloudfunctions.net/' + ifSandbox + 'tripeOnRequestCreatePaymentInfo?token=' + JSON.stringify(token) + '&customer_id=' + customer_id,
            json: true
          }).done(function (data) {
            console.log(data);
            if (data.error) {
              global_message.actionNotification(error, 'warning');
              self.setProperties({ errorMessage: data.error });
            } else if (data.success) {
              global_message.actionNotification('Card saved', 'success');
            }
          }).fail(function (error) {
            console.log(error);
            global_message.actionNotification(error, 'warning');
          }).always(function () {
            console.log('finished');
            global_message.actionNotification('New card ready to use', 'success');
          });
          return token;
        }).catch(function (error) {
          console.log(error);
        });
      },
      toggle_changeDefaultSourceMenu: function toggle_changeDefaultSourceMenu() {
        this.toggleProperty('changeDefaultSourceMenu');
      }
    }
  });
});