define('fund-figure-app/components/reports/ms-535', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    fundInfo: Ember.inject.service(),

    getAccounts: Ember.computed('fundInfo.allAccounts.@each.budget', 'fundInfo.allAccounts.@each.balance', function () {
      var _this = this,


      //Appropriation Accounts
      gen_gov = Array(),
          pub_safety = Array(),
          air_avi = Array(),
          high_str = Array(),
          sani = Array(),
          wat_tr = Array(),
          elec = Array(),
          health = Array(),
          welfare = Array(),
          cul_rec = Array(),
          consv = Array(),
          debt_ser = Array(),
          capt_ovly = Array(),
          tran_out = Array(),
          taxes_gov = Array(),


      //Revenue Accounts
      taxes = Array(),
          lic_fee = Array(),
          fed_gov = Array(),
          state_gov = Array(),
          other_gov = Array(),
          charge_serv = Array(),
          misc_source = Array(),
          inter_in = Array(),
          other_fin = Array(),


      //Proprietary/SpecialFunds
      prop_spec = Array(),


      //Accounts Payable
      acct_payable = Array(),
          muni_accounts = this.get('fundInfo.allAccounts');

      var index = 0;
      _this.set('allAccounts', muni_accounts);
      muni_accounts.forEach(function (account) {

        var source = account.get('source');
        switch (source) {
          case 'General Government':
            gen_gov.addObject(account);
            ++index;
            break;
          case 'Public Safety':
            pub_safety.addObject(account);
            ++index;
            break;
          case 'Airport or Aviation Center':
            air_avi.addObject(account);
            ++index;
            break;
          case 'Highways and Streets':
            high_str.addObject(account);
            ++index;
            break;
          case 'Sanitation':
            sani.addObject(account);
            ++index;
            break;
          case 'Water Distribution and Treatment':
            wat_tr.addObject(account);
            ++index;
            break;
          case 'Electric':
            elec.addObject(account);
            ++index;
            break;
          case 'Health':
            health.addObject(account);
            ++index;
            break;
          case 'Welfare':
            welfare.addObject(account);
            ++index;
            break;
          case 'Culture and Recreation':
            cul_rec.addObject(account);
            ++index;
            break;
          case 'Conservation':
            consv.addObject(account);
            ++index;
            break;
          case 'Debt Service':
            debt_ser.addObject(account);
            ++index;
            break;
          case 'Capital Outlay':
            capt_ovly.addObject(account);
            ++index;
            break;
          case 'Interfund Operating Transfers Out':
            tran_out.addObject(account);
            ++index;
            break;
          case 'Payment to or Property Tax Assessments on Behalf of Other Governments':
            taxes_gov.addObject(account);
            ++index;
            break;
          // Revenue Accounts
          case 'Revenue From Taxes':
            taxes.addObject(account);
            ++index;
            break;
          case 'Revenue From Licenses, Permits and Fees':
            lic_fee.addObject(account);
            ++index;
            break;
          case 'Revenue From the Federal Government':
            fed_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From the State of New Hampshire':
            state_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From Other Governments':
            other_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From Charges For Services':
            charge_serv.addObject(account);
            ++index;
            break;
          case 'Revenue from Miscellaneous Sources':
            misc_source.addObject(account);
            ++index;
            break;
          case 'Interfund Operating Transfers In':
            inter_in.addObject(account);
            ++index;
            break;
          case 'Other Financial Sources':
            other_fin.addObject(account);
            ++index;
            break;
          default:
            // taxes.addObject(account);
            ++index;
            break;
        }

        // populate Proprietary/special fund array
        var json = account.toJSON();
        var ID = json.stateName;
        switch (ID) {
          case 'Transfer To Proprietary Funds':
            if (json.parentAccount) {
              break;
            }
            prop_spec.addObject(account);
            break;
          case 'Transfer To Special Revenue Fund':
            if (json.parentAccount) {
              break;
            }
            prop_spec.addObject(account);
            break;
          // account payable
          case 'Accounts Payable':
            if (json.parentAccount) {
              break;
            }
            acct_payable.addObject(account);
            break;
          default:
            break;
        }

        if (index == muni_accounts.get('length')) {
          console.log('hit the limit');
          //Set Appropriations Info and Revenue Info
          //No revenue accounts here yet!!!!
          _this.setProperties({
            generalGovAppropriations: gen_gov,
            publicSafetyAppropriations: pub_safety,
            airTrafficAppropriations: air_avi,
            highwayAppropriations: high_str,
            sanitationAppropriations: sani,
            waterTreatmentAppropriations: wat_tr,
            electricAppropriations: elec,
            healthAppropriations: health,
            welfareAppropriations: welfare,
            cultureRecAppropriations: cul_rec,
            conservationAppropriations: consv,
            debtServiceAppropriations: debt_ser,
            capitalOverlayAppropriations: capt_ovly,
            interfundOutAppropriations: tran_out,
            otherGovermentAppropriations: taxes_gov,
            taxesRevenue: taxes,
            licRevenue: lic_fee,
            fedRevenue: fed_gov,
            stateRevenue: state_gov,
            otherGovRevenue: other_gov,
            chargeRevenue: charge_serv,
            miscRevenue: misc_source,
            interRevenue: inter_in,
            otherRevenue: other_fin,
            prop_special_funds: prop_spec,
            accounts_payable: acct_payable
          });
        }
      });
    }),

    // appropriationAccountsArray: Ember.computed('allAccounts', function() {
    //   let accounts = this.get('allAccounts');
    //   if (!accounts) {
    //     return;
    //   }
    //   let appropriationAccounts = accounts.filterBy('appropriation'),
    //     appropriationParentAccounts = appropriationAccounts.filter(a => {
    //       let b = a.toJSON();
    //       if (!b.parentAccount && b.stateName !== 'Accounts Payable') {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     });
    //   this.set('check', appropriationParentAccounts);
    //   return appropriationParentAccounts;
    // }),

    // totalRevenueBudget: Ember.computed('allAccounts', function() {
    //   let accounts = this.get('allAccounts');
    //   if (!accounts) {
    //     return;
    //   }
    //   let revenueAccounts = accounts.filterBy('appropriation', false),
    //     revenueParentAccounts = revenueAccounts.filter(a => {
    //       let b = a.toJSON();
    //       if (!b.parentAccount) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     });
    //   return revenueParentAccounts;
    // }),

    moneyFormat: {
      pos: "%s %v",
      neg: "%s %v",
      zero: "$0"
    }
  });
});