define('fund-figure-app/components/dashboard/transactions/received-deposit/deposit-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },
    didInsertElement: function didInsertElement() {
      this.$('.ember-power-select-trigger').focus();
    },

    set_transactCredit: Ember.computed('transactCredit', function () {
      this.set('transaction.transactCredit', this.get('transactCredit'));
    }),
    actions: {
      removeDepositItem: function removeDepositItem(item, deposit) {
        // let amount = item.amount
        item.set('deposit', deposit);
        var _this = this,
            d_json = deposit.toJSON(),
            i_json = item.toJSON();
        if (!d_json.d_department && !d_json.d_date || !i_json.transactDebit && !i_json.desc && !i_json.amount) {
          _this.get('transactionArray').removeObject(item);
          return;
        }
        deposit.get('d_department').then(function (d_department) {
          var desc = 'Add deposit item back: ' + item.get('desc') + ':' + item.get('amount'),
              undoObject = { 'model': item, 'desc': desc };
          _this.get('undoObjects').addObject(undoObject);
          _this.get('transactionArray').removeObject(item);
        }).catch(function (err) {
          console.log(err);
        });
      },
      checkTab: function checkTab(e, evt) {
        if (evt.keyCode === 9) {
          $('.addItem').focus();
          evt.preventDefault();
          return;
        }
        console.log('not tab');
        return;
      },
      notifyTransactionArrayChange: function notifyTransactionArrayChange() {
        console.log('transactionArray changed in child');
        this.get('notifyChange')();
      }
    }
  });
});