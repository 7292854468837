define('fund-figure-app/models/municipal-vendors-db', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
		vendors: _emberData.default.hasMany('vendor')
	});
});