define('fund-figure-app/components/dashboard/invoices-manifests/manifests/check-display', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		invoices: [],
		classNames: [],
		classNameBindings: ['hasInvoices'],
		moneyFormat: {
			pos: "%v",
			zero: "0.00"
		},
		hasInvoices: Ember.computed('vendorInvoices', {
			get: function get(key) {
				if (this.get('vendorInvoices').length > 0) {
					return true;
				} else {
					return false;
				}
			}
		}),
		vendorInvoices: Ember.computed('invoiceManifest.[]', {
			get: function get(key) {
				var _this = this;

				this.set('invoices', this.get('invoiceManifest').filter(function (a) {
					return a.toJSON().vendor === _this.get('vendor.id');
				}));
				return this.get('invoiceManifest').filter(function (a) {
					return a.toJSON().vendor === _this.get('vendor.id');
				});
			}
		}),
		checkNumber: Ember.computed('connectedUser.currentFund.currentCheckNumber', {
			get: function get(key) {
				var elementID = document.getElementById(this.elementId),
				    beginCheckNumber = this.get('connectedUser.currentFund.currentCheckNumber');
				console.log(beginCheckNumber);
				return ('00000' + (Number(beginCheckNumber) + Number($('.has-invoices').index(elementID)))).slice(-5);
			}
		})
	});
});