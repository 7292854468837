define('fund-figure-app/components/dashboard-assets/asset/list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [],
    bigBrother: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    assetInfo: Ember.inject.service(),
    undo: Ember.inject.service(),
    router: Ember.inject.service(),
    doubleClick: function doubleClick() {
      Ember.get(this, 'router').transitionTo('dashboard-assets.asset', Ember.get(this, 'asset'));
    },

    roundNumber: Ember.computed('asset.acquired', function (number) {

      return Ember.get(this, 'commonTasks').preciseRound(number, 2);
    }),
    actions: {
      deleteAsset: function deleteAsset(asset) {
        Ember.get(this, 'assetInfo').deleteAsset(asset);
      },
      editAsset: function editAsset(asset) {
        Ember.get(this, 'assetInfo').editAsset(asset);
      },
      disposeAsset: function disposeAsset(asset) {
        var globalMessage = Ember.get(this, 'globalMessaging');
        asset.set('disposed', Date.now());
        Ember.get(this, 'undo').createUndo(asset, false, null, null, asset.get('description') + ' asset was disposed').then(function (res) {
          asset.save().then(function () {
            globalMessage.actionNotification(asset.get('description') + ' disposed', 'success');
          });
        }).catch(function (error) {
          asset.rollbackAttributes();
          asset.save().then(function () {
            globalMessage.actionNotification('Could not dispose of this asset', 'warning');
          });
        });
      },
      reinstateAsset: function reinstateAsset(asset) {
        var globalMessage = Ember.get(this, 'globalMessaging');
        asset.set('disposed', null);
        Ember.get(this, 'undo').createUndo(asset, false, null, null, asset.get('description') + ' asset was reinstated').then(function (res) {
          asset.save().then(function () {
            globalMessage.actionNotification(asset.get('description') + ' reinstated', 'success');
          });
        }).catch(function (error) {
          asset.rollbackAttributes();
          asset.save().then(function () {
            globalMessage.actionNotification('Could not reinstate this asset', 'warning');
          });
        });
      }
    }
  });
});