define('fund-figure-app/components/dashboard-support/manage-funds/manage-chart-of-accounts/sub-type/sub-type-create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('subTypeModel', this.get('store').createRecord('municipal-fund-type-sub'));
    },

    actions: {
      save: function save(subTypeModel) {
        var model = this.get('model');
        subTypeModel.save().then(function (savedSub) {
          model.get('fundSubTypes').addObject(savedSub);
          model.save();
        });
      }
    }
  });
});