define('fund-figure-app/components/reports/town-reports/budget-line-item-detail/reduce-array-promises-delta-changes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    reducedArrayNumerator: null,
    reducedArrayDenomenator: null,

    getReducedArrayNumerator: Ember.computed('array', {
      get: function get() {
        var _this = this;

        var promiseRequests = Ember.get(this, 'promiseStringNumerator').split('.'),
            array = Ember.get(this, 'array');
        if (!array[0]) {
          return;
        }

        function methodThatReturnsAPromise(object) {
          return new Promise(function (resolve, reject) {
            object.get(promiseRequests[0]).then(function (res) {
              resolve(res);
            });
          });
        }

        var result = array.reduce(function (total, nextObject) {
          return total.then(function (res) {
            console.log(promiseRequests);
            if (promiseRequests.length == 1) {
              return res + nextObject.get(promiseRequests[0]);
            }
            return methodThatReturnsAPromise(nextObject).then(function (currentResult) {
              if (currentResult) {
                return res + currentResult.get(promiseRequests[1]);
              } else {
                return res;
              }
            });
          });
        }, Promise.resolve(0));

        return Promise.resolve(result).then(function (res) {
          Ember.set(_this, 'reducedArrayNumerator', res);
          return res;
        });
      }
    }),
    getReducedArrayDenomenator: Ember.computed('array', {
      get: function get() {
        var _this2 = this;

        var promiseRequests = Ember.get(this, 'promiseStringDenomenator').split('.'),
            array = Ember.get(this, 'array');
        if (!array[0]) {
          return;
        }

        function methodThatReturnsAPromise(object) {
          return new Promise(function (resolve, reject) {
            object.get(promiseRequests[0]).then(function (res) {
              resolve(res);
            });
          });
        }

        var result = array.reduce(function (total, nextObject) {
          return total.then(function (res) {
            console.log(promiseRequests);
            if (promiseRequests.length == 1) {
              return res + nextObject.get(promiseRequests[0]);
            }
            return methodThatReturnsAPromise(nextObject).then(function (currentResult) {
              if (currentResult) {
                return res + currentResult.get(promiseRequests[1]);
              } else {
                return res;
              }
            });
          });
        }, Promise.resolve(0));

        return Promise.resolve(result).then(function (res) {
          Ember.set(_this2, 'reducedArrayDenomenator', res);
          return res;
        });
      }
    }),

    getDeltaChanges: Ember.computed('reducedArrayNumerator', 'reducedArrayDenomenator', {
      get: function get() {
        var num = Ember.get(this, 'reducedArrayNumerator'),
            den = Ember.get(this, 'reducedArrayDenomenator');
        if (den == 0) {
          return 100;
        }
        return Math.round((+num - +den) / +num * 100);
      }
    })
  });
});