define('fund-figure-app/components/dashboard/settings/manage-users/add-user/user-verify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    actions: {
      sendEmailForm: function sendEmailForm(municipalUserModel) {},
      printForm: function printForm(municipalUserModel) {}
    }
  });
});