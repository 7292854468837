define('fund-figure-app/components/dashboard-support/manage-warrant-articles/state-warrant-article/warrant-article-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appError: Ember.inject.service(),
    actions: {
      editView: function editView() {
        this.toggleProperty('edit-view');
      },
      save: function save(model) {
        var appError = this.get('appError'),
            _this = this;
        model.save().then(function () {
          _this.toggleProperty('edit-view');
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving article';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});