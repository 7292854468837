define('fund-figure-app/components/d3/visual-donut-chart', ['exports', 'd3-array', 'd3-scale', 'd3-selection', 'd3-format', 'd3-collection', 'd3-shape', 'd3-scale-chromatic'], function (exports, _d3Array, _d3Scale, _d3Selection, _d3Format, _d3Collection, _d3Shape, _d3ScaleChromatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['donut-visual'],

    drawChart: Ember.computed('data', function () {
      var data = this.get('data'),
          _this = this;
      if (data === undefined || data === null || data.length === 0) {
        console.log('no data');
        return 0;
      }

      var cfg = {
        marginChart: {
          top: 20,
          right: 120,
          bottom: 20,
          left: 120
        }
      },
          parentWidth = this.get('width') || this.$().parent().width() || 400,
          parentHeight = this.get('height') || this.$().parent().height() || 400,
          width = parentWidth - cfg.marginChart.left - cfg.marginChart.right,
          height = parentHeight - cfg.marginChart.top - cfg.marginChart.bottom,
          colour = (0, _d3Scale.scaleOrdinal)(_d3ScaleChromatic.schemeDark2),
          // colour scheme
      variable = this.get('variable'),
          // value in data that will dictate proportions on chart
      category = this.get('category'),
          // compare data by
      padAngle = this.get('padAngle') || 0.015,
          // effectively dictates the gap between slices
      floatFormat = (0, _d3Format.format)('.4r'),
          cornerRadius = this.get('cornerRadius') || 3,
          // sets how rounded the corners are on each slice
      formatGen = (0, _d3Format.format)(this.get('formatString') || ''),
          svg = (0, _d3Selection.select)(this.element);
      svg.select('g').remove();
      svg.attr('width', parentWidth).attr('height', parentHeight);

      console.log(data);
      // generate chart
      // ===========================================================================================
      // Set up constructors for making donut. See https://github.com/d3/d3-shape/blob/master/README.md
      var radius = Math.min(width, height) / 2;
      // creates a new pie generator
      var pie = (0, _d3Shape.pie)().value(function (d) {
        return d[variable];
      });

      // contructs and arc generator. This will be used for the donut. The difference between outer and inner
      // radius will dictate the thickness of the donut
      var arc = (0, _d3Shape.arc)().outerRadius(radius * 0.8).innerRadius(radius * 0.6).cornerRadius(cornerRadius).padAngle(padAngle);

      // this arc is used for aligning the text labels
      var outerArc = (0, _d3Shape.arc)().outerRadius(radius * 0.9).innerRadius(radius * 0.9);
      // ===========================================================================================

      // ===========================================================================================
      // append the svg object to the selection    
      // ===========================================================================================
      // 
      var g = svg.append('g'),
          background = g.append('g').append('rect').attr('width', width).attr('height', height).attr('opacity', 0).on('click', selectPath),
          path = g.append('g').attr('transform', 'translate(' + parentWidth / 2 + ',' + parentHeight / 2 + ')').attr('class', 'slices').selectAll('.path'),
          label = g.append('g').attr('transform', 'translate(' + parentWidth / 2 + ',' + parentHeight / 2 + ')').attr('class', 'labelName').selectAll('.text'),
          polyline = g.append('g').attr('transform', 'translate(' + parentWidth / 2 + ',' + parentHeight / 2 + ')').attr('class', 'lines').selectAll('.polyline');

      restart();

      function restart() {
        console.log('data', data);
        // ===========================================================================================
        // g elements to keep elements within svg modular
        // svg.append('g').attr('class', 'slices');
        // svg.append('g').attr('class', 'labelName');
        // svg.append('g').attr('class', 'lines');
        // ===========================================================================================

        // ===========================================================================================
        // add and colour the donut slices
        path = path.data(pie(data));
        path.exit().remove();
        path = path.enter().append('path').attr('fill', function (d) {
          console.log(d.data);return colour(d.data[category]);
        }).attr('d', arc).on('click', selectPath);

        // .merge(path)
        // ===========================================================================================

        // ===========================================================================================
        // add text labels
        label = label.data(pie(data));
        label.exit().remove();
        label = label.enter().append('text').attr('dy', '.35em').html(function (d) {
          // let d1 = pie(d);
          // add "key: value" for given category. Number inside tspan is bolded in stylesheet.
          return d.data[category] + ': <tspan>' + formatGen(d.data[variable]) + '</tspan>';
        }).attr('transform', function (d) {
          // effectively computes the centre of the slice.
          // see https://github.com/d3/d3-shape/blob/master/README.md#arc_centroid
          var pos = outerArc.centroid(d);

          // changes the point to be on left or right depending on where label is.
          pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
          return 'translate(' + pos + ')';
        }).style('text-anchor', function (d) {
          // let d1 = pie(d);
          // if slice centre is on the left, anchor text to start, otherwise anchor to end
          return midAngle(d) < Math.PI ? 'start' : 'end';
        });
        // .merge(label);

        // add lines connecting labels to slice. A polyline creates straight lines connecting several points
        polyline = polyline.data(pie(data));
        polyline.exit().remove();
        polyline = polyline.enter().append('polyline').attr('points', function (d) {
          // see label transform function for explanations of these three lines.
          var pos = outerArc.centroid(d);
          pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
          return [arc.centroid(d), outerArc.centroid(d), pos];
        });
        // .merge(polyline);

        // add tooltip to mouse events on slices and labels
        (0, _d3Selection.selectAll)('.labelName text, .slices path').call(toolTip);
      };

      // Functions

      // calculates the angle for the middle of a slice
      function midAngle(d) {
        return d.startAngle + (d.endAngle - d.startAngle) / 2;
      }

      // function that creates and adds the tool tip to a selected element
      function toolTip(selection) {

        // add tooltip (svg circle element) when mouse enters label or slice
        selection.on('mouseenter', function (data) {

          g.append('text').attr('class', 'toolCircle').attr('dy', -15).attr('transform', 'translate(' + parentWidth / 2 + ',' + parentHeight / 2 + ')') // hard-coded. can adjust this to adjust text vertical alignment in tooltip
          .html(toolTipHTML(data)) // add text to the circle.
          .style('font-size', '.9em').style('text-anchor', 'middle'); // centres text in tooltip

          g.append('circle').attr('class', 'toolCircle').attr('transform', 'translate(' + parentWidth / 2 + ',' + parentHeight / 2 + ')').attr('r', radius * 0.55) // radius of tooltip circle
          .style('fill', colour(data.data[category])) // colour based on category mouse is over
          .style('fill-opacity', 0.35);
        });

        // remove the tooltip when mouse leaves the slice/label
        selection.on('mouseout', function () {
          (0, _d3Selection.selectAll)('.toolCircle').remove();
        });
      }

      // function to create the HTML string for the tool tip. Loops through each key in data object
      // and returns the html string key: value
      function toolTipHTML(data) {

        var tip = '',
            i = 0;

        for (var key in data.data) {

          // if value is a number, format it as a percentage
          var value = data.data[key];
          if (key === 'amount') {
            value = !isNaN(parseFloat(data.data[key])) ? formatGen(data.data[key]) : data.data[key];
          }

          // leave off 'dy' attr for first tspan so the 'dy' attr on text element works. The 'dy' attr on
          // tspan effectively imitates a line break.
          if (i === 0) tip += '<tspan x="0">' + key + ': ' + value + '</tspan>';else tip += '<tspan x="0" dy="1.2em">' + key + ': ' + value + '</tspan>';
          i++;
        }

        return tip;
      }

      function selectPath(d, i) {
        if (_d3Selection.event.defaultPrevented) return;
        if (d) {
          Ember.get(_this, 'drillDown')(d);
        } else {
          Ember.get(_this, 'drillUp')();
        }
      }

      function arcTween(a) {
        var i = d3.interpolate(this._current, a);
        this._current = i(0);
        return function (t) {
          return arc(i(t));
        };
      }
    })
  });
});