define('fund-figure-app/components/dashboard-payroll/payroll/payroll-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    payroll: Ember.inject.service(),
    weekIndex: 0,
    maxIndex: 0,

    weekArray: Ember.computed('payroll.current_Period', {
      get: function get() {
        var period = this.get('payroll.current_Period'),
            masterArray = [];
        if (!period) {
          return;
        }
        var duration = Math.round(moment.duration(moment(period.get('lastDay')).endOf('day').diff(moment(period.get('firstDay')).startOf('day'))).asWeeks());
        this.set('maxIndex', duration - 1);
        for (var h = 0; h < duration; h++) {
          var array = [];
          for (var i = 0; i < 7; i++) {
            var day = moment(period.get('firstDay')).add(i, 'days').add(h, 'weeks').valueOf();
            array.push(day);
          }
          masterArray.push({ array: array });
        }
        console.log(masterArray);
        return masterArray;
      }
    }),

    sortBy: 'employeeFilter',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    sortedTimeCards: Ember.computed.sort('payroll.current_Period.municipalTimeCards', 'sortDefinition'),

    actions: {
      changePeriod: function changePeriod(index) {
        this.set('payroll.setCurrentPeriod', index);
      },
      changeWeek: function changeWeek(index) {
        this.incrementProperty('weekIndex', index);
      },
      addTimeCard: function addTimeCard(selectedModel, more) {
        var period = this.get('payroll.current_Period'),
            store = this.get('store'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            newTimeCard = store.createRecord('municipal-time-card', {
          employee: selectedModel
        });

        period.get('municipalTimeCards').addObject(newTimeCard);
      },
      addHours: function addHours(hours, timeCard) {
        console.log(hours, timeCard);
      },
      deleteTimeCard: function deleteTimeCard(timeCard) {
        var _this = this;

        var appError = this.get('appError'),
            bigBrother = this.get('bigBrother');
        //might have references from timeCardDay if hours were logged
        //will have reference in municipalPayPeriod
        timeCard.get('municipalTimeCardDays').then(function (timeCardDays) {
          if (timeCardDays) {
            console.log(timeCardDays.toArray());
            //time card has references for days of work, need to remove reference before deleting timecard
            return timeCardDays.toArray().forEach(function (day) {
              day.destroyRecord();
            });
          } else {
            return;
          }
        }).then(function () {
          var timePeriod = _this.get('payroll.current_Period');
          timePeriod.get('municipalTimeCards').removeObject(timeCard);
          timeCard.destroyRecord();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while deleting timecard from timeperiod';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});