define('fund-figure-app/components/dashboard-assets/asset/list-item/action-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      editAsset: function editAsset(asset) {
        Ember.get(this, 'editAsset')(asset);
      },
      disposeAsset: function disposeAsset(asset) {
        Ember.get(this, 'disposeAsset')(asset);
      },
      deleteAsset: function deleteAsset(asset) {
        Ember.get(this, 'deleteAsset')(asset);
      },
      reinstateAsset: function reinstateAsset(asset) {
        Ember.get(this, 'reinstateAsset')(asset);
      }
    }
  });
});