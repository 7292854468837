define('fund-figure-app/components/dashboard-support/manage-funds/fund-edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			save: function save(model) {
				model.save();
			}
		}
	});
});