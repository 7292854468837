define('fund-figure-app/components/dashboard-assets/navigation/side-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    helpComponentName: Ember.computed('router.currentURL', {
      get: function get() {
        return 'help-window' + Ember.get(this, 'router.currentURL');
      }
    }),

    actions: {
      showModal: function showModal(modalString) {
        console.log(modalString);
        Ember.set(this, 'connectedUser.modalName', modalString);
        Ember.set(this, 'connectedUser.showModal', true);
      },
      formatAsCurrency: function formatAsCurrency(value, evt) {
        console.log('currency invoked');
        if (value < 1) {
          this.set('warrantAmount', null);
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.set('warrantAmount', formated);
        return;
      },
      changeFiscalYear: function changeFiscalYear(selection) {
        console.log('changing to year' + selection);
        this.get('connectedUser').getFiscalYear(selection);
      },
      yearEndClose: function yearEndClose() {
        // Fund Balance =
        // Unexpended appropriations
        // +
        // Excess Revenues Collected (Cash on Hand)
        // -
        // Uncollected Taxes (All or just from the current years warrants)
        // -
        // Liabilities
        var fundInfo = Ember.get(this, 'fundInfo');
        console.log(fundInfo.totalAppropriations, fundInfo.totalExpendituresFinalized, fundInfo.totalRevenueCollected, fundInfo.totalEstimatedRevenue);
        alert('going to cash ' + (fundInfo.totalAppropriations - fundInfo.totalExpendituresFinalized + (fundInfo.totalRevenueCollected - fundInfo.totalEstimatedRevenue)));
      }
    }
  });
});