define('fund-figure-app/components/selection-helper/single-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    classNameBindings: ['isHovering:hovering', 'isActive:active'],
    classNames: 'flex-grow1 flex-row flex-center relative',
    isHovering: false,
    isActive: false,

    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
    },
    click: function click() {
      this.toggleProperty('isActive');
    },


    actions: {
      changeAccount: function changeAccount(account) {
        var connectedUser = this.get('connectedUser'),
            _this = this,
            router = this.get('router'),
            user = this.get('connectedUser.userAccount');
        if (!user) {
          return;
        }
        router.transitionTo('dashboard.state-of-affairs');
        user.set('defaultAccount', account);
        user.save().then(function () {
          console.log('changed defaultAccount');
          connectedUser.getFiscalYear();
          _this.notifyPropertyChange('connectedUser.currentAccount');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});