define('fund-figure-app/components/municipal-handler/municipal-setup-step-one/edit-parent', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    edit: true,
    totalBudget: null,
    totalRevenue: null,
    totalExpense: null,
    totalTransferTo: null,
    totalTransferFrom: null,
    transferModel: null,

    budgetTotal: Ember.computed('model.subAccounts.[]', 'budget', function () {
      var total = 0;
      var _this = this;
      return this.get('model.subAccounts').then(function (subAccounts) {
        if (subAccounts.get('length') >= 1) {
          console.log('we have sub accounts');
          return subAccounts.forEach(function (account) {
            if (!account.get('budget')) {
              return;
            }
            if (typeof account.get('budget') === "undefined") {
              return;
            }
            console.log(Number(account.get('budget').replace(/,/g, '')));
            total = total + Number(account.get('budget').replace(/,/g, ''));
          });
        } else {
          if (!_this.get('model.budget')) {
            return;
          }
          if (typeof _this.get('model.budget') === "undefined") {
            return;
          }
          return total = +Number(_this.get('model.budget').replace(/,/g, ''));
        }
      }).then(function () {
        _this.set('totalBudget', total);
        return total;
      }).catch(function (error) {
        console.log(error);
      });
    }),
    payers: Ember.computed('model.municipality.municipalPayersDb.payers', function () {
      return this.get('model.municipality').then(function (municipality) {
        return municipality.get('municipalPayersDb');
      }).then(function (mPayersDb) {
        console.log(mPayersDb);
        return mPayersDb.get('payers');
      }).then(function (payers) {
        return payers;
      }).catch(function (error) {
        console.log(error);
      });
    }),

    // ACCOUNT CALCULATION
    revenue: Ember.computed('model.transactions', function () {
      var _this2 = this;

      return this.get('model.transactions').then(function (transactions) {
        return transactions.filter(function (transaction) {
          return transaction.hasAttribute('revenueTo');
        });
      }).then(function (revenueTransactions) {
        if (revenueTransactions.length > 0) {
          return revenueTransactions.reduce(function (a, b) {
            return a.amount + b.amount;
          }, 0);
        } else {
          return false;
        }
      }).then(function (amount) {
        _this2.set('totalRevenue', amount);
      }).catch(function (error) {
        console.log(error);
      });
    }),
    expense: Ember.computed('model.transactions', function () {
      var _this3 = this;

      return this.get('model.transactions').then(function (transactions) {
        return transactions.filter(function (transaction) {
          return transaction.hasAttribute('expenseTo');
        });
      }).then(function (expenseTransactions) {
        if (expenseTransactions.length > 0) {
          console.log(expenseTransactions);
          return expenseTransactions.reduce(function (a, b) {
            return a.amount + b.amount;
          }, 0);
        } else {
          return false;
        }
      }).then(function (amount) {
        _this3.set('totalExpense', amount);
      }).catch(function (error) {
        console.log(error);
      });
    }),
    transferTo: Ember.computed('model.transactions', function () {
      var _this4 = this;

      var model_id = this.get('model.id');
      return this.get('model.transactions').then(function (transactions) {
        return transactions.filter(function (transaction) {
          return transaction.get('transferTo') === model_id;
        });
      }).then(function (transferToTransactions) {
        if (transferToTransactions.length > 0) {
          console.log(transferToTransactions);
          return transferToTransactions.reduce(function (a, b) {
            return a.amount + b.amount;
          }, 0);
        } else {
          return false;
        }
      }).then(function (amount) {
        _this4.set('totalTransferTo', amount);
      }).catch(function (error) {
        console.log(error);
      });
    }),
    transferFrom: Ember.computed('model.transactions', function () {
      var _this5 = this;

      var model_id = this.get('model.id');
      return this.get('model.transactions').then(function (transactions) {
        return transactions.filter(function (transaction) {
          return transaction.get('transferFrom') === model_id;
        });
      }).then(function (transferFromTransactions) {
        if (transferFromTransactions.length > 0) {
          console.log(transferFromTransactions);
          return transferFromTransactions.reduce(function (a, b) {
            return a.amount + b.amount;
          }, 0);
        } else {
          return false;
        }
      }).then(function (amount) {
        _this5.set('totalTransferFrom', amount);
      }).catch(function (error) {
        console.log(error);
      });
    }),
    currentBalance: Ember.computed('totalRevenue', 'totalExpense', 'totalTransferTo', 'totalTransferFrom', function () {
      var totalRevenue = this.get('totalRevenue');
      var totalExpense = this.get('totalExpense');
      var totalTransferTo = this.get('totalTransferTo');
      var totalTransferFrom = this.get('totalTransferFrom');

      return totalRevenue + totalTransferTo - (totalExpense + totalTransferFrom);
    }),

    // QUERY ACCOUNT
    municipalAccounts: Ember.computed('connectedUser.fiscalYearPromise', function () {
      var fiscalYear = this.get('connectedUser.fiscalYearPromise');
      return fiscalYear.then(function (fiscalYear) {
        return fiscalYear.get('municipalAccounts');
      }).then(function (municipalAccounts) {
        return municipalAccounts;
      });
    }),

    actions: {
      toggleTransactionType: function toggleTransactionType(type) {
        var _this = this;
        this.setProperties({ check: false, transfer: false, invoice: false });
        this.set(type, true);
        if (type === 'transfer') {
          var currentAccount = _this.get('model');
          var transfer = _this.get('store').createRecord('transaction', {
            transferFrom: currentAccount.get('id')
          });
          _this.set('transferModel', transfer);
        } else {
          if (_this.get('transferModel')) {
            _this.get('transferModel').destroyRecord();
          }
        }
      },
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        // model.save();
        this.notifyPropertyChange('budgetTotal');
        return;
      },
      addSubAccount: function addSubAccount(account) {
        var subAccountNumber = String(account.get('subAccounts.length')).padStart(3, "0");
        console.log(subAccountNumber);
        var _this = this;
        account.get('stateAccountNumber').then(function (stateAccount) {
          return stateAccount.get('classification');
        }).then(function (classification) {
          if (classification === "Expense") {
            var _subAccountNumber = account.get('subAccount.length');
            console.log(_subAccountNumber);
            var subAccount = _this.get('store').createRecord('municipal-account', {
              parentAccount: account,
              stateAccountNumber: account.get('stateAccountNumber'),
              activated: true,
              accountNumber: String(account.get('subAccounts.length')).padStart(3, "0"),
              municipalYear: account.get('municipalYear'),
              municipality: account.get('municipality')
            });

            account.get('subAccounts').addObject(subAccount);
            account.save().then(function (savedAccount) {
              return subAccount.save();
            }).catch(function (error) {
              console.log(error);
            });
          } else {
            account.save();
          }
        });
      },
      save: function save(account) {
        var globalMessage = this.get('globalMessaging');
        account.save().then(function (savedAccount) {
          var message = null;
          if (account.get('nickname')) {
            message = account.get('nickname') + ' ' + 'was saved successfully';
          } else {
            message = account.get('accountNumber') + ' ' + 'was saved successfully';
          }
          globalMessage.actionNotification(message, true);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});