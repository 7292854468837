define('fund-figure-app/components/reports/print/general-print', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    showReport: false,
    loadingReport: true,

    willDestroy: function willDestroy() {
      $('#iframe').remove();
    },


    actions: {
      previewPDF: function previewPDF() {
        var component = this.get('componentId') || "export";
        $('#' + component).printThis({
          debug: true
        });
      },
      closePreview: function closePreview() {
        this.set('showReport', false);
      }
    }
  });
});