define('fund-figure-app/components/dashboard/settings/manage-funds/fund-create/step-three', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    router: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    /**
     * checks to insure all fund information was present before showing save button
     * @param  {[model]} 'model.[]' [municipalFundModel]
     * @return {[boolean]} [true if all present, false if any are not]
     */
    input_complete: Ember.computed('model.{fund_id,fund_name,year_voted,warrant_article}', {
      get: function get() {
        var j = this.get('model').toJSON();
        console.log('j', j);
        if (j.fund_id && j.fund_name && j.year_voted && j.warrant_article) {
          return true;
        } else {
          return false;
        }
      }
    }),
    actions: {
      /**
       * take input for fund id and pad 0 to number or cut to three digits
       * @param  {[input string]} e [fund_id input string]
       * @return {[string]}   [description]
       */
      padZero: function padZero(e) {
        this.set('model.fund_id', this.get('commonTasks').padString(e, '0', 3));
      },

      /**
       * Save fund and add to municipalYear
       * @param  {[municipal-fund model]} fund
       * @return null
       */
      saveFund: function saveFund(fund) {
        var municipalYear = this.get('connectedUser.fiscalYear'),
            router = this.get('router'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother');
        return municipalYear.get('municipality').then(function (municipalDb) {
          fund.setProperties({ municipalYear: municipalYear, municipalDb: municipalDb });
          fund.save().then(function (savedFund) {
            municipalYear.get('municipalFunds').addObject(savedFund);
            municipalYear.save().then(function () {
              router.transitionTo('dashboard.settings.manage-funds');
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'Trying to save municipalYear after adding a municipalFund Model';
              appError.logError(err, specialMessage);
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'Trying to save fund after setting municipalYear and MunicipalDb';
            appError.logError(err);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'Trying to get municipalDb model from fiscalYear in connectedUser reference';
          appError.logError(err);
        });
      }
    }
  });
});