define('fund-figure-app/components/dashboard/reconciliation/review-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    router: Ember.inject.service(),
    reconciledOnly: true,
    moneyFormat: {
      neg: "<green>%s%v</green>",
      pos: "<red>(%s%v)</red>",
      zero: "%s0.00"
    },
    fileInfo: Ember.computed('model.documents', {
      get: function get() {
        return this.get('model.documents') || null;
      }
    }),
    municipalTransactions: Ember.computed('connectedUser.fiscalYear.municipalTransactions.[]', 'statementDate', 'reconciledOnly', 'model', function () {
      var reconciledOnly = this.get('reconciledOnly'),
          _this = this,
          report = this.get('model').toJSON({ includeId: true }),
          year = this.get('connectedUser.fiscalYear').toJSON({ includeId: true });
      if (reconciledOnly) {
        //should only display reconciled for this report
        var query = '' + report.id,
            queryDeposits = this.get('store').query('municipalDeposit', {
          orderBy: 'reconciledReport',
          equalTo: query
        }).then(function (res) {
          console.log(res);
          if (res.get('length') < 1) {
            return [];
          } else {
            return res.toArray();
          }
        }),
            queryChecks = this.get('store').query('municipalCheck', {
          orderBy: 'reconciledReport',
          equalTo: query
        }).then(function (res) {
          console.log(res);
          if (res.get('length') < 1) {
            return [];
          } else {
            return res.toArray();
          }
        });

        return Promise.all([queryDeposits, queryChecks]).then(function (res) {
          console.log(res[0], res[1]);
          _this.set('allTransactions', res[0].concat(res[1]));
          return res[0].concat(res[1]);
        });
      } else {
        var _query = '' + year.id,
            _queryDeposits = this.get('store').query('municipalDeposit', {
          orderBy: 'year',
          equalTo: _query
        }).then(function (res) {
          return res.toArray().filter(function (deposit) {
            if (deposit.get('reconciled') == false && deposit.get('t_warrant') != true) {
              return true;
            } else {
              return false;
            }
          });
        }).catch(function (err) {
          console.log(err);
          return;
        }),
            _queryChecks = this.get('store').query('municipalCheck', {
          orderBy: 'municipalYear',
          equalTo: _query
        }).then(function (res) {
          return res.toArray().filter(function (check) {
            // insure check isnt already reconciled and that it was transacted                      
            if (check.get('reconciled') == false && _typeof(check.toJSON().p_transaction) != undefined) {
              return true;
            } else {
              return false;
            }
          });
        }).catch(function (err) {
          console.log(err);
          return;
        });

        return Promise.all([_queryDeposits, _queryChecks]).then(function (res) {
          console.log(res[0], res[1]);
          _this.set('allTransactions', res[0].concat(res[1]));
          return res[0].concat(res[1]);
        });
      }
    }),
    sortedMunicipalTransactions: Ember.computed.sort('allTransactions', 'sortDefinitionLedger'),
    sortedBankTransactions: Ember.computed.sort('model.municipalBankAccount.bankAccountTransactions', 'sortDefinition'),
    sortBy: 'date',
    sortByLedger: 'date',
    groupValueSort: 'date',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    sortDefinitionLedger: Ember.computed('sortBy', 'reverseSort', {
      get: function get() {
        var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
        return [this.get('sortByLedger') + ':' + sortOrder];
      }
    }),
    actions: {
      addReconciled: function addReconciled(bankTransaction, ops) {
        var ledgerTransaction = ops.target.ledgerTransaction,
            _this = this,
            reconciled_report = this.get('model'),
            globalMessaging = this.get('globalMessaging');
        ledgerTransaction.get('reconciledBankTransaction').then(function (alreadyReconciled) {
          if (alreadyReconciled) {
            console.log(ledgerTransaction.get('reconciledBankTransaction'));
            globalMessaging.actionNotification('already has a reconciled bank transaction', 'warning');
            return;
          }
          bankTransaction.set('reconciled', true);
          bankTransaction.set('reconciled_report', reconciled_report);
          ledgerTransaction.setProperties({ reconciledBankTransaction: bankTransaction, reconciled: true, reconciledReport: reconciled_report });
          ledgerTransaction.save().then(function (savedLedgerTransaction) {
            bankTransaction.save().then(function (savedBank) {
              if (savedLedgerTransaction.constructor.modelName === 'municipal-check') {
                reconciled_report.get('municipalChecks').addObject(savedLedgerTransaction);
              } else {
                reconciled_report.get('municipalDeposits').addObject(savedLedgerTransaction);
              }
              reconciled_report.get('bankAccountTransactions').addObject(savedBank);
              reconciled_report.save();
              _this.notifyPropertyChange('model');
            });
          }).catch(function (error) {
            _this.get('appError').logError(error);
            console.log(error);
          });
        }).catch(function (error) {
          _this.get('appError').logError(error);
          console.log(error);
        });
      },
      removeReconciled: function removeReconciled(ledgerTransaction, bank) {
        var globalMessaging = this.get('globalMessaging'),
            _this = this,
            reconciled_report = this.get('model'),
            bankTransaction = this.get('store').findRecord('bank-account-transaction', bank.get('id'));
        bankTransaction.then(function (bankAccount) {
          reconciled_report.get('bankAccountTransactions').removeObject(bank);
          bankAccount.setProperties({ reconciled: false, reconciled_report: null });
          if (ledgerTransaction.constructor.modelName === 'municipal-check') {
            reconciled_report.get('municipalChecks').removeObject(ledgerTransaction);
            ledgerTransaction.setProperties({ reconciledBankTransaction: null, reconciled: false, reconciled_report: null });
          } else {
            reconciled_report.get('municipalDeposits').removeObject(ledgerTransaction);
            ledgerTransaction.setProperties({ reconciledBankTransaction: null, reconciled: false, reconciled_report: null });
          }
          ledgerTransaction.save().then(function () {
            bankAccount.save();
            reconciled_report.save();
            // globalMessaging.actionNotification('removed reconciliation', 'success');
            _this.notifyPropertyChange('model');
            return;
          }).catch(function (error) {
            _this.get('appError').logError(error);
          });
        }).catch(function (err) {
          _this.get('appError').logError(err);
        });
      },
      reconcileThis: function reconcileThis(transaction) {
        var _this = this,
            reconciled_report = this.get('model'),
            globalMessaging = this.get('globalMessaging');
        if (transaction.get('reconciled')) {
          transaction.setProperties({ reconciled: false, reconciledReport: null });
          transaction.save().then(function (savedTransaction) {
            if (savedTransaction.constructor.modelName === 'municipal-check') {
              reconciled_report.get('municipalChecks').removeObject(savedTransaction);
            } else {
              reconciled_report.get('municipalDeposits').removeObject(savedTransaction);
            }
            reconciled_report.save();
            _this.notifyPropertyChange('model');
          }).catch(function (error) {
            _this.get('appError').logError(error);
            globalMessaging.actionNotification(error, 'cancel');
          });
        } else {
          transaction.setProperties({ reconciled: true, reconciledReport: reconciled_report });
          transaction.save().then(function (savedTransaction) {
            if (savedTransaction.constructor.modelName === 'municipal-check') {
              reconciled_report.get('municipalChecks').addObject(savedTransaction);
            } else {
              reconciled_report.get('municipalDeposits').addObject(savedTransaction);
            }
            reconciled_report.save();
            _this.notifyPropertyChange('model');
          }).catch(function (error) {
            _this.get('appError').logError(error);
            globalMessaging.actionNotification(error, 'cancel');
          });
        }
      },
      toggleReconcile: function toggleReconcile() {
        this.toggleProperty('reconciledOnly');
      },
      toggleBalanceBegin: function toggleBalanceBegin(model) {
        var _this2 = this;

        model.save().then(function () {
          _this2.toggleProperty('showBalanceBegin');
        }).catch(function (err) {
          _this.get('appError').logError(err);
          console.log(err);
        });
      },
      toggleBalanceEnd: function toggleBalanceEnd(model) {
        var _this3 = this;

        model.save().then(function () {
          _this3.toggleProperty('showBalanceEnd');
        }).catch(function (err) {
          _this.get('appError').logError(err);
          console.log(err);
        });
      },
      toggleSortByMenu: function toggleSortByMenu() {
        this.toggleProperty('showSortByMenu');
      },
      changeSort: function changeSort(bankValue, ledgerValue) {
        // let newSort = this.get('groupValueSort');
        console.log(bankValue, ledgerValue);
        this.set('sortBy', ['' + bankValue]);
        this.set('sortByLedger', ['' + ledgerValue]);
        this.toggleProperty('showSortByMenu');
      },
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('model');
        this.get('documentManager').saveDocument(fileInfo, 'statements').then(function (res) {
          model.get('documents').addObject(res);
          model.save();
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('model'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            appError = this.get('appError'),
            _this = this;
        model.get('documents').removeObject(file);
        model.save().then(function () {
          fiscalYear.get('municipalDocuments').removeObject(file);
          fiscalYear.save().then(function () {
            file.destroyRecord();
          }).catch(function (error) {
            appError.logError(error);
            console.log(error);
          });
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      finalizeReport: function finalizeReport(report) {
        var _this4 = this;

        var now = moment().valueOf(),
            appError = this.get('appError');
        // this.set('finalizing_report', true);
        report.set('date_finalized', now);
        report.save().then(function () {
          _this4.set('finalizing_report', false);
          _this4.get('router').transitionTo('dashboard.reconciliation');
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      }
    }
  });
});