define("fund-figure-app/components/dashboard/transactions/ledger-transaction-deposit", ["exports"], function (exports) {
   "use strict";

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Component.extend({
      moneyFormat: {
         pos: "<green>%s %v</green>",
         neg: "<red>%s %v</red>",
         zero: "%s  0.00"
      },
      actions: {}
   });
});