define('fund-figure-app/components/dashboard/transactions/ledger-display-account', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Component.extend({
      connectedUser: Ember.inject.service(),
      moneyFormat: {
         pos: "<green>%s %v</green>",
         neg: "<red>(%s %v)</red>",
         zero: "%s  0.00"
      }
   });
});