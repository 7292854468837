define('fund-figure-app/components/dashboard/transactions/new-transaction-revenue', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },

    didInsertElement: function didInsertElement() {
      this.createDeposit();
      this.createNewTransaction();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('deposit').destroyRecord();
      this.get('transactionArray').clear();
    },
    createDeposit: function createDeposit() {
      var store = this.get('store'),
          now = moment().format('MM/DD/YYYY'),
          deposit = store.createRecord('municipal-deposit', {
        d_date: moment().subtract(3, 'days').unix()
      });
      this.set('deposit', deposit);
    },
    createNewTransaction: function createNewTransaction(TransactionCredit, TransactionDebit, entryDate) {
      var store = this.get('store');

      var newTransaction = store.createRecord('transaction', {
        entryDate: moment().unix(),
        t_Revenue: true
      });
      this.get('transactionArray').addObject(newTransaction);
    },

    actions: {
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        return;
      },
      paymentMethod: function paymentMethod(paymentString) {
        var transaction = this.get('transaction');
        console.log(paymentString);
        console.log(transaction.get('paymentMethod'));
        if (paymentString === transaction.get('paymentMethod')) {
          console.log('reset');
          transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
          return;
        }
        transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
        transaction.set('paymentMethod', paymentString);
        switch (paymentString) {
          case "cash":
            transaction.set('cash', true);
            break;
            return;
          case "check":
            transaction.set('check', true);
            break;
            return;
          default:
            transaction.set('card', true);
            break;
            return;
        }
      }
    }
  });
});