define('fund-figure-app/components/dashboard/settings/vendor-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    municipalSettings: null,

    settings: Ember.computed('connectedUser.fiscalYear.municipality.municipalSetting.[]', function () {
      var _this = this;
      var store = this.get('store');
      if (this.get('connectedUser.fiscalYear')) {
        console.log('vendor settings with fiscal year');
        return this.get('connectedUser.fiscalYear.municipality')
        // .then(fiscalYear => {
        //   return fiscalYear.get('municipality');
        // })
        .then(function (municipality) {
          console.log(municipality);
          return municipality.get('municipalSetting');
        }).then(function (municipalSetting) {
          _this.set('municipalSettings', store.peekRecord('municipalSetting', municipalSetting.id));
          return store.peekRecord('municipalSetting', municipalSetting.id);
          // return municipalSetting;
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        console.log('no fiscal year found');
        return;
      }
    }),
    actions: {
      save: function save(model) {
        model.rollbackAttributes();
        this.get('globalMessaging').actionNotification("Feature not available yet, sorry", 'warning');
        return;

        // let message = this.get('globalMessaging');      
        // model.save()
        //   .then(() => {
        //     message.actionNotification('Saved', 'success');
        //   })
        //   .catch(error => {
        //     console.log(error);
        //     message.actionNotification(error, 'warning');
        //   })
      },
      cancel: function cancel(model) {
        var message = this.get('globalMessaging');
        model.rollbackAttributes().then(function () {
          message.actionNotification('Removed Changes', 'success');
        }).catch(function (error) {
          console.log(error);
          message.actionNotification(error, 'warning');
        });
      }
    }
  });
});