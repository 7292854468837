define('fund-figure-app/components/card-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalMessaging: Ember.inject.service(),

    actions: {
      updateDefaultCard: function updateDefaultCard(card_id, customer_id) {
        var global_message = this.get('globalMessaging');
        var _this = this;
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/updateCustomerDefaultSource?card_id=" + card_id + '&customer_id=' + customer_id,
          json: true
        }).done(function (data) {
          if (data.error) {
            global_message.actionNotification(error, 'warning');
            self.setProperties({ errorMessage: data.error });
          } else if (data) {
            global_message.actionNotification('Card set as default', 'success');
            _this.set('statusChange', false);
          }
        }).fail(function (error) {
          console.log(error);
          global_message.actionNotification(error, 'warning');
        });
      }
    }
  });
});