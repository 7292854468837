define('fund-figure-app/components/dashboard/transactions/new-transaction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    transactionArray: [],
    undoObjects: [],

    didInsertElement: function didInsertElement() {
      this.set('t_Received_Deposit', true);
    },
    willDestroyElement: function willDestroyElement() {
      var model = this.get('deposit');
      var documentService = this.get('documentManager');
      if (model.isNew) {
        if (!model.toJSON().documents) {
          console.log('no documents');
        } else {
          if (Object.keys(model.toJSON().documents).length > 1) {
            return documentService.removeDocuments(model.get('documents').toArray()).then(function (res) {
              if (res.error) {
                alert('error erasing ' + res.error);
              } else {}
            });
          } else {
            return documentService.removeDocument(model.get('documents.firstObject')).then(function (res) {
              if (res.error) {
                alert('error erasing ' + res.error);
              } else {
                model.destroyRecord();
              }
            }).catch(function (err) {
              console.log(err);
            });
          }
        }
      } else {
        alert('how was this saved?');
      }
    },

    checked: Ember.computed('transactionArray', function () {
      console.log('transactionArray changed');
    }),
    createTransaction: function createTransaction(TransactionCredit, TransactionDebit, entryDate) {
      var store = this.get('store'),
          deposit = this.get('deposit'),
          newTransaction = store.createRecord('transaction', {
        timestamp: moment().unix(),
        t_Revenue: true,
        deposit: deposit
      });
      this.get('transactionArray').addObject(newTransaction);
    },

    actions: {
      toggleTransaction: function toggleTransaction(transact) {
        // this.get('transaction').setProperties({ t_Revenue: false, t_Transfer: false, t_Expense: false });
        // this.get('transaction').set(transact, true);
        this.setProperties({ t_Received_Deposit: false, t_Transfer: false, t_Expense: false, t_Pay_Invoice: false, t_Received_Invoice: false, t_create_manifest: false });
        this.set(transact, true);
      },
      toggleShowOpps: function toggleShowOpps() {
        this.toggleProperty('showOpps');
      },
      undo: function undo(object, transactionArray) {
        var undoObjects = this.get('undoObjects');
        undoObjects.removeObject(object);
        transactionArray.addObject(object.model);
        if (undoObjects.length < 1) {
          this.set('showOpps', false);
        }
      },
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('deposit');
        this.get('documentManager').saveDocument(fileInfo, 'deposits', true).then(function (res) {
          model.get('documents').addObject(res);
          model.save();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while saving document';
          appError.logError(err, specialMessage);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('deposit'),
            municipalFund = this.get('connectedUser.currentFund'),
            appError = this.get('appError'),
            deleteDocument = this.get('documentManager'),
            _this = this;
        model.get('documents').removeObject(file);
        model.save().then(function () {
          console.log('file deleted from fund list');
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving the deposit after removing document';
          appError.logError(err, specialMessage);
        });
      },
      submitDeposit: function submitDeposit(deposit, transactionArray) {
        // TransactionCredit, TransactionDebit, TransactionRecord, revenueToSubAccount   //OLD STUFF
        this.set('saving', true);
        var globalMessage = this.get('globalMessaging'),
            groupInfo = deposit,
            transactionsWithInfo = 0;
        for (var i = transactionArray.length - 1; i >= 0; i--) {
          var transactDebit = transactionArray[i].toJSON().transactDebit || false,
              transactCredit = transactionArray[i].toJSON().transactCredit || false,
              amount = transactionArray[i].toJSON().amount;
          if (transactDebit && transactCredit && amount) {
            transactionsWithInfo++;
          }
        }
        if (transactionArray.length !== transactionsWithInfo) {
          this.set('saving', false);
          globalMessage.actionNotification('One or more transactions does not a have receiving account or amount', 'warning');
          return;
        }
        var jsonGroup = groupInfo.toJSON();

        if (!jsonGroup.department) {
          this.set('saving', false);
          globalMessage.actionNotification('No deposit or invoice info', 'warning');
          return;
        }
        if (groupInfo == null || !groupInfo) {
          this.set('saving', false);
          globalMessage.actionNotification('No deposit or invoice info', 'warning');
          return;
        }
        if (!groupInfo.get('date') || !groupInfo.get('date_received')) {
          this.set('saving', false);
          globalMessage.actionNotification('No date', 'warning');
          return;
        }
        if (transactionArray.length < 1) {
          this.set('saving', false);
          globalMessage.actionNotification('No transactions entered', 'warning');
          return;
        }

        var transactionDate = moment(groupInfo.get('date_received'), 'MM/DD/YYYY').valueOf(),
            depositDate = moment(groupInfo.get('date'), 'MM/DD/YYYY').valueOf(),
            _this = this,
            store = this.get('store'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            currentFund = this.get('connectedUser.currentFund'),
            user = this.get('connectedUser.currentAccount'),
            municipal_id = this.get('connectedUser.currentAccount').toJSON().municipality,
            m_year = municipal_id + '-' + moment(transactionDate, 'x').format("YYYY"),
            m_month = m_year + '-' + moment(transactionDate, 'x').format('MM'),
            m_week = m_year + '-' + moment(transactionDate, 'x').format('WW'),
            m_day = m_month + '-' + moment(transactionDate, 'x').format('ddd'),
            m_weekday = m_week + '-' + moment(transactionDate, 'x').format('E');
        groupInfo.setProperties({
          timestamp: moment().valueOf(),
          date_received: transactionDate,
          user: user,
          date: depositDate,
          m_year: m_year,
          m_month: m_month,
          m_week: m_week,
          m_day: m_day,
          m_weekday: m_weekday,
          municipality: municipal_id,
          year: fiscalYear,
          municipalFund: currentFund
        });

        for (var i = transactionArray.length - 1; i >= 0; i--) {
          transactionArray[i].setProperties({
            timestamp: moment().valueOf(),
            date: transactionDate,
            m_year: m_year,
            m_month: m_month,
            m_week: m_week,
            m_day: m_day,
            m_weekday: m_weekday,
            municipality: municipal_id,
            year: fiscalYear,
            municipalFund: currentFund
          });
          transactionArray[i].save().catch(function (error) {
            console.log(error);
          });
          var currentTotal = groupInfo.get('total') || 0;
          var newTotal = Number(currentTotal) + Number(transactionArray[i].get('amount').replace(/,/g, ''));
          console.log(newTotal);
          groupInfo.set('total', newTotal);
          groupInfo.get('transactions').addObject(transactionArray[i]);
          currentFund.get('municipalTransactions').addObject(transactionArray[i]);
          console.log(transactionArray[i].toJSON().asset);
          if (transactionArray[i].toJSON().asset) {
            var asset = transactionArray[i].get('asset');
            asset.get('transactions').addObject(transactionArray[i]);
            asset.save();
          }
        }
        groupInfo.save().then(function (savedGroup) {
          console.log('saved group info');
          currentFund.get('municipalDeposits').addObject(savedGroup);
          currentFund.save();
          return savedGroup;
        }).then(function (savedGroup) {
          // _this.createNewTransaction(TransactionCredit, TransactionDebit, TransactionRecord.get('entryDate'));
          var message = 'Successfully Saved Transaction';
          //because the deposit and invoice screen break down the deposit and invoice when transitioned we have
          //to create new model when one is saved
          //1. check which model is being saved, deposit or invoice
          //2. create the model and clear transactionArray
          if (savedGroup.constructor.modelName === 'municipal-deposit') {
            var newDeposit = store.createRecord('municipal-deposit', {
              timestamp: moment().valueOf(),
              date: moment(savedGroup.get('date'), 'x').format('MM/DD/YYYY'),
              date_received: moment(savedGroup.get('date_received'), 'x').format('MM/DD/YYYY')
            });
            _this.set('deposit', newDeposit);
            _this.get('transactionArray').clear();
            // _this.createNewTransaction();
          } else {
            alert('appears not a deposit or invoice was just entered');
          }
          return globalMessage.actionNotification(message, 'success');
        }).catch(function (error) {
          console.log(error);
        });
      },
      validateCheckNumber: function validateCheckNumber() {
        var checkNumber = this.get('connectedUser.fiscalYear.currentCheckNumber'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            addLeadingZeros = '000000' + checkNumber,
            validNumber = addLeadingZeros.slice(-5);
        this.set('connectedUser.fiscalYear.currentCheckNumber', validNumber);
        fiscalYear.save();
      },
      notifyChange: function notifyChange() {
        console.log('transactionArray changed in parent');
        this.notifyPropertyChange('transactionArray');
      },
      closeModal: function closeModal() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalString', null);
      }
    }
  });
});