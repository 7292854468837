define('fund-figure-app/components/dashboard/payroll/employee-timecard-week-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		daysArray: Ember.computed('timeCard.municipalTimeCardDays.[]', {
			get: function get() {
				return this.get('timeCard.municipalTimeCardDays');
			}
		}).property('timeCard.municipalTimeCardDays'),
		actions: {
			notifyDayAdded: function notifyDayAdded() {
				console.log('update reduce array');
				this.notifyPropertyChange('timeCard.municipalTimeCardDays');
			}
		}
	});
});