define('fund-figure-app/components/d3/visual-sankey-flow', ['exports', 'd3-sankey', 'd3-selection', 'd3-scale', 'd3-force', 'd3-drag', 'd3-timer', 'd3-fetch', 'd3-array', 'd3-format', 'd3-scale-chromatic'], function (exports, _d3Sankey, _d3Selection, _d3Scale, _d3Force, _d3Drag, _d3Timer, _d3Fetch, _d3Array, _d3Format, _d3ScaleChromatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['sankey-flow'],
    width: null,
    height: null,
    colorScheme: null,

    drawChart: Ember.computed('data', function () {
      var data = this.get('data'),
          colorScheme = this.get('colorScheme'),
          _this = this;
      if (data === undefined || data === null) {
        console.log('no data');
        return 0;
      }
      console.log('data', data);
      var margin = { top: 1, right: 1, bottom: 6, left: 1 },
          width = 710 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom,
          formatNumber = (0, _d3Format.format)(',.0f'),
          numberFormat = function numberFormat(d) {
        return formatNumber(d) + ' TWh';
      },
          color = (0, _d3Scale.scaleOrdinal)(colorScheme || _d3ScaleChromatic.schemeDark2),
          svg = (0, _d3Selection.select)(this.element);

      svg.select('g').remove();
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);

      var sankey = (0, _d3Sankey.sankey)().nodeWidth(15).nodePadding(10).extent([[1, 1], [width - 1, height - 5]]).size([width, height]);

      var path = (0, _d3Sankey.sankeyLinkHorizontal)();

      var freqCounter = 1;

      sankey.nodeId(function (d) {
        return d.name;
      });

      sankey.nodes(data.nodes).links(data.links);

      var _sankey = sankey(data),
          nodes = _sankey.nodes,
          links = _sankey.links;

      var g = svg.append('g'),
          link = g.attr("fill", "none").attr("stroke-opacity", 0.5).selectAll('.link'),
          rect = g.selectAll('rect'),
          text = rect.style("font", "10px sans-serif").selectAll('.text');

      restart();

      function restart() {
        console.log('restart');

        link = link.data(links);
        link.exit().remove();
        link = link.enter().append("path").attr("class", "link").attr("d", path).attr("stroke", function (d) {
          return d.uid;
        }).attr("stroke-width", function (d) {
          return Math.max(1, d.width);
        });

        link.append('title').text(function (d) {
          return d.source.name + ' \u2192 ' + d.target.name + '\n' + numberFormat(d.value);
        });

        rect = rect.data(nodes);
        rect.exit().remove();
        rect = rect.enter().append('g').attr('transform', function (d) {
          return 'translate(' + d.x0 + ',' + d.y0 + ')';
        }).attr("class", 'node');
        // .call(drag()
        //   .on("start", dragstarted)
        //   .on("drag", dragged)
        //   .on("end", dragended));

        rect.append("rect")
        // .attr("x", d => d.x0)
        // .attr("y", d => d.y0)
        // .attr("height", d => d.y1 - d.y0)
        // .attr("width", d => d.x1 - d.x0)
        // .attr("fill", d => color(d.name))
        // .merge(rect)
        .attr('height', function (d) {
          return d.y1 - d.y0;
        }).attr('width', sankey.nodeWidth()).style('fill', function (d) {
          d.color = color(d.name.replace(/ .*/, ''));
          return d.color;
        }).style('stroke', 'none').append('title').text(function (d) {
          return d.name + '\n' + (0, _d3Format.format)(d.value);
        });

        rect.append('text').style('fill', 'black').style('font-size', '12px').attr('x', -6).attr('y', function (d) {
          return (d.y1 - d.y0) / 2;
        }).attr('dy', '.35em').attr('text-anchor', 'end').attr('transform', null)
        // .style({
        //   'pointer-events': 'none',
        //   'text-shadow': '0 1px 0 #fff',
        //   'font-size': '12px'
        // })
        .text(function (d) {
          return d.name;
        }).filter(function (d) {
          return d.x0 < width / 2;
        }).attr('x', 6 + sankey.nodeWidth()).attr('text-anchor', 'start');

        rect.append("title").text(function (d) {
          return d.name + '\n' + (0, _d3Format.format)(d.value);
        });
      }

      function dragmove(d) {
        (0, _d3Selection.select)(this).attr('transform', 'translate(' + d.x0 + ',' + (d.y0 = Math.max(0, Math.min(height - d.y0, _d3Selection.event.y))) + ')');
      }

      function dragstarted(d) {
        // select(this).classed("active", true);
      }

      function dragged(d) {
        (0, _d3Selection.select)(this).attr("transform", "translate(" + d.x0 + "," + (d.y0 = Math.max(0, Math.min(height - d.y0, _d3Selection.event.y))) + ")");
        link.attr("d", path);
        // select(this).attr("x", d.x0 = event.x).attr("y", d.y0 = event.y);
        // link.attr('d', path);
      }

      function dragended(d) {
        // select(this).classed("active", false);
        restart();
      }

      var linkExtent = (0, _d3Array.extent)(links, function (d) {
        return d.value;
      });
      var frequencyScale = (0, _d3Scale.scaleLinear)().domain(linkExtent).range([0.05, 1]);
      var particleSize = (0, _d3Scale.scaleLinear)().domain(linkExtent).range([1, 5]);

      links.forEach(function (link) {
        link.freq = frequencyScale(link.value);
        link.particleSize = 2;
        link.particleColor = (0, _d3Scale.scaleLinear)().domain([0, 1]).range([link.source.color, link.target.color]);
      });

      var t = (0, _d3Timer.timer)(tick, 1000);
      var particles = [];

      function tick(elapsed, time) {
        particles = particles.filter(function (d) {
          return d.current < d.path.getTotalLength();
        });

        (0, _d3Selection.selectAll)('path.link').each(function (d) {
          // if (d.freq < 1) {
          for (var x = 0; x < 2; x += 1) {
            var offset = (Math.random() - 0.5) * (d.dy - 4);
            if (Math.random() < d.freq) {
              var length = this.getTotalLength();
              particles.push({ link: d, time: elapsed, offset: offset, path: this, length: length, animateTime: length, speed: 0.5 + Math.random() });
            }
          }
          // }
          /*    
              else {
                for (var x = 0; x<d.freq; x++) {
                  var offset = (Math.random() - .5) * d.dy;
                  particles.push({link: d, time: elapsed, offset: offset, path: this})
                }
              } 
          */
        });

        // particleEdgeCanvasPath(elapsed);
      }

      function particleEdgeCanvasPath(elapsed) {
        var context = (0, _d3Selection.select)(this.element).node().getContext('2d');

        context.clearRect(0, 0, 1000, 1000);

        context.fillStyle = 'gray';
        context.lineWidth = '1px';
        for (var x in particles) {
          if ({}.hasOwnProperty.call(particles, x)) {
            var currentTime = elapsed - particles[x].time;
            // var currentPercent = currentTime / 1000 * particles[x].path.getTotalLength();
            particles[x].current = currentTime * 0.15 * particles[x].speed;
            var currentPos = particles[x].path.getPointAtLength(particles[x].current);
            context.beginPath();
            context.fillStyle = particles[x].link.particleColor(0);
            context.arc(currentPos.x, currentPos.y + particles[x].offset, particles[x].link.particleSize, 0, 2 * Math.PI);
            context.fill();
          }
        }
      }
    })
  });
});