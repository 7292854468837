define('fund-figure-app/routes/dashboard/settings/manage-users/add-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // model: null,
    // beforeModel(/* transition */) {
    //    this.transitionTo('dashboard.settings.manage-users.add-user.user-information'); // Implicitly aborts the on-going transition.
    //  }
    model: function model() {
      return this.store.createRecord('municipalUser', {
        is_new: true
      });
    },
    redirect: function redirect(model, transition) {
      this.transitionTo('dashboard.settings.manage-users.add-user.user-information', model);
    }
  });
});