define('fund-figure-app/models/municipal-bank-account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    accountId: _emberData.default.attr('string'),
    hide: _emberData.default.attr('boolean', { defaultValue: false }), //Municipality can hide account so it doesnt show in list
    bankAccountTransactions: _emberData.default.hasMany('bank-account-transaction', { inverse: null }),
    balances: _emberData.default.attr(),
    mask: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    official_name: _emberData.default.attr('string'),
    subtype: _emberData.default.attr('string'),
    type: _emberData.default.attr('string')
  });
});