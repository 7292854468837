define('fund-figure-app/components/dashboard/invoices-manifests/manifests/manifests-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    manifestArray: [],
    setManifestArray: Ember.computed('connectedUser.currentFund.municipalManifests.[]', {
      get: function get() {
        var _this = this;
        return this.get('connectedUser.currentFund.municipalManifests').then(function (manifests) {
          _this.set('manifestArray', manifests.toArray());
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),
    sortedManifests: Ember.computed.sort('manifestArray.[]', function (a, b) {
      if (a.get('date_paid') > b.get('date_paid')) {
        return 1;
      } else {
        return 0;
      }
    })
  });
});