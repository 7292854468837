define('fund-figure-app/models/municipal-time-card', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    employee: _emberData.default.belongsTo('vendor'),
    municipalPayPeriod: _emberData.default.belongsTo('municipal-pay-period'),
    municipalTimeCardDays: _emberData.default.hasMany('municipal-time-card-day'),
    totalHoursWorked: _emberData.default.attr('number'),
    municipalAccount: _emberData.default.belongsTo('municipalAccount'),
    employeeFilter: Ember.computed('employee.employee_firstName', 'employee.employee_lastName', {
      get: function get() {
        return this.get('employee.employee_firstName') + ' ' + this.get('employee.employee_lastName');
      }
    })
  });
});