define('fund-figure-app/components/d3/capital-improvement-plan/cip-schedule', ['exports', 'd3-transition', 'd3-array', 'd3-selection', 'd3-scale', 'd3-format', 'd3-time-format', 'd3-scale-chromatic', 'd3-axis'], function (exports, _d3Transition, _d3Array, _d3Selection, _d3Scale, _d3Format, _d3TimeFormat, _d3ScaleChromatic, _d3Axis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['cip-schedule'],
    colorScheme: ['#f0c430', '#3998d8', '#f19b2b', '#31ad64', '#e54e43', '#35495d', '#12ADDD', '#f79a59', '#38ca73'],

    willDestroyElement: function willDestroyElement() {
      this.$().remove();
      this.destroy();
      this._super.apply(this, arguments);
    },


    parentWidth: Ember.computed('didInsertElement', {
      get: function get() {
        console.log(document.querySelector('#' + Ember.get(this, 'elementId')));
        return this.get('width') || this.$().parent().width() > 400 ? this.$().parent().width() : 400 || 400;
      }
    }),
    parentHeight: Ember.computed('didInsertElement', {
      get: function get() {
        return this.get('height') || this.get('data').length * 100 > 400 ? this.get('data').length * 100 : this.$().parent().height() > 400 ? this.$().parent().height() : 400 || 400;
      }
    }),
    drawChart: Ember.computed('data', 'parentWidth', function () {
      var data = this.get('data'),
          hover = Ember.get(this, 'hover'),
          _this = this;
      if (data === undefined || data === null || data.length === 0) {
        console.log('no data');
        return 0;
      }
      console.log(data);
      var cfg = null;
      if (!hover) {
        cfg = {
          marginChart: {
            top: 20,
            right: 40,
            bottom: 20,
            left: 120,
            ticks: 8
          }
        };
      } else {
        cfg = {
          marginChart: {
            top: 20,
            right: 320,
            bottom: 20,
            left: 120,
            ticks: 10
          }
        };
      };
      // parentWidth = this.get('width') || this.$().parent().width() > 400 ? this.$().parent().width() : 400 || 400,
      // parentHeight = this.get('height') || this.$().parent().height() > 400 ? this.$().parent().height() : 400 || 400,
      var width = Ember.get(this, 'parentWidth') - cfg.marginChart.left - cfg.marginChart.right,
          height = Ember.get(this, 'parentHeight') - cfg.marginChart.top - cfg.marginChart.bottom,
          colour = (0, _d3Scale.scaleOrdinal)(Ember.get(this, 'colorScheme') || _d3ScaleChromatic.schemeDark2),
          // colour scheme
      variable = this.get('variable'),
          // value in data that will dictate proportions on chart
      category = this.get('category'),
          // compare data by
      padAngle = this.get('padAngle') || 0.015,
          // effectively dictates the gap between slices
      floatFormat = (0, _d3Format.format)('.4r'),
          cornerRadius = this.get('cornerRadius') || 3,
          // sets how rounded the corners are on each slice
      formatGen = (0, _d3Format.format)(this.get('formatString') || ''),
          svg = (0, _d3Selection.select)(this.element);
      // set(this, 'width', get(this, 'parentWidth'));
      svg.select('g').remove();
      svg.attr('width', Ember.get(this, 'parentWidth')).attr('height', Ember.get(this, 'parentHeight'));

      // tool tip
      var div = (0, _d3Selection.select)("body").append("div").attr("class", "d3-tooltip").style("opacity", 0);

      var categoriesNames = Object.values(data),
          timeRange = [(0, _d3Array.min)(Object.values(data), function (d) {
        return d.acquired;
      }), (0, _d3Array.max)(Object.values(data), function (d) {
        return d.replacementYear;
      })],
          keys = Object.keys(data);

      var y = (0, _d3Scale.scaleBand)().domain(keys).rangeRound([height - cfg.marginChart.bottom, cfg.marginChart.top]).paddingInner(.1).paddingOuter(.1).align(.5),


      //samples
      x = (0, _d3Scale.scaleTime)().range([0, width]).domain(timeRange),


      //transition
      t = (0, _d3Transition.transition)().duration(750);

      colour.domain(data);

      var g = svg.append("g"),
          background = g.append("g").attr("class", "background").append('rect').attr('width', width).attr('height', height).attr('opacity', 0),
          //.on('mousedown', mousedownCanvas),      
      legend = g.selectAll('.legend'),
          group = g.selectAll('.assets'),
          tooltip = g.append('text').style("text-anchor", "middle").classed('svg-tooltip text', true).attr("transform", "translate(" + (width / 2 + cfg.marginChart.left) + " ," + (height + cfg.marginChart.top + 15) + ")");

      // bars = group.append('g').attr('class', 'bar');

      var rectTransform = function rectTransform(d, i) {
        return "translate(" + (+x(d.acquired) + cfg.marginChart.left) + "," + y(i) + ")";
      };

      restart();

      function restart() {
        group = group.data(data);
        group.exit().remove();
        group = group.enter().append('g').on("mouseover", enterAsset).on("mouseout", exitAsset);
        group.append("rect").attr("rx", 5).attr("ry", 5)
        // .attr('class', 'pointer')                
        .attr("width", 0).attr("y", 0).attr("transform", function (d, i) {
          return rectTransform(d, i);
        }).transition(t).attr("height", function (d) {
          return y.bandwidth();
        }).attr("width", function (d) {
          return Math.max(1, x(d.replacementYear) - x(d.acquired));
        }).attr("fill", function (d, i) {
          return colour(i);
        });

        // background
        //   .on('click', backgroundClicked);

        //Add current year line
        g.append('path').attr('class', 'stat-line vertical shadowed').style("stroke-dasharray", "3, 3").attr("d", 'M ' + (x(new Date()) + cfg.marginChart.left) + ' ' + cfg.marginChart.top + ' V ' + (height - cfg.marginChart.bottom));
        //Add current year label
        g.append('text').attr('x', x(new Date()) + cfg.marginChart.left).attr('y', cfg.marginChart.top - 5).attr('font-size', 'smaller').attr("text-anchor", "middle").attr('fill', 'red').text('Today');

        // Add the x Axis
        g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + (height - cfg.marginChart.bottom) + ')').call((0, _d3Axis.axisBottom)(x).tickFormat((0, _d3TimeFormat.timeFormat)("%b %Y")).ticks(cfg.marginChart.ticks)).selectAll('.tick').attr('class', 'text').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

        // text label for the x axis
        g.append("text").attr("transform", "translate(" + (width + cfg.marginChart.left) + " ," + (height + cfg.marginChart.top + 35) + ")").attr('class', 'text').style("text-anchor", "middle").text("Months");

        // Add the y axis
        g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', 0)').call((0, _d3Axis.axisLeft)(y).tickFormat(function (d) {
          return data[d].name;
        }).tickSize(0).tickPadding(8)).selectAll('.tick').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

        // Add legend  
        if (!hover) {
          //no legend needed
        } else {
          legend = legend.data(data);
          legend.exit().remove();
          legend = legend.enter().append('g').attr("transform", 'translate(' + (width + cfg.marginChart.right + cfg.marginChart.left) + ', 0)').attr('class', 'legend text').attr("text-anchor", "end").attr("font-family", "sans-serif").attr("font-size", 10);

          legend.append("rect").attr("x", -19).attr("width", 19).attr("height", 19).attr("transform", function (d, i) {
            return 'translate(0, ' + i * 20 + ')';
          }).attr("fill", function (d, i) {
            return colour(i);
          });

          legend.append("text").attr("x", -24).attr("y", 9.5).attr("dy", "0.35em").attr("transform", function (d, i) {
            return 'translate(0, ' + i * 20 + ')';
          }).text(function (d) {
            return d.depModel;
          });
        }
      };

      // function groupClicked(d) {
      //   data = {};
      //   get(_this, 'groupClicked')(d).then(res => {
      //     restart();
      //   });
      // };

      function enterAsset(d, i) {
        if (!hover) {
          console.log('hover is false', hover);
          return;
        }
        console.log('hover enabled enter');
        (0, _d3Selection.select)(this).classed('hover', true);
        // let parent = select(this).node().parentNode;
        (0, _d3Selection.select)(this).classed('shadowed', true);
        // Add Acquired info
        (0, _d3Selection.select)(this).append('text').text('Acquired ' + moment(d.acquired).format('YYYY')).attr("text-anchor", "start").attr('class', 'text-shadow assetText').attr('x', x(d.acquired) + cfg.marginChart.left + 5).attr('y', y(i) + y.bandwidth() / 2 - 5);
        (0, _d3Selection.select)(this).append('text').text('Acquired ' + moment(d.acquired).format('YYYY')).attr("text-anchor", "start").attr('class', 'text-top assetText').attr("fill", "white").attr('x', x(d.acquired) + cfg.marginChart.left + 5).attr('y', y(i) + y.bandwidth() / 2 - 5);
        // Add Replacement info      
        (0, _d3Selection.select)(this).append('text').text('Replace ' + moment(d.replacementYear).format('YYYY')).attr("text-anchor", "end").attr('class', 'text-shadow assetText').attr('x', x(d.replacementYear) + cfg.marginChart.left - 5).attr('y', y(i) + y.bandwidth() / 2 + 10);
        (0, _d3Selection.select)(this).append('text').text('Replace ' + moment(d.replacementYear).format('YYYY')).attr("text-anchor", "end").attr('class', 'text-top assetText').attr("fill", "white").attr('x', x(d.replacementYear) + cfg.marginChart.left - 5).attr('y', y(i) + y.bandwidth() / 2 + 10);
        // Add tooltip info
        var toolTip = (0, _d3Selection.select)(this).append('g').attr('class', 'asset-tooltip');
        toolTip.append('rect').attr("rx", 5).attr("ry", 5).attr('fill', 'white').attr('class', 'shadowed').attr('stroke', "black").attr('x', width + cfg.marginChart.left + 5).attr('y', height / 2 + 5).attr('width', cfg.marginChart.right - 10).attr('height', height / 2 - cfg.marginChart.bottom - 10);
        toolTip.append('text').attr('fill', 'black').attr('x', width + cfg.marginChart.left + 10).attr('y', height / 2 + 25).text('Estimate life span: ' + d.lifeSpan);
        toolTip.append('text').attr('fill', 'black').attr('x', width + cfg.marginChart.left + 10).attr('y', height / 2 + 45).text('Years until replacement: ' + moment(d.replacementYear).diff(moment(), 'years'));
        toolTip.append('text').attr('fill', 'black').attr('x', width + cfg.marginChart.left + 10).attr('y', height / 2 + 65).text('Original cost: ' + (0, _d3Format.format)("($,.2f")(d.cost));
        toolTip.append('text').attr('fill', 'black').attr('x', width + cfg.marginChart.left + 10).attr('y', height / 2 + 85).text('Save per year: ' + (0, _d3Format.format)("($,.2f")(d.cip_cost_per_year));
        toolTip.append('text').attr('fill', 'black').attr('x', width + cfg.marginChart.left + 10).attr('y', height / 2 + 105).text('Future estimated cost: ' + (0, _d3Format.format)("($,.2f")(d.cip_total_save));
      };

      function exitAsset(d) {
        if (!hover) {
          return;
        }
        console.log('hover enabled exit');
        // Use D3 to select element, change color back to normal   
        console.log((0, _d3Selection.select)(this));
        if ((0, _d3Selection.select)(this).classed('assetText')) {
          console.log('hovering over text do nothing');
          return;
        }
        // let parent = select(this).node().parentNode;
        (0, _d3Selection.select)(this).classed('hover', false);
        (0, _d3Selection.select)(this).classed('shadowed', false);
        div.transition().duration(500).style("opacity", 0);
        //remove dotted line      
        (0, _d3Selection.selectAll)('.assetText').remove();
        (0, _d3Selection.selectAll)('.asset-tooltip').remove();
      }

      // function backgroundClicked(d) {
      //   data = {};
      //   get(_this, 'backgroundClicked')(d).then(res => {
      //     if (res) {
      //       restart();
      //     }
      //   })
      // }
    }).property('data')
  });
});