define('fund-figure-app/models/municipal-time-card-day', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    municipalTimeCard: _emberData.default.belongsTo('municipal-time-card'),
    employee: _emberData.default.belongsTo('vendor'),
    municipalPayPeriod: _emberData.default.belongsTo('municipal-pay-period'),
    hoursWorked: _emberData.default.attr('number'),
    timeOff: _emberData.default.belongsTo('municipal-pay-time-off-options'),
    date: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string')
  });
});