define('fund-figure-app/helpers/file-size', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileSize = fileSize;
  function fileSize(params) {
    if (!params[0] || typeof params[0] == 'undefined') {
      return params;
    }
    var size = params[0],
        length = String(size).length,
        factor = Math.pow(10, params[1] || 1);
    if (length <= 6) {
      console.log(size, length, '<6');
      return Math.round(size / 1000 * factor) / factor + ' KB';
    } else if (length > 6 && length <= 9) {
      console.log(size, length, '<=9');
      return Math.round(size / 1000000 * factor) / factor + ' MB';
    } else if (length > 9 && length <= 12) {
      console.log(size, length, '<=12');
      return Math.round(size / 1000000000 * factor) / factor + ' GB';
    }
  }

  exports.default = Ember.Helper.helper(fileSize);
});