define('fund-figure-app/components/dashboard/vendorss/invoices/create-manifest', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    classNames: ["container"],
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    invoices: [],
    invoiceManifest: [],
    detailedView: true,

    willDestroyElement: function willDestroyElement() {
      this.get('invoices').clear();
      this.get('invoiceManifest').clear();
    },

    manifestArray: Ember.computed('invoiceManifest', {
      get: function get(key) {
        console.log('manifestChanged');
        return this.get('invoiceManifest');
      }
    }),

    outstandingInvoices: Ember.computed('connectedUser.fiscalYear.municipalTransactions.[]', function () {
      var _this = this,
          year = this.get('connectedUser.fiscalYear').toJSON({ includeId: true }),
          query = '' + year.id,
          queryInvoices = this.get('store').query('municipalInvoice', {
        orderBy: 'year',
        equalTo: query
      }).then(function (res) {
        return res.toArray().filter(function (invoice) {
          if (!invoice.toJSON().manifest) {
            return true;
          } else {
            return false;
          }
        });
      }).then(function (array) {
        _this.set('invoices', array);
      }).catch(function (err) {
        console.log(err);
        return;
      });
      return Promise.all([queryInvoices]).then(function (res) {
        return res[0];
      });
    }),
    actions: {
      toggleManifestModal: function toggleManifestModal() {
        this.toggleProperty('viewManifest');
      },
      toggleManifestView: function toggleManifestView() {
        this.toggleProperty('detailedView');
      },
      notifyChange: function notifyChange() {
        this.notifyPropertyChange('invoiceManifest');
        this.notifyPropertyChange('invoices');
      },
      print: function print() {
        window.print();
      },
      createManifest: function createManifest(invoices, fiscalYear) {
        if (!invoices) {
          return;
        }
        var store = this.get('store');
        var router = this.get('router');
        fiscalYear.get('municipality').then(function (municipalModel) {
          var _store$createRecord;

          var length = invoices.length,
              municipal_id = municipalModel.get('id'),
              year = fiscalYear.toJSON().year,
              m_year = municipal_id + '-' + moment().format("YYYY"),
              m_month = m_year + '-' + moment().format('MM'),
              m_week = m_year + '-' + moment().format('WW'),
              m_day = m_month + '-' + moment().format('ddd'),
              m_weekday = m_week + '-' + moment().format('E'),
              index = 0,
              manifestTotal = 0,
              manifest = store.createRecord('manifest', (_store$createRecord = {
            municipality: municipal_id,
            date_created: moment().valueOf(),
            m_year: m_year,
            m_month: m_month,
            m_week: m_week,
            m_day: m_day,
            m_weekday: m_weekday
          }, _defineProperty(_store$createRecord, 'municipality', municipalModel), _defineProperty(_store$createRecord, 'municipalYear', fiscalYear), _store$createRecord));
          console.log(manifest);
          invoices.forEach(function (invoice) {
            index++;
            manifestTotal += invoice.get('total');
            invoice.set('manifest', manifest);
            manifest.get('municipalInvoices').addObject(invoice);
            manifest.set('total', manifestTotal);
            invoice.save().then(function (invoice_saved) {
              if (index === length) {
                manifest.save().then(function (manifestSaved) {
                  fiscalYear.get('municipalManifests').addObject(manifestSaved);
                  fiscalYear.save();
                  return router.transitionTo('dashboard.vendors.manifests');
                }).catch(function (err) {
                  console.log(err);
                });
              }
            }).catch(function (err) {
              console.log(err);
            });
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});