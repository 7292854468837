define('fund-figure-app/models/reconciled-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    date_created: _emberData.default.attr('number'),
    date_finalized: _emberData.default.attr('number'),
    municipalBank: _emberData.default.belongsTo('municipal-bank', { async: true, inverse: null }),
    municipalBankAccount: _emberData.default.belongsTo('municipal-bank-account', { async: true, inverse: null }),
    municipalChecks: _emberData.default.hasMany('municipal-check'),
    municipalDeposits: _emberData.default.hasMany('municipal-deposit'),
    bankAccountTransactions: _emberData.default.hasMany('bank-account-transaction'),
    statement_date_begin: _emberData.default.attr('number'),
    statement_date_end: _emberData.default.attr('number'),
    statement_balance_begin: _emberData.default.attr('number'),
    statement_balance_end: _emberData.default.attr('number'),
    reconciliation_balance: _emberData.default.attr('number'),
    documents: _emberData.default.hasMany('file', { async: true, inverse: null }),
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    year: _emberData.default.belongsTo('municipal-year', { async: true, inverse: null }),
    createdBy: _emberData.default.belongsTo('municipal-user', { async: true }),
    finalizedBy: _emberData.default.belongsTo('municipal-user', { async: true })
    // editHistory: DS.hasMany('editHistory')
  });
});