define('fund-figure-app/models/feedback-chat-room', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    members: _emberData.default.hasMany('municipal-user'),
    messageNotifications: _emberData.default.hasMany('municipal-user'), //when someone messages, all other members are added so they get notifications
    feedbackChats: _emberData.default.hasMany('feedback-chat'),
    feedback: _emberData.default.belongsTo('feedback', { async: true, inverse: null }),
    name: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('number'),
    last_chat: _emberData.default.attr('number')
  });
});