define('fund-figure-app/components/helper/sort-array-models-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortByDefinitions: null, //string of attributes to sort by
    sortBy: null,
    sortByObject: null, //object containing definition and prettyDesc of current sort
    reverseSort: false,
    sortedArray: null,

    didInsertElement: function didInsertElement() {
      this.set('sortedArray', this.get('sortArray'));
    },


    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? ':desc' : ':asc';
      var sortBy = this.get('sortBy');
      if (!sortBy) {
        return [];
      }
      console.log(sortBy.split(':'));
      if (sortBy.split(':').length > 1) {
        var multiSorts = [];
        for (var i = 0; i < sortBy.split(':').length; i++) {
          var str = '' + sortBy.split(':')[i] + sortOrder;
          multiSorts.push(str);
        }
        console.log('multiSorts', multiSorts);
        return multiSorts;
      } else {
        console.log('reg sort', ['' + this.get('sortBy') + sortOrder]);
        return ['' + this.get('sortBy') + sortOrder];
      }
    }),
    sortDefinitionsArrayPretty: Ember.computed('sortByDefinitions', {
      get: function get() {
        var sortByDefinitionArray = this.get('sortByDefinitions').split(','),
            newArray = [];
        for (var i = 0; i < sortByDefinitionArray.length; i++) {
          var newObject = {};
          if (sortByDefinitionArray[i].split(':').length > 1) {
            newObject['definition'] = '' + sortByDefinitionArray[i];
            newObject['prettyDesc'] = sortByDefinitionArray[i].split(':')[0].split('_').reverse()[0] + ' then by ' + sortByDefinitionArray[i].split(':')[1].split('_').reverse()[0];
            newArray.push(newObject);
          } else {
            newObject['definition'] = '' + sortByDefinitionArray[i];
            newObject['prettyDesc'] = '' + sortByDefinitionArray[i];
            newArray.push(newObject);
          }
        }
        console.log('newArray', newArray);
        return newArray;
      }
    }),
    sortArray: Ember.computed.sort('arrayToSort', 'sortDefinition'),

    actions: {
      noBubbles: function noBubbles() {},
      clearSort: function clearSort() {
        this.set('sortBy', null);
        this.set('sortByObject', null);
        this.set('sortedArray', this.get('sortArray'));
        this.toggleProperty('isActive');
      },
      updateSortBy: function updateSortBy(object) {
        this.set('sortBy', object.definition);
        this.set('sortByObject', object);
        this.set('sortedArray', this.get('sortArray'));
        this.toggleProperty('isActive');
      }
    }
  });
});