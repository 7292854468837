define('fund-figure-app/components/dashboard/assets/assets-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    assetsArray: [],
    filteredAssets: [],

    setAssetsArray: Ember.computed('connectedUser.fiscalYear.municipalAssets', {
      get: function get() {
        var array = this.get('connectedUser.fiscalYear.municipalAssets');
        this.setProperties({ assetsArray: array.toArray(), filteredAssets: array.toArray() });
        return array;
      }
    }),

    actions: {
      clearFilter: function clearFilter() {
        this.set('filteredAssets', this.get('assetsArray'));
      },
      submitFilter: function submitFilter(filterArray) {
        var _this = this;

        var payments = this.get('assetsArray');
        filterArray.forEach(function (filter) {
          switch (filter.filter) {
            case 'byAmount':
              var amount = Number(filter.amount);
              if (filter.logic == "amountGreater") {
                var filtered = payments.filter(function (payment) {
                  return payment.get('amount') > amount;
                });
                payments = filtered;
              }
              if (filter.logic == "amountLess") {
                var _filtered = payments.filter(function (payment) {
                  return payment.get('amount') < amount;
                });
                payments = _filtered;
              }
              if (filter.logic == "amountEquals") {
                var _filtered2 = payments.filter(function (payment) {
                  return payment.get('amount') === amount;
                });
                payments = _filtered2;
              }
              break;
            case 'byDate':
              var beginDate = Number(filter.beginDate);
              var endDate = Number(filter.endDate);
              if (filter.logic == "dateGreater") {
                var _filtered3 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate;
                });
                payments = _filtered3;
              }
              if (filter.logic == "dateLess") {
                var _filtered4 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') < beginDate;
                });
                payments = _filtered4;
              }
              if (filter.logic == "dateBetween") {
                var _filtered5 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate && payment.get('date') < endDate;
                });
                payments = _filtered5;
              }
              break;
            default:
              break;
          }
          _this.set('filteredAssets', payments);
        });
      }
    }
  });
});