define('fund-figure-app/components/dashboard/vendorss/vendor-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    vendors_DB: null,
    vendors: null,

    buildVendors: Ember.computed('connectedUser.currentAccount.municipality.municipalVendorsDb.vendors.[]', 'connectedUser.currentAccount.municipality.globalVendorsDb.vendors.[]', 'connectedUser.currentAccount.municipality.municipalSetting.vendor_db_personal', 'vendors_DB.vendors', function () {
      var municipality = null,
          _this = this;
      return this.get('connectedUser.currentAccount.municipality').then(function (muni) {
        municipality = muni;
        return muni.get('municipalSetting');
      }).then(function (muni_setting) {
        var isPersonal = muni_setting.get('vendor_db_personal');
        if (isPersonal) {
          return municipality.get('municipalVendorsDb');
        } else {
          return municipality.get('globalVendorsDb');
        }
      }).then(function (vendor_db) {
        _this.set('vendors_DB', vendor_db);
        if (!vendor_db) {
          throw 'No Vendor DB for option stated';
        }
        return vendor_db.get('vendors');
      }).then(function (vendors) {
        _this.set('vendors', vendors);
        return vendors.toArray();
      }).catch(function (err) {
        console.log(err);
      });
    }),

    willDestroyElement: function willDestroyElement() {
      var undoList = this.get('connectedUser.undoObjects'),
          vendors_db = this.get('vendors_DB');
      vendors_db.save();
    },


    actions: {
      deleteVendor: function deleteVendor(vendor) {
        var d = vendor.toJSON(),
            vendors = this.get('vendors'),
            vendors_DB = this.get('vendors_DB'),
            undoList = this.get('connectedUser.undoObjects'),
            description = 'Add ' + d.businessName + ' back to vendor list',
            undoObject = { model: vendor, description: description, modelDeleted: true, secondaryModel: ['{"' + vendors_DB.get('id') + '":"vendors"}'] };
        undoList.addObject(undoObject);
        // vendors_DB.get('vendors').removeObject(vendor);
        vendors.removeObject(vendor);
        vendor.destroyRecord();
      },
      hideVendor: function hideVendor(vendor) {
        var globalMessage = this.get('globalMessaging');
        vendor.toggleProperty('deactivated');
        vendor.save().catch(function (err) {
          globalMessage.actionNotification('Error changing vendor status', 'warning');
        });
      },
      addNewVendor: function addNewVendor(vendorName) {
        var store = this.get('store'),
            _this = this,
            router = this.get('router'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            get_municipality = fiscalYear.get('municipality');

        get_municipality.then(function (municipality) {
          return municipality.get('municipalVendorsDb');
        }).then(function (municipalVendorsDb) {
          var newVendor = store.createRecord('vendor', {
            businessName: vendorName
          });
          municipalVendorsDb.get('vendors').addObject(newVendor);
          municipalVendorsDb.save();
          return newVendor.save();
        }).then(function (vendorSaved) {
          router.transitionTo("dashboard.vendors.edit-vendor", vendorSaved);
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});