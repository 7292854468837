define('fund-figure-app/components/plaid-link', ['exports', 'ember-plaid/components/plaid-link', 'fund-figure-app/config/environment'], function (exports, _plaidLink, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var plaidConfig = _environment.default['ember-plaid'];

  exports.default = _plaidLink.default.extend({
    clientName: plaidConfig.clientName,
    product: plaidConfig.product,
    key: plaidConfig.key,
    env: plaidConfig.env,
    selectAccount: plaidConfig.selectAccount
  });
});