define("fund-figure-app/routes/account-handler/create-with-pin/pin-step-three", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      // this._super();
      return this.get("session").fetch().catch(function () {});
    }
  });
});