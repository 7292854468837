define('fund-figure-app/components/dashboard/billing/invoice-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    activeInvoice: 'Fund Figure',

    actions: {
      activeInvoices: function activeInvoices(activeInvoice) {
        Ember.set(this, 'activeInvoice', activeInvoice);
      }
    }
  });
});