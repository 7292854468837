define('fund-figure-app/components/dashboard/vendorss/invoices/create-manifest/manifest-invoice-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    date_close: Ember.computed('invoice.[]', function () {
      var invoice = this.get('invoice'),
          i_json = invoice.toJSON(),
          dueDate = i_json.i_due,
          now = moment();
      if (dueDate - now < 864000) {
        return true;
      } else {
        return false;
      }
    }),
    click: function click() {
      var invoice = this.get('invoice'),
          invoiceArray = this.get('invoiceManifest'),
          invoices = this.get('invoices');
      if ((invoiceArray !== undefined || invoiceArray.length !== 0) && invoiceArray.includes(invoice)) {
        invoiceArray.removeObject(invoice);
        invoices.addObject(invoice);
        this.get('notifyChange')();
        return;
      } else {
        invoiceArray.addObject(invoice);
        invoices.removeObject(invoice);
        this.get('notifyChange')();
        return;
      }
    },

    actions: {
      expandItems: function expandItems() {
        this.toggleProperty('expandItems');
      }
    }
  });
});