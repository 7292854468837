define('fund-figure-app/components/account-handler/create-step-four', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      addSubAccount: function addSubAccount(muni) {
        var _this = this;

        var user = this.get('connectedUser.currentAccount');

        user.set('is_new', 'account-handler.add-sub-accounts');
        return user.save().then(function () {
          return _this.get('router').transitionTo('account-handler.add-sub-accounts', muni);
        }).catch(function (error) {
          console.log(error);
        });
      },
      askAboutPayee: function askAboutPayee(muni) {
        var _this2 = this;

        var user = this.get('connectedUser.currentAccount');
        console.log('step 4 user', user);
        if (!user) {
          this.get('connectedUser').getFiscalYear(null);
        }
        user.set('is_new', 'account-handler.account-create-step-five/' + muni.get('id'));
        return user.save().then(function () {
          _this2.get('connectedUser').getFiscalYear(null);
          return _this2.get('router').transitionTo('account-handler.account-create-step-five', muni);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});