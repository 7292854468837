define('fund-figure-app/controllers/dashboard', ['exports', 'fund-figure-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    connectedUser: Ember.inject.service(),
    test: Ember.computed('ENV', {
      get: function get() {
        if (_environment.default.environment === 'development') {
          return true;
        }
        return false;
      }
    })
  });
});