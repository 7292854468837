define('fund-figure-app/services/payroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    pay_Periods: null, //list of pay periods for the current year selected
    current_Period: null, //period currently selected, defaults to period of the current day

    init: function init() {
      this._super.apply(this, arguments);
      this.get('setCurrentPeriod');
    },


    // setCurrentPeriod(toIndex) {
    //   let _this = this,
    //     now = moment();
    //   //Check if this is initial set up or someone changing period shown
    //   if (toIndex) {
    //     //Someone changing to new period
    //   } else {
    //     //Initial set up, get current day and set to period that includes the day      
    //     this
    //       .get('connectedUser.fiscalYear.payrollDb')
    //       .then(payrollDb => {
    //         return payrollDb.get('municipalPayPeriods');
    //       })
    //       .then(municipalPayPeriods => {
    //         let payPeriodArray = municipalPayPeriods.toArray(),
    //           numberPeriods = payPeriodArray.length,
    //           startAt = Math.round(numberPeriods / 2); //start testing date from middle of array and work forwards or backwards depending on if date is before or after
    //         for (var i = numberPeriods - 1; i >= 0; i--) {
    //           console.log('testing Period', payPeriodArray[startAt]);
    //           //check if after the first day       
    //           if (now.isSameOrAfter(payPeriodArray[startAt].firstDay, 'day')) {
    //             console.log('date is after first day');
    //             //check if before lastDay
    //             if (now.isSameOrBefore(payPeriodArray[startAt].lastDay, 'day')) {
    //               //today is in the period, set it baby
    //               console.log('currentPeriod', payPeriodArray[startAt])
    //               _this.set('current_Period', payPeriodArray[startAt])
    //               i = -1
    //             } else {
    //               console.log('today is after the period');
    //               //today is after this period add 1 to startAt
    //               startAt++
    //             }
    //           } else {
    //             console.log('today is before start period');
    //             //not after the first day, reduce startAt by 1
    //             startAt--
    //           }
    //         }
    //       })
    //   }
    // },

    setCurrentPeriod: Ember.computed('connectedUser.fiscalYear.payrollDb.@each.municipalPayPeriods', 'connectedUser.fiscalYear.[]', {
      get: function get() {
        if (!this.get('connectedUser.fiscalYear')) {
          return;
        }
        var _this = this,
            now = moment();
        this.get('connectedUser.fiscalYear.payrollDb').then(function (payrollDb) {
          if (!payrollDb) {
            return false;
          }
          return payrollDb.get('municipalPayPeriods');
        }).then(function (municipalPayPeriods) {
          if (!municipalPayPeriods) {
            return false;
          }
          var payPeriodArray = municipalPayPeriods.toArray(),
              numberPeriods = payPeriodArray.length,
              startAt = Math.round(numberPeriods / 2); //start testing date from middle of array and work forwards or backwards depending on if date is before or after
          if (now.isBetween(payPeriodArray[0].get('firstDay'), payPeriodArray.slice(-1)[0].get('lastDay'))) {
            for (var i = numberPeriods - 1; i >= 0; i--) {
              console.log('testing Period', payPeriodArray[startAt]);
              //check if after the first day       
              if (now.isSameOrAfter(payPeriodArray[startAt].get('firstDay'), 'day')) {
                console.log('date is after first day');
                //check if before lastDay
                if (now.isSameOrBefore(payPeriodArray[startAt].get('lastDay'), 'day')) {
                  //today is in the period, set it baby
                  console.log('currentPeriod', payPeriodArray[startAt]);
                  _this.set('current_Period', payPeriodArray[startAt]);
                  i = -1;
                } else {
                  console.log('today is after the period');
                  //today is after this period add 1 to startAt
                  startAt++;
                }
              } else {
                console.log('today is before start period');
                //not after the first day, reduce startAt by 1
                startAt--;
              }
            }
          } else {
            _this.set('current_Period', payPeriodArray[0]);
          }
        });
      },
      set: function set(name, value) {
        var _this = this;
        this.get('connectedUser.fiscalYear.payrollDb').then(function (payrollDb) {
          return payrollDb.get('municipalPayPeriods');
        }).then(function (municipalPayPeriods) {
          var payPeriodArray = municipalPayPeriods.toArray(),
              indexOfCurrentPeriod = payPeriodArray.indexOf(_this.get('current_Period')),
              newIndex = indexOfCurrentPeriod + value;
          if (newIndex < 0 || newIndex >= payPeriodArray.length) {
            return;
          } else {
            return _this.set('current_Period', payPeriodArray[newIndex]);
          }
        });
      }
    }),

    updatePayPeriod: function updatePayPeriod() {
      console.log('should update pay periods');
      this.notifyPropertyChange('payFrequency');
    }
  });
});