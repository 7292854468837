define('fund-figure-app/services/connected-user', ['exports', 'fund-figure-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentAccount: null,
    fundsList: null,
    showFinalizeWarrantMenu: false,
    showFinalizeBudgetMenu: false,
    showReport: false,
    fiscalYear: null,
    fiscalYearsAvailable: null,
    userAccount: null,
    loading: true,
    vendors_DB: null,
    vendors: null,
    employees: null,
    mobile: false,

    // new_responses: computed('currentAccount.responses.[]', {
    //   get() {
    //     return get(this, 'currentAccount.responses');
    //   }
    // }),
    user_messages: Ember.computed('currentAccount.conversations.[]', {
      get: function get() {
        return Ember.get(this, 'currentAccount.conversations');
      }
    }),

    // report info
    dates: false,
    endDate: Ember.computed('fiscalYear', function () {
      var year = Ember.get(this, 'fiscalYear.year'),
          now = moment().year(year).format("MM/DD/YYYY");
      return now;
    }),
    beginDate: Ember.computed('endDate', function () {
      var now = this.get('endDate'),
          begin = moment(now, "MM/DD/YYYY").subtract(30, 'days').format('MM/DD/YYYY');
      return begin;
    }),
    shouldRefresh: Ember.computed('currentAccount.shouldRefresh', {
      get: function get() {
        // console.log('tracking shouldRefresh');
        if (!Ember.get(this, 'currentAccount')) {
          // console.log('currentAccount null');
          return false;
        }
        if (!Ember.get(this, 'currentAccount.shouldRefresh')) {
          // console.log('shouldRefresh is false');
          return false;
        }
        // console.log('shouldRefresh is true');
        return true;
      }
    }),
    account: null,

    // Estimated Amounts
    estimatedRevenue: null,

    test: Ember.computed('ENV', {
      get: function get() {
        if (_environment.default.environment === 'development') {
          return true;
        }
        return false;
      }
    }),

    user: Ember.computed('session', function () {
      var _this = this;
      var store = this.get('store');
      var session = this.get('session');
      var check = session.content.uid || false;
      if (!session.content.uid) {
        return null;
      }
      return store.findRecord('user', session.content.uid).then(function (user) {
        _this.set('userAccount', user);
        _this.getFiscalYear(null);
        return user;
      }).catch(function (error) {});
    }),

    currentFund: Ember.computed('fiscalYear.municipalFunds.firstObject', {
      get: function get() {
        Ember.set(this, 'loading', false);
        return this.get('fiscalYear.municipalFunds.firstObject');
      }
    }),

    setFund: function setFund(fund) {
      this.set('currentFund', fund);
      this.notifyPropertyChange('currentFund');
    },


    // trackMessageResponses: computed('currentAccount.responses.[]', 'user_messages.[]', {
    //   get() {
    //     console.log('tracking messages');
    //     let id = get(this, 'currentAccount.id'),
    //       _this = this,
    //       newArray = [],
    //       store = get(this, 'store');
    //     // console.log('trackMessages: ', id);
    //     return get(this, 'firebaseApp')
    //       .database()
    //       .ref('feedbackChatRooms')
    //       .orderByChild(`messageNotifications/${id}`)
    //       .equalTo(true)
    //       .once('value')
    //       .then(res => {
    //         return res.forEach(mes => {
    //           // console.log(mes.key);
    //           store.find('feedbackChatRoom', mes.key)
    //             .then(res => {
    //               newArray.push(res);
    //               _this.set('new_responses', newArray);
    //             })
    //             .catch(err => {
    //               // console.log(err);
    //             })
    //         })
    //       })
    //       .catch(err => {
    //         // console.log(err);
    //       });
    //   }
    // }).property('notifications'),

    prevFiscalYear: Ember.computed('fiscalYearsAvailable', 'fiscalYear', function () {
      var fiscalYearsAvailable = this.get('fiscalYearsAvailable');
      if (!fiscalYearsAvailable) {
        return false;
      }
      var fiscalYear = this.get('fiscalYear');
      if (!fiscalYear) {
        return false;
      }
      var indexCurrentYear = fiscalYearsAvailable.indexOf(fiscalYear);
      if (indexCurrentYear < 1) {
        return false;
      }
      var previousYearIndex = indexCurrentYear - 1;
      return fiscalYearsAvailable.objectAt(previousYearIndex);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('user');
      this.getFiscalYear(null);
    },
    getFiscalYear: function getFiscalYear(selection) {
      var _this2 = this;

      var store = this.get('store');
      var router = this.get('router');
      var _this = this;
      var user = this.get('user');
      this.set('loading', true);
      if (this.get('user') == null) {
        router.transitionTo('account-handler.account-menu');
        _this.set('loading', false);
        _this.get('setFund');
        return;
      }
      if (selection) {
        // console.log('we have selection');
        _this.set('fiscalYear', selection);
        selection.get('municipalFunds').then(function (fund) {
          _this.set('currentFund', fund.get('firstObject'));
          _this.set('loading', false);
        }).catch(function (err) {
          // console.log(err);
        });
      } else {
        // console.log('no selection');
        return user.then(function (aUser) {
          // console.log(aUser.toJSON());
          if (aUser.toJSON().defaultAccount) {
            // console.log('has defaultAccount');
            return aUser.get('defaultAccount');
          } else {
            aUser.set('defaultAccount', aUser.get('municipalUsers.lastObject'));
            aUser.save();
            return aUser.get('defaultAccount');
          }
        }).then(function (lastMunicipalUser) {
          // let userAccount = this.get('userAccount');
          //  userAccount.set('defaultAccount', lastMunicipalUser);
          //  userAccount.save();
          _this.set('currentAccount', lastMunicipalUser);
          // _this.get('trackMessageResponses');
          Ember.get(_this2, 'new_responses');
          // _this.getMessagesUser(lastMunicipalUser);
          Ember.get(_this2, 'user_messages');
          _this.get('buildVendors');
          _this.get('buildEmployees');
          // console.log('lastmunicipalUser', lastMunicipalUser);
          if (lastMunicipalUser.get('is_new')) {
            var string = lastMunicipalUser.get('is_new');
            var transitionArray = string.split('/');
            // console.log('transitionArray', transitionArray);
            if (transitionArray.length > 0) {
              router.transitionTo(transitionArray[0], transitionArray[1]);
            } else {
              router.transitionTo(transitionArray[0]);
            }
            return lastMunicipalUser.get('municipality');
          } else {
            // router.transitionTo('dashboard.state-of-affairs');
            return lastMunicipalUser.get('municipality');
          }
          // return lastMunicipalUser.get('municipality');        
        }).then(function (userMunic) {
          // console.log('userMunic', userMunic);
          if (!userMunic) {
            return;
          }
          return userMunic.get('municipalYearsDb');
        }).catch(function (err) {
          // console.log('error getting municipalYearsDb', err);
        }).then(function (yearsDb) {
          _this.set('fiscalYearsAvailable', yearsDb.get('years'));
          return yearsDb.get('years.lastObject') || null;
        }).then(function (mostCurrentYear) {
          if (!mostCurrentYear) {
            return null;
          }
          _this.set('fiscalYear', mostCurrentYear);
          _this.set('fundList', mostCurrentYear.get('municipalFunds'));
          return mostCurrentYear.get('municipalFunds.firstObject');
        })
        // .then(municipalFund => {
        //   console.log('fund', municipalFund);
        //   _this.get('buildVendors');
        //   // _this.set('currentFund', _this.get('fiscalYear.municipalFunds.firstObject'));
        //   return
        // })
        .then(function () {
          _this.set('loading', false);
          return;
        }).catch(function (error) {
          // console.log(error);
        });
      }
    },


    buildEmployees: Ember.computed('currentAccount.municipality.municipalVendorsDb.vendors.[]', 'currentAccount.municipality.globalVendorsDb.vendors.[]', 'currentAccount.municipality.municipalSetting.vendor_db_personal', 'vendors_DB.vendors', function () {
      var _this3 = this;

      var municipality = null,
          _this = this;
      return new Promise(function (resolve, reject) {
        _this3.get('currentAccount.municipality').then(function (muni) {
          municipality = muni;
          return muni.get('municipalVendorsDb');
        }).then(function (employee_db) {
          _this.set('vendors_DB', employee_db);
          if (!employee_db) {
            throw 'No Vendor DB for option stated';
          }
          return employee_db.get('vendors');
        }).then(function (employees) {
          _this.set('employees', employees.toArray().filter(function (a) {
            return a.get('employee') == true;
          }));
          resolve(employees.toArray().filter(function (a) {
            return a.get('employee') == true;
          }));
        }).catch(function (err) {
          console.log('build employees error:', err);
          reject(err);
        });
      });
    }),

    buildVendors: Ember.computed('currentAccount.municipality.municipalVendorsDb.vendors.[]', 'currentAccount.municipality.globalVendorsDb.vendors.[]', 'currentAccount.municipality.municipalSetting.vendor_db_personal', 'vendors_DB.vendors', function () {
      var municipality = null,
          _this = this;
      return this.get('currentAccount.municipality').then(function (muni) {
        municipality = muni;
        return muni.get('municipalSetting');
      }).then(function (muni_setting) {
        var isPersonal = muni_setting.get('vendor_db_personal');
        if (isPersonal) {
          return municipality.get('municipalVendorsDb');
        } else {
          return municipality.get('globalVendorsDb');
        }
      }).then(function (vendor_db) {
        _this.set('vendors_DB', vendor_db);
        if (!vendor_db) {
          throw 'No Vendor DB for option stated';
        }
        return vendor_db.get('vendors');
      }).then(function (vendors) {
        _this.set('vendors', vendors.toArray().filter(function (a) {
          return a.get('employee') != true;
        }));
        return vendors.toArray().filter(function (a) {
          return a.get('employee') != true;
        });
      }).catch(function (err) {
        // console.log(err);
      });
    }),
    preciseRound: function preciseRound(number, precision) {
      var factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    }
  });
});