define('fund-figure-app/helpers/date-between', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dateBetween = dateBetween;
    function dateBetween(params /*, hash*/) {
        var beginDate = moment(params[0]),
            betweenDate = params[1],
            endDate = params[2];

        if (!betweenDate) {
            return beginDate.from(endDate, true);
        } else {
            if (moment(betweenDate).isBetween(beginDate, endDate, null, '[]')) {
                return true;
            } else {
                return false;
            }
        }
    }

    exports.default = Ember.Helper.helper(dateBetween);
});