define('fund-figure-app/components/ember-plaid', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalMessaging: Ember.inject.service(),
    connectedUser: Ember.inject.service(),

    actions: {
      processPlaidToken: function processPlaidToken(token, metadata, account_id) {
        console.log(token, metadata, account_id);
        if (this.get('update')) {
          console.log('only update so dont exchange');
          $.get({
            url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_updatedAccount?bank_account_id=" + JSON.stringify(account_id),
            json: true
          }).done(function (data) {
            console.log(data);
            if (data.error) {
              sendMessage.actionNotification(data.error, false);
            } else if (data.success) {
              sendMessage.actionNotification(data.success, true);
            }
          });
          return;
        }

        var municipality = this.get('connectedUser.fiscalYear.municipality.id');
        var municipalFund = this.get('connectedUser.currentFund.id');
        var sendMessage = this.get('globalMessaging');
        municipality.then(function (munic) {
          var municipal_id = munic.id;
          $.get({
            url: "https://us-central1-aacc-603.cloudfunctions.net/plaidOnRequestAccessToken?token=" + JSON.stringify(token) + '&municipality=' + municipality + '&municipalityFund=' + municipalFund + '&institution=' + JSON.stringify(metadata.institution.name),
            json: true
          }).done(function (data) {
            console.log(data);
            if (data.error) {
              sendMessage.actionNotification(data.error, false);
            } else if (data.success) {
              sendMessage.actionNotification(data.success, true);
            }
          });
        });
      },
      updatePlaid: function updatePlaid(token, metadata, account_id) {
        console.log(token, metadata, account_id);
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_updatedAccount?bank_account_id=" + JSON.stringify(account_id),
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            sendMessage.actionNotification(data.error, false);
          } else if (data.success) {
            sendMessage.actionNotification(data.success, true);
          }
        });
      },
      getAccounts: function getAccounts() {
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_addWebHookManuelly",
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            self.setProperties({ errorMessage: data.error });
          } else if (data.success) {
            sendMessage.actionNotification(data.success);
          } else {}
          console.log(data);
        });
      }
    }
  });
});