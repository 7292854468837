define('fund-figure-app/components/dashboard/index-display/main-window', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service(),
		connectedUser: Ember.inject.service()
	});
});