define('fund-figure-app/components/dashboard/reconciliation/index-display/report-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    theDifference: null,
    difference: Ember.computed('report.municipalChecks.[]', 'report.municipalDeposits.[]', 'report.statement_balance_end', 'report.statement_balance_begin', {
      get: function get() {
        var _this = this,
            checks = this.get('report.municipalChecks'),
            deposits = this.get('report.municipalDeposits'),
            begin_balance = this.get('report.statement_balance_begin'),
            end_balance = this.get('report.statement_balance_end'),
            checks_total = 0,
            deposits_total = 0,
            checksPromise = checks.then(function (res) {
          if (!res) {
            return 0;
          }
          checks_total = res.reduce(function (total, check, index, checks) {
            return total + check.get('amount');
          }, 0);
        }),
            depositsPromise = deposits.then(function (res) {
          if (!res) {
            return 0;
          }
          deposits_total = res.reduce(function (total, deposit, index, deposits) {
            return total + deposit.get('total');
          }, 0);
        });
        return Promise.all([checksPromise, depositsPromise]).then(function (res) {
          var difference = end_balance - (begin_balance - checks_total + deposits_total);
          console.log(end_balance, begin_balance, checks_total, deposits_total);
          _this.set('theDifference', difference);
          return difference;
        });
      }
    })
  });
});