define('fund-figure-app/components/modals/check/void-reissue-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    actions: {
      cancelVoidCheck: function cancelVoidCheck() {
        Ember.get(this, 'cancelVoidCheck')();
      },
      proceedVoidCheckReissue: function proceedVoidCheckReissue() {
        Ember.get(this, 'proceedVoidCheckReissue')();
      }
    }
  });
});