define('fund-figure-app/components/dashboard/payroll/payroll-index-page', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    keyManager: Ember.inject.service(),
    employeesSorted: [],
    currentEmployee: null,
    currentPayroll: null,
    inputIndex: 0,
    payrollIndex: 0,
    payrollData: [],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var nextEmployeeBind = Ember.get(this, 'keyManager').addMacro({
        callback: Ember.run.bind(this, this.nextEmployee),
        executionKey: 'Tab',
        priority: 10,
        keyEvent: 'keydown'
      });
      var nextInputBind = Ember.get(this, 'keyManager').addMacro({
        callback: Ember.run.bind(this, this.nextInput),
        executionKey: 'enter',
        priority: 10,
        keyEvent: 'keydown'
      });
      Ember.set(this, 'nextEmployeeBind', nextEmployeeBind);
      Ember.set(this, 'nextInputBind', nextInputBind);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var nextEmployeeBind = Ember.get(this, 'nextEmployeeBind');
      Ember.get(this, 'keyManager').removeMacro(nextEmployeeBind);
      var nextInputBind = Ember.get(this, 'nextInputBind');
      Ember.get(this, 'keyManager').removeMacro(nextInputBind);
    },
    nextEmployee: function nextEmployee(event) {
      if (event) {
        event.preventDefault();
      }
      var employee = Ember.get(this, 'currentEmployee') || 0,
          employeeArray = Ember.get(this, 'sortedArray');
      if (employee == 0) {
        Ember.set(this, 'currentEmployee', employeeArray[0]);
        this.checkPayroll(employeeArray[0]);
      } else {
        var employeeIndex = employeeArray.indexOf(employee);
        console.log(employeeIndex, employeeArray.length);
        if (employeeIndex + 1 == employeeArray.length) {
          Ember.set(this, 'currentPayroll', null);
          Ember.set(this, 'currentEmployee', 0);
          return;
        }
        Ember.set(this, 'currentEmployee', employeeArray[employeeIndex + 1]);
        this.checkPayroll(employeeArray[employeeIndex + 1]);
      }
      Ember.set(this, 'inputIndex', 0);
      // $('.employeeInput').first().focus();
    },
    checkPayroll: function checkPayroll(employee) {
      var _this = this;

      var appError = Ember.get(this, 'appError');
      if (employee.toJSON().employee_payroll_infos.length) {
        if (Ember.get(this, 'store').peekRecord('employee-payroll-info', employee.get('employee_payroll_infos.firstObject.id'))) {
          Ember.set(this, 'currentPayroll', Ember.get(this, 'store').peekRecord('employee-payroll-info', employee.get('employee_payroll_infos.firstObject.id')));
        } else {
          Ember.get(this, 'store').findRecord('employee-payroll-info', employee.get('employee_payroll_infos.firstObject.id')).then(function (payroll) {
            Ember.set(_this, 'currentPayroll', payroll);
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error finding payroll model in payroll route for employee';
            appError.logError(err, specialMessage);
          });
        }
      } else {
        console.log('supposedly not payroll data', employee.toJSON());
        this.addPayroll(employee);
      }
      $('.employeeInput').first().focus();
    },
    addPayroll: function addPayroll(employee, reimbursement) {
      var municipality = Ember.get(this, 'connectedUser.currentAccount.municipality'),
          defaultAccount = employee.get('default_accounts.firstObject'),
          payrollModel = Ember.get(this, 'store').createRecord('employeePayrollInfo', {
        employee: employee,
        gross_pay: '0',
        municipality: municipality,
        gross_pay_account: defaultAccount
      });
      if (reimbursement) {
        payrollModel.set('reimbursement', true);
        // payrollModel.set('gross_pay_account', null);
      };
      employee.get('employee_payroll_infos').addObject(payrollModel);
      employee.save();
      payrollModel.save();

      Ember.set(this, 'currentPayroll', payrollModel);
      Ember.set(this, 'payrollIndex', employee.get('employee_payroll_infos.length') - 1);
      $('.employeeInput').first().focus();
    },
    deletePayroll: function deletePayroll(payroll, employee) {
      var _this2 = this;

      var payrollIndex = Ember.get(this, 'payrollIndex'),
          appError = Ember.get(this, 'appError'),
          description = 'Add payroll for ' + employee.get('employee_firstName') + ' ' + employee.get('employee_lastName') + ' - ' + payroll.get('gross_pay_account.nickname');
      var createUndo = Ember.get(this, 'undo').createUndo(payroll, true, employee, 'employee_payroll_infos', description);
      createUndo.then(function () {
        employee.get('employee_payroll_infos').removeObject(payroll);
        employee.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving employee after removing payroll info';
          appError.logError(err, specialMessage);
        });
        payroll.destroyRecord();
        if (employee.get('employee_payroll_infos.length') == 0) {
          //payroll deleted was the only one present go to next employee
          _this2.nextEmployee();
        } else if (employee.get('employee_payroll_infos.length') > payrollIndex) {
          //payroll deleted was first of middle of array of payrolls, go to next payroll
          var newPayroll = employee.get('employee_payroll_infos').objectAt(payrollIndex);
          Ember.set(_this2, 'currentPayroll', newPayroll);
        } else {
          //payroll deleted is last one so goto payroll previous
          var _newPayroll = employee.get('employee_payroll_infos').objectAt(payrollIndex - 1);
          Ember.set(_this2, 'currentPayroll', _newPayroll);
        }
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'Error creating undo object';
        appError.logError(err, specialMessage);
      });
    },
    nextInput: function nextInput(event) {
      if (event) {
        event.preventDefault();
      }
      var max = $('.employeeInput').length;
      console.log(max, Ember.get(this, 'inputIndex') + 1);
      if (Ember.get(this, 'inputIndex') + 1 == max) {
        $('#submitPayroll').click();
        this.nextEmployee();
      } else {
        $('.employeeInput').eq(Ember.get(this, 'inputIndex')).focus();
        // this.incrementProperty('inputIndex');
      }
    },


    // grossPayCheck: computed('payroll', {
    //   get() {
    //     console.log('grossPay');
    //     this.toggleProperty('grossPay');
    //   }
    // }).property('payroll'),

    employees: Ember.computed('connectedUser.employees.[]', {
      get: function get() {
        var _this3 = this;

        this.get('employeesSorted').clear();
        this.get('connectedUser.employees').forEach(function (a) {
          a.get('by_department');
          _this3.get('employeesSorted').push(a);
        });
        return this.get('connectedUser.employees');
      }
    }),

    payrolls: Ember.computed('payroll', 'employees.[]', {
      get: function get() {
        var _this4 = this;

        var municipality = Ember.get(this, 'connectedUser.currentAccount').toJSON().municipality;
        Ember.get(this, 'store').query('employeePayrollInfo', {
          orderBy: 'municipality',
          equalTo: '' + municipality
        }).then(function (result) {
          console.log(result);
          Ember.set(_this4, 'payrollData', result);
          return result;
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),

    sortByDesc: Ember.computed('sortBy', {
      get: function get() {
        var sortBy = this.get('sortBy');
        if (sortBy) {
          var sortByArray = sortBy.split(':');
          if (sortByArray.length > 1) {
            return 'Sorted by ' + sortByArray[0] + ' then by ' + sortByArray[1];
          } else {
            return 'Sorted by ' + sortByArray[0];
          }
        } else {
          return null;
        }
      }
    }),

    sortedArray: Ember.computed('employeesSorted', 'sortBy', 'reverseSort', {
      get: function get() {
        console.log('redoing sort of employees');
        var sortBy = Ember.get(this, 'sortBy') || false,
            arrayOfEmployees = Ember.get(this, 'employeesSorted');
        if (!sortBy) {
          console.log('no sort so sortedArray will just be employeesSorted');
          return Ember.get(this, 'connectedUser.employees');
        }

        var sortBySplit = sortBy.split(':'),
            atr1 = false,
            atr1Order = false,
            atr2 = false,
            atr2Order = false;
        if (sortBySplit.length > 1) {
          atr1 = sortBySplit[0];
          atr1Order = this.get(atr1 + '_reverseSort');
          atr2 = sortBySplit[1];
          atr2Order = this.get(atr2 + '_reverseSort');
        } else {
          atr1 = sortBy;
          atr1Order = this.get(atr1 + '_reverseSort');
        }

        //Sort array    
        return arrayOfEmployees.sort(function (a, b) {
          if (!atr1Order) {
            if (a.toJSON()[atr1] > b.toJSON()[atr1]) {
              return 1;
            } else if (a.toJSON()[atr1] < b.toJSON()[atr1]) {
              return -1;
            }
          } else {
            if (a.toJSON()[atr1] < b.toJSON()[atr1]) {
              return 1;
            } else if (a.toJSON()[atr1] > b.toJSON()[atr1]) {
              return -1;
            }
          }
          //check if atr2 exists
          if (atr2) {
            // Else go to the 2nd item
            if (!atr2Order) {
              if (a.toJSON()[atr2] < b.toJSON()[atr2]) {
                return -1;
              } else if (a.toJSON()[atr2] > b.toJSON()[atr2]) {
                return 1;
              } else {
                // nothing to split them
                return 0;
              }
            } else {
              if (a.toJSON()[atr2] > b.toJSON()[atr2]) {
                return -1;
              } else if (a.toJSON()[atr2] < b.toJSON()[atr2]) {
                return 1;
              } else {
                // nothing to split them
                return 0;
              }
            }
          }
        });
      }
    }),

    actions: {
      sortBy: function sortBy(value) {
        var sortBy = Ember.get(this, 'sortBy');
        if (!sortBy) {
          Ember.set(this, 'sortBy', value);
        } else {
          var sortByArray = Ember.get(this, 'sortBy').split(':');
          if (sortByArray.includes(value)) {
            this.toggleProperty('reverseSort');
            this.toggleProperty(value + '_reverseSort');
          } else {
            this.set('sortBy', sortBy + ':' + value);
          }
        }
      },
      showSubmitPayrollStandard: function showSubmitPayrollStandard(modalName) {
        console.log(modalName);
        Ember.set(this, 'connectedUser.modalName', '' + modalName);
        Ember.set(this, 'connectedUser.showModal', true);
      },
      clearSort: function clearSort() {
        Ember.set(this, 'sortBy', false);
        Ember.set(this, 'employeesSorted', Ember.get(this, 'connectedUser.employees'));
        this.notifyPropertyChange('sortBy');
      },
      clearPayroll: function clearPayroll(payroll) {
        payroll.setProperties({ gross_pay: null, federal_tax: null, fica_additional_medicare: null, fica_medicare_tax: null, fica_oasdi_tax: null, retirement: null, health_care: null });
        payroll.save();
      },
      deletePayroll: function deletePayroll(payroll, employee) {
        this.deletePayroll(payroll, employee);
      },
      changePayroll: function changePayroll(value, employee) {
        var payrollIndex = Ember.get(this, 'payrollIndex');
        if (payrollIndex + value < 0) {
          return;
        }
        if (payrollIndex + value == employee.get('employee_payroll_infos.length')) {
          return;
        }
        Ember.set(this, 'payrollIndex', payrollIndex + value);
        var newPayroll = employee.get('employee_payroll_infos').objectAt(payrollIndex + value);
        Ember.set(this, 'currentPayroll', newPayroll);
      },
      addPayroll: function addPayroll(employee) {
        this.addPayroll(employee, false);
      },

      /**
       * create payroll model with reimbursement boolean
       * @param {modelClass} employee Vendor model with employee set to true
       */
      addReimbursement: function addReimbursement(employee) {
        this.addPayroll(employee, true);
      },

      /**
       * when user uses mouse to select employee
       * @param  {modelClass} employee model representing employee clicked
       * @return {NA}          No return
       */
      selectEmployee: function selectEmployee(employee) {
        Ember.set(this, 'currentEmployee', employee);
        this.checkPayroll(employee);
        Ember.set(this, 'inputIndex', 0);
        Ember.set(this, 'payrollIndex', 0);
        $('.employeeInput').first().focus();
      },

      /**
       * whenever someone clicks input detects if its a tab or enter and sends to function
       * @param  {string} string value of input
       * @param  {class} event  event from click action
       * @return {NA}        no return value
       */
      keyDetection: function keyDetection(string, event) {
        this.notifyPropertyChange('payroll');
        if (event.key == "Tab") {
          this.nextEmployee(event);
        } else if (event.key == "Enter") {
          this.nextInput(event);
        }
      },

      /**
       * whenever somene clicks input or focuses updates tab input index
       * @param  {string} string number of input to set inputIndex to
       * @param  {string} event  current value of input
       * @return {NA}        no return value
       */
      updateInputIndex: function updateInputIndex(string, event) {
        console.log(string, event);
        if (string) {
          Ember.set(this, 'inputIndex', Number(string) + 1);
        }
      },
      updateGrossPayAccount: function updateGrossPayAccount(selected, item) {
        Ember.set(this, 'currentPayroll.gross_pay_account', selected);
        Ember.get(this, 'currentPayroll').save();
      },
      getPayrollTotal: function getPayrollTotal() {
        this.notifyPropertyChange('payroll');
      },
      savePayroll: function savePayroll(payroll, employee) {
        var employeeName = employee.get('employee_firstName') + ' ' + employee.get('employee_lastName');
        Ember.get(this, 'globalMessaging').actionNotification('payroll of ' + (0, _formatMoney.default)(payroll.get('gross_pay')) + ' saved for ' + employeeName, 'success');
        payroll.save();
        employee.save();
        // set(this, 'currentPayroll.hasDirtyAttributes', false);
      }
    }
  });
});