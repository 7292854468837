define('fund-figure-app/components/modals/view/file-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    scale: 1,
    pdfDoc: null,
    canvas: document.getElementById('fileViewCanvas'),
    pdfPages: null,
    pdfPage: null,
    pageRendering: false,
    pageNumPending: null,
    click: function click() {
      Ember.set(this, 'connectedUser.fileToView', null);
      Ember.set(this, 'connectedUser.modalName', null);
      Ember.set(this, 'connectedUser.showModal', false);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      Ember.set(this, 'pageRendering', true);
      var documentURL = Ember.get(_this, 'connectedUser.fileToView.fileDownload');
      console.log(documentURL);
      var loadingTask = pdfjsLib.getDocument({ url: documentURL });
      pdfjsLib.GlobalWorkerOptions.workerSrc = '../../build/webpack/pdf.worker.bundle.js';
      loadingTask.promise.then(function (pdfDoc_) {
        Ember.set(_this, 'pdfDoc', pdfDoc_);
        Ember.set(_this, 'pdfPages', pdfDoc_.numPages);
        return pdfDoc_.getPage(1);
      }).then(function (page) {
        console.log('page', page);
        Ember.set(_this, 'pdfPage', 1);
        var viewport = page.getViewport(Ember.get(_this, 'scale')),
            canvas = document.getElementById('fileViewCanvas'),
            context = canvas.getContext('2d');
        console.log(viewport);
        canvas.height = viewport.height || viewport.viewBox[3];
        canvas.width = viewport.width || viewport.viewBox[2];
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);

        // Wait for rendering to finish
        return renderTask.promise.then(function () {
          Ember.set(_this, 'pageRendering', false);
          if (Ember.get(_this, 'pageNumPending') !== null) {
            console.log(Ember.get(_this, 'pageNumPending'));
            // New page rendering is pending
            _this.renderPage(Ember.get(_this, 'pageNumPending'));
            Ember.set(_this, 'pageNumPending', null);
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    renderPage: function renderPage(num) {
      var _this = this;
      console.log('rendering page');
      Ember.set(this, 'pageRendering', true);
      // Using promise to fetch the page
      Ember.get(this, 'pdfDoc').getPage(num).then(function (page) {
        var viewport = page.getViewport(Ember.get(_this, 'scale')),
            canvas = document.getElementById('fileViewCanvas'),
            context = canvas.getContext('2d');
        console.log(viewport);
        canvas.height = viewport.height || viewport.viewBox[3];
        canvas.width = viewport.width || viewport.viewBox[2]; /* viewport.width is also NaN */

        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);

        // Wait for rendering to finish
        return renderTask.promise.then(function () {
          Ember.set(_this, 'pageRendering', false);
          if (Ember.get(_this, 'pageNumPending') !== null) {
            console.log(Ember.get(_this, 'pageNumPending'));
            // New page rendering is pending
            _this.renderPage(Ember.get(_this, 'pageNumPending'));
            Ember.set(_this, 'pageNumPending', null);
          }
        });
      });
    },
    queueRenderPage: function queueRenderPage(num) {
      if (Ember.get(this, 'pageRendering')) {
        Ember.set(this, 'pageNumPending', num);
      } else {
        this.renderPage(num);
      }
    },

    actions: {
      nextPage: function nextPage() {
        if (Ember.get(this, 'pdfPage') >= Ember.get(this, 'pdfPages')) {
          return;
        }
        this.incrementProperty('pdfPage');
        this.queueRenderPage(Ember.get(this, 'pdfPage'));
      },
      previousPage: function previousPage() {
        if (Ember.get(this, 'pdfPage') <= 1) {
          return;
        }
        this.decrementProperty('pdfPage');
        this.queueRenderPage(Ember.get(this, 'pdfPage'));
      },
      increaseScale: function increaseScale() {
        if (Ember.get(this, 'scale') > 10) {
          return;
        }
        this.incrementProperty('scale', .25);
        this.queueRenderPage(Ember.get(this, 'pdfPage'));
      },
      decreaseScale: function decreaseScale() {
        if (Ember.get(this, 'scale') > 10) {
          return;
        }
        this.decrementProperty('scale', .25);
        this.queueRenderPage(Ember.get(this, 'pdfPage'));
      }
    }
  });
});