define('fund-figure-app/components/dashboard-support/manage-funds/manage-chart-of-accounts/chart-of-account/index-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    commonAccounts: Ember.computed('', {
      get: function get() {
        return this.get('store').query('accountNumber', {
          orderBy: 'setupSearch',
          equalTo: '-L4Tcd3B2MDRvYb-fjNc::-dfghhdfgh'
        });
      }
    }),
    actions: {
      //Copy everything about account and add to chartofaccounts for fund and create a parent to watch in accountNumbers
      copyAccount: function copyAccount(account) {
        var store = this.get('store'),
            model = this.get('model'),
            state = model.toJSON().state,
            j = account.toJSON(),
            newAccountNumber = store.createRecord('accountNumber', {
          accountNumber: j.accountNumber,
          friendlyName: j.friendlyName,
          stateName: j.stateName,
          appropriation: j.appropriation,
          description: j.description,
          source: j.source,
          classification: j.classification,
          transactDebit: j.transactDebit,
          rev: j.rev,
          subRev: j.subRev,
          state: j.state
        });

        newAccountNumber.set('setupSearch', state + '::' + model.get('id'));
        newAccountNumber.save().then(function (savedAccountNumber) {
          model.get('accountNumbers').addObject(savedAccountNumber);
          model.save().catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      },

      //remove account associated with chartofaccount
      removeAccount: function removeAccount(account) {
        var chartOfAccounts = this.get('model');
        chartOfAccounts.get('accountNumbers').removeObject(account);
        chartOfAccounts.save().then(function () {
          account.destroyRecord().catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});