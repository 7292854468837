define('fund-figure-app/routes/dashboard-support/manage-warrant-articles/state-warrant-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').query('warrantArticleTemplate', {
        orderBy: 'state',
        equalTo: params.state_id
      });
    }
  });
});