define('fund-figure-app/components/dashboard/transactions/new-transaction-transfer', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    transfer: null,

    didInsertElement: function didInsertElement() {
      this.createNewTransfer();
    },
    createNewTransfer: function createNewTransfer(transferTo, transferFrom, entryDate) {
      var store = this.get('store');
      var newTransfer = store.createRecord('transaction', {
        transfer: true,
        transferFrom: transferFrom,
        entryDate: entryDate
      });
      this.set('transfer', newTransfer);
    },


    actions: {
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        // model.save();
        // this.notifyPropertyChange('budgetTotal');
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },
      submit: function submit(transferTo, transferFrom, transferRecord) {
        var globalMessage = this.get('globalMessaging');
        var _this = this;
        var store = this.get('store');
        var fiscalYear = this.get('connectedUser.fiscalYear');
        var municipal_id = this.get('connectedUser.currentAccount').toJSON().municipality;
        var m_year = municipal_id + '-' + moment(transferRecord.get('entryDate')).format("YYYY");
        var m_month = m_year + '-' + moment(transferRecord.get('entryDate')).format('MM');
        var m_week = m_year + '-' + moment(transferRecord.get('entryDate')).format('WW');
        var m_day = m_month + '-' + moment(transferRecord.get('entryDate')).format('ddd');
        var m_weekday = m_week + '-' + moment(transferRecord.get('entryDate')).format('E');
        transferRecord.setProperties({
          transfer: true,
          m_year: m_year,
          m_month: m_month,
          m_week: m_week,
          m_day: m_day,
          m_weekday: m_weekday
        });

        var savedRecord = transferRecord.save();

        var saveRecordTo = savedRecord.then(function () {
          return store.findRecord('municipal-account', transferTo.get('id'));
          throw 'issue finding t_to';
        }).then(function (result) {
          result.get('transactions').addObject(transferRecord);
          console.log(result);
          return result.save();
          throw 'issue saving t_to';
        }).catch(function (error) {
          console.log(error);
        });

        var saveRecordFrom = saveRecordTo.then(function () {
          return store.findRecord('municipal-account', transferFrom.get('id'));
          throw 'error finding transferFrom Record';
        }).then(function (result) {
          result.get('transactions').addObject(transferRecord);
          return result.save();
          throw 'issue saving t_from';
        }).catch(function (error) {
          console.log(error);
        });

        var saveYear = saveRecordFrom.then(function () {
          fiscalYear.get('municipalTransactions').addObject(transferRecord);
          return fiscalYear.save();
        }).then(function () {
          _this.createNewTransfer(transferTo, transferFrom, transferRecord.get('entryDate'));
          var message = 'Successfully Saved Transfer';
          return globalMessage.actionNotification(message, true);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});