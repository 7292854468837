define('fund-figure-app/models/municipal-invoice', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    date_received: _emberData.default.attr('number'), //invoice received date
    date: _emberData.default.attr('number'), //date on invoice
    date_due: _emberData.default.attr('number'), //date invoice is due
    transactions: _emberData.default.hasMany('transaction'),
    documents: _emberData.default.hasMany('file', { async: true, inverse: null }),
    manifest: _emberData.default.belongsTo('manifest'), //connecting to manifest object invoice was paid on
    total: _emberData.default.attr('number'),
    invoice_number: _emberData.default.attr('string'),
    municipalCheck: _emberData.default.belongsTo('municipal-check'),
    voidChecks: _emberData.default.hasMany('municipal-check'), //if check to pay this invoice was voided, would show up here
    vendor: _emberData.default.belongsTo('vendor'),
    timestamp: _emberData.default.attr('number'),
    i_paid: _emberData.default.attr('number'), //date invoice was paid
    pay_check: _emberData.default.attr('boolean'), //true if this is a paycheck for employee
    net_pay: _emberData.default.attr('number'), //amount to pay out on employee check
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
    municipality: _emberData.default.belongsTo('municipal-db', { inverse: null }),
    year: _emberData.default.belongsTo('municipal-year', { inverse: null }),
    municipalFund: _emberData.default.belongsTo('municipal-fund', { inverse: null }),
    user: _emberData.default.belongsTo('municipal-user', { async: true, inverse: null }), //who made the entry
    date_string: Ember.computed('date_received', 'date_due', {
      get: function get() {
        var string = moment(this.get('date_received')).format('MMM') + moment(this.get('date_due')).format('MMM');
        return string;
      }
    }),
    searchField: Ember.computed('vendor.businessName', 'vendor.employee_firstName', 'vendor.employee_lastName', 'vendor.businessCategory', 'vendor.contact_firstName', 'vendor.contact_lastName', 'vendor.email', 'vendor.phone', {
      get: function get() {
        return Ember.get(this, 'vendor.businessName') + ' ' + Ember.get(this, 'vendor.employee_firstName') + ' ' + Ember.get(this, 'vendor.employee_lastName') + ' ' + Ember.get(this, 'vendor.businessCategory') + ' ' + Ember.get(this, 'vendor.contact_firstName') + ' ' + Ember.get(this, 'vendor.contact_lastName') + ' ' + Ember.get(this, 'vendor.email') + ' ' + Ember.get(this, 'vendor.phone');
      }
    }),
    status: Ember.computed('manifest', 'municipalCheck', 'municipalCheck.reconciled', 'municipalCheck.void', {
      get: function get() {
        var manifest = Ember.get(this, 'manifest') || false;
        if (manifest) {
          // set(this, 'status', 'pending');
          return 'pending';
        } else {
          // set(this, 'status', 'processing');
          return 'processing';
        }
      }
    })
  });
});