define('fund-figure-app/components/dashboard/vendorss/invoices/create-manifest/manifest-linked-invoices/general-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		classNames: ['page-break-no', 'general-info'],
		classNameBindings: ['hasInvoices'],
		hasInvoices: Ember.computed('vendorInvoices', {
			get: function get(key) {
				if (this.get('vendorInvoices').length > 0) {
					return true;
				} else {
					return false;
				}
			}
		}),
		vendorInvoices: Ember.computed('invoiceManifest', {
			get: function get(key) {
				var _this = this;

				if (!this.get('invoiceManifest')) {
					return false;
				}
				return this.get('invoiceManifest').filter(function (a) {
					return a.toJSON().vendor === _this.get('vendor.id');
				});
			}
		})
		// checkNumber:computed('connectedUser.fiscalYear.currentCheckNumber', {
		// 	get(key){
		// 		let elementID = document.getElementById(this.elementId),
		// 		beginCheckNumber = this.get('connectedUser.fiscalYear.currentCheckNumber');			
		// 		return ('00000' + (Number(beginCheckNumber) + Number($('.has-invoices').index(elementID)-1))).slice(-5);
		// 	}
		// })
	});
});