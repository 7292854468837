define('fund-figure-app/components/dashboard/settings/manage-users/edit-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      save: function save(model, avatar_id) {
        var router = this.get('router'),
            store = this.get('store');
        if (avatar_id) {
          store.find('avatar', avatar_id).then(function (avatar) {
            model.save().then(function () {
              avatar.save().then(function () {
                router.transitionTo('dashboard.settings.manage-users');
              }).catch(function (err) {
                alert(err);
              });
            }).catch(function (err) {
              alert(err);
            });
          }).catch(function (err) {
            alert(err);
          });
        } else {
          model.save().then(function () {
            router.transitionTo('dashboard.settings.manage-users');
          }).catch(function (err) {
            alert(err);
          });
        }
      },
      toggleDeactivate: function toggleDeactivate(model) {
        model.toggleProperty('deactivated');
      }
    }
  });
});