define('fund-figure-app/routes/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      this.transitionTo('dashboard.state-of-affairs');
    }
  });
});