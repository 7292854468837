define('fund-figure-app/components/dashboard/transactions/ledger-transaction-invoice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    invoiceItems: [],

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },

    actions: {
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            model = this.get('invoice');
        this.get('documentManager').saveDocument(fileInfo, 'invoices', true).then(function (res) {
          model.get('documents').addObject(res);
          return model.save();
        }).then(function (fileModel) {
          var logDesc = '' + fileModel.get('name'),
              desc = 'Added file ' + file.get('name') + ' to storage',
              undoObject = { model: fileModel, desc: desc, changes: null, logDesc: logDesc };
          bigBrother.logChange(undoObject);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while saving document';
          appError.logError(err, specialMessage);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('invoice'),
            undo = this.get('undo'),
            bigBrother = this.get('bigBrother'),
            municipalFund = this.get('connectedUser.currentFund'),
            appError = this.get('appError'),
            deleteDocument = this.get('documentManager'),
            _this = this;
        deleteDocument.removeDocument(file).then(function (res) {
          file.destroyRecord();
          model.get('documents').removeObject(file);
          model.save().then(function () {
            var logDesc = '' + file.get('name'),
                desc = 'deletion of file ' + file.get('name') + ' from storage',
                undoObject = { model: file, desc: desc, changes: null, logDesc: logDesc };
            undo.undo(undoObject);
            bigBrother.logChange(undoObject);
            console.log('file deleted from fund list');
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving the invoice after removing document';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error removing the document either from storage or from database';
          appError.logError(err, specialMessage);
        });
      },
      saveFileChanges: function saveFileChanges(file) {
        var appError = this.get('appError'),
            _this = this;
        file.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving file';
          appError.logError(err, specialMessage);
        });
      }
    }

  });
});