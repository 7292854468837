define('fund-figure-app/components/cards/user-profile-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    cardFlip: false,
    activityArray: [],

    click: function click() {
      this.toggleProperty('cardFlip');
    },


    last_activity: Ember.computed('user.connections', {
      get: function get() {
        var user = this.get('user.id'),
            _this = this,
            promise = this.get('store').query('connectionHistory', {
          orderBy: 'account',
          limitToLast: 5,
          equalTo: user
        }).then(function (res) {
          return res;
        }).catch(function (err) {
          console.log(err);
        });
        return Promise.all([promise]).then(function (res) {
          if (res[0].toArray().length) {
            console.log(res[0]);
            _this.set('activityArray', res[0].toArray());
            return res[0].toArray();
          } else {
            return;
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),

    actions: {
      sendEmailForm: function sendEmailForm() {}
    }

  });
});