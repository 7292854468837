define('fund-figure-app/components/dashboard/transactions/new-transaction-expenditure', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    assetInfo: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    transaction: null,

    actions: {
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        // model.save();
        // this.notifyPropertyChange('budgetTotal');
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },
      removeFile: function removeFile(index) {
        var fileInfo = this.get('fileInfo');
        fileInfo.removeAt(index);
      },
      paymentMethod: function paymentMethod(paymentString) {
        var transaction = this.get('transaction');
        console.log(paymentString);
        console.log(transaction.get('paymentMethod'));
        if (paymentString === transaction.get('paymentMethod')) {
          console.log('reset');
          transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
          return;
        }
        transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
        transaction.set('paymentMethod', paymentString);
        switch (paymentString) {
          case "cash":
            transaction.set('cash', true);
            break;
            return;
          case "check":
            transaction.set('check', true);
            break;
            return;
          default:
            transaction.set('card', true);
            break;
            return;
        }
      }
    }
  });
});