define('fund-figure-app/components/budget/edit-budgets/account-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    classNameBindings: ['isActive'],
    router: Ember.inject.service(),
    isActive: false,
    parentAccountsList: null,
    parentAccounts: Ember.computed('fundInfo.allAccounts.[]', function () {
      var p_accounts = Ember.get(this, 'fundInfo.allAccounts').filter(function (account) {
        if (account.toJSON().parentAccount !== null) {
          return false;
        } else {
          return true;
        }
      });
      Ember.set(this, 'parentAccountsList', p_accounts);
      return p_accounts;
    }),
    actions: {
      formatAsCurrency: function formatAsCurrency(attr, value, evt) {
        if (value < 1) {
          Ember.get(this, 'account').set(attr, null);
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        Ember.get(this, 'account').set(attr, formated);
        Ember.get(this, 'account').save();
        return;
      },
      activateAccount: function activateAccount(account) {
        var router = Ember.get(this, 'router');
        var fiscalYear = account.get('municipalYear');
        account.set('activated', true);
        var _this = this;
        account.get('stateAccountNumber').then(function (stateAccount) {
          return stateAccount.get('classification');
        }).then(function (classification) {
          if (classification === "Expense") {
            var subAccount = Ember.get(_this, 'store').createRecord('municipal-account', {
              parentAccount: account,
              stateAccountNumber: account.get('stateAccountNumber'),
              activated: true,
              nickname: 'General Expenses',
              accountNumber: '000',
              municipalYear: account.get('municipalYear'),
              municipality: account.get('municipality')
            });

            account.get('subAccounts').addObject(subAccount);
            account.save().then(function (savedAccount) {
              return subAccount.save();
            }).then(function () {
              _this.notifyPropertyChange(account);
              router.transitionTo('municipal-handler.municipal-setup-step-one.edit-account', account);
            }).catch(function (error) {
              console.log(error);
            });
          } else {
            account.save();
          }
        });
        // this.
      }
    }
  });
});