define('fund-figure-app/components/account-number-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    edit: false,
    options: {},
    municipalCustodians: Ember.computed('store', function () {
      return this.get('store').findAll('municipal-custodian');
    }),
    doubleClick: function doubleClick() {
      var account = this.get('account');
      var messageService = this.get('globalMessaging');
      var edit = this.get('edit');
      var _this = this;
      if (edit) {
        account.save().then(function (savedAccount) {
          var message = 'Account ' + savedAccount.get('accountNumber') + ' was saved';
          messageService.actionNotification(message, true);
          _this.toggleProperty('edit');
        }).catch(function (error) {
          messageService.actionNotification(error, false);
        });
      } else {
        this.toggleProperty('edit');
      }
    }
  });
});