define('fund-figure-app/services/fund-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    undo: Ember.inject.service(),
    totalEstimatedRevenue: null,
    totalAppropriations: null,
    totalExpenditures: null,
    totalTransactions: null,
    totalRevenueCollected: null,
    allAccounts: null,
    revenue: null,
    appropriations: null,
    generalFund: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.get(this, 'getAccounts');
    },


    getAccounts: Ember.computed('connectedUser.currentFund', 'connectedUser.currentFund.municipalAccounts.@each.budget', 'connectedUser.currentFund.municipalAccounts.@each.balance', {
      get: function get() {
        // console.log('getting accounts');      
        Ember.set(this, 'revenue', null);
        Ember.set(this, 'appropriations', null);
        Ember.set(this, 'general', null);
        var _this = this,
            revenue = Array(),
            appropriations = Array(),

        // expenditures = Array(),
        generalFund = Array(),
            currentFund = this.get('connectedUser.currentFund');
        if (!currentFund) {
          // console.log(currentFund);
          // console.log('fund');
          return;
        }
        return currentFund.get('municipalAccounts').then(function (muni_accounts) {
          // console.log('municipal_accounts', muni_accounts, muni_accounts.get('length'));
          if (muni_accounts.get('length') == 0) {
            // console.log('accounts zero');
            return _this.setProperties({
              revenue: null,
              appropriations: null,
              // expenditures: expenditures,
              generalFund: null
            });
          }
          var index = 0,
              length = muni_accounts.get('length') || 1;
          _this.set('allAccounts', muni_accounts.toArray());
          muni_accounts.forEach(function (account) {
            var classification = account.get('classification');
            switch (classification) {
              case 'Revenue':
                revenue.addObject(account);
                ++index;
                break;
              case 'Expense':
                appropriations.addObject(account);
                ++index;
                break;
              case 'Cash':
                generalFund.addObject(account);
                ++index;
                break;
              default:
                ++index;
                break;
            }
            if (index == length) {
              // console.log('hit the limit');
              //Set Appropriations Info
              _this.setProperties({
                revenue: revenue,
                appropriations: appropriations,
                // expenditures: expenditures,
                generalFund: generalFund
              });
            }
          });
        });
      }
    }),

    getAppropriations: Ember.computed('appropriations.[]', function () {
      var _this2 = this;

      var appropriations = this.get('appropriations'),
          acct_payable = Array();
      if (!appropriations) {
        // console.log('no appropriations');
        return this.set('totalAppropriations', 0);
      }
      var amount = Promise.resolve(appropriations.reduce(function (total, r_account) {
        // if (!r_account.get('budget')) {
        //   return total
        // }
        var account = r_account.toJSON();

        var ID = account.stateName;
        if (ID === 'Accounts Payable') {
          // console.log('found acct payable');
          acct_payable.addObject(r_account);
          return total;
        }
        if (account.budget && account.parentAccount == null) {
          return total + account.budget;
        }
        return total;
      }, 0));
      return amount.then(function (res) {
        // console.log('finished aprop accounts:' + res);
        _this2.setProperties({ totalAppropriations: res, accountsPayable: acct_payable });
        return res;
      });
    }),

    estimatedRevenue: Ember.computed('revenue.[]', function () {
      var _this3 = this;

      var revenueAccounts = this.get('revenue');
      if (!revenueAccounts) {
        // console.log('no revenue estimated');
        return this.set('totalEstimatedRevenue', 0);
      }

      var amount = Promise.resolve(revenueAccounts.reduce(function (total, r_account) {
        if (!r_account.get('budget')) {
          return total;
        }
        var account = r_account.toJSON();
        if (account.budget && account.parentAccount == null) {
          return total + account.budget;
        }
        return total;
      }, 0));
      return amount.then(function (res) {
        // console.log('finished rev accounts:' + res);
        _this3.set('totalEstimatedRevenue', res);
        return res;
      });
    }),

    municipalTransactions: Ember.computed('connectedUser.currentFund.municipalTransactions.[]', {
      get: function get() {
        return Ember.get(this, 'connectedUser.currentFund.municipalTransactions');
      }
    }),

    municipalArticles: Ember.computed('connectedUser.currentFund.municipalArticles.[]', {
      get: function get() {
        return Ember.get(this, 'connectedUser.currentFund.municipalArticles');
      }
    }),

    getGeneralFund: Ember.computed('generalFund.[]', function () {
      var generalFund = this.get('generalFund');
      if (!generalFund) {
        return this.set('totalGeneralFund', 0);
      }
      // let filteredFund = generalFund.filterBy('stateAccountNumber', "-L4RYdcf9FLhiOx5roXP");
      // this.set('totalGeneralFund', filteredFund.get('balance'));
      var cashFund = generalFund.filter(function (a) {
        var b = a.toJSON();
        if (b.stateName === "Cash On Hand") {
          return true;
        } else {
          return false;
        }
      });
      if (!cashFund[0]) {
        return this.set('totalGeneralFund', 0);
      }
      return this.set('totalGeneralFund', cashFund[0].get('balance'));
    }),

    // 1080 accountLoc -L4S7gX-OfZoRaMMpevB  = total not collected yet
    // 1013 accountLoc -L4S7ZXWRB9jfv8o2k57  = interest from securities
    // 1110 accountLoc -L4S7n9PZqcYTJBBOzF9  = tax lien payments
    // 1150 accountLoc -L4S81yB6xI3pJXpJ7-8  = accounts receivable

    // 3120 accountLoc -L4QmIhsWS5Pnnb7d-Fu  = land use change taxes
    // 3121 accountLoc -L4QmUdZyZ3GCO1dAVsq  = land use change taxes
    // 3180 accountLoc -L4Qmbo9kQ3GegDkBIYO  = resident tax
    // 3185 accountLoc -L4QmrZ9mbnRZAysIfJ1  = stumpage tax
    // 3186 accountLoc -L4Qn0cV1Pq7x90Ap8Kt  = PILOT tax
    // 3187 accountLoc -L4Qn74iauhK_cQCLIIg  = excavation tax
    // 3189 accountLoc -L4QnDd01Ey9zt0YHG-8  = other tax
    // 3190 accountLoc -L4QnKlJb32zawIBnL6R  = interest
    // 3210 accountLoc -L4QnRWvnEQvg7Tku2jA  = business tax
    // 3220 accountLoc -L4QnWr-jtzrh3oxv0m5  = motor vehicle
    // 3230 accountLoc -L4QnbQS9tLYQSVVDtfh  = building permit 
    // 3290 accountLoc -L4Qnh41Zb9rYXsdbCZ0  = other taxes
    // 3311 accountLoc -L4Qno4BiT5SSR6q-OuO  = urband renewal
    // 3312 accountLoc -L4Qnuwp_MOl05bpaceU  = environmental
    // 3319 accountLoc -L4Qo-Qs9gDRYkkcRwiA  = federal grants
    // 3351 accountLoc -L4Qo8EvABd0AsEAr-B_  = state of nh
    // 3352 accountLoc -L4QoDku4smOUNM_28WJ  = meals tax
    // 3353 accountLoc -L4QoJjTQL2hpmkbLEsl  = highway block grant
    // 3354 accountLoc -L4QoOhzg1J9bIx-N5Z0  = water pollution
    // 3355 accountLoc -L4QoTiRBvyYrKyAhFvx  = house commun
    // 3356 accountLoc -L4Qo_p8Z-UVGO9WCmon  = state fed forest
    // 3357 accountLoc -L4Qof-n0qrvWL1oaj8Y  = flood control
    // 3359 accountLoc -L4QokPqbYdWMJRqcoZP  = there state
    // 3379 accountLoc -L4QpC4cLVuLaxE17ufI  = rev from local gov
    // 3401 accountLoc -L4QpMgN_ZukVOqXS2I2  = income from depart
    // 3402 accountLoc -L4QpQMSKvWQ8ZBvqClK  = wtr supply charges
    // 3403 accountLoc -L4QpTf31-MTOYiswypy  = swr charges
    // 3404 accountLoc -L4QpWsZx5sjUe4yb1Dp  = garbage refuse charges
    // 3405 accountLoc -L4QpZniX4umJNLb9E2I  = elec charges
    // 3406 accountLoc -L4Qpbvv5LbZXXNTFfAA  = airport fees
    // 3409 accountLoc -L4Qpf1QcG1BGMPa21Cj  = ther chargers
    // 3500 accountLoc -L4QplfBrpPKSuWzKkDj  = rev spec assmnts
    // 3501 accountLoc -L4QppD93qu2gZ4-ANl_  = municipal sale
    // 3502 accountLoc -L4QpsM5gj3Vbu5wOofP  = interest or investments
    // 3503 accountLoc -L4QpvLegqy5rWI00OxH  = rents
    // 3504 accountLoc -L4QpzKzvdO15jK4YwLN  = fines
    // 3506 accountLoc  -L4Qq2_jmInsrS3N9IAJ = insurance returns
    // 3508 accountLoc -L4Qq6YtwcVfMawk6T4F  = pub/prv contributions
    // 3509 accountLoc -L4Qq9YWxlJBZD3Lgmx_  = misc sources
    // 3911 accountLoc -L4QqJAW1ivOMid0bgJw  = trans from gen fund
    // 3912 accountLoc -L4QqMQjH3VQlBQ9etwu  = trans from spec rev
    // 3913 accountLoc -L4QqPNgOH7rERe4E8-R  = trans from cap proj
    // 3914 accountLoc -L4QqSV5baLXUVfSuP0R  = trans from prop funds
    // 3915 accountLoc -L4QqVPwtCLsag3i3Rwt  = trans from cap res
    // 3916 accountLoc -L4QqYnZiyjo09ZqAE96  = trans from fud funds
    // 3917 accountLoc -L4Qqb5FQngUmGtInsEC  = trans from cons funds
    // 3934 accountLoc -L4QqhoOpEc7Geqb9a-W  = long term notes proceed
    // 3935 accountLoc -L4QqlGDr-YK47Cs3g66  = bond proceeds
    // 3939 accountLoc -L4QqoD_dIJUDKQLgsKr  = long term financial

    getRevenueCollected: Ember.computed('allAccounts', function () {
      var accountList = this.get('allAccounts');
      if (!accountList) {
        return;
      }
      var store = this.get('store'),
          collected = accountList.filter(function (a) {
        var b = a.toJSON();
        if (['-L4S7gX-OfZoRaMMpevB', '-L4S7ZXWRB9jfv8o2k57', '-L4S7n9PZqcYTJBBOzF9', '-L4S81yB6xI3pJXpJ7-8', '-L4QmIhsWS5Pnnb7d-Fu', '-L4QmUdZyZ3GCO1dAVsq', '-L4Qmbo9kQ3GegDkBIYO', '-L4QmrZ9mbnRZAysIfJ1', '-L4Qn0cV1Pq7x90Ap8Kt', '-L4Qn74iauhK_cQCLIIg', '-L4QnDd01Ey9zt0YHG-8', '-L4QnKlJb32zawIBnL6R', '-L4QnRWvnEQvg7Tku2jA', '-L4QnWr-jtzrh3oxv0m5', '-L4QnbQS9tLYQSVVDtfh', '-L4Qnh41Zb9rYXsdbCZ0', '-L4Qno4BiT5SSR6q-OuO', '-L4Qnuwp_MOl05bpaceU', '-L4Qo-Qs9gDRYkkcRwiA', '-L4Qo8EvABd0AsEAr-B_', '-L4QoDku4smOUNM_28WJ', '-L4QoJjTQL2hpmkbLEsl', '-L4QoOhzg1J9bIx-N5Z0', '-L4QoTiRBvyYrKyAhFvx', '-L4Qo_p8Z-UVGO9WCmon', '-L4Qof-n0qrvWL1oaj8Y', '-L4QokPqbYdWMJRqcoZP', '-L4QpC4cLVuLaxE17ufI', '-L4QpMgN_ZukVOqXS2I2', '-L4QpQMSKvWQ8ZBvqClK', '-L4QpTf31-MTOYiswypy', '-L4QpWsZx5sjUe4yb1Dp', '-L4QpZniX4umJNLb9E2I', '-L4Qpbvv5LbZXXNTFfAA', '-L4Qpf1QcG1BGMPa21Cj', '-L4QplfBrpPKSuWzKkDj', '-L4QppD93qu2gZ4-ANl_', '-L4QpsM5gj3Vbu5wOofP', '-L4QpvLegqy5rWI00OxH', '-L4QpzKzvdO15jK4YwLN', '-L4Qq2_jmInsrS3N9IAJ', '-L4Qq6YtwcVfMawk6T4F', '-L4Qq9YWxlJBZD3Lgmx_', '-L4QqJAW1ivOMid0bgJw', '-L4QqMQjH3VQlBQ9etwu', '-L4QqPNgOH7rERe4E8-R', '-L4QqSV5baLXUVfSuP0R', '-L4QqVPwtCLsag3i3Rwt', '-L4QqYnZiyjo09ZqAE96', '-L4Qqb5FQngUmGtInsEC', '-L4QqhoOpEc7Geqb9a-W', '-L4QqlGDr-YK47Cs3g66', '-L4QqoD_dIJUDKQLgsKr'].indexOf(b.stateAccountNumber) > -1 && !b.parentAccount) {
          return true;
        } else {
          return false;
        }
      }),
          totalRevenueCollected = collected.reduce(function (total, amount) {
        var json = amount.toJSON(),
            balance = json.balance || 0,
            budget = json.budget || 0;
        // console.log(balance, budget);
        if (json.transactDebit) {
          // if (!json.balance) {
          //   return total = total;
          // }
          return total = total + Number(String(balance).replace(/,/g, '')) || 0;
        } else {
          // if (!json.balance) {
          //   return total = total + (json.budget - (json.balance || 0));
          // }
          return total = total + Number(String(balance).replace(/,/g, '')) || 0;
          // return total = total + (Number(String(budget).replace(/,/g, '')) - Number(String(balance).replace(/,/g, '')) || 0);
        }
      }, 0);
      this.set('totalRevenueCollected', totalRevenueCollected);
      return totalRevenueCollected;
    }),

    getExpenditures: Ember.computed('appropriations.[]', 'totalAppropriations', 'accountsPayable.@each.balance', {
      get: function get(key) {
        var _this4 = this;

        var expenses = this.get('appropriations');
        if (!expenses) {
          // console.log('no expenses');
          return this.set('totalExpenditures', 0);
        }
        var amount = Promise.resolve(expenses.reduce(function (total, r_account) {
          if (!r_account.get('balance') || r_account.get('balance') === 0) {
            return total;
          }
          var account = r_account.toJSON();
          if (account.balance && account.parentAccount == null) {
            return total + Number(account.balance);
          }
          return total;
        }, 0));
        return amount.then(function (res) {
          // console.log('finished exp accounts:' + res);
          if (res === 0) {
            _this4.set('totalExpenditures', res);
            return res;
          }
          Ember.set(_this4, 'totalExpenditures', 0 - res);
          Ember.set(_this4, 'totalExpendituresFinalized', res - Ember.get(_this4, 'totalAppropriations'));
          return res;
        });
      }
    }),

    /**
     * delete fund from database only if fund has no transactions, ie never used
     * @param  {Class} fund [Ember model for municipalFund]
     * @return {Promise}     [return either success of deletion or error reporting why could not delete]
     */
    deleteFund: function deleteFund(fund) {
      var undo = Ember.get(this, 'undo'),
          globalMessage = Ember.get(this, 'globalMessaging');
      return new Promise(function (resolve, reject) {
        if (fund.toJSON().transactions) {
          var f_Message = 'Fund has transactions, should be deactivated and not deleted';
          globalMessage.actionNotification(f_Message, 'warning');
          reject(f_Message);
        }
        fund.get('municipalYear').then(function (municipalYear) {
          undo.createUndo(fund, true, municipalYear, 'municipalYear', 'Undo deletion of fund ' + fund.get('fund_name'), 'deleted fund ' + fund.get('fund_name') + ' with id:' + fund.id).then(function () {
            municipalYear.get('municipalFunds').removeObject(fund);
            municipalYear.save().then(function (res) {
              return fund.destroyRecord();
            }).then(function (res) {
              var s_message = 'Fund deleted';
              globalMessage.actionNotification(s_message, 'success');
              resolve(s_message);
            });
          });
        }).catch(function (err) {
          throw new Error(err);
        });
      });
    },


    /**
     * deactivate fund account so it can no longer be used for transactions, ie municipality has voted to close fund
     * @param  {Class} fund [ember model for municipalFund]
     * @return {Promise}      [either success of deactivating account or error as to why deactivation failed]
     */
    deactivateFund: function deactivateFund(fund) {
      var undo = Ember.get(this, 'undo'),
          globalMessage = Ember.get(this, 'globalMessaging');
      return new Promise(function (resolve, reject) {
        if (fund.toJSON().deactivated !== null) {
          reject('fund already deactivated');
        }
        fund.set('deactivated', moment().valueOf());
        undo.createUndo(fund, false, null, null, 'Undo changes made to ' + fund.get('fund_name') + ' Fund', fund.get('fund_name') + ' Fund with id:' + fund.id).then(function (model) {
          return model.save();
        }).catch(function (err) {
          throw new Error(err);
        }).then(function (savedFundModel) {
          globalMessage.actionNotification('Deactivated ' + savedFundModel.get('fund_name') + ' Fund', 'success');
          resolve('done');
        }).catch(function (err) {
          throw new Error(err);
        });
      });
    },


    /**
     * reactivate a fund account only if the account was deactivated in same year
     * @param  {Class} fund [Ember model for municipalFund]
     * @return {Promise}      [either successful reactivation, or request to create a similar account if years have passed, or reason reactivation failed]
     */
    reactivateFund: function reactivateFund(fund) {
      var _this5 = this;

      var undo = Ember.get(this, 'undo'),
          globalMessage = Ember.get(this, 'globalMessaging');
      return new Promise(function (resolve, reject) {
        var fiscalYears = Ember.get(_this5, 'connectedUser.fiscalYearsAvailable'),
            mostRecentFiscalYear = fiscalYears.get('lastObject');
        console.log(fiscalYears, mostRecentFiscalYear);
        if (moment(fund.get('deactivated')).format('YYYY') === mostRecentFiscalYear.get('year')) {
          fund.set('deactivated', null);
          undo.createUndo(fund, false, null, null, 'Undo changes made to ' + fund.get('fund_name') + ' Fund', fund.get('fund_name') + ' Fund with id:' + fund.id).then(function (model) {
            return model.save();
          }).catch(function (err) {
            throw new Error(err);
          }).then(function (savedFundModel) {
            globalMessage.actionNotification('Reactivated ' + savedFundModel.get('fund_name') + ' Fund', 'success');
            resolve('done');
          }).catch(function (err) {
            throw new Error(err);
          });
        } else {
          reject('fund has been deactivated to long');
        }
      });
    }
  });
});