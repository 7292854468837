define('fund-figure-app/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );
    this.transition(this.fromRoute('account-handler.account-menu'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-one'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-two'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-three'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-four'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-five'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.toRoute('account-handler.account-create-step-six'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.accounts.index'), this.use('toRight'), this.reverse('toLeft'));

    this.transition(this.fromRoute('dashboard.reconciliation'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.reports'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.expenditures'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.transactions'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.receivables'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.vendors.index'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.vendors.invoices'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.vendors.manifests'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.vendors.manifests.manifest'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard.reconciliation'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings.manage-users'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings.manage-users.add-user'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings.manage-users.add-user.user-information'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings.manage-users.add-user.user-role'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.settings.manage-users.add-user.user-user-verify'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.reconciliation.view-report'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.fromRoute('dashboard.reconciliation.new-report'), this.use('toDown'), this.reverse('toUp'));

    ///Dashboard-Support
    this.transition(this.fromRoute('dashboard-support.conversations'), this.use('toRight'), this.reverse('toLeft'));

    this.transition(this.fromRoute('dashboard-support.conversations.index'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard-support.conversations.user-conversations'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard-assets.asset'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('dashboard-assets.asset.index'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.fromRoute('dashboard-assets.asset.statistics'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.fromRoute('dashboard-assets.asset.transactions'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.fromRoute('dashboard-assets.asset.depreciation'), this.use('toDown'), this.reverse('toUp'));
    ////

    this.transition(this.hasClass('toDown'), this.toValue(true), this.use('toDown', { duration: 1000 }), this.reverse('toUp', { duration: 1000 }));
    this.transition(this.hasClass('toLeft'), this.toValue(true), this.use('toLeft', { duration: 1000 }), this.reverse('toRight', { duration: 1000 }));

    this.transition(this.hasClass('fade'), this.toValue(true), this.use('fade', { duration: 300 }), this.reverse('fade', { duration: 300 }));

    this.transition(this.hasClass('toRight'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('toRightLong'), this.toValue(true), this.use('toRight', { duration: 1000 }), this.reverse('toLeft', { duration: 1000 }));

    this.transition(this.hasClass('scale'), this.toValue(true), this.use('scale'), this.reverse('scale'));

    this.transition(this.hasClass('toUp'), this.toValue(true), this.use('toUp', { duration: 1000 }), this.reverse('toDown', { duration: 1000 }));
  };
});