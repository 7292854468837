define('fund-figure-app/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    dueDays: _emberData.default.attr('number'),
    dueDesc: _emberData.default.attr('string'),
    desc: _emberData.default.attr('string'),
    rev: _emberData.default.attr('string'),
    subRev: _emberData.default.attr('string'),
    nameLong: _emberData.default.attr('string'),
    componentName: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean')
  });
});