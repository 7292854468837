define('fund-figure-app/models/feedback-chat', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    message: _emberData.default.attr('string'),
    owner: _emberData.default.belongsTo('user'),
    municipalUser: _emberData.default.belongsTo('municipalUser'),
    timestamp: _emberData.default.attr('number'),
    feedbackChatRoom: _emberData.default.belongsTo('feedback-chat-room', { async: true, inverse: 'feedbackChats' })
  });
});