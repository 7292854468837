define('fund-figure-app/components/fund-select-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    // globalMessaging: service(),
    // firebaseApp: service(),
    // store: service(),
    feedbackDisplay: false,

    actions: {
      toggleFeedbackDisplay: function toggleFeedbackDisplay() {
        this.toggleProperty('feedbackDisplay');
      },
      changeToFund: function changeToFund(fund) {
        this.get('connectedUser').setFund(fund);
        this.toggleProperty('feedbackDisplay');
      }
    }
  });
});