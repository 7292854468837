define('fund-figure-app/routes/dashboard/reports/report', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.findRecord('report', params.report_id);
		}
	});
});