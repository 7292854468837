define('fund-figure-app/models/municipal-year', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    year: _emberData.default.attr('string'),
    closed: _emberData.default.attr('boolean'),
    assetMod: _emberData.default.attr('boolean'), //true if municipality is using asset module
    // currentCheckNumber: DS.attr('string'),
    boardMembers: _emberData.default.hasMany('municipal-board-member'),
    // finalizedBudget: DS.attr('boolean'),
    // taxWarrants: DS.hasMany('municipalDeposit', { async: true, inverse: 'year' }),
    // totals below are calculated by a firebase function when budget is finalized and approved by DRA
    // totalAppropriated: DS.attr('number'),
    // totalEstimatedRevenue: DS.attr('number'),
    // totals below are calcualted by firebase function when year closed, will only be present after year closed
    // totalExpenditures: DS.attr('number'),
    // totalRevenue: DS.attr('number'),
    // generalFundBalance reflects what was left when year was closed, will only be present after year closed
    // generalFundBalance: DS.attr('number'),
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    municipalFunds: _emberData.default.hasMany('municipal-fund'), //General, proprietary, fiduciary
    municipalPayPeriods: _emberData.default.hasMany('municipal-pay-period'),
    // municipalAssets: DS.hasMany('assets'),
    municipalAccounts: _emberData.default.hasMany('municipal-account', { async: true, inverse: null }), //this should be deprecated in favor of municipalFunds
    municipalTransactions: _emberData.default.hasMany('transaction'), //moved to fund
    payrollDb: _emberData.default.belongsTo('payroll-db')
    // municipalChecks: DS.hasMany('municipalCheck', { async: true, inverse: null }), //moved to fund
    // municipalManifests: DS.hasMany('manifest', { async: true, inverse: null }), //moved to fund
    // reconciledReports: DS.hasMany('reconciled-report', { async: true, inverse: null }), //moved to fund
    // municipalDocuments: DS.hasMany('file', { async: true, inverse: null }) //moved to municipalDb
  });
});