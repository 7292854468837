define('fund-figure-app/models/bank-account-transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    transaction_id: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    date: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    transaction_type: _emberData.default.attr('string'),
    pending: _emberData.default.attr('boolean'),
    account_id: _emberData.default.belongsTo('municipalBankAccount', { async: true, inverse: null }),
    category: _emberData.default.attr(),
    reconciled: _emberData.default.attr('boolean'),
    reconciled_report: _emberData.default.belongsTo('reconciled-report', { async: true, inverse: null })
    // reconciledInvoice: DS.belongsTo('municipal-invoice', {async:true, inverse:null}),
    // reconciledDeposit: DS.belongsTo('municipal-deposit', {async:true, inverse:null})
  });
});