define('fund-figure-app/services/big-brother', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    appError: Ember.inject.service(),
    commonTasks: Ember.inject.service(),

    logChange: function logChange(changeObject) {
      var m_user = this.get('connectedUser.currentAccount'),
          commonTasks = this.get('commonTasks'),
          ts = moment().valueOf(),
          store = this.get('store'),
          lines = 0,
          fiscalYear = this.get('connectedUser.fiscalYear'),
          municipal_id = this.get('connectedUser.currentAccount').toJSON().municipality,
          m_year = municipal_id + '-' + moment().format("YYYY"),
          m_date = m_year + ('' + moment().format('-MM-DD'));
      if (!commonTasks.isEmpty(changeObject.changes)) {
        lines = Object.keys(changeObject.changes).length;
      }

      console.log(lines);
      m_user.get('municipality').then(function (municipalDb) {
        console.log(changeObject.model.constructor.modelName);
        var a_model = store.createRecord('audit-user-change', {
          modelId: changeObject.model.get('id'),
          modelName: '' + changeObject.model.constructor.modelName,
          municipalUser: m_user,
          lines: lines || 1,
          modelDeleted: changeObject.modelDeleted || null,
          desc: changeObject.desc || null,
          logDesc: changeObject.logDesc || null,
          changes: changeObject.changes || null,
          municipalDb: municipalDb,
          ts: ts,
          q_year: m_year,
          q_date: m_date
        });
        a_model.save();
      }).catch(function (err) {
        console.log(err);
        appError.logError(err);
      });
    }
  });
});