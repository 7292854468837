define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoice-add/invoice-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    assetInfo: Ember.inject.service(),

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },
    didInsertElement: function didInsertElement() {
      this.$('.ember-power-select-trigger').focus();
    },

    filteredAssets: Ember.computed('assetInfo.allAssets', 'transaction.transactDebit', {
      get: function get() {
        var accountId = Ember.get(this, 'transaction.transactDebit').get('id'),
            parrentAccountId = Ember.get(this, 'transaction.transactDebit').get('parentAccount.id');
        if (!accountId) {
          console.log('no account id');
          return;
        }
        if (parrentAccountId) {
          console.log('parentAccount of sub = ', parrentAccountId);
          accountId = parrentAccountId;
        }
        var assets = Promise.resolve(Ember.get(this, 'assetInfo.allAssets'));
        console.log(accountId, parrentAccountId);
        if (!assets || assets.length == 0) {
          console.log('no assets');
          return;
        }
        return assets.then(function (res) {
          return res.filter(function (d) {
            if (d.toJSON().invoiceToAccount == accountId) {
              return true;
            } else {
              return false;
            }
          });
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }),
    actions: {
      removeInvoiceItem: function removeInvoiceItem(item, invoice) {
        // let amount = item.amount
        item.set('invoice', invoice);
        var _this = this;
        console.log(invoice.toJSON().vendor);
        invoice.get('vendor').then(function (vendor) {
          var desc = 'Add ' + vendor.get('businessName') + '\'s invoice item back ' + item.get('desc') + ':' + item.get('amount'),
              undoObject = { 'model': item, 'desc': desc };
          _this.get('undoObjects').addObject(undoObject);
          _this.get('transactionArray').removeObject(item);
        }).catch(function (err) {
          console.log(err);
        });
      },
      searchFunc: function searchFunc(term, object) {
        console.log(term, object.options);
        var array = object.options;
        var result = array.filter(function (option) {
          var promise1 = option.get('stateAccountNumber').then(function (res) {
            if (String(res.get('accountNumber')).indexOf(term) >= 0 || res.get('friendlyName').indexOf(term) >= 0) {
              console.log(true);
              return true;
            } else {
              console.log(false);
              return false;
            }
          }).catch(function (err) {
            console.log(err);
            return false;
          });
          return Promise.all([promise1]).then(function (res) {
            return res;
          });
        });
        return Promise.all([result]).then(function (res1) {
          return res1;
        });
      },
      checkTab: function checkTab(e, evt) {
        if (evt.keyCode === 9) {
          $('.addItem').focus();
          evt.preventDefault();
          return;
        }
        this.get('notifyChange')();
        return;
      },
      notifyTransactionArrayChange: function notifyTransactionArrayChange() {
        console.log('transactionArray changed in child');
        this.get('notifyChange')();
      }
    }
  });
});