define('fund-figure-app/controllers/dashboard-assets/asset', ['exports', 'd3-collection', 'd3-array'], function (exports, _d3Collection, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    commonTasks: Ember.inject.service(),
    store: Ember.inject.service(),
    assetInfo: Ember.inject.service(),
    transactionTotal: null,
    getTransactionTotal: Ember.computed('model', 'model.transactions', 'model.transactions.@each.amount', {
      get: function get() {
        var _this2 = this;

        var _this = this;
        return new Promise(function (resolve, reject) {
          Ember.get(_this, 'model.transactions').then(function (transactions) {
            var total = (0, _d3Collection.nest)().key(function (d) {
              return 'total';
            }).rollup(function (v) {
              return (0, _d3Array.sum)(v, function (d) {
                return Number(d.get('amount'));
              });
            }).object(transactions.toArray());
            if (total.total > 0) {
              Ember.set(_this2, 'transactionTotal', total.total);
              resolve(total);
            } else {
              Ember.set(_this2, 'transactionTotal', 0);
              reject('no transactions');
            }
          }).catch(function (err) {
            throw new Error(err);
          });
        });
      }
    }),
    actions: {
      editAsset: function editAsset(asset) {
        Ember.get(this, 'assetInfo').editAsset(asset);
      },
      saveAssetImage: function saveAssetImage(imageObject) {
        var _this3 = this;

        var model = Ember.get(this, 'model');
        if (model.toJSON().picture) {
          model.get('picture').then(function (picture) {
            picture.set(imageObject);
            return picture.save();
          });
        } else {
          var newPicture = Ember.get(this, 'store').createRecord('picture', imageObject);
          newPicture.save().then(function (savedPicture) {
            model.set('picture', savedPicture);
            return Ember.get(_this3, 'assetInfo').saveAsset(model);
          }).then(function (res) {}).catch(function (err) {
            throw new Error(err);
          });
        }
        console.log(imageObject);
      }
    }
  });
});