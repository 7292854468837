define('fund-figure-app/components/dashboard/payments/payments-index-display/by-invoice-hierarchy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      voidCheckModal: function voidCheckModal() {
        this.get('voidCheckModal')();
      },
      voidCheckReissueModal: function voidCheckReissueModal() {
        this.get('voidCheckReissueModal')();
      }
    }
  });
});