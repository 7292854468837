define('fund-figure-app/components/dashboard/warrant-articles/create-warrant-article', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    router: Ember.inject.service(),
    undo: Ember.inject.service(),
    articleModel: null,
    templates: null,
    didInsertElement: function didInsertElement() {
      this.createWarrantArticleModel();
    },


    municipalArticleTemplates: Ember.computed('connectedUser.fiscalYear', 'connectedUser.fiscalYear.municipality.info', {
      get: function get() {
        var _this2 = this;

        var appError = this.get('appError'),
            _this = this;
        return this.get('connectedUser.fiscalYear.municipality.info').then(function (municipalInfo) {
          return municipalInfo.toJSON().state;
        }).then(function (state_id) {
          return _this2.get('store').query('warrantArticleTemplate', {
            orderBy: 'state',
            equalTo: state_id
          });
        }).then(function (query) {
          console.log(query.toArray().sort());
          _this.set('templates', query.toArray().sort(function (a, b) {
            return ('' + a.get('category')).localeCompare(b.get('category'));
          }));
          return query.toArray().sort();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error querying municipal articles types based on state';
          appError.logError(err, specialMessage);
        });
      }
    }),
    createWarrantArticleModel: function createWarrantArticleModel() {
      var store = this.get('store'),
          _this = this,
          appError = this.get('appError'),
          fund = this.get('connectedUser.currentFund'),
          year = this.get('connectedUser.fiscalYear'),
          autoNumber = Ember.get(this, 'commonTasks').padString((fund.get('municipalArticles.length') || 0) + 1, '0', 2),
          municipality = this.get('connectedUser.fiscalYear.municipality'),
          newWarrantArticle = store.createRecord('municipal-article', {
        municipality: municipality,
        municipalYear: year,
        municipalFund: fund,
        number: autoNumber
      });
      return _this.set('articleModel', newWarrantArticle);
    },

    actions: {
      next: function next(template, article) {
        article.set('warrantArticleTemplate', template);
        article.set('language', template.get('language'));
        article.set('title', template.get('type'));
        article.set('category', template.get('category'));
        this.get('router').transitionTo('dashboard.warrant-articles.create-warrant-article.step-two', article);
      }
    }
  });
});