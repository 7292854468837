define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoice-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    invoiceItems: [],
    willDestroyElement: function willDestroyElement() {
      this.get('invoiceItems').clear();
    },
    didInsertElement: function didInsertElement() {
      this.get('invoiceItems').clear();
    },

    date_close: Ember.computed('invoice.[]', 'invoice.manifest', function () {
      var invoice = this.get('invoice'),
          invoicesDueNow = this.get('invoicesDueNow'),
          i_json = invoice.toJSON(),
          dueDate = i_json.date_due,
          now = moment().format('x');
      // invoicesDueNow.clear();
      if (dueDate - now < 604800000) {
        invoicesDueNow.addObject(invoice);
        return true;
      } else {
        invoicesDueNow.removeObject(invoice);
        return false;
      }
    }),
    date_soon: Ember.computed('invoice.[]', 'invoice.manifest', function () {
      var invoice = this.get('invoice'),
          invoicesDueSoon = this.get('invoicesDueSoon'),
          i_json = invoice.toJSON(),
          dueDate = i_json.date_due,
          now = moment().format('x');
      // invoicesDueSoon.clear();
      if (dueDate - now < 1209600000 && dueDate - now > 604800000) {
        invoicesDueSoon.addObject(invoice);
        return true;
      } else {
        invoicesDueSoon.removeObject(invoice);
        return false;
      }
    }),
    actions: {
      expandItems: function expandItems() {
        this.toggleProperty('expandItems');
      }
    }
  });
});