define('fund-figure-app/components/municipal-handler/municipal-setup-step-one/edit-expenditure-account', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    edit: true,

    /**
     * Gets and sums budget for each sub account to create a total for the parent account
     * @param  {modelArray} 'model.subAccounts'  watch all subaccounts of the model
     * @param {model} 'model.budget' watch the budget amount of the parent account
     * @return {NA}
     */
    budgetTotal: Ember.computed('model.subAccounts.[]', 'model.budget', function () {
      var total = 0;
      var _this = this;
      var model = this.get('model');
      var parentAccount = null;
      return model.get('subAccounts').then(function (subAccounts) {
        if (subAccounts.get('length') >= 1) {
          console.log('we have sub accounts');
          return subAccounts.forEach(function (account) {
            if (!account.get('budget')) {
              return;
            }
            if (typeof account.get('budget') === "undefined") {
              return;
            }
            parentAccount = model;
            total = total + Number(account.get('budget'));
          });
        } else {
          if (!_this.get('model.budget')) {
            return;
          }
          if (typeof _this.get('model.budget') === "undefined") {
            return;
          }
          return total = +Number(_this.get('model.budget'));
        }
      }).then(function () {
        _this.set('totalBudget', total);
        return total;
      }).then(function (total) {
        // if (parentAccount !== null) {
        // console.log('parentAccount', total);
        model.set('budget', total);
        model.save();
        // } else {
        //   console.log('sub Account', total);
        //   model.set('budget', total);
        //   model.save();
        // }
      }).catch(function (error) {
        console.log(error);
      });
    }),

    municipalAccountFunctions: Ember.computed('', function () {
      return this.get('store').findAll('municipalAccountFunction');
    }),

    actions: {
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        if (attr !== 'budget') {
          model.set(attr, value);
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, num);
        // model.save();
        this.notifyPropertyChange('budgetTotal');
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },


      /**
       * add a sub account to the currently selected parent account
       * @param {EmberModel} account model of the current parent account which the sub account should be added
       */
      addSubAccount: function addSubAccount(account) {
        var subAccountNumber = String(account.get('subAccounts.length')).padStart(3, "0"),
            municipalFund = Ember.get(this, 'connectedUser.currentFund'),
            _this = this;
        account.get('stateAccountNumber').then(function (stateAccount) {
          return stateAccount.get('classification');
        }).then(function (classification) {
          if (classification === "Expense") {
            var _subAccountNumber = account.get('subAccounts.length');
            console.log(_subAccountNumber);
            var subAccount = _this.get('store').createRecord('municipal-account', {
              parentAccount: account,
              stateAccountNumber: account.get('stateAccountNumber'),
              activated: true,
              classification: account.get('classification'),
              appropriation: account.get('appropriation'),
              accountNumber: String(account.get('subAccounts.length')).padStart(3, "0"),
              municipalYear: account.get('municipalYear'),
              municipalFund: account.get('municipalFund'),
              municipality: account.get('municipality'),
              transactDebit: account.get('transactDebit'),
              transactCredit: account.get('transactCredit'),
              stateName: account.get('stateName'),
              stateNumber: account.get('stateNumber')
            });
            subAccount.save().then(function (savedSub) {
              account.get('subAccounts').addObject(savedSub);
              municipalFund.get('municipalAccounts').addObject(savedSub);
              account.save().then(function (savedAccount) {
                return municipalFund.save().catch(function (err) {
                  console.log(err);
                  var specialMessage = 'error saving fund with new sub account added to account list';
                  appError.logError(err, specialMessage);
                });
              }).then(function () {
                $('.space69').animate({ scrollTop: document.getElementById('subAccounts').scrollHeight }, "slow");
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving the parent account';
                appError.logError(err, specialMessage);
              });
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving the new sub account model';
              appError.logError(err, specialMessage);
            });
          } else {
            //we dont add sub accounts to revenue accounts
            account.save();
          }
        });
      },
      saveParent: function saveParent(account) {
        var globalMessage = this.get('globalMessaging');
        account.save().then(function (savedAccount) {
          var message = null;
          if (account.get('nickname')) {
            message = account.get('nickname') + ' ' + 'was saved successfully';
          } else {
            message = 'saved successfully';
          }
          globalMessage.actionNotification(message, 'success');
        }).catch(function (error) {
          globalMessage.actionNotification('Permission was denied, budget finalized', 'warning');
          account.rollbackAttributes();
          console.log(error);
        });
      },


      /**
       * Save sub account after changes have been made by clicking save button
       * @param  {EmberModel} account       model of sub account to save
       * @param  {EmberModel} parentAccount model of parent account which sub account is referenced
       * @return {NA}
       */
      saveSub: function saveSub(account, parentAccount) {
        var globalMessage = this.get('globalMessaging'),
            appError = Ember.get(this, 'appError'),
            undo = Ember.get(this, 'undo'),
            bigBrother = Ember.get(this, 'bigBrother'),
            commonTasks = Ember.get(this, 'commonTasks'),
            municipalFund = Ember.get(this, 'connectedUser.currentFund');
        account.save().then(function (savedAccount) {
          //
          var subAccountChanges = undo.getChanges(account);
          console.log(subAccountChanges);
          if (!commonTasks.isEmpty(subAccountChanges)) {
            var desc = 'Undo changes made to ' + model.get('stateNumber') + ' ' + model.get('accountNumber'),
                logDesc = model.get('employee_firstName') + ' ' + model.get('employee_lastName'),
                undoObject = { model: account, desc: desc, changes: subAccountChanges, logDesc: logDesc };
            bigBrother.logChange(undoObject);
            undo.get('undoObjects').addObject(undoObject);
          }
          //
          parentAccount.save();
          var message = null;
          if (account.get('nickname')) {
            message = account.get('nickname') + ' ' + 'was saved successfully';
          } else {
            message = 'saved successfully';
          }
          globalMessage.actionNotification(message, 'success');
        }).catch(function (error) {
          globalMessage.actionNotification('Permission was denied, budget finalized', 'warning');
          account.rollbackAttributes();
          console.log(error);
        });
      },


      /**
       * Removes subaccount when remove button is clicked
       * @param  {EmberModel} subAccount model of the subaccount attached to the parent account
       * @param  {EmberModel} account    model of the parent account which the subaccount is referenced from
       * @return {NA}           
       */
      removeSubAccount: function removeSubAccount(subAccount, account) {
        var _this = this,
            appError = this.get('appError'),
            undo = this.get('undo');
        this.get('store').findRecord('municipal-fund', account.toJSON().municipalFund).then(function (municipalFund) {
          var undoObject = null;
          //create description for undo button
          if (subAccount.get('nickname')) {
            var description = 'Add ' + subAccount.get('nickname') + ', Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
            undoObject = { 'model': subAccount, 'modelDeleted': true, 'secondaryModels': [{ 'model': municipalFund, 'location': 'municipalAccounts' }, { 'model': account, 'location': 'subAccounts' }], 'desc': description };
          } else {
            var _description = 'Add ' + ' Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
            undoObject = { 'model': subAccount, 'modelDeleted': true, 'secondaryModels': [{ 'model': municipalFund, 'location': 'municipalAccounts' }, { 'model': account, 'location': 'subAccounts' }], 'desc': _description };
          }
          //send undo object to undo service which manages all undos
          undo.get('undoObjects').addObject(undoObject);
          //remove sub account from municipalfund reference
          municipalFund.get('municipalAccounts').removeObject(subAccount);
          //save after removing account
          municipalFund.save().then(function () {
            account.get('subAccounts').removeObject(subAccount);
            return account.save();
          }).then(function () {
            return subAccount.destroyRecord();
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'failed to destroy subaccount';
            appError.logError(err, specialMessage);
          });
        });
      }
    }
  });
});