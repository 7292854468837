define('fund-figure-app/components/dashboard-assets/no-asset-db', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    loading: true,
    index: 5,
    previousYearsArray: Array(),

    getMunicipality: Ember.computed('', {
      get: function get() {
        var _this2 = this;

        return Ember.get(this, 'connectedUser.currentFund.municipalDb').then(function (res) {
          console.log('municipality', res);
          Ember.set(_this2, 'municipality', res);
          return res;
        });
      }
    }),
    cost: Ember.computed('municipality.info', function () {
      var _this = this;
      var yearly = 1200,
          monthly = Math.round(yearly / 12, 0),
          stripeMonthlyDesc = '$' + monthly + ' monthly',
          stripeYearlyDesc = '$' + yearly + ' yearly';
      _this.setProperties({ monthlyCost: monthly, yearlyCost: yearly, stripeMonthDesc: stripeMonthlyDesc, stripeYearlyDesc: stripeYearlyDesc });
      return true;
    }),

    checkAssetDb: function checkAssetDb(fund) {
      console.log('checking for asset DB');
      if (fund.toJSON().assetDb) {
        console.log('found asset db, send to array');
        Ember.get(this, 'previousYears').push(fund);
      } else {
        console.log('no asset db');
      }
    },
    checkPreviousYearFund: function checkPreviousYearFund(fund) {
      console.log('checking for previous year');
      if (fund.toJSON().previousYear) {
        console.log('found previous year');
        return fund.get('previousYear');
      } else {
        console.log('no previous year end search');
        return false;
      }
    },

    previousYears: Ember.computed('connectedUser.currentFund', 'index', {
      get: function get() {
        Ember.set(this, 'loading', true);
        var index = Ember.get(this, 'index'),
            currentFund = Ember.get(this, 'connectedUser.currentFund');

        for (var i = index - 1; i >= 0; i--) {
          var previousYearFund = this.checkPreviousYearFund(currentFund);
          if (previousYearFund) {
            console.log('found previous year', previousYearFund);
            this.checkAssetDb(previousYearFund);
            currentFund = previousYearFund;
          } else {
            console.log('no more previous years end for loop');
            Ember.set(this, 'loading', false);
            break;
          }
        }
      }
    }),
    actions: {
      createNewAssetDb: function createNewAssetDb() {
        Ember.set(this, 'newAssetManagementSubscription', true);
        // let municipalFund = get(this, 'connectedUser.currentFund');
        // municipalFund
        //   .get('municipalDb')
        //   .then(municipalDb => {
        //     let newAssetDb = get(this, 'store').createRecord('asset-db', {
        //      id:null,
        //       municipalFund: municipalFund,
        //       municipalDb: municipalDb
        //     })
        //     newAssetDb
        //      .save()
        //      .catch(err=>{
        //        throw new Error('saving new asset db',err);
        //      })
        //      .then(savedAssetDb=>{
        //        municipalFund.set('assetDb', savedAssetDb);
        //        return municipalFund.save()
        //      })
        //      .catch(err=>{
        //        throw new Error('saving new db location to municipal db',err);
        //      })
        //   })
      },
      processStripeToken: function processStripeToken(plan, town, amount, token) {
        console.log(plan, town, amount, token);
        var self = this,
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            ifSandbox = 's',
            connectedUser = Ember.get(this, 'connectedUser'),
            store = this.get('store'),
            municipal_id = town.id,
            municipal_customer_id = town.toJSON().paymentInfo || null,
            card_id = token.card.id,
            email = token.email;
        if (connectedUser.get('test')) {
          console.log('we in sandbox');
          ifSandbox = 'sandboxS';
        }
        this.set('sendingInformation', true);
        globalMessage.actionNotification('Setting up Asset Subscription', 'success');

        if (!municipal_id) {
          console.log('no municipal ID');
          globalMessage.actionNotification('No Municipal Account Present', 'cancel');
          return;
        }
        $.get({
          url: 'https://us-central1-aacc-603.cloudfunctions.net/' + ifSandbox + 'tripeOnRequestCreateAssetManagementSubscription?token=' + JSON.stringify(token) + '&municipal_id=' + municipal_id + '&plan=' + plan + '&quantity=' + amount + '&email=' + email + '&municipal_customer_id=' + municipal_customer_id,
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            console.log('received response but had error');
            self.setProperties({ errorMessage: data.error });
          } else if (data.success) {
            console.log('received response with no error');
            globalMessage.actionNotification('Account created with subscription service', 'success');
          }
        });
      }
    }

  });
});