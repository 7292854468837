define('fund-figure-app/routes/account-handler/add-account-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.store.findRecord('municipal-db', params.munic_id);
		}
	});
});