define('fund-figure-app/components/file-upload-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    firebaseApp: Ember.inject.service(),
    // classNames: ['drop-area', 'margin-h-80'],
    // classNameBindings: ['dropArea:file:drop-area'],
    rawSize: '',
    fileInfo: Ember.A(),
    // image: false,

    dropArea: Ember.computed('file', function () {
      var file = this.get('file');
      if (file) {
        return true;
      } else {
        return false;
      }
    }),

    newImage: function newImage(input) {
      // this.setProperties({image:'',imageLandscape:'',imagePortrait:''});
      var _URL = window.URL || window.webkitURL;
      var _this = this;
      // let fileInfo = this.get('fileInfo');
      // let firebase = this.get('firebaseApp');    
      var array = [];
      for (var i = input.files.length - 1; i >= 0; i--) {
        (function (file) {
          var fileReader = new FileReader();
          fileReader.onload = function (e) {
            console.log(file.name);
            var arrayBuffer = e.target.result;
            var arr = new Uint8Array(e.target.result).subarray(0, 8);
            var header = "";
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            var type = null;
            console.log(header);
            switch (header) {
              case "89504e47":
                type = "image/png";
                break;
              case "47494638":
                type = "image/gif";
                break;
              case "ffd8ffe00104a46":
              case "ffd8ffe1":
              case "ffd8ffe2":
                type = "image/jpeg";
                break;
              case "3c3f786d6c207665":
                _this.setProperties({ fileSVG: true, filePDF: false, fileXSL: false, fileDOC: false });
                type = "application/svg";
                break;
              case "255044462d312e33":
                _this.setProperties({ fileSVG: false, filePDF: true, fileXSL: false, fileDOC: false });
                type = "application/pdf";
                break;
              case "38425053":
                type = "application/psd";
                break;
              case "504b34":
                type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                break;
              case "d0cf11e0a1b11ae1":
                _this.setProperties({ fileSVG: false, filePDF: false, fileXSL: true, fileDOC: false });
                type = 'application/vnd.ms-excel';
                break;
              case "d0cf11e0":
                _this.setProperties({ fileSVG: false, filePDF: false, fileXSL: false, fileDOC: true });
                type = "application/doc"; //or doc, xls, ppt, msg, and other
                break;
              case "49492A00":
                type = "image/tif";
                break;
              case "4D4D002A":
                type = "image/tiff";
                break;
              default:
                type = "unknown"; // Or you can use the blob.type as fallback
                break;
            }
            var blob = new Blob([arrayBuffer], { type: type });
            var metadata = {
              type: type,
              name: file.name,
              contentDisposition: "attachment; filename=" + file.name,
              lastModified: file.lastModified,
              size: file.size,
              filePDF: _this.get('filePDF'),
              fileJPG: _this.get('fileJPG'),
              fileXSL: _this.get('fileXSL'),
              fileDOC: _this.get('fileDOC'),
              fileSVG: _this.get('fileSVG')
            };

            // array.addObject({ "file": blob, "metadata": metadata });
            // if (fileInfo) {
            //   console.log('array exists');
            //   _this.get('fileInfo').pushObject({ "file": blob, "metadata": metadata });
            // } else {
            //   _this.setProperties({ fileInfo: array });
            // }  
            _this.get('saveFile')({ "file": blob, "metadata": metadata });
          };
          fileReader.readAsArrayBuffer(file);
        })(input.files[i]);
      }
    },

    click: function click() {
      Ember.$("input[type='file']").click();
    },

    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$("input[type='file']").click(function (event) {
        event.stopPropagation();
      });
      $("input[type='file']").on('change', function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.newImage(e.target);
      });
      $(document).on({
        'drop': function drop(e) {
          e.preventDefault();
          // e.stopPropagation();
          // console.log(e.dataTransfer);
          self.newImage(e.dataTransfer);
        },
        'dragover': function dragover(e) {
          e.preventDefault();
          $(this).addClass('dragging');
        }
      }, ".drop-area");
    }
  });
});