define('fund-figure-app/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.belongsTo('asset-type'),
    municipality: _emberData.default.belongsTo('municipal-db'),
    department: _emberData.default.belongsTo('municipal-department'),
    invoiceToAccount: _emberData.default.belongsTo('municipal-account'), //account where asset should show up as a possible exp
    transactions: _emberData.default.hasMany('transaction'),
    name: _emberData.default.attr('string'), //asset name or what employees commonly refer to asset as
    description: _emberData.default.attr('string'), //description of the asset, public facing
    make: _emberData.default.belongsTo('asset-vehicle-make'),
    model: _emberData.default.belongsTo('asset-vehicle-model'),
    cipFund: _emberData.default.belongsTo('municipal-fund'), //fund where asset is part of cip plan
    cipParentAccount: _emberData.default.belongsTo('municipal-account'), //parent account asset belongs to in cipFund
    cipSubAccount: _emberData.default.belongsTo('municipal-account'), //sub account in parent account asset may belong to in cipFund
    vin: _emberData.default.attr('string'),
    modelYear: _emberData.default.attr('string'),
    condition: _emberData.default.attr('string'),
    squareFoot: _emberData.default.attr('number', { defaultValue: 0 }), //size of building
    pid: _emberData.default.attr('string'),
    propertyLoc: _emberData.default.attr('string'),
    acquired: _emberData.default.attr('number'), //date which asset was acquired
    disposed: _emberData.default.attr('number'), //date which asset was removed from use
    replacementYear: Ember.computed('lifeSpan', 'acquired', {
      get: function get() {
        return moment(Ember.get(this, 'acquired')).add(Ember.get(this, 'lifeSpan'), 'years');
      }
    }).property('lifeSpan'),
    picture: _emberData.default.belongsTo('picture'),
    notes: _emberData.default.attr('string'),
    function: _emberData.default.belongsTo('municipal-account-function'), //
    lifeSpan: _emberData.default.attr('number'), //intended life span of service to municipality
    cost: _emberData.default.attr('number'),
    valueNew: _emberData.default.attr('number'),
    depAsset: _emberData.default.belongsTo('asset-depreciation'), //asset model to use
    depModel: _emberData.default.attr('string'), //actual type of asset with in depreciation model
    mileageBegin: _emberData.default.attr('number'), //used for vehicles
    mileage: _emberData.default.attr('number'), //used for vehicles
    hoursBegin: _emberData.default.attr('number'), //used for equipment
    hours: _emberData.default.attr('number'), //used for equipment
    pipeLength: _emberData.default.attr('number'), //used for infrastructure
    pipeDiameter: _emberData.default.attr('number'), //used for infrastructure
    pipeMaterial: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('number'), //unix date of when asset was added
    vehicle: _emberData.default.attr('boolean', { defaultValue: false }),
    equipment: _emberData.default.attr('boolean', { defaultValue: false }),
    building: _emberData.default.attr('boolean', { defaultValue: false }),
    infrastructure: _emberData.default.attr('boolean', { defaultValue: false }),
    construction: _emberData.default.attr('boolean', { defaultValue: false }),
    land: _emberData.default.attr('boolean', { defaultValue: false }),
    taxDeeded: _emberData.default.attr('boolean', { defaultValue: false }),
    fiscalYearAdded: _emberData.default.belongsTo('municipal-year'), //fiscal year asset was added in use
    municipalFund: _emberData.default.belongsTo('municipal-fund'), //fund which asset belongs too
    depreciatedValue: Ember.computed('modelYear', 'mileage', 'condition', 'depModel', 'depAsset', {
      get: function get(key) {
        var model = this;
        var assetType = model.get('type') || null,
            assetYear = model.get('modelYear') || null,
            assetMileage = model.get('mileage') || null,
            assetModel = model.get('depModel') || null,
            assetCondition = model.get('condition') || null,
            assetDepreciationModel = model.get('depAsset'),
            assetPurchaseValue = model.get('valueNew'),
            assetLifeSpan = model.get('lifeSpan'),
            assetCost = model.get('cost');

        return Promise.all([assetType, assetYear, assetMileage, assetModel, assetCondition, assetDepreciationModel, assetPurchaseValue, assetCost, assetLifeSpan]).then(function (res) {
          console.log('res', res);
          if (!res[5]) {
            Ember.set(model, 'currentValue', res[7]);
            console.log(res[7]);
            return res[7];
          }
          var modelCofficient = getModelCofficient(res[5], res[3]),
              conditionCofficient = getConditionCofficient(res[5], res[4]),
              mileageCofficient = getMileageCofficient(res[5], res[2]),
              ageCofficient = getAgeCofficient(res[5], res[1], res[8]),
              currentValue = modelCofficient * conditionCofficient * mileageCofficient * ageCofficient * res[6];
          console.log(modelCofficient, conditionCofficient, mileageCofficient, ageCofficient, res[6]);
          console.log(currentValue);

          Ember.set(model, 'currentValue', currentValue);
          return modelCofficient * conditionCofficient * mileageCofficient * ageCofficient;
        });
      }
    }),
    currentValue: Ember.computed('depreciatedValue', {
      get: function get(key) {
        return this.get('depreciatedValue').then(function (res) {
          return res;
        });
      },
      set: function set(key, value) {
        return value;
      }
    }).property('depreciatedValue'),
    cip_total_save: Ember.computed('cost', 'lifeSpan', {
      get: function get() {
        var cost = Ember.get(this, 'cost'),
            lifeSpan = Ember.get(this, 'lifeSpan');
        return Math.round(cost * (Math.pow(1 + .025, lifeSpan) - 1) + cost);
      }
    }),
    cip_cost_per_year: Ember.computed('cip_total_save', {
      get: function get() {
        var lifeSpan = Ember.get(this, 'lifeSpan');
        return Math.round(Ember.get(this, 'cip_total_save') / lifeSpan);
      }
    })
  });


  function getModelCofficient(assetDepreciationModel, assetModel) {
    if (!assetModel) {
      console.log('no asset model');
      return 1;
    }
    return assetDepreciationModel.get('assetModel.' + assetModel);
  }

  function getConditionCofficient(assetDepreciationModel, assetCondition) {
    if (!assetCondition) {
      console.log('no asset condition');
      return 1;
    }
    return assetDepreciationModel.get('condition.' + assetCondition);
  }

  function getAgeCofficient(assetDepreciationModel, assetYear, assetLifeSpan) {
    if (!assetYear) {
      console.warn('no model year given');
      return 1;
    }
    var years = moment().diff(moment(assetYear), 'years', false);
    console.log(years, assetLifeSpan);
    if (years > assetLifeSpan) {
      return assetDepreciationModel.get('year.' + assetLifeSpan) || .1;
    } else {
      return assetDepreciationModel.get('year.' + years) || .1;
    }
  }

  function getMileageCofficient(assetDepreciationModel, assetMileage) {
    var cofficient = false,
        higherMileageRange = null,
        lowerMileageRange = null;
    if (!assetMileage || assetMileage == 0) {
      console.log('no asset mileage or its 0');
      return 1;
    }
    var mileageKeyArray = Object.keys(assetDepreciationModel.get('mileage'));
    for (var i = mileageKeyArray.length - 1; i >= 0; i--) {
      if (assetMileage <= mileageKeyArray[i]) {
        higherMileageRange = { "cof": assetDepreciationModel.get('mileage.' + mileageKeyArray[i]), "mileage": mileageKeyArray[i] };
      }
      if (assetMileage >= mileageKeyArray[i]) {
        lowerMileageRange = { "cof": assetDepreciationModel.get('mileage.' + mileageKeyArray[i]), "mileage": mileageKeyArray[i] };
      }
      if (higherMileageRange && lowerMileageRange) {
        var rangeDifference = higherMileageRange["mileage"] - lowerMileageRange["mileage"];
        if (rangeDifference === 0) {
          return higherMileageRange["cof"];
        }

        var assetMileageDifference = assetMileage - lowerMileageRange["mileage"],
            percentDifference = assetMileageDifference / rangeDifference,
            cofDifference = higherMileageRange["cof"] - lowerMileageRange["cof"],
            finalCof = lowerMileageRange["cof"] + cofDifference * percentDifference;
        console.log(rangeDifference, assetMileageDifference, percentDifference, cofDifference, finalCof);
        return finalCof;
      }
      var mileage = assetDepreciationModel.get('mileage.' + mileageKeyArray[i]);
      console.log('mileage key', mileage);
    }
  }
});