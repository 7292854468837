define('fund-figure-app/components/dashboard/budget/child-account-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    editAll: false,

    watchEdit: Ember.observer('editAll', function () {
      var editAll = this.get('editAll');
      if (editAll) {
        return;
      }
      var account = this.get('subAccount');
      account.save().then(function () {}, function (error) {
        console.log('Error : ' + error);
      });
    }),

    municipalFunctions: Ember.computed('', function () {
      return this.get('store').findAll('municipal-account-function');
    }),

    didInsertElement: function didInsertElement() {
      this.get('editAll_toggle');
    },
    doubleClick: function doubleClick(event) {
      event.stopPropagation();
      this.toggleProperty('editParent');
      var subAccount = this.get('subAccount'),
          globalMessage = this.get('globalMessaging');
      if (subAccount.hasDirtyAttributes) {
        subAccount.save();
      } else {}
    },


    editAll_toggle: Ember.computed('editAll', function () {
      var editAll = this.get('editAll'),
          subAccount = this.get('subAccount');
      if (editAll) {
        this.set('edit', true);
      } else {
        this.set('edit', false);
        if (subAccount.hasDirtyAttributes) {
          subAccount.save().catch(function (err) {
            console.log(err);
          });
        }
      }
    }).property('editAll', 'editParent'),

    actions: {
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        console.log('notify budget child');
        if (value < 1) {
          model.set(attr, null);
          this.get('notifyBudget')();
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        // model.save();
        this.get('notifyBudget')();
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },
      removeSubAccount: function removeSubAccount(subAccount, account) {
        var _this = this;
        var undoObject = {};
        // let account_id = account.get('id');

        if (subAccount.get('nickname')) {
          var description = 'Add ' + subAccount.get('nickname') + ', Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
          undoObject = { 'model': subAccount, 'description': description, 'modelDeleted': true, 'secondaryModel': [{ 'model': account, 'location': 'subAccounts' }] };
          _this.get('connectedUser.undoObjects').addObject(undoObject);
          account.get('subAccounts').removeObject(subAccount);
        } else {
          var _description = 'Add ' + ' Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
          undoObject = { 'model': subAccount, 'description': _description, 'account': account, 'accountNumber': account.get('stateAccountNumber.accountNumber') };
          _this.get('connectedUser.undoObjects').addObject(undoObject);
          account.get('subAccounts').removeObject(subAccount);
        }
      }
    }
  });
});