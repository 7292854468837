define('fund-figure-app/components/dashboard/personnel/vendors/vendors-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    showHiddenVendors: false,
    sortedVendors: Ember.computed.sort('vendorsToShow', function (a, b) {
      if (a.toJSON().businessName > b.toJSON().businessName) {
        return 1;
      } else if (a.toJSON().businessName < b.toJSON().businessName) {
        return -1;
      } else {
        return 0;
      }
    }),
    vendorsToShow: Ember.computed('vendorsCurrent', 'vendorsHidden', 'showHiddenVendors', {
      get: function get() {
        if (Ember.get(this, 'showHiddenVendors')) {
          return Ember.get(this, 'vendorsHidden');
        } else {
          return Ember.get(this, 'vendorsCurrent');
        }
      }
    }),
    vendorsCurrent: Ember.computed.filterBy('getVendors', 'deactivated', false),
    vendorsHidden: Ember.computed.filterBy('getVendors', 'deactivated', true),
    getVendors: Ember.computed('connectedUser.vendors', 'connectedUser.vendors_DB.vendors', 'connectedUser.currentAccount.municipality.municipalVendorsDb.vendors.[]', {
      get: function get() {
        return this.get('connectedUser.vendors');
      }
    }),

    actions: {
      deleteVendor: function deleteVendor(vendor) {
        var d = vendor.toJSON(),
            vendors = this.get('connectedUser.vendors'),
            vendors_DB = this.get('connectedUser.vendors_DB'),
            undoList = this.get('connectedUser.undoObjects'),
            description = 'Add ' + d.businessName + ' back to vendor list',
            undoObject = { model: vendor, description: description, modelDeleted: true, secondaryModel: ['{"' + vendors_DB.get('id') + '":"vendors"}'] };
        undoList.addObject(undoObject);
        // vendors_DB.get('vendors').removeObject(vendor);
        vendors.removeObject(vendor);
        vendor.destroyRecord();
        this.get('connectedUser.buildVendors');
      },
      hideVendor: function hideVendor(vendor) {
        var globalMessage = this.get('globalMessaging');
        vendor.toggleProperty('deactivated');
        vendor.save().catch(function (err) {
          globalMessage.actionNotification('Error changing vendor status', 'warning');
        });
      },
      addNewVendor: function addNewVendor(vendorName) {
        var store = this.get('store'),
            _this = this,
            router = this.get('router'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            get_municipality = fiscalYear.get('municipality');

        get_municipality.then(function (municipality) {
          return municipality.get('municipalVendorsDb');
        }).then(function (municipalVendorsDb) {
          var newVendor = store.createRecord('vendor', {
            businessName: vendorName
          });
          municipalVendorsDb.get('vendors').addObject(newVendor);
          municipalVendorsDb.save();
          return newVendor.save();
        }).then(function (vendorSaved) {
          _this.get('connectedUser.buildVendors');
          router.transitionTo("dashboard.personnel.vendors.vendor.vendor-edit", vendorSaved);
        }).catch(function (err) {
          console.log(err);
        });
      },
      toggleHiddenVendors: function toggleHiddenVendors() {
        this.toggleProperty('showHiddenVendors');
      }
    }
  });
});