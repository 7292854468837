define('fund-figure-app/routes/dashboard-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    connectedUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    // store: service(),
    beforeModel: function beforeModel() {
      // this._super();
      return this.get("session").fetch().catch(function () {});
    },
    afterModel: function afterModel() {
      var firebase = this.get('firebaseApp'),
          _this = this,

      // store = this.get('store'),
      session = this.get('session');
      if (!session || typeof session == 'undefined' || !session.content.isAuthenticated) {
        console.log('no authenticated session');
        return this.transitionTo('account-handler.account-menu');
      }
      var date = moment().format('x');
      var user = this.store.findRecord('user', session.content.uid);
      var foundUser = user.then(function (result) {
        if (!result.get('avitar')) {
          return false;
        }
        console.log(result.get('avitar'));
        //////CODE BELOW IS FOR MULTI ACCOUNT SUPPORT//////
        var staff = result.get('defaultAccount');
        if (!result.toJSON().defaultAccount) {
          console.log('no defaultAccount');
          return result.get('municipalUsers.lastObject');
        } else {
          return staff;
        }
      }).catch(function (error) {
        console.log(error);
      });

      foundUser.then(function (staff) {
        if (!staff) {
          _this.transitionTo('account-handler.account-menu');
        }
        if (staff.get('is_new')) {
          console.log('staff has isNew');
          var string = staff.get('is_new');
          var transitionArray = string.split('/');
          console.log(transitionArray);
          if (transitionArray.length > 0) {
            _this.transitionTo(transitionArray[0], transitionArray[1]);
          } else {
            _this.transitionTo(transitionArray[0]);
          }
        } else {}
        // console.log('transition to dashboard support');
        // _this.transitionTo('dashboard-support');

        // since I can connect from multiple devices or browser tabs, we store each connection instance separately
        // any time that connectionsRef's value is null (i.e. has no children) I am offline        
        var connectionHistoryRef = firebase.database().ref('connectionHistories/');
        var myStaffRef = firebase.database().ref('municipalUsers/' + staff.get('id'));
        var newConnectionHistory = connectionHistoryRef.push();
        // stores the timestamp of my last disconnect (the last time I was seen online)
        var lastUserOnline = firebase.database().ref('users/' + user.get('id'));

        var connectedRef = firebase.database().ref('.info/connected');
        connectedRef.on('value', function (snap) {
          if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)

            // add this device to my connections list
            // this value could contain info about the device or a timestamp too
            var ua = navigator.userAgent;
            console.log(ua);
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Phone/i.test(ua)) {
              //mobile device
              console.log('mobile device');
              var _date2 = moment().format('x');
              var connection = myStaffRef.child('connections').push(true);
              var connectionsRef = firebase.database().ref('connections/' + connection.key);

              connectionsRef.update({
                device: 'mobile',
                time: _date2
              });
              newConnectionHistory.update({
                device: 'mobile',
                timeOn: _date2,
                user: staff.get('user.id'),
                account: myStaffRef.key
              });
              _this.store.findRecord('connection', connectionsRef.key);
              // when I disconnect, remove this device
              connectionsRef.onDisconnect().remove();
              connection.onDisconnect().remove();
              myStaffRef.child('shouldRefresh').onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: moment().format('x')
              });
            } else if (/OS X|Windows|Linux/i.test(ua)) {
              //desktop
              console.log('signed in desktop');
              var _date3 = moment().format('x');
              var _connection = myStaffRef.child('connections').push(true);
              var _connectionsRef = firebase.database().ref('connections/' + _connection.key);
              _connectionsRef.update({
                device: 'desktop',
                time: _date3
              });
              newConnectionHistory.update({
                device: 'desktop',
                timeOn: _date3,
                user: staff.get('user.id'),
                account: myStaffRef.key
              });
              _this.store.findRecord('connection', _connectionsRef.key);
              // console.log(staff);
              // when I disconnect, remove this device
              _connectionsRef.onDisconnect().remove();
              _connection.onDisconnect().remove();
              myStaffRef.child('shouldRefresh').onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: moment().format('x')
              });
            } else if (/tvOS|xbox/i.test(ua)) {
              //tv platforms
              var _date4 = moment().format('x');
              var _connection2 = myStaffRef.child('connections').push(true);
              var _connectionsRef2 = firebase.database().ref('connections/' + _connection2.key);
              _connectionsRef2.update({
                device: 'tv platforms',
                time: _date4
              });

              newConnectionHistory.update({
                device: 'tv platforms',
                timeOn: _date4,
                user: staff.get('user.id'),
                account: staff.get('id')
              });
              _this.store.findRecord('connection', _connectionsRef2.key);
              // when I disconnect, remove this device
              _connectionsRef2.onDisconnect().remove();
              _connection2.onDisconnect().remove();
              myStaffRef.child('shouldRefresh').onDisconnect().remove();
              newConnectionHistory.onDisconnect().update({
                timeOff: moment().format('x')
              });
            }
            var _date = moment().format('x');
            // when I disconnect, update the last time I was seen online
            myStaffRef.onDisconnect().update({ lastOnline: _date });
            lastUserOnline.onDisconnect().update({ activity: _date });
          }
        }, function (error) {
          console.log(error);
        });
      }).catch(function (error) {
        console.log(error);
      });
    }
  });
});