define('fund-figure-app/services/asset-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    store: Ember.inject.service(),

    vehicleAssets: Ember.computed.filterBy('allAssets', 'vehicle', true).property('allAssets.[]'),
    vehicleValues: Ember.computed.mapBy('vehicleAssets', 'currentValue'),
    totalVehicleValue: Ember.computed.sum('vehicleValues'),

    buildingAssets: Ember.computed.filterBy('allAssets', 'building', true).property('allAssets.[]'),
    buildingValues: Ember.computed.mapBy('buildingAssets', 'currentValue'),
    totalBuildingValue: Ember.computed.sum('buildingValues'),

    equipmentAssets: Ember.computed.filterBy('allAssets', 'equipment', true).property('allAssets.[]'),
    equipmentValues: Ember.computed.mapBy('equipmentAssets', 'currentValue'),
    totalEquipmentValue: Ember.computed.sum('equipmentValues'),

    infrastructureAssets: Ember.computed.filterBy('allAssets', 'infrastructure', true).property('allAssets.[]'),
    infrastructureValues: Ember.computed.mapBy('infrastructureAssets', 'currentValue'),
    totalInfrastructureValue: Ember.computed.sum('infrastructureValues'),

    taxDeedAssets: Ember.computed.filterBy('allAssets', 'taxDeeded', true).property('allAssets.[]'),
    taxDeedValues: Ember.computed.mapBy('taxDeedAssets', 'currentValue'),
    totalTaxDeedValue: Ember.computed.sum('taxDeedValues'),

    landAssets: Ember.computed.filterBy('allAssets', 'land', true).property('allAssets.[]'),
    landValues: Ember.computed.mapBy('landAssets', 'currentValue'),
    totalLandValue: Ember.computed.sum('landValues'),

    // totalToSave: computed('allAssets', {

    // })

    init: function init() {
      this._super.apply(this, arguments);
      // get(this, 'allAssets');
    },


    currentFund: Ember.computed('connectedUser.currentFund', {
      get: function get(key) {
        return Ember.get(this, 'connectedUser.currentFund');
      }
    }),

    assetDb: Ember.computed('currentFund.assetDb.[]', 'currentFund', {
      get: function get(key) {
        return Ember.get(this, 'currentFund.assetDb').then(function (res) {
          return res;
        });
      }
    }),

    assets: Ember.computed('assetDb.assets.[]', {
      get: function get(key) {
        var _this2 = this;

        console.log('getting assets');

        return Ember.get(this, 'assetDb').then(function (assetDb) {
          if (!assetDb) {
            throw new Error('No Asset Db');
          } else {
            return assetDb.get('assets');
          }
        }).then(function (res) {
          console.log(res);
          Ember.set(_this2, 'allAssets', res);
          return res;
        });
      }
    }).property('refresh'),

    allAssets: Ember.computed('assets.[]', 'assets.@each.cost', 'assets.@each.valueNew', {
      get: function get(key) {
        console.log('getting assets from assets');
        return Ember.get(this, 'assets');
      },
      set: function set(key, value) {
        return value;
      }
    }),

    cipAssets: Ember.computed('allAssets.@each.cip_total_save', 'allAssets.@each.cip_cost_per_year', {
      get: function get() {
        return Ember.get(this, 'allAssets').filter(function (a) {
          if (a.toJSON().cipFund) {
            return true;
          } else {
            return false;
          }
        });
      }
    }),

    deleteAsset: function deleteAsset(asset) {
      console.log(asset.toJSON());
      if (!asset.toJSON().transactions || asset.toJSON().transactions.length === 0) {
        var bigBrother = Ember.get(this, 'bigBrother'),
            _this = this,
            globalMessage = Ember.get(this, 'globalMessaging'),
            undo = Ember.get(this, 'undo'),
            desc = 'Add ' + asset.get('description') + ' back to asset list',
            //only used if sending to undo service
        logDesc = 'removed asset ' + asset.get('description'); //description of what changed
        // undoObject = { model: asset, desc: desc, changes: null, logDesc: logDesc };

        return Ember.get(this, 'connectedUser.currentFund.assetDb').then(function (assetDb) {
          assetDb.get('assets').removeObject(asset);
          return assetDb.save();
        }).catch(function (err) {
          throw new Error(err);
        }).then(function (savedAssetDb) {
          return undo.createUndo(asset, true, savedAssetDb, 'assets', desc, logDesc);
        }).then(function (asset) {
          return asset.destroyRecord();
        }).then(function () {
          _this.notifyPropertyChange('assetChanged');
          // bigBrother.logChange(undoObject);
          globalMessage.actionNotification('Removed Asset ' + asset.get('description'), 'success');
        }).catch(function (err) {
          throw new Error(err);
        });
      } else {
        alert('Asset has associated transactions and cannot be removed, did you mean to decommission the asset?');
      }
    },
    editAsset: function editAsset(asset) {
      Ember.set(this, 'connectedUser.modalName', 'dashboard-assets/asset/edit-asset');
      Ember.set(this, 'currentAsset', asset);
      Ember.set(this, 'connectedUser.showModal', true);
    },
    createAsset: function createAsset(asset) {
      var _this3 = this;

      var globalMessage = Ember.get(this, 'globalMessaging'),
          bigBrother = Ember.get(this, 'bigBrother'),
          _this = this;
      return asset.save().then(function (savedAsset) {
        Ember.get(_this3, 'connectedUser.currentFund.assetDb').then(function (assetDb) {
          assetDb.get('assets').addObject(savedAsset);
          return assetDb.save();
        });
        // get(this, 'assetInfo.allAssets').addObject(savedAsset);
        // get(this, 'assetInfo.notifyAssetChanged');
        var desc = 'Undo changes made to  ',
            //only used if sending to undo service
        logDesc = 'added asset ' + asset.get('description'),
            //description of what changed
        undoObject = { model: asset, desc: desc, changes: null, logDesc: logDesc };
        bigBrother.logChange(undoObject);
        globalMessage.actionNotification('Saved New Asset ' + asset.get('description'), 'success');
        _this3.notifyPropertyChange('refresh');
        return savedAsset;
      }).catch(function (err) {
        throw new Error(err);
      });
    },
    saveAsset: function saveAsset(asset) {
      var _this4 = this;

      var bigBrother = Ember.get(this, 'bigBrother'),
          _this = this,
          globalMessage = Ember.get(this, 'globalMessaging'),
          undo = Ember.get(this, 'undo'),
          assetInfo = asset.get('description') || asset.get('depModel') || asset.id,
          desc = 'Undo changes made to  ' + assetInfo,
          //only used if sending to undo service
      logDesc = 'Saved asset ' + assetInfo; //description of what changed
      return undo.createUndo(asset, false, null, null, desc, logDesc).catch(function (err) {
        throw new Error('error creating undo');
      }).then(function () {
        return asset.save();
      }).catch(function (err) {
        throw new Error('error saving asset model');
      }).then(function (savedAsset) {
        globalMessage.actionNotification('Saved Asset', 'success');
        Ember.set(_this4, 'connectedUser.showModal', false);
        Ember.set(_this4, 'connectedUser.modalName', null);
        Ember.set(_this4, 'currentAsset', null);
        _this4.singleAssetGanttData(savedAsset);
        _this4.notifyPropertyChange('refresh');
        Ember.get(_this4, 'assets');
        return savedAsset;
      });
    },
    singleAssetGanttData: function singleAssetGanttData(model) {
      console.log('data changed');
      var object = {};
      object["name"] = model.get('name') || null;
      object["model"] = model.get('model.name') || null;
      object["modelYear"] = model.get('modelYear') || null;
      if (model.get('vehicle') || model.get('equipment')) {
        if (!model.get('depModel')) {
          object["depModel"] = model.get('name');
        } else {
          object["depModel"] = model.get('depModel').replace(/_/g, ' ').replace(/\w*\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }) || null;
        }
      } else if (model.get('building')) {
        object["depModel"] = model.get('name') || null;
      } else if (model.get('land') || model.get('taxDeeded')) {
        object["depModel"] = model.get('propertyLoc') || null;
      } else {
        object["depModel"] = model.get('name');
      }
      object["lifeSpan"] = model.get('lifeSpan') || null;
      object["cost"] = model.get('cost') || null;
      object["cipFund"] = model.get('cipFund.fund_name') || null;
      object["department"] = model.get('municipal-department.name') || null;
      object["condition"] = model.get('condition') || null;
      object["acquired"] = model.get('acquired') || null;
      object["disposed"] = model.get('disposed') || null;
      object["replacementYear"] = model.get('replacementYear').valueOf() || null;
      object["cip_total_save"] = model.get('cip_total_save') || null;
      object["cip_cost_per_year"] = model.get('cip_cost_per_year') || null;

      Ember.set(this, 'data', [object]);
      console.log([object]);
      this.notifyPropertyChange('data');
      return [object];
    },
    multiAssetGanttData: function multiAssetGanttData(models) {
      var cipAssets = models.map(function (a) {
        console.log(a);
        var object = {};
        object["name"] = a.get('name') || null;
        object["model"] = a.get('model.name') || null;
        object["modelYear"] = a.get('modelYear') || null;
        if (a.get('vehicle') || a.get('equipment')) {
          if (!a.get('depModel')) {
            object["depModel"] = a.get('name');
          } else {
            object["depModel"] = a.get('depModel').replace(/_/g, ' ').replace(/\w*\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }) || null;
          }
        } else if (a.get('building')) {
          object["depModel"] = a.get('name') || null;
        } else if (a.get('land') || a.get('taxDeeded')) {
          object["depModel"] = a.get('propertyLoc') || null;
        } else {
          object["depModel"] = a.get('name');
        }
        object["lifeSpan"] = a.get('lifeSpan') || null;
        object["cost"] = a.get('cost') || null;
        object["cipFund"] = a.get('cipFund.fund_name') || null;
        object["department"] = a.get('municipal-department.name') || null;
        object["condition"] = a.get('condition') || null;
        object["acquired"] = a.get('acquired') || null;
        object["disposed"] = a.get('disposed') || null;
        object["replacementYear"] = a.get('replacementYear').valueOf() || null;
        object["cip_total_save"] = a.get('cip_total_save') || null;
        object["cip_cost_per_year"] = a.get('cip_cost_per_year') || null;
        return object;
      });
      if (cipAssets.length > 0) {
        // let data = nest().key(d => d.depModel).object(cipAssets);
        console.log(cipAssets);
        Ember.set(this, 'multiData', cipAssets);
      } else {
        Ember.get(this, 'globalMessaging').actionNotification('No assets are in a capital improvement plan', 'warning');
      }
    }
  });
});