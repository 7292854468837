define('fund-figure-app/components/dashboard/settings/manage-funds/fund-create/step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    fundTypes: Ember.computed('', {
      get: function get() {
        var store = this.get('store');
        return store.findAll('municipal-fund-type');
      }
    }),
    actions: {
      setFundType: function setFundType(fundType) {
        var model = this.get('model'),
            router = this.get('router');
        model.set('municipalFundType', fundType);
        router.transitionTo('dashboard.settings.manage-funds.fund-create.step-two');
      }
    }
  });
});