define('fund-figure-app/components/dashboard-support/manage-funds/manage-chart-of-accounts/chart-of-account/chart-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    appError: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    undo: Ember.inject.service(),
    editAccounts: false,

    states: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('state');
      }
    }).property('init'),
    stateReports: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('report');
      }
    }).property('init'),
    townReports: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('town-report');
      }
    }).property('init'),
    visualReports: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('visual-report');
      }
    }).property('init'),
    departments: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('municipal-department');
      }
    }).property('init'),
    accountNumbers: Ember.computed('model', {
      get: function get() {
        var model = this.get('model');
        if (!model) {
          return;
        }
        var query = model.toJSON().state + '::' + model.get('id');
        return this.get('store').query('account-number', {
          orderBy: 'setupSearch',
          equalTo: query
        });
      }
    }),
    actions: {
      save: function save(model) {
        model.save().then(function (savedModel) {
          savedModel.get('fundType').then(function (fundType) {
            fundType.get('chartOfAccounts').addObject('savedModel');
            fundType.save();
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      },
      saveAccountEdit: function saveAccountEdit(account) {
        var undoObjects = this.get('undo.undoObjects'),
            bigBrother = this.get('bigBrother'),
            changes = account.changedAttributes(),
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            changesObject = [],
            message = 'Saved ' + account.get('friendlyName');
        if (changes) {
          console.log(changes);
          for (var key in changes) {
            var newSub = '{ "' + key + '" : {"old": "' + String(changes[key].slice(0, 1)).replace(/"/g, '\\\"') + '", "new": "' + String(changes[key].slice(1)).replace(/"/g, '\\\"') + '" } }';
            console.log(newSub);
            changesObject.addObject(JSON.parse(newSub));
          }
          var desc = 'Undo changes made to ' + account.get('friendlyName'),
              logDesc = '' + account.get('friendlyName'),
              undoObject = { model: account, desc: desc, changes: changesObject, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undoObjects.addObject(undoObject);
          console.log(undoObjects);
          account.save().catch(function (err) {
            console.log(err);
          });
        }
      },
      editAccountsToggle: function editAccountsToggle() {
        this.toggleProperty('editAccounts');
      },
      setEditAccount: function setEditAccount(account) {
        this.set('editAccount', account);
      },
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('model');
        this.get('documentManager').saveDocument(fileInfo, 'general').then(function (res) {
          model.get('documents').addObject(res);
          model.save();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while saving document';
          appError.logError(err, specialMessage);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('model'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            fund = this.get('connectedUser.currentFund'),
            appError = this.get('appError'),
            deleteDocument = this.get('documentManager'),
            _this = this;
        deleteDocument.removeDocument(file).then(function (res) {
          model.get('documents').removeObject(res);
          model.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving the deposit after removing document';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error removing the document either from storage or from database';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});