define('fund-figure-app/models/account-number', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    accountNumber: _emberData.default.attr('number'),
    friendlyName: _emberData.default.attr('string'), //account name for our internal display
    stateName: _emberData.default.attr('string'), //name used on state reports
    appropriation: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    warrant: _emberData.default.attr('boolean', { defaultValue: false }), //Marked on account for finalizing a warrant
    uncollected: _emberData.default.attr('boolean', { defaultValue: false }), //Marked on account for receiving taxes
    // below was added to potential remove risk of moving money to accounts that shouldnt be able to receive it
    // this may be added in future
    // canTransferTo: DS.hasMany('account-number', {async:true, inverse:null}),
    classification: _emberData.default.attr('string'),
    transactDebit: _emberData.default.attr('boolean'), //Normal balance is debit aka debit increases balance
    // transactCredit:DS.attr('boolean'), //Normal balance is credit aka credit increases balance
    rev: _emberData.default.attr('string'),
    subRev: _emberData.default.attr('string'),
    state: _emberData.default.belongsTo('state', { async: true, inverse: null }),
    custodian: _emberData.default.hasMany('municipalCustodian'),
    update: _emberData.default.attr('boolean'),
    municipalDepartment: _emberData.default.belongsTo('municipal-department'),
    setupSearch: _emberData.default.attr('string'), //this string is the state ID concat with fund type ie general fund for NH is -L4Tcd3B2MDRvYb-fjNc::-dfghhdfgh
    searchField: Ember.computed('accountNumber', 'friendlyName', 'stateName', {
      get: function get() {
        var accountNumber = this.get('accountNumber'),
            friendlyName = this.get('friendlyName'),
            stateName = this.get('stateName');
        return accountNumber + friendlyName + stateName;
      }
    })
  });
});