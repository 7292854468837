define('fund-figure-app/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() /* transition */{
      this.transitionTo('dashboard'); // Implicitly aborts the on-going transition.
    }
  });
});