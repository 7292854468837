define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoice-item/transaction-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		updateInvoiceTransactionArray: Ember.computed('invoiceItems', 'transaction', {
			get: function get(key) {
				var item = this.get('transaction');
				this.get('invoiceItems').addObject(item);
			}
		})
	});
});