define('fund-figure-app/models/municipal-db', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    info: _emberData.default.belongsTo('municipalities', { async: true, inverse: null }),
    municipalYearsDb: _emberData.default.belongsTo('municipal-years-db', { async: true, inverse: 'municipality' }),
    municipalUsers: _emberData.default.hasMany('municipal-user'),
    municipalSetting: _emberData.default.belongsTo('municipal-setting'),
    municipalVendorsDb: _emberData.default.belongsTo('municipal-vendors-db'),
    globalVendorsDb: _emberData.default.belongsTo('municipal-vendors-db'),
    payrollDb: _emberData.default.belongsTo('payroll-db'),
    managesPayrollTaxes: _emberData.default.attr('boolean'),
    auditDashboard: _emberData.default.attr('boolean'), //activate audit dashboard
    paymentInfo: _emberData.default.belongsTo('municipalPaymentInfo', { async: true, inverse: null })
  });
});