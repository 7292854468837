define('fund-figure-app/components/dashboard/feedback-messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),

    messages: null,

    sortedFeedback: Ember.computed('feedbackAll.@each.addressed', 'messages.@each.addressed', function () {
      if (!this.get('connectedUser.currentAccount')) {
        return;
      }
      return Ember.get(this, 'connectedUser.currentAccount.conversations').toArray().sort(function (a, b) {
        if (a.get('timestamp') < b.get('timestamp')) {
          return 1;
        }
        if (a.get('timestamp') > b.get('timestamp')) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }).sort(function (a, b) {
        if (!a.get('addressed')) {
          return 1;
        } else {
          return -1;
        }
      });
    }),

    actions: {
      newConversation: function newConversation(mUser) {
        store = this.get('store'), municipality_id = this.get('connectedUser.fiscalYear').toJSON().municipality, municipality = store.find('municipalDb', municipality_id), newFeedback = store.createRecord('feedback', {
          municipalUser: mUser,
          municipality: municipality,
          timestamp: moment().valueOf()
        }), newChatRoom = store.createRecord('feedback-chat-room', {
          name: 'Name...'
        });

        newChatRoom.get('members').addObject(mUser);
        newFeedback.set('feedbackChatRoom', newChatRoom);
        newFeedback.save().then(function (savedFeedback) {
          mUser.get('conversations').addObject(savedFeedback);
          newChatRoom.save().then(function () {
            mUser.save().catch(function (err) {
              console.log(err);
            });
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }

  });
});