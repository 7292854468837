define('fund-figure-app/components/dashboard/personnel/employees/employees-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    undo: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    showHiddenPersonnel: false,
    sortedPersonnel: Ember.computed.sort('personnelToShow', function (a, b) {
      if (a.toJSON().employee_firstName > b.toJSON().employee_firstName) {
        return 1;
      } else if (a.toJSON().employee_firstName < b.toJSON().employee_firstName) {
        return -1;
      } else {
        return 0;
      }
    }),
    personnelToShow: Ember.computed('personnelCurrent', 'personnelHidden', 'showHiddenPersonnel', {
      get: function get() {
        if (Ember.get(this, 'showHiddenPersonnel')) {
          return Ember.get(this, 'personnelHidden');
        } else {
          return Ember.get(this, 'personnelCurrent');
        }
      }
    }),
    personnelCurrent: Ember.computed.filterBy('employees', 'deactivated', false),
    personnelHidden: Ember.computed.filterBy('employees', 'deactivated', true),
    employees: Ember.computed('connectedUser.employees', 'connectedUser.employees_DB.vendors', 'connectedUser.currentAccount.municipality.municipalVendorsDb.vendors.[]', {
      get: function get() {
        return this.get('connectedUser.employees');
      }
    }),
    actions: {
      deleteEmployee: function deleteEmployee(employee) {
        var d = employee.toJSON(),
            _this = this,
            globalMessage = this.get('globalMessaging');
        if (d.municipalChecks || d.municipalInvoices) {
          return globalMessage.actionNotification('Cannot delete employee that has received checks, try hiding instead', 'warning');
        }
        var employees = this.get('connectedUser.employees'),
            employees_DB = this.get('connectedUser.vendors_DB'),
            undoList = this.get('undo.undoObjects'),
            description = 'Add ' + d.employee_firstName + ' ' + d.employee_lastName + ' back to employee list',
            undoObject = { model: employee, description: description, modelDeleted: true, secondaryModel: ['{"' + employees_DB.get('id') + '":"employees"}'] };
        undoList.addObject(undoObject);
        employees_DB.get('vendors').removeObject(employee);
        employees.removeObject(employee);
        employees_DB.save().then(function () {
          employee.destroyRecord();
          _this.get('connectedUser.buildEmployees');
        });
      },
      hideVendor: function hideVendor(employee) {
        var globalMessage = this.get('globalMessaging');
        employee.toggleProperty('deactivated');
        employee.save().catch(function (err) {
          globalMessage.actionNotification('Error changing employee status', 'warning');
        });
      },
      addNewVendor: function addNewVendor(employeeName) {
        var store = this.get('store'),
            _this = this,
            router = this.get('router'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            get_municipality = fiscalYear.get('municipality');

        get_municipality.then(function (municipality) {
          return municipality.get('municipalVendorsDb');
        }).then(function (municipalVendorsDb) {
          var newVendor = store.createRecord('vendor', {
            employee_firstName: employeeName,
            employee: true
          });
          municipalVendorsDb.get('vendors').addObject(newVendor);
          municipalVendorsDb.save();
          return newVendor.save().then(function (savedEmployee) {
            municipalVendorsDb.get('vendors').addObject(savedEmployee);
            municipalVendorsDb.save();
            return savedEmployee;
          });
        }).then(function (employeeSaved) {
          return _this.get('connectedUser.buildEmployees').then(function () {
            return employeeSaved;
          });
        }).then(function (employeeSaved) {
          router.transitionTo("dashboard.personnel.employees.employee.employee-edit", employeeSaved);
        }).catch(function (err) {
          console.log(err);
        });
      },
      toggleHiddenPersonnel: function toggleHiddenPersonnel() {
        this.toggleProperty('showHiddenPersonnel');
      }
    }
  });
});