define('fund-figure-app/helpers/role-decode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roleDecode = roleDecode;
  function roleDecode(params /*, hash*/) {
    switch (params[0]) {
      case 30:
        return 'Public';
      case 40:
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
      case 46:
      case 47:
        return 'Administrative Assistant';
      case 48:
        return 'Department Assistant';
      case 49:
        return 'Budget Committee';
      case 50:
        return 'Select Board';
      case 51:
        return 'Police Dept';
      case 52:
        return 'Fire Dept';
      case 53:
        return 'Highway Dept';
      case 54:
        return 'Assessing Dept';
      case 55:
      case 56:
      case 57:
      case 58:
        return 'Human Resources';
      case 59:
        return 'Department Head';
      case 60:
      case 61:
      case 62:
      case 63:
      case 64:
      case 65:
      case 66:
      case 67:
        return 'Accountant'; //municipal
      case 68:
        return 'Finance Staff'; //municipal
      case 69:
        return 'Finance Administrator'; //municipal
      case 70:
        return 'Administrator'; //municipal
      case 71:
      case 72: //althetic coach
      case 73:
      case 74: //substitute
      case 75:
      case 76:
      case 77:
      case 78:
      case 79:
        return 'Fund Figure Accountant';
      case 80:
        return 'Fund Figure Moderator';
      case 81:
      case 82:
      case 83:
      case 84:
      case 85: //assistant
      case 86:
      case 87: //teacher
      case 88:
      case 89:
        return 'Fund Figure Administrator';
      case 90:
        return 'Fund Figure Support';
      case 91:
        return 'Fund Figure Support - Beta';
      case 92:
        return 'Fund Figure Beta';
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 100:
        return 'Fund Figure Super User';
      default:
        return null;
        break;
    }
  }

  exports.default = Ember.Helper.helper(roleDecode);
});