define('fund-figure-app/components/dashboard/settings/bank-accounts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		globalMessaging: Ember.inject.service(),
		store: Ember.inject.service(),
		actions: {
			save: function save(model) {
				var message = this.get('globalMessaging');
				model.toggleProperty('hide');
				model.save().then(function () {
					message.actionNotification('Saved', 'success');
				}).catch(function (error) {
					console.log(error);
					message.actionNotification(error, 'warning');
				});
			},
			cancel: function cancel(model) {
				var message = this.get('globalMessaging');
				var store = this.get('store');
				var record = store.peekRecord('municipalities', model);
				record.rollbackAttributes().then(function () {
					message.actionNotification('Removed Changes', 'success');
				}).catch(function (error) {
					console.log(error);
					message.actionNotification(error, 'warning');
				});
			}
		}
	});
});