define('fund-figure-app/components/account-handler/create-step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    addingInfo: false,

    nhTowns: Ember.computed('', function () {
      var store = this.get('store');
      return store.findAll('municipalities');
    }),

    actions: {
      saveInfo: function saveInfo(userModel, town) {
        var _this = this;

        this.set('addingInfo', true);
        var store = this.get('store'),
            router = this.get('router'),
            connectedUser = this.get('connectedUser'),

        // avatar = store.createRecord('avatar'),
        municipalDB = store.createRecord('municipal-db', {
          info: town
        });
        // avatar.save();

        var municipalUser = store.createRecord('municipal-user', {
          user: userModel,
          role: 90
        });
        municipalDB.get('municipalUsers').addObject(municipalUser);

        municipalDB.save().then(function (muni_Db) {
          var routeString = 'account-handler.account-create-step-three/' + muni_Db.get('id');
          municipalUser.setProperties({ is_new: routeString, municipality: muni_Db });
          return municipalUser.save();
        }).then(function (muniUser) {
          connectedUser.set('currentAccount', muniUser);
          userModel.get('municipalUsers').addObject(muniUser);
          userModel.set('is_new', null);
          return userModel.save();
        }).then(function () {
          _this.set('addingInfo', false);
          router.transitionTo('account-handler.account-create-step-three', municipalDB.get('id'));
        }).catch(function (error) {
          console.log('error saving user with new municipal account');
          console.log(error);
        });
      }
    }
  });
});