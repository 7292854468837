define('fund-figure-app/components/d3/visual-bubbles', ['exports', 'd3-selection', 'd3-scale', 'd3-force', 'd3-drag', 'd3-array', 'd3-scale-chromatic'], function (exports, _d3Selection, _d3Scale, _d3Force, _d3Drag, _d3Array, _d3ScaleChromatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['visual-bubbles'],
    width: null,
    height: null,
    // colorScheme: null,

    // getColorScheme: computed('data', 'colorScheme', {
    //   get() {
    //     return get(this, 'colorScheme')
    //   },
    //   set(key, value) {
    //     console.log('key', key);
    //     console.log('value', value);
    //   }
    // }),

    drawChart: Ember.computed('data', function () {
      var data = this.get('data'),
          radius = 1,
          colorScheme = this.get('colorScheme'),
          _this = this;
      if (data === undefined || data === null) {
        console.log('no data');
        return 0;
      }
      var store = this.get('store'),
          cfg = {
        marginChart: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }
      },
          parentWidth = this.get('width') || this.$().parent().width() || 400,
          parentHeight = this.get('height') || this.$().parent().height() || 400,
          width = parentWidth - cfg.marginChart.left - cfg.marginChart.right,
          height = parentHeight - cfg.marginChart.top - cfg.marginChart.bottom,
          z = (0, _d3Scale.scaleOrdinal)(_d3ScaleChromatic.schemeCategory10),

      // pack = pack()
      // .size([width, height])
      // .padding(1.5),
      svg = (0, _d3Selection.select)(this.element);
      svg.select('g').remove();
      z.domain(data.map(function (c) {
        c.source;
      }));
      console.log('drawing graph links');
      svg.attr('width', width).attr('height', height);

      var scaleRadius = (0, _d3Scale.scaleLinear)().domain([(0, _d3Array.min)(data, function (d) {
        return +d.budget;
      }), (0, _d3Array.max)(data, function (d) {
        return +d.budget;
      })]).range([50, 200]);

      var force = (0, _d3Force.forceCollide)().radius(function (d) {
        return scaleRadius(+d.budget);
      }).iterations(1);

      var simulation = (0, _d3Force.forceSimulation)(data).force('charge', (0, _d3Force.forceManyBody)()).force('collide', force).force('x', (0, _d3Force.forceX)(width / 2)).force('y', (0, _d3Force.forceY)(height / 2)).on('tick', tick);

      var tooltip = (0, _d3Selection.select)("body").append("div").classed('well-white radius padding-20', true).style("position", "absolute").style("z-index", "9999999").style("visibility", "hidden").style("top", 0).style("left", 0);

      var g = svg.append("g"),
          background = g.append("g").append('rect').attr('width', width).attr('height', height).attr('opacity', 0).on('mousedown', mousedownCanvas),
          circle = g.append("g").selectAll(".circle"),
          AccountTitle = g.append("g").attr("class", "texts").selectAll("text"),
          accountBudget = g.append("g").attr("class", "texts").selectAll("text");

      restart();

      function restart() {

        circle = circle.data(data);
        circle.exit().remove();
        circle = circle.enter().append("circle").style("fill", function (d) {
          return z(d.source);
        }).attr("r", function (d) {
          return scaleRadius(+d.budget);
        }).attr('stroke', "#e8eaea").attr('stroke-width', 4).merge(circle).on('click', selectNode).call((0, _d3Drag.drag)().on("start", dragstarted).on("drag", dragged).on("end", dragended));
        // .on('mouseenter', hover)        
        // .on('mouseleave', noHover) 

        AccountTitle = AccountTitle.data(data);
        AccountTitle.exit().remove();
        AccountTitle = AccountTitle.enter().append("text").attr("font-size", function (d) {
          return scaleRadius(+d.budget) / 5.5;
        }).attr("dx", 0)
        // .attr("dy", d => -scaleRadius(d.budget) / 5)
        .attr("dy", 0).style('text-anchor', 'middle').attr('radius', function (d) {
          return scaleRadius(+d.budget);
        }).text(function (d) {
          return d.accountName;
        }).call(wrap)
        // .text(function(d){
        //  return wrap(d.accountName, scaleRadius(d.budget), this)
        // })
        .merge(AccountTitle);

        accountBudget = accountBudget.data(data);
        accountBudget.exit().remove();
        accountBudget = accountBudget.enter().append("text").attr("font-size", function (d) {
          return scaleRadius(+d.budget) / 3.5;
        }).style("font-weight", "700").style('fill', "whitesmoke").attr("dx", 0).attr("dy", function (d) {
          return -scaleRadius(+d.budget) / 3;
        }).style('text-anchor', 'middle').text(function (d, element) {
          // return getName(d, this);
          return Number(d.budget).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }).merge(accountBudget);

        // Update and restart the simulation.
        simulation.nodes(data);
        // simulation.force("link").links(links);
        simulation.alpha(1).restart();
      }

      function wrap(text) {
        text.each(function () {
          var text = (0, _d3Selection.select)(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              width = text.attr('radius'),
              lineNumber = 0,
              lineHeight = 1,
              // ems
          y = text.attr("y"),
              x = text.attr('x'),
              dy = parseFloat(text.attr("dy")),
              tspan = text.text(null).append("tspan").attr('x', x).attr("dy", dy + "em");
          while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr('x', x).attr("dy", lineHeight + dy + 'em').text(word);
            }
          }
        });
      }

      function tick() {
        circle.attr('cx', function (d) {
          return d.x = Math.max(scaleRadius(+d.budget), Math.min(width - scaleRadius(+d.budget), d.x));
        }).attr('cy', function (d) {
          return d.y = Math.max(scaleRadius(+d.budget), Math.min(height - scaleRadius(+d.budget), d.y));
        });
        AccountTitle.attr('x', function (d) {
          return d.x = Math.max(scaleRadius(+d.budget), Math.min(width - scaleRadius(+d.budget), d.x));
        }).attr('y', function (d) {
          return d.y = Math.max(scaleRadius(+d.budget), Math.min(height - scaleRadius(+d.budget), d.y));
        }).selectAll('tspan').attr('x', function (d) {
          return d.x = Math.max(scaleRadius(+d.budget), Math.min(width - scaleRadius(+d.budget), d.x));
        });
        accountBudget.attr('x', function (d) {
          return d.x = Math.max(radius, Math.min(width - radius, d.x));
        }).attr('y', function (d) {
          return d.y = Math.max(radius, Math.min(height - radius, d.y));
        });
      }

      function hover(d) {
        if (_d3Selection.event.defaultPrevented) return;
        (0, _d3Selection.select)(this).attr('stroke', '#e54e43').text(function (d) {
          return 'hey there';
        });
        tooltip.style("visibility", "visible").style("top", d.y + 50 + 'px').style("left", d.x + 50 + 'px').text(Number(d.budget).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
      }

      function noHover(d) {
        if (_d3Selection.event.defaultPrevented) return;
        (0, _d3Selection.select)(this).attr('stroke', '#e8eaea');
        tooltip.style("visibility", "hidden");
      }

      function dragstarted(d) {
        if (!_d3Selection.event.active) {
          simulation.alphaTarget(0.3).restart();
        }
        _d3Selection.event.subject.fx = _d3Selection.event.subject.x;
        _d3Selection.event.subject.fy = _d3Selection.event.subject.y;
      }

      function dragged(d) {
        _d3Selection.event.subject.fx = _d3Selection.event.x;
        _d3Selection.event.subject.fy = _d3Selection.event.y;
      }

      function dragended(d) {
        if (!_d3Selection.event.active) simulation.alphaTarget(0);
        _d3Selection.event.subject.fx = null;
        _d3Selection.event.subject.fy = null;
      }

      function mousedownCanvas(d, i) {
        _this.canvasClick(d);
      }

      function selectNode(d, i) {
        if (_d3Selection.event.defaultPrevented) return;
        console.log(d.model.toJSON().subAccounts);
        if (d.model.toJSON().subAccounts.length > 1) {
          data.clear();
          // data.push({d});       
          restart();
          d.model.get('subAccounts').forEach(function (sub) {
            var JSON = sub.toJSON();
            var object = {};
            if (JSON.nickname) {
              object['accountName'] = JSON.nickname;
            } else {
              object['accountName'] = 'No Name';
            }
            object['model'] = sub;
            object['source'] = JSON.function;
            object['budget'] = JSON.budget;
            // subArray.push(object);
            data.push(object);
          });
          if (data.length == d.model.toJSON().subAccounts.length) {
            console.log('restart');
            scaleRadius = (0, _d3Scale.scaleLinear)().domain([(0, _d3Array.min)(data, function (d) {
              return +d.budget;
            }), (0, _d3Array.max)(data, function (d) {
              return +d.budget;
            })]).range([75, 125]);
            // data.push(d);
            // _this.set('data', subArray);
            restart();
          }
        } else {
          return;
        }
      }

      function mousedownNode(d, i) {
        if (i == 0) {
          return;
        }
        nodes.splice(i, 1);
        links = links.filter(function (l) {
          return l.source !== d && l.target !== d;
        });
        userNetwork.get('supporters').removeObject(d.model);
        userNetwork.save().then(function () {
          d.model.get('avatar').then(function (res) {
            return res.destroyRecord();
          }).then(function () {
            return d.model.destroyRecord();
          }).catch(function (err) {
            console.log(err);
          });
        }).then(function () {
          restart();
        }).catch(function (err) {
          console.log(err);
        });
        _d3Selection.event.stopPropagation();
      }
    })
  });
});