define('fund-figure-app/components/feedback-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    store: Ember.inject.service(),
    feedbackDisplay: false,
    sendFeedback: true,
    feedbacks: [],
    chatRooms: [],

    getFeedback: Ember.computed('connectedUser.currentAccount', 'feedbackDisplay', function () {
      return Ember.get(this, 'connectedUser.user_messages');
    }),
    actions: {
      toggleFeedbackDisplay: function toggleFeedbackDisplay() {
        this.toggleProperty('feedbackDisplay');
      },
      toggleFeedbackMenu: function toggleFeedbackMenu(show) {
        this.setProperties({
          sendFeedback: false,
          chat: false
        });
        this.toggleProperty(show);
      },
      sendFeedback: function sendFeedback(feedBack, userAccount) {
        if (!feedBack) {
          return;
        }
        var _this = this,
            globalMessage = Ember.get(this, 'globalMessaging'),
            feedRef = Ember.get(this, 'firebaseApp').database().ref('/null/').push(),
            municipality = Ember.get(this, 'connectedUser.currentAccount.municipality'),
            store = Ember.get(this, 'store'),
            ts = moment().valueOf(),
            url = window.location.href,
            newFeedRef = 'feedback' + feedRef.key,
            newChatRoomRef = 'feedback-chat-room' + feedRef.key,
            chatRoomModel = store.createRecord('feedback-chat-room', {
          id: newChatRoomRef,
          timestamp: ts,
          last_chat: moment().valueOf()
        });
        chatRoomModel.get('members').addObject(userAccount);
        municipality.then(function (mun) {
          var feedbackModel = store.createRecord('feedback', {
            id: newFeedRef,
            municipality: mun,
            municipalUser: userAccount,
            timestamp: ts,
            feedback: feedBack,
            url: url,
            feedbackChatRoom: chatRoomModel
          });

          feedbackModel.save().then(function (savedFeedback) {
            userAccount.get('conversations').addObject(savedFeedback);
            userAccount.save();
            chatRoomModel.set('feedback', savedFeedback);
            chatRoomModel.save().then(function (savedChat) {
              _this.setProperties({ successMessage: 'Saved', errorMessage: null });
              setTimeout(function () {
                _this.setProperties({ successMessage: null, errorMessage: null, feedbackDisplay: false, notes: null });
              }, 3000);
            }).catch(function (error) {
              feedbackModel.rollBackAttributes();
              globalMessage.actionNotification(error, 'warning');
            });
          }).catch(function (error) {
            globalMessage.actionNotification(error, 'warning');
          });
        }).catch(function (err) {
          globalMessage.actionNotification(error, 'warning');
        });
      }
    }
  });
});