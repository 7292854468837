define('fund-figure-app/components/account-handler/create-with-pin/pin-step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    firebaseApp: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    newUser: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: true, haveAccountType: false, havePin: false, haveStaffConfirm: false });
    },


    actions: {
      saveAccountType: function saveAccountType(accountType, aUser, cost) {
        var hasAccountAlready = aUser.get('accountInfo.id');
        var store = this.get('store');
        var route = this.get('router');
        var getUser = store.findRecord('user', aUser.get('id'));
        getUser.then(function (user) {
          if (typeof hasAccountAlready === 'undefined') {
            var endPeriod = Date.now();
            var accountInfoStore = store.createRecord('account-info', {
              user: user,
              accountType: accountType,
              accountCost: cost,
              endTrialPeriod: endPeriod
            });

            accountInfoStore.save().then(function () {
              user.set('isNew', 'account.user-create-with-pin.step-three');
              user.save().then(function () {
                route.transitionTo('account.user-create-with-pin.step-three');
              }, function (error) {
                console.log('Error : ' + error);
              });
            }, function (error) {
              console.log(error);
            });
          } else {
            var accountInfo = store.findRecord('accountInfo', hasAccountAlready);

            accountInfo.then(function (theAccount) {
              theAccount.set('accountType', accountType);
              theAccount.save().then(function () {
                user.set('isNew', 'account.user-create-with-pin.step-three');
                user.save().then(function () {
                  route.transitionTo('account.user-create-with-pin.step-three');
                }, function (error) {
                  console.log('Error : ' + error);
                });
              }, function (error) {
                console.log(error);
              });
            }, function (error) {
              console.log('Error : ' + error);
            });
          }
        });
      }
    }
  });
});