define('fund-figure-app/models/municipal-payment-info', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		brand: _emberData.default.attr('string'),
		country: _emberData.default.attr('string'),
		created: _emberData.default.attr('number'),
		cards: _emberData.default.hasMany('card', { async: true, inverse: null }),
		current_period_end: _emberData.default.attr('number'),
		current_period_start: _emberData.default.attr('number'),
		municipality: _emberData.default.belongsTo('municipal-db'),
		paid: _emberData.default.attr('boolean'),
		plan: _emberData.default.attr('string'),
		plan_amount: _emberData.default.attr('number'),
		plan_id: _emberData.default.attr('string'),
		plan_status: _emberData.default.attr('string'),
		stripe_id: _emberData.default.attr('string'),
		storageLimit: _emberData.default.attr('number'), //in kilobytes
		storageAmount: _emberData.default.attr('number'), //in kilobytes
		// subscription_id:DS.belongsTo('subscription', {async:true}),
		subscriptions: _emberData.default.hasMany('subscription'),
		default_source: _emberData.default.belongsTo('card', { async: true, inverse: null }), //id to retrieve card info
		delinquent: _emberData.default.attr('boolean'),
		email: _emberData.default.attr('string'),
		invoice_prefix: _emberData.default.attr('string'),
		sources: _emberData.default.attr()
	});
});