define('fund-figure-app/models/municipal-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', { async: true, inverse: 'municipalUsers' }), //Null until a user claims account
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    role: _emberData.default.attr('number'), //assigned by person who created user
    is_new: _emberData.default.attr('string'), //used during creation process, removed once claimed
    connections: _emberData.default.hasMany('connection'),
    shouldRefresh: _emberData.default.attr('boolean'), //Backend can set this to true when changes could affect users, setting true will cause a alert on clients window
    lastOnline: _emberData.default.attr('number'),
    name_first: _emberData.default.attr('string'), //Used until a user claims this account, then name is transfered to parent user account
    name_last: _emberData.default.attr('string'), //Used until a user claims this account, then name is transfered to parent user account
    email: _emberData.default.attr('string'), //Used until a user claims this account, then name is transfered to parent user account
    pin: _emberData.default.attr('string'), //Used to attach a user to created account
    responses: _emberData.default.hasMany('feedback'), //When user receives response link is placed here  
    conversations: _emberData.default.hasMany('feedback'), //List of all user feedback messages
    avatar: _emberData.default.belongsTo('avatar', { inverse: null }),
    deactivated: _emberData.default.attr('boolean')
  });
});