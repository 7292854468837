define('fund-figure-app/components/dashboard/transactions/button-add-transaction', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'a',
		attributeBindings: ['tabindex'],
		tabindex: 5,
		click: function click() {
			this.get('addItem')();
		},
		keyPress: function keyPress() {
			this.get('addItem')();
		}
	});
});