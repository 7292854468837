define('fund-figure-app/controllers/dashboard/budget/edit-main', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		cardView: false,
		lineView: true,
		actions: {
			toggleView: function toggleView(view) {
				console.log(view);
				this.setProperties({ cardView: false, lineView: false });
				this.toggleProperty(view);
			},
			toggleEditAll: function toggleEditAll() {
				this.toggleProperty('editAll');
			}
		}
	});
});