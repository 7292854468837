define('fund-figure-app/models/municipal-fund-type-sub', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    municipalFundType: _emberData.default.belongsTo('municipal-fund-type', { async: true, inverse: null }),
    rev: _emberData.default.attr('string'),
    subRev: _emberData.default.attr('string'),
    lawLink: _emberData.default.attr('string')
  });
});