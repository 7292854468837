define('fund-figure-app/routes/dashboard-support/manage-funds/manage-chart-of-accounts/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var parentModel = this.modelFor('dashboard-support.manage-funds.manage-chart-of-accounts');

      return Ember.RSVP.hash({
        fund: parentModel,
        newChart: this.store.createRecord('chart-of-account', {
          fundType: parentModel
        })
      });
    }
  });
});