define('fund-figure-app/components/dashboard/reconciliation/view-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    check_total: 0,
    bank_invoice_total: 0,

    municipalInvoiceBankTotal: Ember.computed('model.municipalChecks.@each.reconciledBankTransaction', 'model.municipalChecks.[]', {
      get: function get() {
        var _this = this;

        return this.get('model.municipalChecks').then(function (municipal_checks) {
          municipal_checks.forEach(function (check) {
            var check_total = check.get('amount');
            _this.set('check_total', check_total);
            return check.get('reconciledBankTransaction').then(function (bank_transaction) {
              var bank_amount = bank_transaction.get('amount'),
                  bank_check_total = (_this.get('bank_check_total') || 0) + bank_amount;
              _this.set('bank_check_total', bank_check_total);
            }).catch(function (err) {
              console.log(err);
            });
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    })
  });
});