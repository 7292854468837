define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoice-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalMessaging: Ember.inject.service(),
    apiTransaction: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    appError: Ember.inject.service(),
    undo: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      saveInvoice: function saveInvoice(invoice) {
        var globalMessage = this.get('globalMessaging'),
            route = this.get('router');
        Ember.get(this, 'apiTransaction').editInvoice(invoice).then(function (res) {
          route.transitionTo('dashboard.invoices-manifests.invoices');
        }).catch(function (err) {
          throw new Error(err);
        });
        // invoice.save()
        //   .then(() => {
        //     globalMessage.actionNotification('Invoice Saved', 'success');
        //     route.transitionTo('dashboard.invoices-manifests.invoices');
        //   })
        //   .catch(err => {
        //     globalMessage.actionNotification(`An error: ${err}`, 'warning');
        //   })
      },
      saveTransaction: function saveTransaction(transaction) {
        var globalMessage = Ember.get(this, 'globalMessaging'),
            connectedUser = Ember.get(this, 'connectedUser.currentAccount'),
            undo = Ember.get(this, 'undo'),
            appError = Ember.get(this, 'appError'),
            bigBrother = Ember.get(this, 'bigBrother'),
            dataChanges = undo.getChanges(transaction);
        transaction.get('invoice.vendor').then(function (vendorModel) {
          var vendor = vendorModel.get('businessName'),
              desc = 'Undo changes made to invoice for ' + vendor,
              logDesc = '' + vendor,
              undoObject = { model: transaction, desc: desc, changes: dataChanges, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
          transaction.save().then(function () {
            globalMessage.actionNotification('Transaction Updated', 'success');
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error setting filing status on insert of element';
            appError.logError(err, specialMessage);
          });
        });
      }
    }
  });
});