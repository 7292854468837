define('fund-figure-app/components/account-handler/create-step-three', ['exports', 'fund-figure-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    yearly: true,
    yearlyCost: null,
    monthlyCost: null,
    sendingInformation: false,

    costByPop: Ember.computed('municipality.info', function () {
      var _this = this;
      return this.get('municipality.info').then(function (muni) {
        if (muni) {
          return Number(muni.get('population').replace(/,/g, ''));
        } else {
          return 0;
        }
      }).then(function (population) {
        console.log(population);
        var yearly = population,
            monthly = Math.round(yearly / 10, 0),
            stripeMonthlyDesc = '$' + monthly + ' monthly',
            stripeYearlyDesc = '$' + yearly + ' yearly';
        _this.setProperties({ monthlyCost: monthly, yearlyCost: yearly, stripeMonthDesc: stripeMonthlyDesc, stripeYearlyDesc: stripeYearlyDesc });
        return true;
      }).catch(function (error) {
        console.log(error);
      });
    }),

    actions: {
      processStripeToken: function processStripeToken(plan, town, amount, token) {
        var self = this,
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            ifSandbox = 's',
            connectedUser = Ember.get(this, 'connectedUser'),
            store = this.get('store'),
            municipal_id = town.get('id'),
            card_id = token.card.id,
            email = token.email;
        if (_environment.default.environment === 'development') {
          ifSandbox = 'sandboxS';
        }
        this.set('sendingInformation', true);
        globalMessage.actionNotification('Setting up Account', 'success');

        if (!municipal_id) {
          console.log('no municipal ID');
          globalMessage.actionNotification('No Municipal Account Present', 'cancel');
          return;
        }
        $.get({
          url: 'https://us-central1-aacc-603.cloudfunctions.net/' + ifSandbox + 'tripeOnRequestCreateSubscription?token=' + JSON.stringify(token) + '&municipal_id=' + municipal_id + '&plan=' + plan + '&quantity=' + amount + '&email=' + email,
          json: true
        }).done(function (data) {
          globalMessage.actionNotification('Account created with subscription service', 'success');
          console.log(data);
          if (data.error) {
            console.log('received response but had error');
            self.setProperties({ errorMessage: data.error });
          } else if (data.success) {
            console.log('received response with no error');
            var currentAccount = connectedUser.get('currentAccount');
            store.findRecord('municipal-user', currentAccount.get('id')).then(function (theUser) {
              theUser.set('is_new', 'account-handler.account-create-step-four/' + town.id);
              theUser.save().then(function () {
                self.setProperties({ errorMessage: '', successMessage: data.success });
                globalMessage.actionNotification('Account successfully created', 'success');
                setTimeout(function () {
                  router.transitionTo('account-handler.account-create-step-four', town);
                  self.setProperties({ successMessage: '' });
                }, 1500);
              }, function (error) {
                console.log('Error : ' + error);
              });
            });
          }
        });
      },
      toggleMonthly: function toggleMonthly() {
        this.toggleProperty('monthly');
      },
      createStripeAccount: function createStripeAccount(email, uid) {
        var self = this;
        if (!email && !uid) {
          self.setProperties({ errorMessage: 'need email and user id to proceed' });
          return;
        }
        $.get({
          url: "https://us-central1-scope-95d64.cloudfunctions.net/createStripeCustomerHttp?email=" + email + '&uid=' + uid
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            self.setProperties({ errorMessage: data.error });
          } else if (data.success) {
            self.setProperties({ errorMessage: '', successMessage: data.success });

            setTimeout(function () {
              self.setProperties({ successMessage: '' });
            }, 1500);
          }
        });
      }
    }
  });
});