define('fund-figure-app/components/account-handler/create-step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    appError: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    creatingUser: false,

    didInsertElement: function didInsertElement() {
      // let newUser = this.get('newUser');
      // newUser.setProperties({ hasUser: false, hasType: false, hasAccountType: false, hasAccountInfo: false, haveStudent: false, isAdmin: false, isTeacher: false, isStudent: false, isParent: false, });
      $("input:password").focus(function () {
        this.type = "text";
      }).blur(function () {
        this.type = "password";
      });
      var emailInput = document.getElementById('email');
      emailInput.oninvalid = function (event) {
        event.target.setCustomValidity('Email should be a valid email address');
      };
    },

    notFilledOut: Ember.computed('email', 'password', 'passwordConfirm', function () {
      var email = this.get('email');
      var passphrase = this.get('password');
      var confirmed = this.get('passwordConfirm');

      if (email && passphrase && confirmed && passphrase === confirmed) {
        return false;
      } else {
        return true;
      }
    }),

    match: Ember.computed('password', 'passwordConfirm', function () {
      var passphrase = this.get('password');
      var confirmed = this.get('passwordConfirm');

      if (passphrase === confirmed || typeof confirmed === 'undefined' || confirmed === null || String(confirmed).length < 9) {
        return true;
      } else {
        return false;
      }
    }),

    shouldBeValidated: function shouldBeValidated(evt) {
      console.log(evt);
      return !(evt.getAttribute("readonly") || evt.readonly) && !(evt.getAttribute("disabled") || evt.disabled) && (evt.getAttribute("pattern") || evt.getAttribute("required"));
    },


    actions: {
      saveInfo: function saveInfo(emailCreate, passwordCreate, passwordCreateConfirm) {
        this.set('creatingUser', true);
        var torii = this.get('session');
        var _this = this;
        var ref = this.get('firebaseApp').database(),
            appError = this.get('appError');
        // var newUser = this.get('newUser');
        if (typeof passwordCreate === 'undefined' || typeof passwordCreateConfirm === 'undefined') {
          _this.setProperties({ passwordError: 'must have a password', creatingUser: false });
          return;
        }
        if (passwordCreate !== passwordCreateConfirm) {
          _this.setProperties({ passwordError: 'passwords do not match', creatingUser: false });
          return;
        }
        if (typeof emailCreate === 'undefined') {
          _this.setProperties({ emailError: 'most provide an email', creatingUser: false });
          return;
        }
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 10; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        var auth = this.get('firebaseApp').auth();
        auth.createUserWithEmailAndPassword(emailCreate, passwordCreate).then(function (d) {
          console.log('creating user');
          var store = _this.get('store');
          // let avatar = store.createRecord('avatar');
          avatar.save();
          var newUser = store.createRecord('user', {
            id: d.uid,
            email: emailCreate,
            pin: text,
            is_new: 'account-handler.account-create-step-two'
          });
          return newUser.save().then(function () {
            torii.open('firebase', {
              provider: 'password',
              email: emailCreate,
              password: passwordCreate
            }).then(function (d) {
              var user = store.findRecord('user', d.uid);
              return user.then(function (res) {
                // _this.set('creatingUser', false);
                _this.get('router').transitionTo('account-handler.account-create-step-two', res);
                return res;
              }).catch(function (err) {
                _this.set('creatingUser', false);
                console.log(err);
              });
            }, function (error) {
              _this.set('creatingUser', false);
              return _this.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
            });
          }).catch(function (error) {
            console.log(error);
            _this.set('creatingUser', false);
            return _this.setProperties({ errorMessage: error.message });
          });
        }).catch(function (err) {
          console.log(err);
          _this.set('creatingUser', false);
          _this.setProperties({ errorMessage: err.message });
          var specialMessage = 'error occured while someone was trying to create new accounts';
          // return appError.logError(err, specialMessage);
        });
      },
      instantValidate: function instantValidate(field, evt) {
        if (this.shouldBeValidated(evt.target)) {
          console.log('lost focus');
          var invalid = evt.target.getAttribute("required") && !evt.target.value || evt.target.getAttribute("pattern") && evt.target.value && !new RegExp(evt.target.getAttribute("pattern")).test(evt.target.value);
          if (!invalid && evt.target.getAttribute("aria-invalid")) {
            console.log('valid');
            evt.target.removeAttribute("aria-invalid");
          } else if (invalid && !evt.target.getAttribute("aria-invalid")) {
            console.log('invalid');
            $(evt.target).parent().addClass('not-valid');
            evt.target.setAttribute("aria-invalid", "true");
          }
        }
      }
    }
  });
});