define('fund-figure-app/components/helper/toggle-property', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		click: function click() {
			var property = this.get('property');
			this.toggleProperty(property);
		}
	});
});