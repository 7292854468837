define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoice-add', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    transactionArray: [],
    accountPayable: null,
    invoice: null,
    newVendor: null,
    undoObjects: [],

    transition: Ember.computed('', {
      get: function get() {
        setTimeout(function () {
          return false;
        }, 1000);
      }
    }),

    didInsertElement: function didInsertElement() {
      this.createInvoice();
      this.get('get_accountsPayable');
      this.get('transactionArray').clear();
      this.createTransaction();
    },
    willDestroyElement: function willDestroyElement() {
      console.log('destroying invoices');
      var model = this.get('invoice');
      var documentService = this.get('documentManager');
      if (model.isNew) {
        if (!model.toJSON().documents) {
          console.log('no documents');
        } else {
          if (Object.keys(model.toJSON().documents).length > 1) {
            return documentService.removeDocuments(model.get('documents').toArray()).then(function (res) {
              if (res.error) {
                alert('error erasing ' + res.error);
              } else {}
            });
          } else {
            return documentService.removeDocument(model.get('documents.firstObject')).then(function (res) {
              if (res.error) {
                alert('error erasing ' + res.error);
              } else {
                model.destroyRecord();
              }
            }).catch(function (err) {
              console.log(err);
            });
          }
        }
        model.destroyRecord();
        this.get('transactionArray').clear();
      } else {
        alert('how was this saved?');
      }
    },


    checked: Ember.computed('transactionArray', function () {
      console.log('transactionArray changed');
    }),
    get_accountsPayable: Ember.computed('connectedUser.currentFund.municipalAccounts', {
      get: function get(key) {
        var store = this.get('store'),
            appError = this.get('appError'),
            _this = this;
        return this.get('connectedUser.currentFund.municipalAccounts').then(function (municipalAccounts) {
          return municipalAccounts.find(function (account) {
            if (account.toJSON().stateName == 'Accounts Payable') {
              console.log('found account payable');
              return true;
            } else {
              console.log('not accounts payable');
              return false;
            }
          });
        }).then(function (accountPayable) {
          _this.set('accountPayable', accountPayable);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'Trying to search for accounts payable in get_accountsPayable';
          appError.logError(err, specialMessage);
        });
      }
    }),
    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },
    createInvoice: function createInvoice() {
      var store = this.get('store'),
          now = moment().format('MM/DD/YYYY'),
          invoice = store.createRecord('municipal-invoice', {
        timestamp: now,
        date_received: moment(now).subtract(2, 'days').format('MM/DD/YYYY'),
        date: moment(now).subtract(7, 'days').format('MM/DD/YYYY')
      });
      this.set('invoice', invoice);
    },

    suggested_invoice_id: Ember.computed('connectedUser.currentFund', 'connectedUser.currentFund.municipalInvoices.[]', 'invoice', {
      get: function get() {
        var invoice = this.get('invoice') || false;
        if (!invoice) {
          return;
        }
        var number = this.get('connectedUser.currentFund.municipalInvoices.length') + 1;
        invoice.set('invoice_number', number);
      }
    }),
    createTransaction: function createTransaction(TransactionCredit, TransactionDebit, entryDate) {
      var store = this.get('store'),
          transactCredit = this.get('accountPayable'),

      //get invoice to add to each transaction created
      invoice = this.get('invoice'),

      //create new transaction
      newTransaction = store.createRecord('transaction', {
        timestamp: moment().valueOf(),
        t_Expense: true,
        invoice: invoice,
        transactCredit: transactCredit
      });
      this.get('transactionArray').addObject(newTransaction);
    },

    actions: {
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('invoice');
        this.get('documentManager').saveDocument(fileInfo, 'invoices', true).then(function (res) {
          model.get('documents').addObject(res);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while saving document';
          appError.logError(err, specialMessage);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('invoice'),
            municipalFund = this.get('connectedUser.currentFund'),
            appError = this.get('appError'),
            deleteDocument = this.get('documentManager'),
            _this = this;
        model.get('documents').removeObject(file);
        model.save().then(function () {
          console.log('file deleted from fund list');
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving the invoice after removing document';
          appError.logError(err, specialMessage);
        });
      },
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          // this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        return;
      },
      addItem: function addItem(e) {
        console.log('accounts payable', this.get('accountPayable'));
        this.createTransaction();
      },
      paymentMethod: function paymentMethod(paymentString) {
        var transaction = this.get('transaction');
        console.log(paymentString);
        console.log(transaction.get('paymentMethod'));
        if (paymentString === transaction.get('paymentMethod')) {
          console.log('reset');
          transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
          return;
        }
        transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
        transaction.set('paymentMethod', paymentString);
        switch (paymentString) {
          case "cash":
            transaction.set('cash', true);
            break;
            return;
          case "check":
            transaction.set('check', true);
            break;
            return;
          default:
            transaction.set('card', true);
            break;
            return;
        }
      },
      submitInvoice: function submitInvoice(invoice, transactionArray) {
        var _this2 = this;

        // TransactionCredit, TransactionDebit, TransactionRecord, revenueToSubAccount   //OLD STUFF
        this.set('saving', true);
        var globalMessage = this.get('globalMessaging'),
            groupInfo = invoice,
            invoiceTotal = 0,

        // vendorModel = this.get('store').findRecord('vendor', invoice.toJSON().vendor),
        transactionsWithInfo = 0;
        this.get('store').findRecord('vendor', invoice.toJSON().vendor).then(function (vendor) {
          for (var i = transactionArray.length - 1; i >= 0; i--) {
            var transactDebit = transactionArray[i].toJSON().transactDebit || false,
                transactCredit = transactionArray[i].toJSON().transactCredit || false,
                amount = transactionArray[i].toJSON().amount;
            if (!transactCredit) {
              transactCredit = _this2.get('accountPayable') || false;
              transactionArray[i].set('transactCredit', transactCredit);
            }
            console.log(transactDebit, transactCredit, amount);

            if (transactDebit && transactCredit && amount) {
              transactionsWithInfo++;
            }
          }
          if (transactionArray.length !== transactionsWithInfo) {
            _this2.set('saving', false);
            globalMessage.actionNotification('One or more transactions does not a have receiving account or amount', 'warning');
            return;
          }
          var jsonGroup = groupInfo.toJSON();

          if (!jsonGroup.vendor) {
            _this2.set('saving', false);
            globalMessage.actionNotification('No deposit or invoice info', 'warning');
            return;
          }
          if (groupInfo == null || !groupInfo) {
            _this2.set('saving', false);
            globalMessage.actionNotification('No deposit or invoice info', 'warning');
            return;
          }
          if (!groupInfo.get('date') || !groupInfo.get('date_received')) {
            _this2.set('saving', false);
            globalMessage.actionNotification('No date', 'warning');
            return;
          }
          if (transactionArray.length < 1) {
            _this2.set('saving', false);
            globalMessage.actionNotification('No transactions entered', 'warning');
            return;
          }

          var transactionDate = moment(groupInfo.get('date_received'), 'MM/DD/YYYY').valueOf(),
              invoiceDate = moment(groupInfo.get('date'), 'MM/DD/YYYY').valueOf(),
              dueDate = moment(groupInfo.get('date_due'), 'MM/DD/YYYY').valueOf(),
              _this = _this2,
              store = _this2.get('store'),
              user = _this2.get('connectedUser.currentAccount'),
              fiscalYear = _this2.get('connectedUser.fiscalYear'),
              currentFund = _this2.get('connectedUser.currentFund'),
              municipal_id = _this2.get('connectedUser.currentAccount').toJSON().municipality,
              m_year = municipal_id + '-' + moment(transactionDate).format("YYYY"),
              m_month = m_year + '-' + moment(transactionDate).format('MM'),
              m_week = m_year + '-' + moment(transactionDate).format('WW'),
              m_day = m_month + '-' + moment(transactionDate).format('ddd'),
              m_weekday = m_week + '-' + moment(transactionDate).format('E');
          console.log('transactionDate', transactionDate);
          groupInfo.setProperties({
            timestamp: moment().valueOf(),
            date_received: transactionDate,
            date: invoiceDate,
            date_due: dueDate,
            user: user,
            municipalFund: currentFund,
            m_year: m_year,
            m_month: m_month,
            m_week: m_week,
            m_day: m_day,
            m_weekday: m_weekday,
            municipality: municipal_id,
            year: fiscalYear
          });

          var _loop = function _loop() {
            var currentTransaction = transactionArray[i];
            currentTransaction.setProperties({
              timestamp: moment().valueOf(),
              function: currentTransaction.get('transactDebit.function'),
              functionDesc: currentTransaction.get('transactDebit.function.desc'),
              date: invoiceDate,
              m_year: m_year,
              m_month: m_month,
              m_week: m_week,
              m_day: m_day,
              m_weekday: m_weekday,
              municipality: municipal_id,
              municipalFund: currentFund,
              year: fiscalYear
            });
            invoiceTotal += +currentTransaction.get('amount');
            currentTransaction.save().then(function (savedTransaction) {
              console.log('transaction saved');
              // check is transaction is connected to asset and add transaction to asset history
              if (currentTransaction.toJSON().asset) {
                console.log('asset', currentTransaction.get('asset.id'));
                _this2.get('store').findRecord('asset', '' + currentTransaction.get('asset.id')).then(function (asset) {
                  console.log('found at: ', asset);
                  asset.get('transactions').addObject(savedTransaction);
                  asset.save().catch(function (err) {
                    throw new Error(err);
                  });
                }).catch(function (err) {
                  throw new Error(err);
                });
              }
            }).catch(function (err) {
              throw new Error('trying to save transaction', err);
            });
          };

          for (var i = transactionArray.length - 1; i >= 0; i--) {
            _loop();
          }
          groupInfo.get('transactions').addObjects(transactionArray);
          groupInfo.set('total', invoiceTotal);
          return groupInfo.save().catch(function (err) {
            throw new Error('error saving grouInfo', err);
          }).then(function (savedGroup) {
            console.log('saved group info');
            vendor.get('municipalInvoices').addObject(savedGroup);
            return vendor.save().then(function () {
              return savedGroup;
            }).catch(function (err) {
              console.log(err);
            });
          }).catch(function (err) {
            throw new Error('error saving vendor', err);
          }).then(function (savedGroup) {
            console.log('vendor saved');
            currentFund.get('municipalTransactions').addObjects(transactionArray);
            currentFund.get('municipalInvoices').addObject(savedGroup);
            return currentFund.save().then(function () {
              return savedGroup;
            });
          }).catch(function (err) {
            throw new Error('error saving currentFund', err);
          }).then(function (savedGroup) {
            console.log('fund saved');
            // _this.createNewTransaction(TransactionCredit, TransactionDebit, TransactionRecord.get('entryDate'));
            var message = 'Successfully Saved Transaction';
            //because the deposit and invoice screen break down the deposit and invoice when transitioned we have
            //to create new model when one is saved
            //1. check which model is being saved, deposit or invoice
            //2. create the model and clear transactionArray
            if (savedGroup.constructor.modelName === 'municipal-invoice') {
              var newInvoice = store.createRecord('municipal-invoice', {
                timestamp: moment().valueOf(),
                date: moment(savedGroup.get('date')).format('MM/DD/YYYY'),
                date_received: moment(savedGroup.get('date_received')).format('MM/DD/YYYY')
              });
              _this.set('invoice', newInvoice);
              _this.set('transactionArray', []);
              _this.createTransaction();
            } else {
              alert('appears not a deposit or invoice was just entered');
            }
            return globalMessage.actionNotification(message, 'success');
          }).catch(function (error) {
            throw new Error('error creating next invoice and sending global message', error);
          });
        }).catch(function (err) {
          console.log(err);
        });
      },
      newVendor: function newVendor(inputStr, bar) {
        var store = this.get('store'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            undo = this.get('undo'),
            _this = this,
            newVendor = store.createRecord('vendor', {
          businessName: inputStr
        });
        this.get('invoice').set('vendor', newVendor);
        this.set('newVendor', newVendor);
        this.toggleProperty('vendorAddModal');
      },
      saveNewPersonnel: function saveNewPersonnel(personnel) {
        var undoObjects = this.get('undo.undoObjects'),
            bigBrother = this.get('bigBrother'),
            isEmployee = this.get('isEmployee') || false,
            personnelChanges = personnel.changedAttributes(),
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            newChangesObject = [],
            message = null;
        if (isEmployee) {
          personnel.set('businessName', null);
          message = 'Created Employee ' + personnel.get('name_first') + ' ' + personnel.get('name_first');
        } else {
          message = 'Created Vendor ' + personnel.get('businessName');
        };
        var store = this.get('store'),
            _this = this,
            fiscalYear = this.get('connectedUser.fiscalYear'),
            get_municipality = fiscalYear.get('municipality');

        get_municipality.then(function (municipality) {
          return municipality.get('municipalVendorsDb');
        }).then(function (municipalVendorsDb) {
          municipalVendorsDb.get('vendors').addObject(personnel);
          municipalVendorsDb.save();
          return personnel.save();
        }).then(function (personnelSaved) {
          if (isEmployee) {
            _this.get('connectedUser.buildEmployees');
          } else {
            _this.get('connectedUser.buildVendors');
          }
          _this.setProperties({ isEmployee: false, personnelType: false, newVendor: null });
          _this.toggleProperty('vendorAddModal');
        }).catch(function (err) {
          console.log(err);
        });
        if (personnelChanges) {
          console.log(personnelChanges);
          for (var key in personnelChanges) {
            var newSub = '{ "' + key + '" : {"old": "' + personnelChanges[key].slice(0, 1) + '", "new": "' + personnelChanges[key].slice(1) + '" } }';
            console.log(newSub);
            newChangesObject.addObject(JSON.parse(newSub));
          }
          var desc = null;
          if (isEmployee) {
            desc = 'Undo changes made to ' + personnel.get('name_first') + ' ' + personnel.get('name_last');
          } else {
            desc = 'Undo changes made to ' + personnel.get('businessName');
          };
          var logDesc = null;
          if (isEmployee) {
            logDesc = personnel.get('name_first') + ' ' + personnel.get('name_last');
          } else {
            logDesc = '' + personnel.get('businessName');
          };
          var undoObject = { model: personnel, desc: desc, changes: newChangesObject, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undoObjects.addObject(undoObject);
          console.log(undoObjects);
          personnel.save().then(function (res) {
            globalMessage.actionNotification(message, 'success');
          }).catch(function (err) {
            console.log(err);
          });
        }
      },
      cancelCreate: function cancelCreate() {
        this.setProperties({ isEmployee: false, personnelType: false, newVendor: null });
        this.get('invoice').set('vendor', null);
        this.toggleProperty('vendorAddModal');
      },
      personnelType: function personnelType(type) {
        var _this = this;
        switch (type) {
          case "vendor":
            _this.set('personnelType', true);
            break;
          case "employee":
            _this.setProperties({ isEmployee: true, personnelType: true });
            break;
          default:
            break;

        }
      },
      notifyChange: function notifyChange() {
        console.log('transactionArray changed in parent');
        this.notifyPropertyChange('transactionArray');
      },
      hideCreateOptionOnSameName: function hideCreateOptionOnSameName(term, blah) {
        var existingOption = blah.length > 0;
        return !existingOption;
      },
      closeModal: function closeModal() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalName', null);
      }
    }
  });
});