define('fund-figure-app/components/reports/town-reports/trial-balance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    sortBy: ['date'],
    transactions: [],
    pageInfo: [],
    setTransactions: Ember.computed('connectedUser.currentFund.municipalTransactions', {
      get: function get() {
        var _this = this;
        return this.get('connectedUser.currentFund.municipalTransactions').then(function (transactions) {
          console.log(transactions.toArray());
          _this.set('transactions', transactions.toArray());
          return transactions.toArray();
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),
    sortedTransactions: Ember.computed.sort('transactions', 'sortBy'),
    generatePageContent: Ember.computed('sortedTransactions', {
      //generate pages based on line limit for report
      get: function get() {
        var lines = this.get('model.lines_per_page'),
            _this = this,
            sortedTransactions = this.get('sortedTransactions'),
            changesLength = sortedTransactions.length,
            page_info = [],
            lastIndex = 0,
            line_count = 0;
        if (sortedTransactions.length < 1) {
          return;
        }
        sortedTransactions.forEach(function (transaction, index) {
          var totalLines = 2;
          if (line_count + totalLines >= lines) {
            var object = {};
            object["startAt"] = lastIndex;
            object["endAt"] = index - 1;
            page_info.push(object);
            line_count = 0;
            lastIndex = index;
          }
          line_count += totalLines;
          if (changesLength == index + 1) {
            var _object = {};
            _object["startAt"] = lastIndex;
            _object["endAt"] = index;
            page_info.push(_object);
            _this.set('pageInfo', page_info);
          }
        });
      }
    }),
    actions: {
      exportData: function exportData() {
        this.set('loading', true);
        var _this = this,
            CSV = '',
            year = this.get('connectedUser.fiscalYear.year'),
            arrayOfRows = $('.tablerow');
        for (var i = 0; i < arrayOfRows.length; i++) {
          var row = "";
          for (var index in $('.tablerow').eq(i).children('.tablecolumn')) {
            if ($('.tablerow').eq(i).children('.tablecolumn').eq(index).html()) {
              console.log('column', $('.tablerow').eq(i).children('.tablecolumn').eq(index).html());
              row += '"' + $('.tablerow').eq(i).children('.tablecolumn').eq(index).text().replace(/\s+/g, '') + '",';
            }
          }
          row.slice(0, row.length - 1);
          //add a line break after each row        
          CSV += row + '\r\n';
        }

        console.log(CSV);
        if (CSV == '') {
          alert("Invalid data");
          return;
        }
        //Initialize file format you want csv or xls
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV),
            dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href", uri);
        dlAnchorElem.setAttribute("download", 'trial_balance_start_' + year + '_to_' + moment().format('MM-DD-YYYY') + '.csv');
        dlAnchorElem.click();
        return;
      }
    }
  });
});