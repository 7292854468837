define('fund-figure-app/components/account-handler/create-with-pin/pin-step-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    newUser: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: false, haveAccountType: false, havePin: false, haveStaffConfirm: false });
    },


    notFilledOut: Ember.computed('email', 'password', 'passwordConfirm', function () {
      var email = this.get('email');
      var passphrase = this.get('password');
      var confirmed = this.get('passwordConfirm');

      if (email && passphrase && confirmed && passphrase === confirmed) {
        return false;
      } else {
        return true;
      }
    }),

    match: Ember.computed('password', 'passwordConfirm', function () {
      var passphrase = this.get('password');
      var confirmed = this.get('passwordConfirm');

      if (passphrase === confirmed || typeof confirmed === 'undefined' || confirmed === null || String(confirmed).length < 9) {
        return true;
      } else {
        return false;
      }
    }),

    actions: {
      saveInfo: function saveInfo(emailCreate, passwordCreate, passwordCreateConfirm, firstName, lastName) {
        var torii = this.get('session');
        var connectedUser = this.get('connectedUser');
        var _this = this;
        var newUser = this.get('newUser');
        var firebaseRef = this.get('firebaseApp').database();
        if (typeof passwordCreate === 'undefined' || typeof passwordCreateConfirm === 'undefined') {
          _this.setProperties({ passwordError: 'must have a password' });
          return;
        }
        if (passwordCreate !== passwordCreateConfirm) {
          _this.setProperties({ passwordError: 'passwords do not match' });
          return;
        }
        if (typeof emailCreate === 'undefined') {
          _this.setProperties({ emailError: 'most provide an email' });
          return;
        }
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 10; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        var auth = this.get('firebaseApp').auth();
        auth.createUserWithEmailAndPassword(emailCreate, passwordCreate).then(function (d) {
          var store = _this.get('store');
          //create user
          var newUser = store.createRecord('user', {
            id: d.uid,
            email: emailCreate,
            pin: text,
            is_new: 'account-handler.create-with-pin.pin-step-three'
          });
          newUser.save().then(function () {
            torii.open('firebase', {
              provider: 'password',
              email: emailCreate,
              password: passwordCreate
            }).then(function (d) {
              var user = store.findRecord('user', d.uid);
              newUser.setProperties({ hasUser: true });
              _this.get('router').transitionTo('account-handler.create-with-pin.pin-step-three');
              return user;
            }, function (error) {
              _this.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
            });
          }, function (error) {
            _this.setProperties({ errorMessageCreate: error.message });
          });
        }, function (error) {
          _this.setProperties({ errorMessageCreate: error.message });
        });
      },
      logout: function logout() {
        var router = this.get('router');
        var store = this.get('store');
        var session = this.get('session');
        var user = store.peekRecord('user', session.content.uid);
        session.close();
        store.unloadRecord(user);
        this.set('connectedUser.user', false);
      }
    }
  });
});