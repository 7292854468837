define('fund-figure-app/components/dashboard-support/manage-users/manage-user/municipal-user-add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    appError: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var store = this.get('store'),
          newUser = store.createRecord('municipal-user', {
        user: this.get('model')
      });
      this.set('newUser', newUser);
      console.log(newUser);
    },


    townList: Ember.computed('connectedUser.currentAccount', {
      get: function get() {
        var store = this.get('store');
        return store.findAll('municipal-db');
      }
    }),

    actions: {
      save: function save() {
        var newUser = this.get('newUser'),
            getMunicipality = newUser.get('municipality'),
            router = this.get('router'),
            model = this.get('model'),
            globalMessaging = this.get('globalMessaging'),
            appError = this.get('appError'),
            user = this.get('connectedUser.currentAccount'),
            bigBrother = this.get('bigBrother'),
            desc = user.get('name_first') + ' ' + user.get('name_last') + ' added new user to ' + model.get('name_first') + ' ' + model.get('name_last'),
            logDesc = user.get('name_first') + ' ' + user.get('name_last') + ' added new municipal user ' + newUser.id + ' to ' + model.get('name_first') + ' ' + model.get('name_last'),
            undoObject = { model: newUser, desc: desc, logDesc: logDesc };
        console.log(model);
        newUser.set('user', model);
        newUser.save().then(function (savedNewUser) {
          model.get('municipalUsers').addObject(savedNewUser);
          model.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error after saving user after adding new municipal user';
            appError.logError(err, specialMessage);
          });
          return savedNewUser;
        }).then(function (savedNewUser) {
          return getMunicipality.then(function (municipality) {
            municipality.get('municipalUsers').addObject(savedNewUser);
            municipality.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving municipality after adding municipal user';
              appError.logError(err, specialMessage);
            });
            return;
          });
        }).then(function () {
          globalMessaging.actionNotification('saved user', 'success');
          bigBrother.logChange(undoObject);
          router.transitionTo('dashboard-support.manage-users.manage-user', model);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving new municipal user';
          appError.logError(err, specialMessage);
        });
      },
      formSubmit: function formSubmit() {
        // document.getElementById("userInfo").submit()
        $("form").find('[type="submit"]').trigger('click');
      }
    }
  });
});