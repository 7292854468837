define('fund-figure-app/components/helper/filter-array-models-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filterArray: [],
    actions: {
      byDate: function byDate() {
        this.toggleProperty('byDate');
      },
      byAmount: function byAmount() {
        this.toggleProperty('byAmount');
      },
      noBubbles: function noBubbles() {},
      submitFilter: function submitFilter() {
        var filterArray = this.get('filterArray').clear(),
            byDate = this.get('byDate'),
            byAmount = this.get('byAmount'),
            _this = this;
        if (this.get('byDate')) {
          if (!_this.get('beginDate')) {
            // dont do anything to array
          } else {
            var filterBeginDate = moment(this.get('beginDate'), 'MM-DD-YYYY').valueOf();
            var filterEndDate = moment(this.get('endDate'), 'MM-DD-YYYY').valueOf() || 0;
            var object = { filter: "byDate", beginDate: filterBeginDate, endDate: filterEndDate, logic: _this.get('filterDateLogic') };
            filterArray.addObject(object);
          }
        }
        if (this.get('byAmount')) {
          if (!_this.get('filterAmount')) {} else {
            if (!_this.get('filterAmountLogic')) {
              _this.set('filterAmountLogic', 'amountEquals');
              _this.set('amountEquals', true);
            }
            var _object = {};
            _object = { filter: "byAmount", amount: _this.get('filterAmount'), logic: _this.get('filterAmountLogic') };
            filterArray.addObject(_object);
          }
        }
        console.log('from filterArray', filterArray);
        this.toggleProperty('isActive');
        this.get('submitFilter')(filterArray);
      },
      clearFilter: function clearFilter() {
        this.get('filterArray').clear();
        this.get('clearFilter')();
        this.toggleProperty('isActive');
      },
      toggleAmountLogic: function toggleAmountLogic(logic) {
        if (this.get(logic)) {
          this.setProperties({ amountGreater: false, amountLess: false, amountEquals: false });
          this.set('filterAmountLogic', null);
        } else {
          this.set('filterAmountLogic', logic);
          this.setProperties({ amountGreater: false, amountLess: false, amountEquals: false });
          this.toggleProperty(logic);
        }
      },
      toggleDateLogic: function toggleDateLogic(logic) {
        if (this.get(logic)) {
          this.setProperties({ dateGreater: false, dateLess: false, dateBetween: false });
          this.set('filterDateLogic', null);
        } else {
          this.set('filterDateLogic', logic);
          this.setProperties({ dateGreater: false, dateLess: false, dateBetween: false });
          this.toggleProperty(logic);
        }
      }
    }
  });
});