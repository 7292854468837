define('fund-figure-app/components/d3/visual-grouped-bars-chart', ['exports', 'd3-transition', 'd3-array', 'd3-selection', 'd3-scale', 'd3-format', 'd3-scale-chromatic', 'd3-axis'], function (exports, _d3Transition, _d3Array, _d3Selection, _d3Scale, _d3Format, _d3ScaleChromatic, _d3Axis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['grouped-bars'],
    colorScheme: ['#f0c430', '#3998d8', '#f19b2b', '#31ad64', '#e54e43', '#35495d', '#12ADDD', '#f79a59', '#38ca73'],

    willDestroyElement: function willDestroyElement() {
      this.$().remove();
      this.destroy();
      this._super.apply(this, arguments);
    },


    parentWidth: Ember.computed('didInsertElement', {
      get: function get() {
        console.log(document.querySelector('#' + Ember.get(this, 'elementId')).clientWidth);
        return this.get('width') || document.querySelector('#' + Ember.get(this, 'elementId')).clientWidth;
      }
    }),
    parentHeight: Ember.computed('didInsertElement', {
      get: function get() {
        return this.get('height') || this.$().parent().height() > 400 ? this.$().parent().height() : 400 || 400;
      }
    }),

    drawChart: Ember.computed('data', 'parentWidth', function () {
      var data = this.get('data'),
          _this = this;
      if (data === undefined || data === null || data.length === 0) {
        console.log('no data');
        return 0;
      }

      var cfg = {
        marginChart: {
          top: 20,
          right: 220,
          bottom: 20,
          left: 120
        }
      },

      // parentWidth = this.get('width') || this.$().parent().width() > 400 ? this.$().parent().width() : 400 || 400,
      // parentHeight = this.get('height') || this.$().parent().height() > 400 ? this.$().parent().height() : 400 || 400,
      width = Ember.get(this, 'parentWidth') - cfg.marginChart.left - cfg.marginChart.right,
          height = Ember.get(this, 'parentHeight') - cfg.marginChart.top - cfg.marginChart.bottom,
          colour = (0, _d3Scale.scaleOrdinal)(Ember.get(this, 'colorScheme') || _d3ScaleChromatic.schemeDark2),
          // colour scheme
      variable = this.get('variable'),
          // value in data that will dictate proportions on chart
      category = this.get('category'),
          // compare data by
      padAngle = this.get('padAngle') || 0.015,
          // effectively dictates the gap between slices
      floatFormat = (0, _d3Format.format)('.4r'),
          cornerRadius = this.get('cornerRadius') || 3,
          // sets how rounded the corners are on each slice
      formatGen = (0, _d3Format.format)(this.get('formatString') || ''),
          svg = (0, _d3Selection.select)(this.element);
      // set(this, 'width', get(this, 'parentWidth'));
      svg.select('g').remove();
      svg.attr('width', Ember.get(this, 'parentWidth')).attr('height', Ember.get(this, 'parentHeight'));

      console.log('data passed to visual', Object.values(data));

      var categoriesNames = Object.keys(data),
          keys = Array.from(new Set(Object.values(data).map(function (r) {
        return Object.keys(r);
      }).join().split(','))),
          y = (0, _d3Scale.scaleLinear)().domain([0, (0, _d3Array.max)(Object.entries(data), function (d) {
        console.log(d[1]);return (0, _d3Array.max)(keys, function (key) {
          return d[1][key];
        });
      })]).nice().rangeRound([height - cfg.marginChart.bottom, cfg.marginChart.top]),


      //samples
      x0 = (0, _d3Scale.scaleBand)().domain(categoriesNames).range([0, width], .2).paddingInner(0.1),


      //series
      x1 = (0, _d3Scale.scaleBand)().domain(keys).range([0, x0.bandwidth() - 10]).padding(0.05),


      //transition
      t = (0, _d3Transition.transition)().duration(750);

      colour.domain(keys);

      console.log(categoriesNames, keys);

      // x0.domain(categoriesNames);
      // x1.domain(uniqueValueNames).rangeRoundBands([0, x0.rangeBand()]);
      // y.domain([0, d3.max(data, function(categorie) { return d3.max(categorie.values, function(d) { return d.value; }); })]);

      var g = svg.append("g"),
          background = g.append("g").attr("class", "background").append('rect').attr('width', width).attr('height', height).attr('opacity', 0),
          //.on('mousedown', mousedownCanvas),      
      legend = g.selectAll('.legend'),
          group = g.selectAll('.group'),
          tooltip = g.append('text').style("text-anchor", "middle").classed('svg-tooltip text', true).attr("transform", "translate(" + (width / 2 + cfg.marginChart.left) + " ," + (height + cfg.marginChart.top + 15) + ")");

      // bars = group.append('g').attr('class', 'bar');

      restart();

      function restart() {
        console.log('drawing data', Object.entries(data));
        //groups
        group = group.data(Object.entries(data));
        group.exit().remove();
        group = group.enter().append('g').attr('class', function (d) {
          return 'group ' + d[0];
        }).attr("transform", function (d) {
          return 'translate(' + (x0(d[0]) + cfg.marginChart.left + 5) + ', ' + cfg.marginChart.bottom + ')';
        }).on('click', groupClicked).on('mouseover', groupEnter).on('mouseout', groupExit).selectAll("rect").data(function (d) {
          return keys.map(function (key) {
            return { key: key, value: d[1][key] || 0 };
          });
        }).enter().append("rect").attr('class', 'pointer').attr("x", function (d) {
          return x1(d.key);
        }).attr("y", function (d) {
          return y(0);
        }).attr("height", 0).attr("width", x1.bandwidth()).transition(t).attr("y", function (d) {
          return y(d.value);
        }).attr("height", function (d) {
          return y(0) - y(d.value);
        }).attr("fill", function (d) {
          return colour(d.key);
        });

        background.on('click', backgroundClicked);

        // Add the x Axis
        g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + height + ')').call((0, _d3Axis.axisBottom)(x0)).selectAll('.tick').attr('class', 'text').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

        // text label for the x axis
        g.append("text").attr("transform", "translate(" + (width / 2 + cfg.marginChart.left) + " ," + (height + cfg.marginChart.top + 35) + ")").attr('class', 'text').style("text-anchor", "middle").text("Months");

        // Add the y axis
        g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + cfg.marginChart.top + ')').call((0, _d3Axis.axisLeft)(y).ticks(5, "($.2f")).selectAll('.tick').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

        // Add legend  
        legend = legend.data(colour.domain().slice().reverse());
        legend.exit().remove();
        legend = legend.enter().append('g').attr("transform", 'translate(' + (width + cfg.marginChart.right) + ', 0)').attr('class', 'legend text').attr("text-anchor", "end").attr("font-family", "sans-serif").attr("font-size", 10);

        legend.append("rect").attr("x", -19).attr("width", 19).attr("height", 19).attr("transform", function (d, i) {
          return 'translate(0, ' + i * 20 + ')';
        }).attr("fill", colour);

        legend.append("text").attr("x", -24).attr("y", 9.5).attr("dy", "0.35em").attr("transform", function (d, i) {
          return 'translate(0, ' + i * 20 + ')';
        }).text(function (d) {
          return d;
        });
      };

      function groupClicked(d) {
        data = {};
        Ember.get(_this, 'groupClicked')(d).then(function (res) {
          restart();
        });
      };

      function groupEnter(d) {
        console.log(d);
        var groupUnderMouse = this;
        (0, _d3Selection.selectAll)('.group').transition().style('opacity', function () {
          return this === groupUnderMouse ? 1.0 : 0.5;
        });
        // select(this)
        //   .classed('shadowed', true)
        //   .attr("fill-opacity","0.5");
        (0, _d3Selection.select)('.svg-tooltip').text('click to drill down ' + d[0]);
      };

      function groupExit(d) {
        (0, _d3Selection.selectAll)('.group').transition().style('opacity', 1);
        (0, _d3Selection.select)('.svg-tooltip').text('');
      }

      function backgroundClicked(d) {
        data = {};
        Ember.get(_this, 'backgroundClicked')(d).then(function (res) {
          if (res) {
            restart();
          }
        });
      }
    })
  });
});