define('fund-figure-app/controllers/dashboard-assets/capital-improvement-plan/index', ['exports', 'd3-collection'], function (exports, _d3Collection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    assetInfo: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    getGanttData: Ember.computed('assetInfo.allAssets.[]', 'assetInfo.allAssets.@each.cip_total_save', 'assetInfo.allAssets.@each.cip_cost_per_year', {
      get: function get() {
        Ember.get(this, 'assetInfo').multiAssetGanttData(Ember.get(this, 'assetInfo.cipAssets'));
      }
    })
  });
});