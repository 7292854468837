define('fund-figure-app/components/dashboard-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    fullscreen: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    feedbackDisplay: false,

    actions: {
      toggleFeedbackDisplay: function toggleFeedbackDisplay() {
        this.toggleProperty('feedbackDisplay');
      },
      toggleFullscreen: function toggleFullscreen() {
        Ember.get(this, 'fullscreen').toggle();
        this.toggleProperty('feedbackDisplay');
      },
      toggleNightMode: function toggleNightMode() {
        this.toggleProperty('connectedUser.nightMode');
        var aThing = $('body.night-mode');
        console.log(aThing);
        if (aThing.length > 0) {
          $('body').removeClass('night-mode');
        } else {
          $('body').addClass('night-mode');
        }
        this.toggleProperty('feedbackDisplay');
      },
      help: function help() {
        this.toggleProperty('connectedUser.showHelpWindow');
      }
    }
  });
});