define('fund-figure-app/components/payment-method', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dynamicMethod: Ember.computed('method', function () {
      var method = this.get('method');
      if (!method) {
        return;
      }
      console.log(method);
      var _this = this;
      switch (method) {
        case "cash":
        case "Cash":
          _this.setProperties({ cash: true, check: false, visa: false, discover: false, mastercard: false, 'american-express': false, citi: false });
          break;
        case "check":
          _this.setProperties({ cash: false, check: true, visa: false, discover: false, mastercard: false, 'american-express': false, citi: false });
          break;
        case "visa":
        case "Visa":
          _this.setProperties({ cash: false, check: false, visa: true, discover: false, mastercard: false, 'american-express': false, citi: false });
          break;
        case "discover":
          _this.setProperties({ cash: false, check: false, visa: false, discover: true, mastercard: false, 'american-express': false, citi: false });
          break;
        case "mastercard":
          _this.setProperties({ cash: false, check: false, visa: false, discover: false, mastercard: true, 'american-express': false, citi: false });
          break;
        case "american-express":
          _this.setProperties({ cash: false, check: false, visa: false, discover: false, mastercard: false, 'american-express': true, citi: false });
          break;
        case "citi":
          _this.setProperties({ cash: false, check: false, visa: false, discover: false, mastercard: false, 'american-express': false, citi: true });
          break;
        default:
          _this.setProperties({ cash: false, check: true, visa: false, discover: false, mastercard: false, 'american-express': false, citi: false });
          break;
      }
    }).property('method')
  });
});