define('fund-figure-app/components/file-preview-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    documentManager: Ember.inject.service(),

    actions: {
      removeFile: function removeFile(document, models) {
        this.set('deleting', true);
        var _this = this;
        this.get('documentManager').removeDocument(document).then(function (res) {
          if (res.error) {
            _this.set('deleting', false);
          } else {
            _this.get('deleteFile')(res);
            _this.set('deleting', false);
          }
        });
      }
    }
  });
});