define('fund-figure-app/components/d3/visual-diverging-bars-with-total', ['exports', 'd3-selection', 'd3-scale', 'd3-array', 'd3-shape', 'd3-transition', 'd3-ease', 'd3-force', 'd3-drag', 'd3-format', 'd3-collection', 'd3-axis', 'd3-time-format', 'd3-color'], function (exports, _d3Selection, _d3Scale, _d3Array, _d3Shape, _d3Transition, _d3Ease, _d3Force, _d3Drag, _d3Format, _d3Collection, _d3Axis, _d3TimeFormat, _d3Color) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['diverging-bars'],
    width: null,
    height: null,
    colorScheme: null,

    drawChart: Ember.computed('data', function () {
      var svg = (0, _d3Selection.select)(this.element),
          colorScheme = this.get('colorScheme'),
          parentHeight = this.$().parent().parent().height(),
          data = this.get('data'),
          y_label = this.get('y_label'),
          chart_title = Ember.get(this, 'chart_title') || 'Chart Title',
          parentWidth = this.$().parent().width(),
          cfg = {
        marginChart: {
          top: 80,
          right: 20,
          bottom: 50,
          left: 80
        }
      },
          width = parentWidth - cfg.marginChart.left - cfg.marginChart.right,
          height = parentHeight - cfg.marginChart.top - cfg.marginChart.bottom,
          categoryLoc = this.get('categoryLoc');
      svg.select('g').remove();

      // console.log(this.$().parent().parent());
      // console.log('Parent_width', parentWidth, 'Parent_height', parentHeight);
      // console.log('width', width, 'height', height);

      if (data === undefined || data === null) {
        // console.log('no data');
        return 0;
      }
      // tool tip
      var div = (0, _d3Selection.select)("body").append("div").attr("class", "d3-tooltip").style("opacity", 0);
      // console.log(data);
      var d = (0, _d3Collection.entries)(data[0]).sort(function (a, b) {
        return a.key > b.key ? 1 : -1;
      });
      // console.log(d);     
      svg.attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 " + parentWidth + ' ' + parentHeight);

      var date_parse = (0, _d3TimeFormat.timeParse)('%m-%d-%Y');

      var date_format = (0, _d3TimeFormat.timeFormat)("%d %b");
      // console.log('keys', d.map(d=>d.key));

      var x = (0, _d3Scale.scaleBand)().domain(["01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]).rangeRound([0, width]).paddingInner(0.1);

      var y0 = (0, _d3Scale.scaleLinear)().domain([0, (0, _d3Array.max)([(0, _d3Array.max)(d.map(function (d) {
        return d.value[0];
      })), (0, _d3Array.max)(d.map(function (d) {
        return d.value[1];
      }))])]).rangeRound([height / 2, 0]);

      var y1 = (0, _d3Scale.scaleLinear)().domain([0, (0, _d3Array.max)([(0, _d3Array.max)(d.map(function (d) {
        return d.value[0];
      })), (0, _d3Array.max)(d.map(function (d) {
        return d.value[1];
      }))])]).rangeRound([0, height / 2]);

      var g = svg.append("g"),
          background = g.append("g").attr("class", "background").append('rect').attr('width', width).attr('height', height).attr('opacity', 0),
          //.on('mousedown', mousedownCanvas),      
      deposit = g.append('g').selectAll('.rect'),
          invoice = g.append('g').selectAll('.rect'),
          depositLabels = g.selectAll('text'),
          invoiceLabels = g.selectAll('text');

      restart();

      function restart() {
        //draw deposit rectangles
        deposit = deposit.data(d);
        deposit.exit().remove();
        deposit = deposit.enter().append('rect').attr('class', function (d) {
          return 'sub-bar green ' + d.value[0];
        }).attr("height", function (d) {
          return y0(0) - y0(d.value[0]);
        }).attr("x", function (d) {
          return x(d.key) + cfg.marginChart.left;
        }).attr("y", function (d) {
          return y0(d.value[0]) + cfg.marginChart.top;
        }).attr("width", x.bandwidth()).on("mouseover", handleMouseOver).on("mouseout", handleMouseOut);

        //add labels for deposit bars
        // depositLabels = depositLabels.data(d);
        // depositLabels.exit().remove();
        // depositLabels = depositLabels
        //   .enter()
        //   .append('text')
        //   .text(d => {
        //     if ((y0(0) - y0(d.value[0])) <= 10) { return } else { return format("($,.2f")(d.value[0]) };
        //   })
        //   .attr("text-anchor", "middle")
        //   .attr('class', 'light-grey')
        //   .attr("x", d => { return x(d.key) + cfg.marginChart.left + x.bandwidth() / 2 })
        //   .attr("y", d => { return y0(d.value[0]) + cfg.marginChart.top + 12 })
        //   .attr("font-family", "sans-serif")
        //   .attr("font-size", "11px")
        //   .attr("fill", "lightGrey");

        //draw invoice rectangles
        invoice = invoice.data(d);
        invoice.exit().remove();
        invoice = invoice.enter().append('rect').attr('class', function (d) {
          return 'sub-bar red ' + d.value[1];
        }).attr("height", function (d) {
          return y1(d.value[1]) - y1(0);
        }).attr("x", function (d) {
          return x(d.key) + cfg.marginChart.left;
        }).attr("y", function (d) {
          return height / 2 + cfg.marginChart.top;
        }).attr("width", x.bandwidth()).on("mouseover", handleMouseOverInvoice).on("mouseout", handleMouseOut);

        //add labels for deposit bars
        // invoiceLabels = invoiceLabels.data(d);
        // invoiceLabels.exit().remove();
        // invoiceLabels = invoiceLabels
        //   .enter()
        //   .append('text')
        //   .text(d => {
        //     if ((y1(d.value[1]) - y1(0)) <= 10) { return } else { return format("($,.2f")(d.value[1]) };
        //   })
        //   .attr('class', 'light-grey')
        //   .attr("text-anchor", "middle")
        //   .attr("x", d => { return x(d.key) + cfg.marginChart.left + x.bandwidth() / 2 })
        //   .attr("y", d => { return (height / 2) + cfg.marginChart.top + (y1(d.value[1]) - y1(0) - 4) })
        //   .attr("font-family", "sans-serif")
        //   .attr("font-size", "11px")
        //   .attr("fill", "lightGrey");
      };

      function handleMouseOver(d, i) {
        // Use D3 to select element, change color and size
        (0, _d3Selection.select)(this).classed('hover', true);
        var parent = (0, _d3Selection.select)(this).node().parentNode;
        (0, _d3Selection.select)(parent).classed('shadowed', true);
        g.append('text').text('' + (0, _d3Format.format)("($,.2f")(d.value[0])).attr("text-anchor", "middle").attr('class', 'text horizontal').attr('x', x(d.key) / 2 + cfg.marginChart.left + x.bandwidth() / 2).attr('y', height / 2 + cfg.marginChart.top + (y0(d.value[0]) - y0(0) - 4));
        //lets add a line to y scale
        g.append('path').attr('class', 'stat-line horizontal shadowed').style("stroke-dasharray", "3, 3").attr("d", 'M ' + (x(d.key) + cfg.marginChart.left) + ' ' + (y0(d.value[0]) + cfg.marginChart.top) + ' H ' + cfg.marginChart.left);
      };

      function handleMouseOverInvoice(d, i) {
        // Use D3 to select element, change color and size
        (0, _d3Selection.select)(this).classed('hover', true);
        var parent = (0, _d3Selection.select)(this).node().parentNode;
        (0, _d3Selection.select)(parent).classed('shadowed', true);
        g.append('text').text('' + (0, _d3Format.format)("($,.2f")(d.value[1])).attr("text-anchor", "middle").attr('class', 'text horizontal').attr('x', x(d.key) / 2 + cfg.marginChart.left + x.bandwidth() / 2).attr('y', height / 2 + cfg.marginChart.top + (y1(d.value[1]) - y1(0) + 18));
        //lets add a line to y scale
        g.append('path').attr('class', 'stat-line horizontal shadowed').style("stroke-dasharray", "3, 3").attr("d", 'M ' + (x(d.key) + cfg.marginChart.left) + ' ' + (height / 2 + cfg.marginChart.top + (y1(d.value[1]) - y1(0))) + ' H ' + cfg.marginChart.left);
      };

      function handleMouseOut(d, i) {
        // Use D3 to select element, change color back to normal
        (0, _d3Selection.select)(this).classed('hover', false);
        var parent = (0, _d3Selection.select)(this).node().parentNode;
        (0, _d3Selection.select)(parent).classed('shadowed', false);
        div.transition().duration(500).style("opacity", 0);
        //remove dotted line
        console.log(d);
        (0, _d3Selection.selectAll)('.horizontal').remove();
      };

      // Add the x Axis
      g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + (height / 2 + cfg.marginChart.top) + ')').call((0, _d3Axis.axisBottom)(x)).selectAll('.tick').attr('class', 'text').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

      // text label for the x axis
      g.append("text").attr("transform", "translate(" + (width / 2 + cfg.marginChart.left) + " ," + (height + cfg.marginChart.top + 35) + ")").attr('class', 'text').style("text-anchor", "middle").text("Weeks into Year");

      // Add the y Axis Deposits
      g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + cfg.marginChart.top + ')').call((0, _d3Axis.axisLeft)(y0).ticks(5)).selectAll('.tick').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

      // Add the y Axis Invoices
      g.append("g").attr("transform", 'translate(' + cfg.marginChart.left + ', ' + (cfg.marginChart.top + height / 2) + ')').call((0, _d3Axis.axisLeft)(y1).ticks(5)).selectAll('.tick').selectAll('text').attr('class', 'text').select('.domain').attr('class', 'text');

      // text label for the y axis deposits
      g.append("text").attr('class', 'text').attr("transform", "rotate(90)").attr("y", 0 - cfg.marginChart.left / 3).attr("x", height / 4 + cfg.marginChart.top).attr("dy", "1em").style("text-anchor", "middle").text("Deposits");

      // text label for the y axis invoices
      g.append("text").attr('class', 'text').attr("transform", "rotate(90)").attr("y", 0 - cfg.marginChart.left / 3).attr("x", height / 4 * 3 + cfg.marginChart.top).attr("dy", "1em").style("text-anchor", "middle").text("Invoices");
      //add title
      g.append("text").attr("y", 10).attr('fill', 'grey').attr("x", parentWidth / 2).attr("dy", "1em").attr('font-size', "1.75em").style("text-anchor", "middle").text(chart_title);
    })

  });
});