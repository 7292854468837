define('fund-figure-app/models/vendor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    businessName: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string'),
    streetNumber: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    municipality: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    businessCategory: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    ein: _emberData.default.attr('string'),
    files: _emberData.default.hasMany('file', { async: true, inverse: null }),
    default_accounts: _emberData.default.hasMany('municipal-account', { async: true }), //used to populate expense account after vendor is chosen
    contact_firstName: _emberData.default.attr('string'),
    contact_lastName: _emberData.default.attr('string'),
    employee_firstName: _emberData.default.attr('string'),
    employee_lastName: _emberData.default.attr('string'),
    employee_private: _emberData.default.belongsTo('employee-private', { async: true }),
    employee_payroll_infos: _emberData.default.hasMany('employee-payroll-info', { async: true, inverse: null }),
    municipalDepartment: _emberData.default.belongsTo('municipal-department', { async: true }),
    municipalChecks: _emberData.default.hasMany('municipal-check', { async: true }),
    municipalInvoices: _emberData.default.hasMany('municipal-invoice', { async: true }),
    deactivated: _emberData.default.attr('boolean', { defaultValue: false }),
    employee: _emberData.default.attr('boolean', { defaultValue: false }),
    total_pay: Ember.computed('employee_payroll_infos.@each.gross_pay', {
      get: function get() {
        var _this = this;
        return this.get('employee_payroll_infos').reduce(function (sum, employee_payroll_info) {
          return sum += +employee_payroll_info.get('gross_pay') || 0;
        }, 0);
      }
    }),
    searchField: Ember.computed('businessCategory', 'businessName', 'employee', 'contact_firstName', 'contact_lastName', 'employee_firstName', 'employee_lastName', {
      get: function get() {
        return '' + this.get('businessName') + this.get('businessCategory') + this.get('contact_firstName') + this.get('contact_lastName') + this.get('employee') + this.get('businessCategory') + this.get('employee_firstName') + this.get('employee_lastName');
      }
    }),
    by_department: Ember.computed('municipalDepartment.[]', {
      get: function get() {
        return this.get('municipalDepartment').then(function (municipalDepartment) {
          if (municipalDepartment) {
            return municipalDepartment.get('name');
          } else {
            return null;
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    }).property('init')
  });
});