define('fund-figure-app/helpers/tele-formatter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.teleFormatter = teleFormatter;
	function teleFormatter(params) {
		// console.log(params);
		if (!params[0]) {
			return 'No Data';
		}
		var string = params[0].replace(/[^0-9#]/g, '');
		switch (string.length) {
			case 7:
				return string.substr(0, 3) + "-" + string.substr(3, 4);
				break;
			case 10:
				return "(" + string.substr(0, 3) + ")" + ' ' + string.substr(3, 3) + "-" + string.substr(6, 4);
				break;
			case 11:
				return string.substr(0, 1) + ' ' + "(" + string.substr(1, 3) + ")" + ' ' + string.substr(4, 3) + "-" + string.substr(7, 4);
				break;
			default:
				return string;
		}
	}

	exports.default = Ember.Helper.helper(teleFormatter);
});