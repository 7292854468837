define('fund-figure-app/components/dashboard/settings/manage-funds/index-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    actions: {
      convertToMulti: function convertToMulti() {
        var store = this.get('store'),
            municipal_Db = null;
        //Steps
        //find oldest fiscalYear
        this.get('connectedUser.currentAccount.municipality').then(function (municipalDb) {
          municipal_Db = municipalDb;
          return municipalDb.get('municipalYearsDb');
        }).then(function (municipalYearDb) {
          return municipalYearDb.get('years');
        }).then(function (years) {
          years.forEach(function (year) {
            var municipalFund = store.createRecord('municipal-fund', {
              fund_name: 'General Fund',
              fund_id: '001',
              conversion: true,
              municipalFundType: '-asdjfhhwke',
              chartOfAccount: '-dfghhdfgh',
              municipalDb: municipal_Db,
              municipalYear: year
            });
            year.get('municipalAccounts').then(function (accounts) {
              municipalFund.get('municipalAccounts').addObjects(accounts);
              municipalFund.save().then(function (savedMunicipalFund) {
                year.get('municipalFunds').addObject(savedMunicipalFund);
                year.save().catch(function (err) {
                  console.log(err);
                });
              }).catch(function (err) {
                console.log(err);
              });
            }).catch(function (err) {
              console.log(err);
            });
            year.get('municipalTransactions').then(function (transactions) {
              municipalFund.get('municipalTransactions').addObjects(transactions);
              municipalFund.save();
            });
          });
        }).catch(function (err) {
          console.log(err);
        });
      },
      fundBtn: function fundBtn(fund) {
        alert(fund);
      },
      deleteFund: function deleteFund(fund) {
        Ember.get(this, 'fundInfo').deleteFund(fund).then(function (res) {}).catch(function (err) {
          throw new Error(err);
        });
      },
      deactivateFund: function deactivateFund(fund) {
        Ember.get(this, 'fundInfo').deactivateFund(fund).then(function (res) {}).catch(function (err) {
          throw new Error(err);
        });
      }
    }
  });
});