define('fund-figure-app/components/dashboard/personnel/employees/employee-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    store: Ember.inject.service(),
    undo: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    appError: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      console.log('setting groupValue');
      var _this = this,
          appError = this.get('appError');
      this.get('model.employee_private').then(function (model) {
        if (!model) {
          return;
        }
        if (model.get('married')) {
          _this.set('radioStatusFiling', "married");
        } else if (model.get('single')) {
          _this.set('radioStatusFiling', "single");
        } else if (model.get('married_single')) {
          _this.set('radioStatusFiling', "married_single");
        }
        if (model.get('payroll_type_hourly')) {
          _this.set('radioPayrollStatus', "payroll_type_hourly");
          _this.set('payrollAmount', model.get('payroll_amount'));
        } else {
          _this.set('radioPayrollStatus', "payroll_type_salary");
          _this.set('payrollAmount', model.get('payroll_amount') * 8 * 5 * 52);
        }
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error setting filing status on insert of element';
        appError.logError(err, specialMessage);
      });
    },
    willDestroyElement: function willDestroyElement() {
      var model = this.get('model'),
          undo = this.get('undo'),
          appError = this.get('appError'),
          commonTasks = this.get('commonTasks'),
          bigBrother = this.get('bigBrother');
      this.get('model.employee_private').then(function (privateData) {
        var privateDataChanges = undo.getChanges(privateData);
        console.log(privateDataChanges);
        if (!commonTasks.isEmpty(privateDataChanges)) {
          var desc = 'Undo changes made to ' + model.get('employee_firstName') + ' ' + model.get('employee_lastName'),
              logDesc = model.get('employee_firstName') + ' ' + model.get('employee_lastName'),
              undoObject = { model: privateData, desc: desc, changes: privateDataChanges, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
        }
        return privateData.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving private employee data changes during destruction of element';
          appError.logError(err, specialMessage);
        });
      }).then(function (savedPrivateData) {
        var modelChanges = undo.getChanges(model);
        if (!commonTasks.isEmpty(modelChanges)) {
          var desc = 'Undo changes made to ' + model.get('employee_firstName') + ' ' + model.get('employee_lastName'),
              logDesc = model.get('employee_firstName') + ' ' + model.get('employee_lastName'),
              undoObject = { model: model, desc: desc, changes: modelChanges, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
        }
        return model.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving employee model during destruction of element';
          appError.logError(err, specialMessage);
        });
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error during the saving and logging of changes to employee model and private data during destruction of element';
        appError.logError(err, specialMessage);
      });
    },

    actions: {
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('model');
        this.get('documentManager').saveDocument(fileInfo, 'vendor document').then(function (res) {
          return [model.get('employee_private'), res];
        }).then(function (resArray) {
          resArray[0].get('files').addObject(resArray[1]);
          resArray[0].save();
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('model'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            appError = this.get('appError'),
            _this = this;
        model.get('employee_private').then(function (res) {
          res.get('files').removeObject(file);
          res.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving employee_private after file removal';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error removing file from employee_private';
          appError.logError(err, specialMessage);
        });
        model.save().then(function () {
          fiscalYear.get('municipalDocuments').removeObject(file);
          fiscalYear.save().then(function () {
            file.destroyRecord();
          }).catch(function (error) {
            appError.logError(error);
            console.log(error);
          });
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      downloadFile: function downloadFile(file) {
        dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href", file.get('fileDownload'));
        dlAnchorElem.setAttribute("download", '' + file.get('name'));
        dlAnchorElem.click();
      },
      changeStatusFiling: function changeStatusFiling(value) {
        var _this = this,
            appError = this.get('appError');
        this.get('model.employee_private').then(function (model) {
          if (!model) {
            console.log('no model present for employee-private so creating one');
            var newModel = _this.get('store').createRecord('employee_private');
            newModel.set(value, true);
            newModel.save().then(function (savedModel) {
              _this.get('model').set('employee_private', savedModel);
              _this.get('model').save().catch(function (err) {
                console.log(err);
                var specialMessage = 'error while saving employee with new employee private model';
                appError.logError(err, specialMessage);
              });
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error while saving new model';
              appError.logError(err, specialMessage);
            });
          } else {
            console.log('model present so just update status of filing');
            model.setProperties({ married: false, single: false, married_single: false });
            model.set(value, true);
            model.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving employee_private model after changing status filing';
              appError.logError(err, specialMessage);
            });
          }
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error getting employee_private model';
          appError.logError(err, specialMessage);
        });
      },

      /**
       * Changes payroll status and recalculates amount
       * @param  {[string]} value [radio button has string description matching attribute on model to set true]
       * @return {[NA]}
       */
      changePayrollStatus: function changePayrollStatus(value) {
        var _this = this,
            appError = this.get('appError');
        this.get('model.employee_private').then(function (model) {
          if (!model) {
            console.log('no model present for employee-private so creating one');
            var newModel = _this.get('store').createRecord('employee_private');
            newModel.set(value, true);
            newModel.save().then(function (savedModel) {
              _this.get('model').set('employee_private', savedModel);
              _this.get('model').save().catch(function (err) {
                console.log(err);
                var specialMessage = 'error while saving employee with new employee private model';
                appError.logError(err, specialMessage);
              });
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error while saving new model';
              appError.logError(err, specialMessage);
            });
          } else {
            console.log('model present so just update status of filing');
            model.setProperties({ payroll_type_salary: false, payroll_type_hourly: false });
            model.set(value, true);
            model.save().then(function (savedModel) {
              if (savedModel.get('payroll_type_hourly')) {
                _this.set('payrollAmount', savedModel.get('payroll_amount'));
              } else {
                _this.set('payrollAmount', _this.get('commonTasks').preciseRound(savedModel.get('payroll_amount') * 8 * 5 * 52, 2));
              }
              return;
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving employee_private model after changing status filing';
              appError.logError(err, specialMessage);
            });
          }
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error getting employee_private model';
          appError.logError(err, specialMessage);
        });
      },

      /**
       * Calculates amount to hourly depending if salary or hourly picked and sets model amount
       * @param  {[string]} value [equal to input amount]
       * @return {[NA]}
       */
      payrollAmount: function payrollAmount(value) {
        var _this = this,
            undo = this.get('undo'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother');
        this.get('model.employee_private').then(function (model) {
          if (model.get('payroll_type_hourly')) {
            return model.set('payroll_amount', value);
          } else {
            return model.set('payroll_amount', _this.get('commonTasks').preciseRound(value / 8 / 5 / 52, 2));
          }
          // let changes = undo.getChanges(model);
          // if (changes) {
          //   let desc = `Undo changes made to ${_this.get('model.employee_firstName')} ${_this.get('model.employee_lastName')}`,
          //     logDesc = `${_this.get('model.employee_firstName')} ${_this.get('model.employee_lastName')}`,
          //     undoObject = { model: model, desc: desc, changes: changes, logDesc: logDesc };
          //   bigBrother.logChange(undoObject);
          //   undo.get('undoObjects').addObject(undoObject);
          // }
          // return model
          //   .save()
          //   .catch(err => {
          //     console.log(err);
          //     let specialMessage = `error saving employee private salary info`;
          //     appError.logError(err, specialMessage);
          //   })
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'specialMessage';
          appError.logError(err, specialMessage);
        });
      },

      /**
       * Add currently selected account to a list of accounts employee may use to draw payroll from
       * @param {[model]} account [model for municipal-account employee should draw payroll from]
       */
      addPayrollOption: function addPayrollOption(account) {
        var _this = this,
            undo = this.get('undo'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother');
        this.get('model.employee_private').then(function (model) {
          model.get('payrollAccounts').addObject(account);
          model.save().then(function () {
            console.log('all set');
            return _this.set('model.employee_private.payrollAccount', null);
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error adding payroll account to employee_private list and saving';
            appError.logError(err, specialMessage);
          });
        });
      },
      removePayrollOption: function removePayrollOption(payrollAccount) {
        var _this = this,
            undo = this.get('undo'),
            appError = this.get('appError'),
            bigBrother = this.get('bigBrother');
        this.get('model.employee_private').then(function (model) {
          model.get('payrollAccounts').removeObject(payrollAccount);

          var desc = 'Undo payroll account removal from ' + _this.get('model.employee_firstName') + ' ' + _this.get('model.employee_lastName'),
              logDesc = _this.get('model.employee_firstName') + ' ' + _this.get('model.employee_lastName'),
              undoObject = { model: model, desc: desc, logDesc: logDesc, secondaryModels: [{ model: payrollAccount, location: 'payrollAccounts' }] };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
          model.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving employe_private data after removing a payroll accounr reference';
            appError.logError(err, specialMessage);
          });
        });
      },
      directDeposit: function directDeposit(employeePrivate) {
        var store = Ember.get(this, 'store');
        store.findRecord('employee-private', employeePrivate).then(function (employeePrivateRecord) {
          if (employeePrivateRecord.get('payroll_payment_direct')) {
            employeePrivateRecord.set('payroll_payment_direct', false);
          } else {
            employeePrivateRecord.set('payroll_payment_direct', true);
          }
          employeePrivateRecord.save();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saing employee private data after direct deposit change';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});