define('fund-figure-app/routes/dashboard-assets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // connectedUser: service(),
    // firebaseApp: service(),
    // store: service(),
    // beforeModel: function() {
    //   // this._super();
    //   return this.get("session").fetch().catch(function() {});
    // },
    // afterModel() {
    //   let firebase = this.get('firebaseApp'),
    //     _this = this,
    //     // store = this.get('store'),
    //     session = this.get('session');
    //   if (!session || typeof(session) == 'undefined' || !session.content.isAuthenticated) {
    //     console.log('no authenticated session');
    //     return this.transitionTo('account-handler.account-menu');
    //   }
    //   let date = moment().format('x');
    //   let user = this.store.findRecord('user', session.content.uid);
    //   let foundUser = user.then(result => {

    //       //////CODE BELOW IS FOR MULTI ACCOUNT SUPPORT//////
    //       let staff = result.get('defaultAccount');
    //       if (!result.toJSON().defaultAccount) {
    //         console.log('no defaultAccount');
    //         return result.get('municipalUsers.lastObject');
    //       } else {
    //         return staff
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });

    //   foundUser.then(staff => {
    //     console.log(staff);
    //       if (!staff) {
    //         _this.transitionTo('account-handler.account-menu');
    //       }
    //       if (staff.get('is_new')) {
    //         console.log('staff has isNew');
    //         let string = staff.get('is_new');
    //         let transitionArray = string.split('/');
    //         console.log(transitionArray);
    //         if (transitionArray.length > 0) {
    //           _this.transitionTo(transitionArray[0], transitionArray[1]);
    //         } else {
    //           _this.transitionTo(transitionArray[0]);
    //         }
    //       } else {
    //         // console.log('transition to dashboard support');
    //         // _this.transitionTo('dashboard-support');
    //       }
    //       // since I can connect from multiple devices or browser tabs, we store each connection instance separately
    //       // any time that connectionsRef's value is null (i.e. has no children) I am offline        
    //       var connectionHistoryRef = firebase.database().ref('connectionHistories/');
    //       var myStaffRef = firebase.database().ref('municipalUsers/' + staff.get('id'));
    //       let newConnectionHistory = connectionHistoryRef.push();
    //       // stores the timestamp of my last disconnect (the last time I was seen online)
    //       var lastUserOnline = firebase.database().ref('users/' + user.get('id'));

    //       var connectedRef = firebase.database().ref('.info/connected');
    //       connectedRef.on('value', function(snap) {
    //         if (snap.val() === true) {
    //           // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)

    //           // add this device to my connections list
    //           // this value could contain info about the device or a timestamp too
    //           let ua = navigator.userAgent;
    //           console.log(ua);
    //           if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS|Phone/i.test(ua)) {
    //             //mobile device
    //             console.log('mobile device');
    //             let date = moment().format('x');
    //             let connection = myStaffRef.child('connections').push(true);
    //             let connectionsRef = firebase.database().ref('connections/' + connection.key);

    //             connectionsRef.update({
    //               device: 'mobile',
    //               time: date
    //             });
    //             newConnectionHistory.update({
    //               device: 'mobile',
    //               timeOn: date,
    //               user: staff.get('user.id'),
    //               account: myStaffRef.key
    //             });
    //             _this.store.findRecord('connection', connectionsRef.key);
    //             // when I disconnect, remove this device
    //             connectionsRef.onDisconnect().remove();
    //             connection.onDisconnect().remove();
    //             myStaffRef.child('shouldRefresh').onDisconnect().remove();
    //             newConnectionHistory.onDisconnect().update({
    //               timeOff: moment().format('x')
    //             });
    //           } else if (/OS X|Windows|Linux/i.test(ua)) {
    //             //desktop
    //             console.log('signed in desktop');
    //             let date = moment().format('x');
    //             let connection = myStaffRef.child('connections').push(true);
    //             let connectionsRef = firebase.database().ref('connections/' + connection.key);
    //             connectionsRef.update({
    //               device: 'desktop',
    //               time: date
    //             });
    //             newConnectionHistory.update({
    //               device: 'desktop',
    //               timeOn: date,
    //               user: staff.get('user.id'),
    //               account: myStaffRef.key
    //             });
    //             _this.store.findRecord('connection', connectionsRef.key);
    //             // console.log(staff);
    //             // when I disconnect, remove this device
    //             connectionsRef.onDisconnect().remove();
    //             connection.onDisconnect().remove();
    //             myStaffRef.child('shouldRefresh').onDisconnect().remove();
    //             newConnectionHistory.onDisconnect().update({
    //               timeOff: moment().format('x')
    //             });
    //           } else if (/tvOS|xbox/i.test(ua)) {
    //             //tv platforms
    //             let date = moment().format('x');
    //             let connection = myStaffRef.child('connections').push(true);
    //             let connectionsRef = firebase.database().ref('connections/' + connection.key);
    //             connectionsRef.update({
    //               device: 'tv platforms',
    //               time: date
    //             });

    //             newConnectionHistory.update({
    //               device: 'tv platforms',
    //               timeOn: date,
    //               user: staff.get('user.id'),
    //               account: staff.get('id')
    //             });
    //             _this.store.findRecord('connection', connectionsRef.key);
    //             // when I disconnect, remove this device
    //             connectionsRef.onDisconnect().remove();
    //             connection.onDisconnect().remove();
    //             myStaffRef.child('shouldRefresh').onDisconnect().remove();
    //             newConnectionHistory.onDisconnect().update({
    //               timeOff: moment().format('x')
    //             });
    //           }
    //           let date = moment().format('x');
    //           // when I disconnect, update the last time I was seen online
    //           myStaffRef.onDisconnect().update({ lastOnline: date });
    //           lastUserOnline.onDisconnect().update({ activity: date });
    //         }
    //       }, function(error) {
    //         console.log(error);
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  });
});