define('fund-figure-app/components/dashboard/settings/manage-funds/fund-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fundInfo: Ember.inject.service(),
    doubleClick: function doubleClick() {
      alert(Ember.get(this, 'fund.id'));
    },

    actions: {
      deleteFund: function deleteFund(fund) {
        Ember.get(this, 'fundInfo').deleteFund(fund).then(function (res) {}).catch(function (err) {
          throw new Error(err);
        });
      },
      deactivateFund: function deactivateFund(fund) {
        Ember.get(this, 'fundInfo').deactivateFund(fund).then(function (res) {}).catch(function (err) {
          throw new Error(err);
        });
      },
      reactivateFund: function reactivateFund(fund) {
        Ember.get(this, 'fundInfo').reactivateFund(fund).then(function (res) {
          return res;
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }
  });
});