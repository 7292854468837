define('fund-figure-app/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    fileType: _emberData.default.attr('string'),
    saveDate: _emberData.default.attr('number'),
    filePath: _emberData.default.attr('string'),
    fileDownload: _emberData.default.attr('string'),
    folder: _emberData.default.attr('string'),
    t_year: _emberData.default.attr('string'),
    t_month: _emberData.default.attr('string'),
    searchTags: _emberData.default.attr('string'), //this is populated when the files is original saved
    metadata: _emberData.default.attr(), //name, size, lastModified
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    municipalYear: _emberData.default.belongsTo('municipal-year', { async: true, inverse: null }),
    municipalFund: _emberData.default.belongsTo('municipal-fund', { async: true, inverse: null }),
    query_public_municipality: _emberData.default.attr('string'), //string of {municipal_id}-public
    query_public_municipalFund: _emberData.default.attr('string'), //string of {municipalFund_id}-public
    query_private_municipality: _emberData.default.attr('string'), //string of {municipal_id}-private
    query_private_municipalFund: _emberData.default.attr('string') //string of {municipalFund_id}-private
  });
});