define('fund-figure-app/routes/account-handler/account-create-step-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			var _this = this;
			var session = this.get('session');
			if (!session.content.isAuthenticated) {
				// return this.store.createRecord('user');
				return;
			} else {
				var model = this.store.findRecord('user', session.content.uid);
				model.then(function (foundUser) {
					_this.transitionTo('account-handler.account-create-step-two', foundUser);
				}).catch(function (error) {
					console.log(error);
				});
			}
		}
	});
});