define('fund-figure-app/components/reports/assets/asset-statistics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    assetInfo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    assetArray: [],

    getAssets: Ember.computed('', {
      get: function get() {
        var _this2 = this;

        var promise = new Promise(function (resolve, reject) {
          resolve(Ember.get(_this2, 'assetInfo.allAssets'));
        });
        promise.then(function (res) {
          Ember.set(_this2, 'assetArray', res);
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }),

    generatePageContent: Ember.computed('assetArray', {
      //generate pages based on line limit for report
      get: function get() {
        var lines = this.get('model.lines_per_page'),
            _this = this,
            assetArray = this.get('assetArray'),
            assetNumber = assetArray.length,
            page_info = [],
            lastIndex = 0,
            line_count = 0;
        if (assetArray.length < 1) {
          return;
        }
        console.log(assetArray);
        assetArray.forEach(function (asset, index) {
          var totalLines = 1;
          if (line_count + totalLines >= lines) {
            var object = {};
            object["startAt"] = lastIndex;
            object["endAt"] = index - 1;
            page_info.push(object);
            line_count = 0;
            lastIndex = index;
          }
          line_count += totalLines;
          if (assetNumber == index + 1) {
            var _object = {};
            _object["startAt"] = lastIndex;
            _object["endAt"] = index;
            page_info.push(_object);
            _this.set('pageInfo', page_info);
          }
        });
      }
    }),

    actions: {
      getAssetInfo: function getAssetInfo(asset) {
        console.log('asset from compute', asset);
        return Ember.get(this, 'assetInfo').singleAssetGanttData(asset);
      }
    }
  });
});