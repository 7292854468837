define('fund-figure-app/models/municipal-check', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    manifest: _emberData.default.belongsTo('manifest'),
    municipalInvoices: _emberData.default.hasMany('municipal-invoice', { inverse: null }),
    vendor: _emberData.default.belongsTo('vendor', { async: true, inverse: null }),
    check_number: _emberData.default.attr('number'),
    date: _emberData.default.attr('number'),
    municipalDb: _emberData.default.belongsTo('municipal-db'),
    amount: _emberData.default.attr('number'),
    p_transaction: _emberData.default.belongsTo('transaction', { inverse: 'municipalCheck' }), //transaction where check was paid out 
    void: _emberData.default.attr('boolean'), //true if check has been voided
    void_reason: _emberData.default.attr('string'),
    new_check: _emberData.default.belongsTo('municipal-check', { inverse: 'previous_check' }), //if check voided this links to new one issued
    previous_check: _emberData.default.belongsTo('municipal-check', { inverse: 'new_check' }), //if product of reissued void check this links to original  
    reconciledBankTransaction: _emberData.default.belongsTo('bank-account-transaction', { async: true, inverse: null }), //link to bank transaction
    reconciled: _emberData.default.attr('boolean', { defaultValue: false }), //this could be deleted if we use reconciled-report as indicator
    reconciledReport: _emberData.default.belongsTo('reconciled-report'), //link to reconciled report
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
    municipalYear: _emberData.default.belongsTo('municipal-year', { async: true, inverse: null }),
    ts: _emberData.default.attr('number'),
    status: Ember.computed('void', 'new_check', {
      get: function get() {
        var new_check = Ember.get(this, 'new_check.id') || false,
            prev_check = Ember.get(this, 'previous_check.id') || false;
        console.log(prev_check);
        if (Ember.get(this, 'void') && new_check) {
          // set(this, 'checkStatus', 'reissued');
          return 'reissued';
        } else if (Ember.get(this, 'void')) {
          // set(this, 'checkStatus', 'voided');
          return 'voided';
        } else if (Ember.get(this, 'reconciled')) {
          // set(this, 'checkStatus', 'paid');
          return 'paid';
        } else if (prev_check) {
          return 'reissue';
        } else {
          // set(this, 'checkStatus', 'in-trans');
          return 'in-trans';
        }
      }
    }),
    searchField: Ember.computed('p_transaction.searchField', 'vendor.searchField', 'amount', {
      get: function get() {
        return this.get('amount') + ' ' + this.get('p_transaction.searchField') + ' ' + this.get('vendor.searchField') + ' ' + moment(this.get('date')).format('MMMM D');
      }
    })
  });
});