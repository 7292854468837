define('fund-figure-app/controllers/dashboard/personnel/employees/employee/employee-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    globalMessaging: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    router: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    undo: Ember.inject.service(),
    appError: Ember.inject.service(),
    actions: {
      saveEmployee: function saveEmployee(employee) {
        var undoObjects = this.get('undo.undoObjects'),
            bigBrother = this.get('bigBrother'),
            employeeChanges = employee.changedAttributes(),
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            newChangesObject = [],
            message = 'Saved ' + employee.get('employee_firstName') + ' ' + employee.get('employee_lastName');
        if (employeeChanges) {
          console.log(employeeChanges);
          for (var key in employeeChanges) {
            var newSub = '{ "' + key + '" : {"old": "' + employeeChanges[key].slice(0, 1) + '", "new": "' + employeeChanges[key].slice(1) + '" } }';
            console.log(newSub);
            newChangesObject.addObject(JSON.parse(newSub));
          }
          var desc = 'Undo changes made to ' + employee.get('employee_firstName') + ' ' + employee.get('employee_lastName'),
              logDesc = employee.get('employee_firstName') + ' ' + employee.get('employee_lastName'),
              undoObject = { model: employee, desc: desc, changes: newChangesObject, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undoObjects.addObject(undoObject);
          console.log(undoObjects);
          employee.save().then(function (res) {
            globalMessage.actionNotification(message, 'success');
            router.transitionTo('dashboard.personnel.employees');
          }).catch(function (err) {
            console.log(err);
          });
        }
      },
      deleteEmployee: function deleteEmployee(employee) {
        var undoObjects = this.get('undo.undoObjects'),
            globalMessages = this.get('globalMessaging'),
            appError = this.get('appError'),
            router = this.get('router'),
            _this = this,
            message = 'Deleted ' + employee.get('employee_firstName') + ' ' + employee.get('employee_lastName'),
            undo_desc = 'Undo deletion of ' + employee.get('employee_firstName') + ' ' + employee.get('employee_lastName');

        this.get('connectedUser.currentAccount.municipality').then(function (municipalDb) {
          return municipalDb.get('municipalVendorsDb').then(function (employeesDb) {
            var undoObject = { model: employee, desc: undo_desc, modelDeleted: true, secondaryModels: [{ model: employeesDb, location: 'employees' }] };
            undoObjects.addObject(undoObject);
            employeesDb.get('vendors').removeObject(employee);
            employeesDb.save().then(function () {
              employee.deleteRecord();
              employee.save();
              _this.get('connectedUser.buildEmployees');
              router.transitionTo('dashboard.personnel.employees');
            }).catch(function (err) {
              console.log(err);
              appError.logError(err);
            });
          }).catch(function (err) {
            console.log(err);
            appError.logError(err);
          });
        }).catch(function (err) {
          console.log(err);
          appError.logError(err);
        });
      },
      cancelEdit: function cancelEdit() {
        this.get('router').transitionTo('dashboard.personnel.employees');
      }
    }
  });
});