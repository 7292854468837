define('fund-figure-app/components/undo-btn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    undo: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    actions: {
      toggleShowOpps: function toggleShowOpps() {
        this.toggleProperty('showOpps');
        // $("#undoChanges").animate({ scrollTop: $(document).height()-$(window).height()}, "slow");
        $("#undoChanges").scrollTop($(document).height());
      },
      undo: function undo(undoObject) {
        if (this.get('undo.undoObjects').length == 1) {
          this.toggleProperty('showOpps');
        }
        this.get('undo').undo(undoObject);
      }
    }
  });
});