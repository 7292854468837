define('fund-figure-app/services/check-third-party-transactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.queryTransactions();
    },


    queryTransactions: computed('connectedUser.fiscalYear', {
      get: function get() {
        if (!this.get('connectedUser.fiscalYear')) {
          return;
        }
        this.get('store').query('transaction-waiting-room', {
          orderBy: 'municipality',
          equalTo: '' + municipal_id
        });
      }
    })
  });
});