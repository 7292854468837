define('fund-figure-app/routes/dashboard-support/manage-warrant-articles/create-warrant-article', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model(params) {
			return this.get('store').find('state', params.state_id);
		}
	});
});