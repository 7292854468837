define('fund-figure-app/components/account-handler/sign-in', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    // routeHistory: service(),
    router: Ember.inject.service(),
    optionalMessage: null,

    // willRender() {
    //   var user = this.get('user.isNew');
    //   var router = this.get('router');
    //   if (user) {
    //     router.transitionTo(user);
    //   }
    // },
    actions: {
      signIn: function signIn(email, password) {
        if (typeof email === 'undefined') {
          this.setProperties({ errorMessage: 'email cannot be blank' });
          return;
        }
        if (typeof password === 'undefined') {
          this.setProperties({ errorMessage: 'password cannot be blank' });
          return;
        }
        this.setProperties({ loading: true, optionalMessage: "Looking for user..." });
        var torii = this.get('session');
        var store = this.get('store');
        // let service = this.get('connectedUser');
        store.peekAll('user').forEach(function (r) {
          r.unloadRecord();
        });
        var route = this.get('router');
        var _this = this;
        _this.setProperties({ errorMessage: '' });
        torii.open('firebase', {
          provider: 'password',
          email: email,
          password: password
        }).then(function (d) {
          _this.setProperties({ loading: true, optionalMessage: "Loading settings..." });
          // _this.toggleProperty('modalSignInDisplay');
          torii.fetch(d).catch(function () {});
          var user = store.findRecord('user', d.uid);
          user.then(function (foundUser) {
            _this.setProperties({ loading: true, optionalMessage: "Loading data..." });
            if (foundUser.get('is_new')) {
              console.log('we have user and a new path : ' + foundUser.get('is_new'));
              route.transitionTo(foundUser.get('is_new'), foundUser);
            } else {
              _this.get('connectedUser.user');
              _this.get('connectedUser').getFiscalYear();
              route.transitionTo('dashboard.state-of-affairs');
            }
          }).catch(function (error) {
            _this.setProperties({ loading: false });
            console.log(error);
          });
        }).catch(function (error) {
          _this.set('loading', false);
          _this.setProperties({ errorMessage: error.message, login: true, displayChoice: false });
        });
      },
      createAccount: function createAccount() {},
      passwordReset: function passwordReset(email) {
        if (typeof email === 'undefined') {
          this.setProperties({ errorMessage: 'provide an email to account for password reset' });
          return;
        }
        var ref = new Firebase("https://project-6125170587165092387.firebaseio.com/");
        ref.resetPassword({
          email: email
        }, function (error) {
          if (error === null) {
            console.log("Password reset email sent successfully");
          } else {
            console.log("Error sending password reset email:", error);
          }
        });
      }
    }
  });
});