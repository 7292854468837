define('fund-figure-app/components/dashboard/warrant-articles/warrant-articles-index', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    appError: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    undo: Ember.inject.service(),
    bigBrother: Ember.inject.service(),

    moneyFormat: {
      pos: "<green>%s%v</green>",
      neg: "<red>%s%v</red>",
      zero: "%s0.00"
    },

    articles: Ember.computed('connectedUser.currentFund', {
      get: function get() {
        var _this2 = this;

        return new Promise(function (resolve, reject) {
          if (!Ember.get(_this2, 'connectedUser.currentFund.municipalArticles')) {
            console.log('no articles');
            reject('no articles');
          }
          Ember.get(_this2, 'connectedUser.currentFund.municipalArticles').then(function (res) {
            resolve(res);
          }).catch(function (err) {
            console.log(err);
            reject(err);
          });
        });
      }
    }),

    moneyImpact: {
      pos: "<red>%s%v</red>",
      neg: "<green>%s%v</green>",
      zero: "%s0.00"
    },

    actions: {
      fixIssue: function fixIssue() {
        Ember.get(this, 'model').forEach(function (article) {
          article.get('warrantArticleTemplate').then(function (template) {
            return template.get('category');
          }).then(function (category) {
            article.set('category', category);
            return article.save();
          }).catch(function (err) {
            console.log(err);
          });
        });
      },
      showWarrantInfo: function showWarrantInfo(article) {},


      /**
       * Used for reduce of array of tax impact and article amount
       * @param  {[type]} val  [description]
       * @param  {[type]} val1 [description]
       * @return {[type]}      [description]
       */
      sum: function sum(val, val1) {
        if (!val1 || (typeof val1 === 'undefined' ? 'undefined' : _typeof(val1)) === undefined || val1 === null) {
          return val;
        }
        return +val + +val1;
      },


      /**
       * Submit the filter and adjust municipal-article array accordingly
       * @return {[type]} [description]
       */
      submitFilter: function submitFilter() {},


      /**
       * Clear the filter from affecting the current list of articles
       * @return {[type]} [description]
       */
      clearFilter: function clearFilter() {},


      /**
       * Resets article language to template language or article chosen
       * @param  {EmberModel} article          ember model of municipal-article
       * @param  {string} templateLanguage string from warrant-article-template.language
       * @return {NA}               
       */
      resetArticle: function resetArticle(article, templateLanguage) {
        return article.set('language', templateLanguage);
      },


      /**
       * Save article and add references as needed
       * @param  {EmberModel} article ember model of municipal-article
       * @return {NA}         
       */
      saveArticle: function saveArticle(article) {
        var _this3 = this;

        var appError = Ember.get(this, 'appError'),
            router = Ember.get(this, 'router'),
            store = Ember.get(this, 'store');
        article.save().then(function () {
          Ember.set(_this3, 'editLanguage', false);
          Ember.set(_this3, 'editArticle', null);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving article ' + article.id;
          appError.logError(err, specialMessage);
        });
      },


      /**
       * Account Article hasMany was changed need to remove the article from the account and add to knew one if selected
       * @param  {emberModel} article     model of current article in edit mode
       * @param  {emberModel} selected    model of selected account
       * @param  {Object} powerObject ember-power-select object containing loads of information
       * @return {[type]}             [description]
       */
      accountForArticleChanged: function accountForArticleChanged(article, selected, powerObject) {
        console.log(article, selected, powerObject, _typeof(powerObject.highlighted));
        if (typeof powerObject.highlighted !== "undefined") {
          //if highlighted then model being added
          article.get('municipalAccounts').addObject(powerObject.highlighted);
          powerObject.highlighted.get('municipalArticles').addObject(article);
          article.save().then(function (res) {
            powerObject.highlighted.save();
          });
        } else if (selected.length < powerObject.selected.length) {
          //if selected is less than model selected, something was removed 
          var newArray = powerObject.selected.removeObjects(selected);
          newArray.forEach(function (account) {
            account.get('municipalArticles').removeObject(article);
            account.save();
          });
          article.set('municipalAccounts', selected);
        }

        // if not highlighted see if selected, if selected should be removed

        // let store = get(this, 'store'),
        //   appError = get(this, 'appError'),
        //   orig_muni_acct_ref = false,
        //   secondaryModels = [], //array to hold and secondary model references for undo
        //   undoObject = {};
        // //check if article has account associated already
        // let promiseRemoveAccountRef = new Promise((resolve, reject) => {
        //   if (article.toJSON().municipalAccounts) {
        //     //associated account present, remove it and reference on article
        //     store.findRecord('municipalAccount', article.toJSON().municipalAccount)
        //       .then(municipalAccount => {
        //         secondaryModels.push({ model: municipalAccount, location: 'municipalArticles' });
        //         orig_muni_acct_ref = municipalAccount;
        //         municipalAccount
        //           .get('municipalArticles')
        //           .removeObject(article);
        //         municipalAccount
        //           .save()
        //           .catch(err => {
        //             console.log(err);
        //             let specialMessage = `error saving account after removing article reference`;
        //             appError.logError(err, specialMessage);
        //             reject(err);
        //           })
        //           .then(() => {
        //             resolve('done');
        //           })
        //       })
        //   } else {
        //     resolve('done');
        //   }
        // });
        // //check if something selected  
        // let promiseAddReference = new Promise((resolve, reject) => {
        //   if (!selected) {
        //     //nothing selected so remove article from account and remove account from article if present
        //     article
        //       .set('municipalAccount', null);
        //     article
        //       .save()
        //       .catch(err => {
        //         console.log(err);
        //         let specialMessage = `error saving article after removing account reference`;
        //         appError.logError(err, specialMessage);
        //         reject(err);
        //       })
        //       .then(() => {
        //         resolve('done');
        //       })
        //   } else {
        //     article
        //       .set('municipalAccount', selected)
        //     article
        //       .save()
        //       .catch(err => {
        //         console.log(err);
        //         let specialMessage = `error saving article after adding selected option to article`;
        //         appError.logError(err, specialMessage);
        //         reject(err);
        //       })
        //       .then(savedArticle => {
        //         selected
        //           .get('municipalArticles')
        //           .addObject(savedArticle);
        //         selected
        //           .save()
        //           .catch(err => {
        //             console.log(err);
        //             let specialMessage = `error saving account after adding article to list of articles`;
        //             appError.logError(err, specialMessage);
        //             reject(err);
        //           })
        //           .then(() => {
        //             resolve('done');
        //           })
        //       })
        //   }
        // });

        // Promise.all([promiseRemoveAccountRef, promiseAddReference]).then(results => {
        //   //write undo
        //   let desc = null,
        //     logDesc = `Article ${article.get('number')}`;
        //   if (selected) {
        //     if (orig_muni_acct_ref) {
        //       desc = `Removed article ${article.get('number')} from account ${orig_muni_acct_ref.get('stateName')} and added to ${selected.get('stateName')}`;
        //     } else {
        //       desc = `Added article ${article.get('number')} to account ${selected.get('stateName')}`;
        //     }
        //   } else {
        //     if (orig_muni_acct_ref) {
        //       desc = `Removed article ${article.get('number')} from account ${orig_muni_acct_ref.get('stateName')}`;
        //     }
        //   }
        //   //check if desc is present if not then nothing changed dont bother with undo or bigBrother
        //   if (desc) {
        //     undoObject = { model: article, modelDeleted: false, secondaryModels: secondaryModels, desc: desc, logDesc: logDesc };
        //     //add undoObject to undo list
        //     get(this, 'undo.undoObjects').addObject(undoObject);
        //     //send undoObject to bigBrother for accountability
        //     get(this, 'bigBrother').logChange(undoObject);
        //   }
        // })
      },


      /**
       * Delete article from municipalWarrants ad ref from municipalAccount (if exists) and municipalFund
       * @param  {EmberModel} article ember model of the municipal-article to be deleted
       * @return {NA}
       */
      deleteArticle: function deleteArticle(article) {
        var _this4 = this;

        //create an array to hold promises to we can know when all are complete and finally delete the article
        var promiseArray = [],
            secondaryModels = [],
            //array to hold and secondary model references for undo
        undoObject = {},
            _this = this;
        //lets check to see what references the file has
        if (article.toJSON().municipalAccount) {
          //has a municipal-account reference, so lets remove it and add to secondaryModels array       
          var removeAccountRef = article.get('municipalAccount').then(function (municipalAccount) {
            municipalAccount.get('municipalArticles').removeObject(article);
            return municipalAccount.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving municipal account ' + municipalAccount.get('id') + ' after removing the article to be deleted ' + article.get('id');
              appError.logError(err, specialMessage);
            }).then(function () {
              secondaryModels.push({ model: municipalAccount, location: 'municipalArtciles' });
            });
          });
          promiseArray.push(removeAccountRef);
        }
        //We know the account will have a fund ref so remove it and add to secondaryModels array
        var removeFundRef = article.get('municipalFund').then(function (municipalFund) {
          municipalFund.get('municipalArticles').removeObject(article);
          return municipalFund.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving municipal fund ' + municipalFund.get('id') + ' after removing the article to be deleted ' + article.get('id');
            appError.logError(err, specialMessage);
          }).then(function () {
            return secondaryModels.push({ model: municipalFund, location: 'municipalArticles' });
          });
        });
        promiseArray.push(removeFundRef);

        Promise.all(promiseArray).then(function (results) {
          //the necessary ref to this article have been removed so we can run the undo process and delete the article
          var desc = 'Add article ' + article.get('number') + ' - ' + article.get('title') + ' back to article list';
          undoObject = { model: article, modelDeleted: true, secondaryModels: secondaryModels, desc: desc };
          //add undoObject to undo list
          Ember.get(_this4, 'undo.undoObjects').addObject(undoObject);
          //send undoObject to bigBrother for accountability
          Ember.get(_this4, 'bigBrother').logChange(undoObject);
          article.destroyRecord().then(function () {
            _this.notifyPropertyChange('model');
          });
        });
      },


      /**
       * Toggle articles passed property between true and false
       * @param  {EmberModel} article ember model of municipal-article
       * @return {NA}
       */
      articlePassed: function articlePassed(article) {
        var appError = Ember.get(this, 'appError');
        article.toggleProperty('passed');
        article.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving article: ' + article.get('id') + ' after toggling passed attribute';
          appError.logError(err, specialMessage);
        });
      },


      /**
       * Sets editLanguage variable to true or false and shows editor for municipal-article.language
       * @return {[type]} [description]
       */
      editLanguage: function editLanguage() {
        this.toggleProperty('editLanguage');
      },


      /**
       * Sets clicked municipal-article to editArticle variable which sparks a new modal for editing article
       * @param  {EmberModel} article ember model of municipal-article clicked for editing
       * @return {NA} 
       */
      clickEdit: function clickEdit(article) {
        Ember.set(this, 'editArticle', article);
      },


      /**
       * Sets editLanguage and editArticle variables back to init stats for viewing index of articles
       * @return {[type]} [description]
       */
      back: function back() {
        Ember.set(this, 'editLanguage', false);
        Ember.set(this, 'editArticle', null);
      },


      /**
       * If someone inputs town valuation amount, calculate tax impact for each article
       * @param  {string} value string represents input
       * @return {NA}
       */
      calculateTaxImpact: function calculateTaxImpact(value) {
        //Should account for no string input
        var perThousandValue = value / 1000;
        Ember.get(this, 'model').forEach(function (article) {
          if (article.get('amount') || 0 > 0) {
            var impact = article.get('amount') / perThousandValue;
            article.set('tax_impact', impact);
          }
        });
      }
    }
  });
});