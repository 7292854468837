define('fund-figure-app/components/reports/ms-434/revenue-items-compact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    moneyFormat: {
      pos: "%s %v",
      neg: "-%s %v",
      zero: "$0"
    },
    accountsRange: null,
    accountsWarrant: null,
    budgetTotal: null,
    accountsSource: null,

    setUpInfo: Ember.computed('compactAccounts', {
      get: function get() {
        var accounts = this.get('compactAccounts'),
            _this = this,
            accountNumberArray = [],
            warrantArray = [],
            budget = 0,
            source = null,
            length = accounts.get('length');
        var promise = new Promise(function (resolve, reject) {
          if (length <= 1) {
            console.log('only 1 account in array');
            if (accounts[0].get('warrantArticle')) {
              warrantArray.push(accounts[0].get('warrantArticle'));
            }
            budget = accounts[0].get('budget');
            accounts[0].get('stateAccountNumber').then(function (stateAccount) {
              source = stateAccount.get('source');
              console.log(accountNumberArray.length + 1, accounts.get('length'));
              accountNumberArray.push(stateAccount.get('accountNumber'));
              resolve();
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            console.log('multi accounts');
            accounts.forEach(function (account) {
              if (account.get('warrantArticle')) {
                warrantArray.push(account.get('warrantArticle'));
              }
              budget += Number(account.get('budget').replace(/,/g, ''));
              account.get('stateAccountNumber').then(function (stateAccount) {
                source = stateAccount.get('source');
                accountNumberArray.push(stateAccount.get('accountNumber'));
                if (accountNumberArray.length + 1 === accounts.get('length')) {
                  resolve();
                }
              }).catch(function (err) {
                console.log(err);
              });
            });
          }
        });
        promise.then(function (results) {
          if (accountNumberArray.length <= 1) {
            console.log('one account');
            console.log(accountNumberArray.length);
            _this.setProperties({ accountsRange: accountNumberArray.toString(), accountsWarrant: warrantArray.toString(), budgetTotal: budget, accountsSource: source });
            return true;
          } else {
            console.log('multi account');
            console.log(accountNumberArray.length);
            var string = Math.min.apply(Math, accountNumberArray) + ' - ' + Math.max.apply(Math, accountNumberArray);
            console.log(string);
            _this.setProperties({ accountsRange: string, accountsWarrant: warrantArray.toString(), budgetTotal: budget, accountsSource: source });
            return true;
          }
        });
      }
    })
  });
});