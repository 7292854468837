define('fund-figure-app/controllers/dashboard/personnel/vendors/vendor/vendor-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    globalMessaging: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    router: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    undo: Ember.inject.service(),
    appError: Ember.inject.service(),
    actions: {
      saveVendor: function saveVendor(vendor) {
        var undoObjects = this.get('undo.undoObjects'),
            bigBrother = this.get('bigBrother'),
            vendorChanges = vendor.changedAttributes(),
            globalMessage = this.get('globalMessaging'),
            router = this.get('router'),
            newChangesObject = [],
            message = 'Saved ' + vendor.get('businessName');
        if (vendorChanges) {
          console.log(vendorChanges);
          for (var key in vendorChanges) {
            var newSub = '{ "' + key + '" : {"old": "' + vendorChanges[key].slice(0, 1) + '", "new": "' + vendorChanges[key].slice(1) + '" } }';
            console.log(newSub);
            newChangesObject.addObject(JSON.parse(newSub));
          }
          var desc = 'Undo changes made to ' + vendor.get('businessName'),
              logDesc = '' + vendor.get('businessName'),
              undoObject = { model: vendor, desc: desc, changes: newChangesObject, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undoObjects.addObject(undoObject);
          console.log(undoObjects);
          vendor.save().then(function (res) {
            globalMessage.actionNotification(message, 'success');
            router.transitionTo('dashboard.personnel.vendors');
          }).catch(function (err) {
            console.log(err);
          });
        }
      },
      deleteVendor: function deleteVendor(vendor) {
        var undoObjects = this.get('undo.undoObjects'),
            globalMessages = this.get('globalMessaging'),
            appError = this.get('appError'),
            router = this.get('router'),
            message = 'Deleted ' + vendor.get('businessName'),
            undo_desc = 'Undo deletion of ' + vendor.get('businessName');

        this.get('connectedUser.currentAccount.municipality').then(function (municipalDb) {
          return municipalDb.get('municipalVendorsDb').then(function (vendorsDb) {
            var undoObject = { model: vendor, desc: undo_desc, modelDeleted: true, secondaryModels: [{ model: vendorsDb, location: 'vendors' }] };
            undoObjects.addObject(undoObject);
            vendorsDb.get('vendors').removeObject(vendor);
            vendorsDb.save().then(function () {
              vendor.deleteRecord();
              vendor.save();
              _this.get('connectedUser.buildEmployees');
              router.transitionTo('dashboard.personnel.vendors');
            }).catch(function (err) {
              console.log(err);
              appError.logError(err);
            });
          }).catch(function (err) {
            console.log(err);
            appError.logError(err);
          });
        }).catch(function (err) {
          console.log(err);
          appError.logError(err);
        });
      },
      cancelEdit: function cancelEdit() {
        this.get('router').transitionTo('dashboard.personnel.vendors');
      }
    }
  });
});