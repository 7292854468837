define('fund-figure-app/components/dashboard/settings/index-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    undo: Ember.inject.service(),
    taxYearOptions: ['July to June', 'January to December'],
    billingCount: ['Annually', 'Bi-annual', 'Tri-annual', 'Quarterly'],
    payPeriods: Ember.computed('connectedUser.currentAccount.municipality.payrollDb', 'connectedUser.currentAccount.municipality.payrollDb.payroll_pay_period_end', 'connectedUser.currentAccount.municipality.payrollDb.payroll_pay_day', {
      get: function get() {
        var _this2 = this;

        var _this = this;
        return this.get('connectedUser.currentAccount.municipality.payrollDb').then(function (payrollModel) {
          if (!payrollModel) {
            return;
          }
          var payroll = payrollModel.toJSON(),
              payRollArray = [],
              taxYearOption = _this.get('connectedUser.fiscalYear.municipality.info.fiscalYear'),
              firstPayPeriodEnd = payroll.payroll_pay_period_end,
              firstPayDay = payroll.payroll_pay_day,
              endOfYear = null;
          if (taxYearOption == 'January to December') {
            endOfYear = moment().set({ 'year': moment(firstPayPeriodEnd).get('year'), 'month': 11, 'date': 31 });
          } else {
            if (moment(firstPayPeriodEnd).month() >= 5) {
              endOfYear = moment().set({ 'year': moment(firstPayPeriodEnd).get('year'), 'month': 5, 'date': 30 }).add(1, 'year');
            } else {
              endOfYear = moment().set({ 'year': moment(firstPayPeriodEnd).get('year'), 'month': 5, 'date': 30 });
            }
          }
          var restOfYear_Duration = moment.duration(moment(endOfYear).diff(moment(firstPayPeriodEnd))),
              payPeriod_to_payDay_Duration = moment.duration(moment(firstPayDay).diff(moment(firstPayPeriodEnd)));
          console.log(restOfYear_Duration);
          if (payroll.payroll_weekly) {
            var weeksLeft = Math.floor(restOfYear_Duration.asWeeks());
            for (var i = weeksLeft - 1; i >= 0; i--) {
              var firstDay = moment(firstPayPeriodEnd).add(i, 'w').add(1, 'd'),
                  lastDay = moment(firstPayPeriodEnd).add(i, 'w').add(1, 'w'),
                  payDay = moment(firstPayPeriodEnd).add(i, 'w').add(1, 'w').add(payPeriod_to_payDay_Duration),
                  object = { firstDay: firstDay, lastDay: lastDay, payDay: payDay };
              payRollArray.push(object);
            }
            return _this2.set('payRolls', payRollArray.reverse());
          } else if (payroll.payroll_bi_weekly) {
            var _weeksLeft = Math.floor(restOfYear_Duration.asWeeks() / 2);
            for (var i = _weeksLeft - 1; i >= 0; i--) {
              var _firstDay = moment(firstPayPeriodEnd).add(i * 2, 'w').add(1, 'd'),
                  _lastDay = moment(firstPayPeriodEnd).add(i * 2, 'w').add(2, 'w'),
                  _payDay = moment(firstPayPeriodEnd).add(i * 2, 'w').add(2, 'w').add(payPeriod_to_payDay_Duration),
                  _object = { firstDay: _firstDay, lastDay: _lastDay, payDay: _payDay };
              payRollArray.push(_object);
            }
            return _this2.set('payRolls', payRollArray.reverse());
          } else if (payroll.payroll_twice_month) {
            var monthsLeft = Math.floor(restOfYear_Duration.asMonths());
            console.log('months left', monthsLeft);
            for (var i = monthsLeft; i >= 0; i--) {
              var end_of_Month = moment(firstPayPeriodEnd).add(i, 'M').endOf('month'),
                  start_of_Month = moment(firstPayPeriodEnd).add(i, 'M').startOf('month'),
                  duration_of_month = Math.floor(moment.duration(end_of_Month.diff(start_of_Month)).asDays()),
                  middle_of_Month_start = moment(firstPayPeriodEnd).add(i, 'M').startOf('month').add(duration_of_month / 2, 'd'),
                  middle_of_Month_end = moment(firstPayPeriodEnd).add(i, 'M').startOf('month').add(duration_of_month / 2, 'd').add(1, 'd'),
                  pay_day_1 = moment(firstPayPeriodEnd).add(i, 'M').startOf('month').add(duration_of_month / 2, 'd').add(payPeriod_to_payDay_Duration),
                  pay_day_2 = moment(firstPayPeriodEnd).add(i, 'M').endOf('month').add(payPeriod_to_payDay_Duration),
                  object1 = { firstDay: start_of_Month, lastDay: middle_of_Month_start, payDay: pay_day_1 },
                  object2 = { firstDay: middle_of_Month_end, lastDay: end_of_Month, payDay: pay_day_2 };
              if (pay_day_1.day() == 0) {
                pay_day_1.day(1);
              } else if (pay_day_1.day() == 6) {
                pay_day_1.day(8);
              }
              if (pay_day_2.day() == 0) {
                pay_day_2.day(1);
              } else if (pay_day_2.day() == 6) {
                pay_day_2.day(8);
              }
              payRollArray.push(object2);
              payRollArray.push(object1);
            }
            return _this2.set('payRolls', payRollArray.reverse());
          } else if (payroll.payroll_monthly) {}
        });
      }
    }).property('connectedUser.currentAccount.municipality.payrollDb.payroll_pay_day', 'connectedUser.currentAccount.municipality.payrollDb.payroll_pay_period_end', 'payFrequency', 'connectedUser.fiscalYear.municipality.info.fiscalYear'),
    actions: {
      save: function save(model) {
        var message = this.get('globalMessaging');
        var store = this.get('store');
        var record = store.peekRecord('municipalities', model);
        record.save().then(function () {
          message.actionNotification('Saved', 'success');
        }).catch(function (error) {
          console.log(error);
          message.actionNotification(error, 'warning');
        });
      },
      toggleManagePayrollTaxes: function toggleManagePayrollTaxes(municipalId) {
        var appError = Ember.get(this, 'appError');
        Ember.get(this, 'store').findRecord('municipalDb', municipalId).then(function (municipalDb) {
          if (municipalDb.get('managesPayrollTaxes')) {
            municipalDb.set('managesPayrollTaxes', false);
          } else {
            municipalDb.set('managesPayrollTaxes', true);
          }
          municipalDb.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving municipal-db after toggle of managesPayrollTaxes attribute';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error finding municipal db with id from fiscal year during toggle of managesPayrollTaxes';
          appError.logError(err, specialMessage);
        });
      },
      cancel: function cancel(model) {
        var message = this.get('globalMessaging');
        var store = this.get('store');
        var record = store.peekRecord('municipalities', model);
        record.rollbackAttributes().then(function () {
          message.actionNotification('Removed Changes', 'success');
        }).catch(function (error) {
          console.log(error);
          message.actionNotification(error, 'warning');
        });
      },
      addBoardMember: function addBoardMember() {
        var fiscalYear = this.get('connectedUser.fiscalYear'),
            member = this.get('store').createRecord('municipal-board-member', {});
        fiscalYear.get('boardMembers').addObject(member);
      },
      saveMember: function saveMember(member) {
        var fiscalYear = this.get('connectedUser.fiscalYear');
        fiscalYear.save().then(function (res) {
          member.save();
        }).catch(function (err) {
          console.log(err);
        });
      },
      removeMember: function removeMember(member) {
        var fiscalYear = this.get('connectedUser.fiscalYear');
        fiscalYear.get('boardMembers').removeObject(member);
        fiscalYear.save().then(function (res) {
          member.destroyRecord();
        }).catch(function (err) {
          console.log(err);
        });
      },


      /**
       * update payroll with new frequency of pay
       * @param  {[string]} value               equal to attribute name to set true
       * @param  {[model]} payrollModelPromise  model for payrollDb attached to year
       * @return {[NA]}
       */
      updatePayrollFrequency: function updatePayrollFrequency(value, payrollModelPromise) {
        var appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            _this = this,
            undo = this.get('undo');
        payrollModelPromise.then(function (payrollModel) {
          if (!payrollModel) {
            var specialMessage = 'Sorry, it appears payroll has not been activated';
            return appError.logError('Sorry, it appears payroll has not been activated', specialMessage);
          }
          payrollModel.setProperties({ payroll_weekly: false, payroll_bi_weekly: false, payroll_twice_month: false, payroll_monthly: false });
          payrollModel.toggleProperty(value);
          var changes = undo.getChanges(payrollModel);

          var desc = "Undo changes made to payroll information",
              logDesc = "Payroll",
              undoObject = { model: payrollModel, desc: desc, changes: changes, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
          payrollModel.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving payroll model after changing payroll frequency';
            appError.logError(err, specialMessage);
          });
          _this.notifyPropertyChange('payFrequency');
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error getting payroll model from promise of action in template';
          appError.logError(err, specialMessage);
        });
      },


      /**
       * activates payroll by adding payrollDb to municipalYear - when creating new year should carry over
       * @param  {[model]} municipalityPromise  model for municipalDb
       * @return {[NA]}                 
       */
      activatePayroll: function activatePayroll(municipalityPromise) {
        return Ember.set(this, 'connectedUser.showActivatePayrollMenu', true);
        var appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            municipalYear = this.get('connectedUser.fiscalYear'),
            store = this.get('store'),
            undo = this.get('undo');
        municipalityPromise.then(function (municipality) {
          var newPayrollDb = store.createRecord('payroll_db', {
            municipality: municipality
          });
          municipalYear.set('payrollDb', newPayrollDb);
          municipalYear.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving municipalDB after adding new payrollDB reference';
            appError.logError(err, specialMessage);
            municipalYear.rollbackAttributes();
            municipalYear.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error when rolling back municipalDb attributes after trying to save with new payroll db';
              appError.logError(err, specialMessage);
            });
          });
          newPayrollDb.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving newPayroll to firebase';
            appError.logError(err, specialMessage);
            municipalYear.rollbackAttributes();
            municipalYear.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error rollingback and saving municipaldb after payroll db save failed';
              appError.logError(err, specialMessage);
            }).then(function () {
              newPayroll.destroyRecord();
            });
          });
        });
      },


      /**
       * update either the end of first pay period or first payday of year
       * @param  {model} payrollModelPromise  payrollDb model
       * @param  {string} location           attribute to update date for
       * @param  {date} selectionDate       date string
       * @return {NA}
       */
      updatePayRollDateInfo: function updatePayRollDateInfo(payrollModelPromise, location, selectionDate) {
        var appError = this.get('appError'),
            bigBrother = this.get('bigBrother'),
            _this = this,
            undo = this.get('undo');
        payrollModelPromise.then(function (payrollModel) {
          payrollModel.set(location, moment(selectionDate).valueOf());
          _this.setProperties({ payroll_pay_day: false, payroll_pay_period_end: false });
          if (!payrollModel) {
            var specialMessage = 'Sorry, it appears payroll has not been activated';
            return appError.logError('Sorry, it appears payroll has not been activated', specialMessage);
          }
          var changes = undo.getChanges(payrollModel);
          var desc = "Undo changes made to payroll information",
              logDesc = "Payroll",
              undoObject = { model: payrollModel, desc: desc, changes: changes, logDesc: logDesc };
          bigBrother.logChange(undoObject);
          undo.get('undoObjects').addObject(undoObject);
          payrollModel.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving payroll model after changing payroll period date for first period of year';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error getting payroll model from promise of action in template';
          appError.logError(err, specialMessage);
        });
      },


      /**
       * create model for each pay period then add ref to payrollDb
       * @param  {model} payrollModelPromise model for payrollDb
       * @param  {array} payRolls            array of payrolls objects
       * @return {NA}
       */
      savePayrollInfo: function savePayrollInfo(payrollModelPromise, payRolls) {
        var store = this.get('store'),
            appError = this.get('appError'),
            globalMessage = this.get('globalMessaging'),
            municipalYear = this.get('connectedUser.fiscalYear'),
            municipalDb = this.get('connectedUser.fiscalYear.municipality'),
            index = 0,
            length = payRolls.length;
        municipalDb.then(function (municipality) {
          payRolls.forEach(function (payroll) {
            var model = store.createRecord('municipal-pay-period', {
              firstDay: payroll.firstDay,
              lastDay: payroll.lastDay,
              payDay: payroll.payDay,
              municipalYear: municipalYear,
              municipalDb: municipality
            });
            model.save().then(function (savedPayPeriod) {
              payrollModelPromise.then(function (payRollDb) {
                payRollDb.get('municipalPayPeriods').addObject(savedPayPeriod);
                payRollDb.save().then(function () {
                  console.log(index, length);
                  if (index == length) {
                    globalMessage.actionNotification('Saved pay periods', 'success');
                  } else {
                    index++;
                  }
                }).catch(function (err) {
                  console.log(err);
                  var specialMessage = 'error saving payroll db after adding new payperiod model';
                  appError.logError(err, specialMessage);
                });
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error retreiving model for payrollDb';
                appError.logError(err, specialMessage);
              });
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error while saving new payroll model before adding it to payroll db';
              appError.logError(err, specialMessage);
            });
          });
        });
      },
      adjustDate: function adjustDate(date) {
        this.toggleProperty(date);
      }
    }
  });
});