define('fund-figure-app/models/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    municipalUser: _emberData.default.belongsTo('municipal-user', { async: true, inverse: null }),
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    timestamp: _emberData.default.attr('number'),
    feedback: _emberData.default.attr('string'),
    addressed: _emberData.default.attr('boolean'),
    addressedBy: _emberData.default.belongsTo('user'),
    addressedOn: _emberData.default.attr('number'),
    closed: _emberData.default.attr('number'),
    closed_by: _emberData.default.belongsTo('user'),
    url: _emberData.default.attr('string'),
    slask_ts: _emberData.default.attr('string'), //slack id for message, subsequent actions will be replies to original ticket
    feedbackChatRoom: _emberData.default.belongsTo('feedback-chat-room', { async: true, inverse: null })
  });
});