define('fund-figure-app/components/reports/town-reports/audit-changes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    auditArray: [],
    pageInfo: [],

    checkDate: Ember.computed('model.dates', {
      get: function get(key) {
        if (this.get('model.dates')) {
          console.log('model dates is true');
          this.set('connectedUser.dates', true);
        } else {
          this.set('connectedUser.dates', false);
        }
      }
    }),

    getChanges: Ember.computed('connectedUser.beginDate', 'connectedUser.endDate', 'connectedUser.currentAccount', {
      get: function get() {
        var beginDate = this.get('connectedUser.beginDate') || moment().subtract(30, 'days'),
            endDate = this.get('connectedUser.endDate') || moment(),
            municipality_id = this.get('connectedUser.currentAccount').toJSON().municipality,
            begin_date = moment(beginDate).format('-YYYY-MM-DD'),
            end_date = moment(endDate).format('-YYYY-MM-DD'),
            _this = this;
        if (!this.get('connectedUser.endDate') && !this.get('connectedUser.beginDate')) {
          this.set('connectedUser.beginDate', beginDate);
          this.set('connectedUser.endDate', endDate);
        }
        console.log('' + (municipality_id + begin_date));
        return this.get('store').query('audit-user-change', {
          orderBy: 'q_date',
          startAt: '' + (municipality_id + begin_date),
          endAt: '' + (municipality_id + end_date)
        }).then(function (res) {
          console.log(res.toArray());
          _this.set('auditArray', res.toArray());
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),
    generatePageContent: Ember.computed('auditArray', {
      //generate pages based on line limit for report
      get: function get() {
        var lines = this.get('model.lines_per_page'),
            _this = this,
            auditArray = this.get('auditArray'),
            changesLength = auditArray.length,
            page_info = [],
            lastIndex = 0,
            line_count = 0;
        if (auditArray.length < 1) {
          return;
        }
        auditArray.forEach(function (audit, index) {
          var totalLines = audit.get('lines') + 1 || audit.get('changes').length + 1;
          if (line_count + totalLines >= lines) {
            var object = {};
            object["startAt"] = lastIndex;
            object["endAt"] = index - 1;
            page_info.push(object);
            line_count = 0;
            lastIndex = index;
          }
          line_count += totalLines;
          if (changesLength == index + 1) {
            var _object = {};
            _object["startAt"] = lastIndex;
            _object["endAt"] = index;
            page_info.push(_object);
            _this.set('pageInfo', page_info);
          }
        });
      }
    })
  });
});