define('fund-figure-app/components/dashboard-assets/general-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    store: Ember.inject.service(),
    assetInfo: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    assetArray: null,

    actions: {
      /*
        Submit filter array from filter-array-button component
       */
      submitFilter: function submitFilter() {},

      /*
        Clear the array from the filter-array-button component
       */
      clearFilter: function clearFilter() {},
      createYearCoeff: function createYearCoeff() {

        var object = {};
        for (var i = 8 - 1; i >= 0; i--) {
          object['' + i] = Ember.get(this, 'commonTasks').preciseRound(-Math.pow(i / 7.597, 2) + .95, 2);
          console.log(object);
        }
        Ember.get(this, 'firebaseApp').database().ref('assetDepreciations/dfgkkgff/year').set(object);
      }
    }
  });
});