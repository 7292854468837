define('fund-figure-app/models/employee-payroll-info', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    gross_pay_account: _emberData.default.belongsTo('municipal-account', { async: true, inverse: null }), //account gross pay will hit 
    gross_pay: _emberData.default.attr('string'),
    federal_tax: _emberData.default.attr('string'),
    fica_additional_medicare: _emberData.default.attr('string'),
    fica_medicare_tax: _emberData.default.attr('string'),
    fica_oasdi_tax: _emberData.default.attr('string'),
    retirement: _emberData.default.attr('string'),
    reimbursement: _emberData.default.attr('boolean'),
    health_care: _emberData.default.attr('string'),
    municipality: _emberData.default.belongsTo('municipal-db'),
    employee: _emberData.default.belongsTo('vendor', { async: true, inverse: 'employee_payroll_infos' }),
    net_pay: Ember.computed('gross_pay', 'federal_tax', 'fica_additional_medicare', 'fica_medicare_max', 'fica_oasdi_tax', 'retirement', 'health_care', {
      get: function get() {
        var gross_pay = Ember.get(this, 'gross_pay'),
            federal_tax = Ember.get(this, 'federal_tax'),
            fica_additional_medicare = Ember.get(this, 'fica_additional_medicare'),
            fica_medicare_tax = Ember.get(this, 'fica_medicare_tax'),
            fica_oasdi_tax = Ember.get(this, 'fica_oasdi_tax'),
            retirement = Ember.get(this, 'retirement'),
            health_care = Ember.get(this, 'health_care');
        return +gross_pay - (+federal_tax + +fica_additional_medicare + +fica_medicare_tax + +fica_oasdi_tax + +retirement + +health_care);
      }
    })
    // total: computed('net_pay', 'federal_tax', 'fica_additional_medicare', 'fica_medicare_max', 'fica_oasdi_tax', 'retirement', 'health_care', {
    //   get() {
    //     let net_pay = get(this, 'net_pay'),
    //       federal_tax = get(this, 'federal_tax'),
    //       fica_additional_medicare = get(this, 'fica_additional_medicare'),
    //       fica_medicare_tax = get(this, 'fica_medicare_tax'),
    //       fica_oasdi_tax = get(this, 'fica_oasdi_tax'),
    //       retirement = get(this, 'retirement'),
    //       health_care = get(this, 'health_care');
    //     return +net_pay + +federal_tax + +fica_additional_medicare + +fica_medicare_tax + +fica_oasdi_tax + +retirement + +health_care;
    //   }
    // })
  });
});