define('fund-figure-app/components/account-handler/create-step-five', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      transitionToCreateStepSix: function transitionToCreateStepSix(muni) {
        var _this = this;

        var connectedUser = this.get('connectedUser.currentAccount');
        console.log(connectedUser);
        connectedUser.set('is_new', 'account-handler.account-create-step-six/' + muni.get('id'));
        return connectedUser.save().then(function () {
          return _this.get('router').transitionTo('account-handler.account-create-step-six', muni);
        }).catch(function (error) {
          console.log(error);
        });
      },
      transitionToVendorSetup: function transitionToVendorSetup(muni) {
        var _this2 = this;

        var connectedUser = this.get('connectedUser.currentAccount');
        console.log(connectedUser);
        connectedUser.set('is_new', 'account-handler.vendor-setup.step-one/' + muni.get('id'));
        return connectedUser.save().then(function () {
          return _this2.get('router').transitionTo('account-handler.vendor-setup.step-one', muni);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});