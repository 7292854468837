define("fund-figure-app/components/reports/ms-002", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    totalAppropriations: null,

    moneyFormat: {
      pos: "%s %v",
      neg: "-%s %v",
      zero: "$0"
    },

    getAppropriations: Ember.computed('connectedUser.fiscalYear.municipalAccounts.@each.budget', function () {
      var fiscalYear = this.get('connectedUser.fiscalYear');
      var _this = this;
      if (!fiscalYear) {
        return;
      }
      return fiscalYear.get('municipalAccounts').then(function (municipalAccounts) {
        return municipalAccounts.filter(function (account) {
          if (account.get('appropriation')) {
            return true;
          } else {
            return false;
          }
        });
      }).then(function (expenseAccounts) {
        if (expenseAccounts.length < 1) {
          return;
        }
        return expenseAccounts.reduce(function (total, r_account) {
          var account = r_account.toJSON();
          if (account.budget && account.parentAccount == null) {
            return total + Number(account.budget.replace(/,/g, ''));
          }
          return total;
        }, 0);
      }).then(function (totalAppropriation) {
        _this.set('totalAppropriations', totalAppropriation);
        return totalAppropriation;
      }).catch(function (error) {
        console.log(error);
      });
    })
  });
});