define('fund-figure-app/components/reports/ms-535/appropriation-items', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    moneyFormat: {
      pos: "%s %v",
      neg: "%s %v",
      zero: "$0"
    }
  });
});