define('fund-figure-app/services/common-tasks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * pad a string to a certain amount {padAmount} and pad item {pad}
     * @param  {[string]} string    [text string to pad]
     * @param  {[string]} pad       [what to pad with]
     * @param  {[number]} padAmount [number to pad string to]
     * @return {[string]}           [padded string]
     */
    padString: function padString(string, pad, padAmount) {
      if (typeof string != 'string') {
        console.log('not a string, make it one');
      }
      if (string.length > padAmount) {
        return string.slice(-padAmount);
      }
      var padding = null;
      for (var i = 0; i < padAmount; i++) {
        padding += pad;
      }
      return ('' + (padding + string)).slice(-padAmount);
    },

    /**
     * round a number to given precision
     * @param  {[number]} number    [number to round]
     * @param  {[number]} precision [number of desired decimal precision]
     * @return {[number]}           [number rounded]
     */
    preciseRound: function preciseRound(number, precision) {
      var factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    },

    /**
     * check if an object is empty
     * @param  {[object or array]}  obj [object or array that might be empty]
     * @return {Boolean}     true or false
     */
    isEmpty: function isEmpty(obj) {
      //check if object coming in is array
      if (typeof obj == "array") {
        if (obj.length == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      }
    },


    /**
     * format a number with a given separator
     * @param  {number or string} number 
     * @param  {string} separator
     * @return {string}
     */
    numberFormatter: function numberFormatter(number, separator) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '' + separator);
    }
  });
});