define('fund-figure-app/models/municipal-article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    /**
     * Used to corrected sort articles regardless of numbers
     * @param  {[type]} 'number' [description]
     * @param  {[type]} {                       get( [description]
     * @return {[type]}          [description]
     */
    sortNumber: Ember.computed('number', {
      get: function get() {
        return '' + Ember.get(this, 'number').padStart(4, '0');
      }
    }),
    title: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    passed: _emberData.default.attr('boolean'),
    vote_yes: _emberData.default.attr('number'),
    vote_no: _emberData.default.attr('number'),
    governing_body_vote_yes: _emberData.default.attr('number'),
    governing_body_vote_no: _emberData.default.attr('number'),
    budget_committee_vote_yes: _emberData.default.attr('number'),
    budget_committee_vote_no: _emberData.default.attr('number'),
    governing_body_desc: _emberData.default.attr('string'),
    budget_committee_desc: _emberData.default.attr('string'),
    pref_clause: _emberData.default.attr('string'),
    prop_clause: _emberData.default.attr('string'),
    purpose: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    municipalFund: _emberData.default.belongsTo('municipal-fund'),
    municipality: _emberData.default.belongsTo('municipal-db'),
    municipalYear: _emberData.default.belongsTo('municipal-year'),
    tax_impact: _emberData.default.attr('number'),
    municipalAccounts: _emberData.default.hasMany('municipal-account', { inverse: 'municipalArticles' }), //Should match to one state parent account
    warrantArticleTemplate: _emberData.default.belongsTo('warrant-article-template'),
    category: _emberData.default.attr('string') //matches template category
  });
});