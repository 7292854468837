define('fund-figure-app/routes/dashboard-assets/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // connectedUser: inject(),
    // model() {
    //   return this.store.query('asset', {
    //     orderBy: 'municipality',
    //     equalTo: get(this.connectedUser.currentAccount, 'municipality.id')
    //   })
    // }
  });
});