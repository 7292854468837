define('fund-figure-app/components/dashboard/transactions/journal-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    appError: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    totalTransaction: null,
    transactions: null,
    filteredTransactions: null,
    dateAttribute: 'timestamp',

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },

    didInsertElement: function didInsertElement() {
      this.get('get_transactions');
      this.get('transactionFilter');
    },

    totalDebitCredit: Ember.computed('connectedUser.currentFund.municipalTransactions.@each.amount', function () {
      var _this = this;
      if (!this.get('connectedUser.currentFund')) {
        return;
      }
      return this.get('connectedUser.currentFund.municipalTransactions').then(function (transactions) {
        return transactions.reduce(function (total, r_account) {
          console.log(r_account);
          if (!r_account.get('amount')) {
            return total;
          }
          return total + Number(r_account.get('amount').replace(/,/g, ''));
        }, 0);
      }).then(function (total) {
        console.log(total);
        _this.set('totalTransaction', total);
        return total;
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'calculating totalDebitCredit';
        appError.logError(err, specialMessage);
      });
    }),

    get_transactions: Ember.computed('checkTransactions', 'fundInfo.municipalTransactions.[]', 'connectedUser.currentFund', 'fundInfo.municipalTransactions', {
      get: function get() {
        console.log('fetching transactions');
        var _this = this,
            appError = this.get('appError');
        return this.get('fundInfo.municipalTransactions').then(function (res) {
          console.log(res);
          _this.setProperties({ transactions: res.toArray(), filteredTransactions: res.toArray() });
          return res.toArray();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'specialMessage';
          appError.logError(err, specialMessage);
        });
      }
    }).property('checkTransactions', 'connectedUser.currentFund'),

    transactionFilter: Ember.computed.filter('filteredTransactions.[]', function (res) {
      var beginDate = this.get('connectedUser.beginDate'),
          endDate = this.get('connectedUser.endDate'),
          dateAttribute = this.get('dateAttribute');
      if (!beginDate || !endDate) {
        console.log('no dates');
        return false;
      }
      if (!res.get('timestamp')) {
        return false;
      }
      console.log('beginDate', beginDate);
      if (moment(beginDate, "MM/DD/YYYY").startOf('day').unix() * 1000 <= res.get(dateAttribute) && res.get(dateAttribute) <= moment(endDate, "MM/DD/YYYY").endOf('day').unix() * 1000) {
        return true;
      } else {
        return false;
      }
    }).property('connectedUser.endDate', 'connectedUser.beginDate', 'filteredTransactions.[]', 'dateAttribute'),

    sortedTransactions: Ember.computed.sort('transactionFilter', 'sortDefinition'),

    sortBy: 'timestamp',

    reverseSort: false,

    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    actions: {
      toggleDate: function toggleDate() {
        this.toggleProperty('transactionDate');
      },
      checkTransactions: function checkTransactions() {
        this.notifyPropertyChange('checkTransactions');
      },
      clearFilter: function clearFilter() {
        this.set('filteredTransactions', this.get('transactions'));
      },
      submitFilter: function submitFilter(filterArray) {
        var _this2 = this;

        var transactions = this.get('transactions');
        filterArray.forEach(function (filter) {
          switch (filter.filter) {
            case 'byAmount':
              var amount = Number(filter.amount);
              if (filter.logic == "amountGreater") {
                var filtered = transactions.filter(function (payment) {
                  return payment.get('amount') > amount;
                });
                transactions = filtered;
              }
              if (filter.logic == "amountLess") {
                var _filtered = transactions.filter(function (payment) {
                  return payment.get('amount') < amount;
                });
                transactions = _filtered;
              }
              if (filter.logic == "amountEquals") {
                var _filtered2 = transactions.filter(function (payment) {
                  return payment.get('amount') == amount;
                });
                transactions = _filtered2;
              }
              break;
            case 'byDate':
              var beginDate = Number(filter.beginDate);
              var endDate = Number(filter.endDate);
              if (filter.logic == "dateGreater") {
                var _filtered3 = transactions.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate;
                });
                transactions = _filtered3;
              }
              if (filter.logic == "dateLess") {
                var _filtered4 = transactions.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') < beginDate;
                });
                transactions = _filtered4;
              }
              if (filter.logic == "dateBetween") {
                var _filtered5 = transactions.filter(function (payment) {
                  return payment.get('timestamp') > beginDate && payment.get('timestamp') < endDate;
                });
                transactions = _filtered5;
              }
              break;
            default:
              break;
          }
          _this2.set('filteredTransactions', transactions);
        });
      }
    }
  });
});