define('fund-figure-app/services/undo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    undoObjects: [],
    //undoObject={
    //model:model to make change,
    //desc: description of what undo will do,
    //changeList: list of attributes as {attribute: [old, new]},
    //modelDeleted:boolean,
    //secondaryModels: list of model and location to add ref {model:class,location:string} to undoObject  
    init: function init() {
      this._super.apply(this, arguments);
      this.get('maintainUndos');
    },


    /**
     * Based on user decision undo what was done
     * @param  {Object} undoObject object containing list of things changed and models affected, see above
     * @return {notification}            upon success a global message goes out
     */
    undo: function undo(undoObject) {
      var globalMessage = Ember.get(this, 'globalMessaging'),
          store = Ember.get(this, 'store'),
          appError = Ember.get(this, 'appError'),
          undoObjects = Ember.get(this, 'undoObjects');
      // check and see if model was deleted
      if (!undoObject.modelDeleted) {
        //check if references where removed from model
        if (undoObject.secondaryModels) {
          var _loop = function _loop() {
            var ref = undoObject.secondaryModels[i];
            store.findRecord(ref.model.get('constructor.modelName'), ref.model.get('id')).then(function (foundModel) {
              foundModel.get(ref.location).addObject(undoObject.model);
              foundModel.save().catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving ' + foundModel.get('constructor.modelName') + ' : ' + foundModel.get('id') + ' while undoing ' + undoObject;
                return appError.logError(err, specialMessage);
              });
            });
          };

          //references were removed, add them back
          for (var i = undoObject.secondaryModels.length - 1; i >= 0; i--) {
            _loop();
          }
        }
        if (undoObject.changes) {
          var index = [],
              length = undoObject.changes.length;
          undoObject.changes.forEach(function (change) {
            for (var key in change) {
              undoObject.model.set(key, change[key]["old"]);
            }
            index.push(1);
          });
          if (index.length === length) {
            undoObject.model.save().then(function () {
              undoObjects.removeObject(undoObject);
              globalMessage.actionNotification('undo successful', 'success');
            }).catch(function (err) {
              console.log(err);
              globalMessage.actionNotification('something went wrong', 'warning');
            });
          }
        } else {
          undoObject.model.save().then(function () {
            undoObjects.removeObject(undoObject);
            globalMessage.actionNotification('undo successful', 'success');
          }).catch(function (err) {
            console.log(err);
            globalMessage.actionNotification('something went wrong', 'warning');
          });
        }
      } else {
        var i;

        (function () {
          console.log('deleted model JSON', undoObject.model.toJSON());
          var modelName = undoObject.model.get('constructor.modelName'),
              newModel = store.createRecord(modelName, undoObject.model.toJSON());
          console.log('newmodel: ', newModel);
          //check to see if model needs to be added as reference to other models
          if (undoObject.secondaryModels) {
            console.log('model has more references that need reference again');
            //references were removed, add them back         
            for (i = undoObject.secondaryModels.length - 1; i >= 0; i--) {
              console.log('employee model:', undoObject.secondaryModels[i].model);
              undoObject.secondaryModels[i].model.get(undoObject.secondaryModels[i].location).addObject(newModel);
              undoObject.secondaryModels[i].model.save().then(function () {
                newModel.save().catch(function (err) {
                  console.log(err);
                  var specialMessage = 'Error saving model that was deleted during undo and trying to add back';
                  appError.logError(err, specialMessage);
                });
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'Error during undo function, model was deleted and errored trying to add it back to secondary model reference';
                appError.logError(err, specialMessage);
              });
            }
            undoObjects.removeObject(undoObject);
            globalMessage.actionNotification('Added model back to DB', 'success');
          } else {
            console.log('model was deleted but has no other references');
            newModel.save().then(function () {
              undoObjects.removeObject(undoObject);
              globalMessage.actionNotification('Added model back to DB', 'success');
            }).catch(function (err) {
              console.log(err);
              globalMessage.actionNotification('something went wrong', 'warning');
            });
          }
        })();
      }
    },


    /**
     * create undo object, add to undos and send info to bigBrother
     * @param  {Firebase Class} model     
     * @param  {boolean} modelDeleted 
     * @param  {Firebase Class} secondaryModel    model that had a relationship to model
     * @param  {string} secondaryLocation attribute that held hasMany relationship to main model
     * @param  {string} desc              Description to show in undo window
     * @param  {string} logDesc [What to display in audit report after the model name in where column ]
     * @return {null}                   no return
     */
    createUndo: function createUndo(model, modelDeleted, secondaryModel, secondaryLocation, desc, logDesc) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var bigBrother = Ember.get(_this, 'bigBrother'),
            undoList = Ember.get(_this, 'undoObjects'),
            changes = _this.getChanges(model, modelDeleted);
        if (secondaryModel) {
          var undoObject = { model: model, desc: desc, modelDeleted: modelDeleted, secondaryModels: [{ model: secondaryModel, location: secondaryLocation }], logDesc: logDesc };
          undoList.addObject(undoObject);
          bigBrother.logChange(undoObject);
          resolve(model);
        } else {
          var _undoObject = { model: model, desc: desc, changes: changes, logDesc: logDesc };
          undoList.addObject(_undoObject);
          bigBrother.logChange(_undoObject);
          resolve(model);
        }
        reject('Should not get here');
      });
    },
    getChanges: function getChanges(model, modelDeleted) {
      var newChangesObject = [],
          commonTasks = this.get('commonTasks'),
          changeList = model.changedAttributes();
      console.log('change-List', changeList);
      if (changeList) {
        for (var key in changeList) {
          var newSub = '{ "' + key + '" : {"old": "' + String(changeList[key].slice(0, 1)).replace(/"/g, '\\\"') + '", "new": "' + String(changeList[key].slice(1)).replace(/"/g, '\\\"') + '" } }';
          console.log(newSub);
          newChangesObject.addObject(JSON.parse(newSub));
        }
        return newChangesObject;
      }
      if (modelDeleted) {
        return ['Model was deleted'];
      }
      return false;
    },


    maintainUndos: Ember.computed('undoObjects.[]', {
      get: function get() {
        //check to insure any undo objects match to a model that was just deleted
        var undoArrayLength = this.get('undoObjects.length') || 0;
      }
    })
  });
});