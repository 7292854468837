define('fund-figure-app/routes/dashboard/warrant-articles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    connectedUser: Ember.inject.service(),
    model: function model() {
      return this.store.query('municipal-article', {
        orderBy: 'municipalYear',
        equalTo: Ember.get(this, 'connectedUser.fiscalYear.id')
      });
    }
  });
});