define('fund-figure-app/components/municipal-handler/municipal-setup-step-one/edit-revenue-account', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    undo: Ember.inject.service(),
    appError: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    edit: true,
    undoObjects: Array(),

    budgetTotal: Ember.computed('model.subAccounts.[]', 'model.budget', function () {
      var total = 0;
      var _this = this;
      var model = this.get('model');
      var parentAccount = null;
      return model.get('subAccounts').then(function (subAccounts) {
        if (subAccounts.get('length') >= 1) {
          console.log('we have sub accounts');
          return subAccounts.forEach(function (account) {
            if (!account.get('budget')) {
              return;
            }
            if (typeof account.get('budget') === "undefined") {
              return;
            }
            parentAccount = model;
            total = total + Number(account.get('budget').replace(/,/g, ''));
          });
        } else {
          if (!_this.get('model.budget')) {
            return;
          }
          if (typeof _this.get('model.budget') === "undefined") {
            return;
          }
          return total = +Number(_this.get('model.budget').replace(/,/g, ''));
        }
      }).then(function () {
        _this.set('totalBudget', total);
        return total;
      }).then(function (total) {
        if (parentAccount) {
          console.log('parentAccount');
          model.set('budget', total);
          model.save();
        } else {
          model.set('budget', total);
          model.save();
        }
      }).catch(function (error) {
        console.log(error);
      });
    }),
    municipalAccountFunctions: Ember.computed('', function () {
      return this.get('store').findAll('municipalAccountFunction');
    }),

    actions: {
      toggleTransactionType: function toggleTransactionType(type) {
        var _this = this;
        this.setProperties({ check: false, transfer: false, invoice: false });
        this.set(type, true);
        if (type === 'transfer') {
          var currentAccount = _this.get('model');
          var transfer = _this.get('store').createRecord('transaction', {
            transferFrom: currentAccount.get('id')
          });
          _this.set('transferModel', transfer);
        } else {
          if (_this.get('transferModel')) {
            _this.get('transferModel').destroyRecord();
          }
        }
      },
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        if (attr !== 'budget') {
          model.set(attr, value);
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, num);
        // model.save();
        this.notifyPropertyChange('budgetTotal');
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },
      addSubAccount: function addSubAccount(account) {
        var appError = this.get('appError'),
            subAccountNumber = String(account.get('subAccounts.length')).padStart(3, "0");
        _this = this;
        account.get('stateAccountNumber').then(function (stateAccount) {
          return stateAccount.get('classification');
        }).then(function (classification) {
          var subAccountNumber = account.get('subAccount.length');
          console.log(subAccountNumber);
          var subAccount = _this.get('store').createRecord('municipal-account', {
            parentAccount: account,
            stateAccountNumber: account.get('stateAccountNumber'),
            activated: true,
            classification: account.get('classification'),
            appropriation: account.get('appropriation'),
            accountNumber: String(account.get('subAccounts.length')).padStart(3, "0"),
            municipalYear: account.get('municipalYear'),
            municipalFund: account.get('municipalFund'),
            stateName: account.get('stateName'),
            stateNumber: account.get('stateNumber'),
            municipality: account.get('municipality'),
            transactDebit: account.get('transactDebit'),
            transactCredit: account.get('transactCredit')
          });

          account.get('subAccounts').addObject(subAccount);
          account.save().then(function (savedAccount) {
            return subAccount.save();
          }).then(function (subAccountSaved) {
            _this.get('store').findRecord('municipal-fund', account.toJSON().municipalFund).then(function (municipalFund) {
              municipalFund.get('municipalAccounts').addObject(subAccountSaved);
              municipalFund.save();
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'failed adding new subaccount to municipal fund';
              appError.logError(err, specialMessage);
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'failed saving new subaccount to budget account parent';
            appError.logError(err, specialMessage);
          });
        });
      },
      saveParent: function saveParent(account) {
        var globalMessage = this.get('globalMessaging');
        account.save().then(function (savedAccount) {
          var message = null;
          if (account.get('nickname')) {
            message = account.get('nickname') + ' ' + 'was saved successfully';
          } else {
            message = 'saved successfully';
          }
          globalMessage.actionNotification(message, 'success');
        }).catch(function (error) {
          globalMessage.actionNotification('Permission was denied, budget finalized', 'warning');
          account.rollbackAttributes();
          console.log(error);
        });
      },
      saveSub: function saveSub(account, parentAccount) {
        var globalMessage = this.get('globalMessaging');
        account.save().then(function (savedAccount) {
          parentAccount.save();
          var message = null;
          if (account.get('nickname')) {
            message = account.get('nickname') + ' ' + 'was saved successfully';
          } else {
            message = 'saved successfully';
          }
          return globalMessage.actionNotification(message, 'success');
        }).catch(function (error) {
          globalMessage.actionNotification('Permission was denied, budget finalized', 'warning');
          account.rollbackAttributes();
          console.log(error);
        });
      },
      removeSubAccount: function removeSubAccount(subAccount, account) {
        var _this = this,
            appError = this.get('appError'),
            undo = _this.get('undo');
        this.get('store').findRecord('municipal-fund', account.toJSON().municipalFund).then(function (municipalFund) {
          //create description for undo button
          if (subAccount.get('nickname')) {
            var description = 'Add ' + subAccount.get('nickname') + ', Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
            undoObject = { 'model': subAccount, 'description': description, 'account': account, 'accountNumber': account.get('stateAccountNumber.accountNumber') };
          } else {
            var _description = 'Add ' + ' Account Number: ' + subAccount.get('accountNumber') + ' back to Account: ' + account.get('stateAccountNumber.accountNumber');
            undoObject = { 'model': subAccount, 'description': _description, 'account': account, 'accountNumber': account.get('stateAccountNumber.accountNumber') };
          }
          //send undo object to undo service which manages all undos
          undo.get('undoObjects').addObject(undoObject);
          //remove sub account from municipalfund reference
          municipalFund.get('municipalAccounts').removeObject(subAccount);
          //save after removing account
          municipalFund.save().then(function () {
            account.get('subAccounts').removeObject(subAccount);
            return account.save();
          }).then(function () {
            return subAccount.destroyRecord();
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'failed to destroy subaccount';
            appError.logError(err, specialMessage);
          });
        });
      }
    }
  });
});