define('fund-figure-app/helpers/number-formatter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberFormatter = numberFormatter;
  function numberFormatter(params /*, hash*/) {
    var number = params[0],
        separator = params[1];
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '' + separator);
  }

  exports.default = Ember.Helper.helper(numberFormatter);
});