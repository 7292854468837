define('fund-figure-app/components/dashboard-support/manage-warrant-articles/index-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),

    states: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('state');
      }
    })

  });
});