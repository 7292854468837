define('fund-figure-app/components/reports/ms-636', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    specialWarrantArticlesArray: null,
    dateFormPosted: moment().format('MMMM DD, YYYY'),

    getAccounts: Ember.computed('fundInfo.allAccounts.@each.budget', function () {
      var _this = this,

      //Appropriation Accounts
      gen_gov = Array(),
          pub_safety = Array(),
          air_avi = Array(),
          high_str = Array(),
          sani = Array(),
          wat_tr = Array(),
          elec = Array(),
          health = Array(),
          welfare = Array(),
          cul_rec = Array(),
          consv = Array(),
          debt_ser = Array(),
          capt_ovly = Array(),
          tran_out = Array(),

      //Revenue Accounts
      taxes = Array(),
          lic_fee = Array(),
          fed_gov = Array(),
          state_gov = Array(),
          other_gov = Array(),
          charge_serv = Array(),
          misc_source = Array(),
          inter_in = Array(),
          other_fin = Array(),
          muni_accounts = Ember.get(this, 'fundInfo.allAccounts');

      var index = 0;
      _this.set('allAccounts', muni_accounts);
      muni_accounts.forEach(function (account) {
        var source = account.get('source');
        switch (source) {
          case 'General Government':
            gen_gov.addObject(account);
            ++index;
            break;
          case 'Public Safety':
            pub_safety.addObject(account);
            ++index;
            break;
          case 'Airport or Aviation Center':
            air_avi.addObject(account);
            ++index;
            break;
          case 'Highways and Streets':
            high_str.addObject(account);
            ++index;
            break;
          case 'Sanitation':
            sani.addObject(account);
            ++index;
            break;
          case 'Water Distribution and Treatment':
            wat_tr.addObject(account);
            ++index;
            break;
          case 'Electric':
            elec.addObject(account);
            ++index;
            break;
          case 'Health':
            health.addObject(account);
            ++index;
            break;
          case 'Welfare':
            welfare.addObject(account);
            ++index;
            break;
          case 'Culture and Recreation':
            cul_rec.addObject(account);
            ++index;
            break;
          case 'Conservation':
            consv.addObject(account);
            ++index;
            break;
          case 'Debt Service':
            debt_ser.addObject(account);
            ++index;
            break;
          case 'Capital Outlay':
            capt_ovly.addObject(account);
            ++index;
            break;
          case 'Interfund Operating Transfers Out':
            tran_out.addObject(account);
            ++index;
            break;
          // Revenue Accounts
          case 'Revenue From Taxes':
            taxes.addObject(account);
            ++index;
            break;
          case 'Revenue From Licenses, Permits and Fees':
            lic_fee.addObject(account);
            ++index;
            break;
          case 'Revenue From the Federal Government':
            fed_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From the State of New Hampshire':
            state_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From Other Governments':
            other_gov.addObject(account);
            ++index;
            break;
          case 'Revenue From Charges For Services':
            charge_serv.addObject(account);
            ++index;
            break;
          case 'Revenue from Miscellaneous Sources':
            misc_source.addObject(account);
            ++index;
            break;
          case 'Interfund Operating Transfers In':
            inter_in.addObject(account);
            ++index;
            break;
          case 'Other Financial Sources':
            other_fin.addObject(account);
            ++index;
            break;
          default:
            // taxes.addObject(account);
            ++index;
            break;
        }
        if (index == muni_accounts.get('length')) {
          console.log('hit the limit');
          //Set Appropriations Info and Revenue Info
          //No revenue accounts here yet!!!!
          _this.setProperties({
            generalGovAppropriations: gen_gov,
            publicSafetyAppropriations: pub_safety,
            airTrafficAppropriations: air_avi,
            highwayAppropriations: high_str,
            sanitationAppropriations: sani,
            waterTreatmentAppropriations: wat_tr,
            electricAppropriations: elec,
            healthAppropriations: health,
            welfareAppropriations: welfare,
            cultureRecAppropriations: cul_rec,
            conservationAppropriations: consv,
            debtServiceAppropriations: debt_ser,
            capitalOverlayAppropriations: capt_ovly,
            interfundOutAppropriations: tran_out,
            taxesRevenue: taxes,
            licRevenue: lic_fee,
            fedRevenue: fed_gov,
            stateRevenue: state_gov,
            otherGovRevenue: other_gov,
            chargeRevenue: charge_serv,
            miscRevenue: misc_source,
            interRevenue: inter_in,
            otherRevenue: other_fin
          });
        }
      });
    }),
    getArticles: Ember.computed('fundInfo.municipalArticles', {
      get: function get() {
        return Ember.get(this, 'fundInfo.municipalArticles');
      }
    }),
    specialWarrantArticles: Ember.computed('getArticles.[]', {
      get: function get() {
        var _this2 = this;

        return Ember.get(this, 'getArticles').then(function (articles) {
          console.log(articles);
          return articles.filter(function (a) {
            if (a.toJSON().category == 'Special') {
              return true;
            } else {
              return false;
            }
          });
        }).then(function (specialWarrantArticles) {
          Ember.set(_this2, 'specialWarrantArticlesArray', specialWarrantArticles);
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }),

    individualWarrantArticles: Ember.computed('getArticles.[]', {
      get: function get() {
        var _this3 = this;

        return Ember.get(this, 'getArticles').then(function (articles) {
          console.log(articles);
          return articles.filter(function (a) {
            if (a.toJSON().category == 'Individual') {
              return true;
            } else {
              return false;
            }
          });
        }).then(function (individualWarrantArticles) {
          Ember.set(_this3, 'individualWarrantArticlesArray', individualWarrantArticles);
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }),

    moneyFormat: {
      pos: "%s %v",
      neg: "-%s %v",
      zero: "$0"
    },
    actions: {
      /**
       * Used for reduce of array of tax impact and article amount
       * @param  {[type]} val  [description]
       * @param  {[type]} val1 [description]
       * @return {[type]}      [description]
       */
      sum: function sum(val, val1) {
        if (!val1 || (typeof val1 === 'undefined' ? 'undefined' : _typeof(val1)) === undefined || val1 === null) {
          return val;
        }
        return +val + +val1;
      },
      parentFilter: function parentFilter(account) {
        if (account.toJSON().parentAccount) {
          return false;
        } else {
          return true;
        }
      },
      articleFilter: function articleFilter(type, article) {
        console.log(type, article.toJSON().category);
        if (article.toJSON().category == type) {
          return true;
        } else {
          return false;
        }
      }
    }
  });
});