define('fund-figure-app/components/dashboard/state-of-affairs/years-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    gettingPublicKey: false,
    receivedPublicKey: false,
    publicKey: null,
    byWeekThenDayOfWeek: null,
    information: false,

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    moneyFormatTransactions: {
      neg: "<green>%s %v</green>",
      pos: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    unreconciledArray: Ember.computed('getUnreconciledTransactions', {
      get: function get() {
        return Ember.get(this, 'getUnreconciledTransactions');
      },
      set: function set(key, value) {}
    }),

    getUnreconciledTransactions: Ember.computed('connectedUser.currentFund.muncipalChecks.[]', 'connectedUser.currentFund.municipalDeposits.[]', 'connectedUser.currentFund.muncipalChecks.@each.reconciled', 'connectedUser.currentFund.muncipalDeposits.@each.reconciled', {
      get: function get() {
        console.log('checking for unreconciled');
        if (!Ember.get(this, 'connectedUser.currentFund')) {
          return;
        }
        var localUnreconciledArray = [],
            unreconciledChecks = Ember.get(this, 'connectedUser.currentFund.municipalChecks').then(function (res) {
          if (res) {
            var unreconciled = res.filter(function (a) {
              if (a.get('reconciled')) {
                return false;
              } else {
                return true;
              }
            });
            localUnreconciledArray.push(unreconciled);
          }
        }),
            unreconciledDeposits = Ember.get(this, 'connectedUser.currentFund.municipalDeposits').then(function (res) {
          if (res) {
            var unreconciled = res.filter(function (a) {
              if (a.get('reconciled')) {
                return false;
              } else {
                return true;
              }
            });
            localUnreconciledArray.push(unreconciled);
          }
        });
        return Promise.all([unreconciledChecks, unreconciledDeposits]).then(function (res) {
          console.log(localUnreconciledArray);
          return localUnreconciledArray;
        });
      }
    }),
    getInformation: Ember.computed('connectedUser.currentFund.municipalBanksDb', 'connectedUser.currentFund.municipalInvoices', 'connectedUser.currentAccount.responds', {
      get: function get() {
        var _this2 = this;

        var fund = Ember.get(this, 'connectedUser.currentFund'),
            account = Ember.get(this, 'connectedUser.currentAccount');

        Promise.all([fund, account]).then(function (res) {
          console.log(res[0]);
          if (typeof res[0] !== 'undefined' && res[0].toJSON().municipalBanksDb !== null) {
            // if (res[0].municipalBanksDb) {
            console.log(res[0].toJSON().municipalBanksDb);
            console.log('have bank attached');
            Ember.set(_this2, 'information', true);
            return;
            // }
          }
          if (typeof res[0] !== 'undefined' && res[0].toJSON().municipalInvoices !== undefined) {
            // if (res[0].municipalInvoices) {
            console.log(res[0].toJSON().municipalInvoices);
            console.log('have invoices');
            Ember.set(_this2, 'information', true);
            return;
            // }
          }
          if (typeof res[1] !== 'undefined' && res[1].toJSON().responds !== undefined) {
            if (res[1].toJSON().responds) {
              console.log(res[1].toJSON().responds);
              console.log('have message responds');
              Ember.set(_this2, 'information', true);
              return;
            }
          }
        });
      }
    }),

    warrantCheck: Ember.computed('connectedUser.fiscalYear.[]', function () {
      var connectedUser = this.get('connectedUser'),
          fiscalYear = connectedUser.get('fiscalYear');
      if (!fiscalYear) {
        return;
      }
      var model = Promise.resolve(fiscalYear);
      return model.then(function (res) {
        console.log(res.get('year'));
        var checkDate = '04-01-' + res.get('year');
        return checkDate;
      });
    }),
    visualData: Ember.computed('connectedUser.currentFund', 'connectedUser.currentFund.municipalDeposits.[]', 'connectedUser.currentFund.municipalInvoices.[]', {
      get: function get() {
        var _this3 = this;

        var fund = this.get('connectedUser.currentFund');
        if (!fund) {
          return;
        }
        var byWeek = [],
            appError = this.get('appError'),
            object = {},
            _this = this,
            Deposits = this.get('connectedUser.currentFund.municipalDeposits').then(function (deposits) {
          var index = 0,
              length = deposits.get('length') || 0;
          if (!length) {
            return;
          }
          // object = {};
          return deposits.forEach(function (deposit) {
            console.log(deposit.toJSON().total, moment(deposit.toJSON().date, 'x').format('WW'), object[moment(deposit.toJSON().date, 'x').format('WW')]);
            if (!object[moment(deposit.toJSON().date, 'x').format('WW')]) {
              object[moment(deposit.toJSON().date, 'x').format('WW')] = [deposit.toJSON().total || 0, 0];
            } else {
              object[moment(deposit.toJSON().date, 'x').format('WW')][0] += deposit.toJSON().total || 0;
            }
            index++;
            if (index == length) {
              console.log(object, 'done deposits');
              return;
            }
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error querying deposts';
          appError.logError(err, specialMessage);
        }),
            Receipts = Deposits.then(function () {
          console.log('getting invoices');
          return _this3.get('connectedUser.currentFund.municipalInvoices');
        }).then(function (invoices) {
          var index = 0,
              length = invoices.get('length') || 0;
          if (!length) {
            return _this.set('byWeekThenDayOfWeek', [object]);
          }
          console.log('invoices', length);
          return invoices.forEach(function (invoice) {
            var objectDate = object[moment(invoice.toJSON().date, 'x').format('WW')];
            console.log(invoice.toJSON().total, moment(invoice.toJSON().date).format('WW'), objectDate);
            if (!objectDate) {
              object[moment(invoice.toJSON().date, 'x').format('WW')] = [0, invoice.toJSON().total];
            } else {
              if (objectDate[1]) {
                objectDate[1] += invoice.toJSON().total || 0;
              } else {
                objectDate[1] = invoice.toJSON().total || 0;
              }
            }
            index++;
            if (index == length) {
              console.log(object, 'done receipts');
              _this.set('byWeekThenDayOfWeek', [object]);
            }
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error querying for receipts';
          appError.logError(err, specialMessage);
        });
      }
    }),
    groupValueSort: 'date',
    sortBy: ['date'],
    actions: {
      toggleNewYearMenu: function toggleNewYearMenu() {
        this.toggleProperty('connectedUser.showNewYearMenu');
      },
      toggleSortByMenu: function toggleSortByMenu() {
        this.toggleProperty('showSortByMenu');
      },
      requestPublicKey: function requestPublicKey(bankId) {
        var sendMessage = this.get('globalMessaging'),
            _this = this;
        this.set('gettingPublicKey', true);
        console.log(bankId);
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_GeneratePublicKey?bank_id=" + JSON.stringify(bankId),
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            _this.set('gettingPublicKey', false);
            sendMessage.actionNotification(data.error, false);
          } else if (data.success) {
            _this.setProperties({ gettingPublicKey: false, publicKey: data.success.public_token, receivedPublicKey: true });
            sendMessage.actionNotification(data.success, true);
          }
        });
      },
      showTransactions: function showTransactions() {
        this.toggleProperty('showTransactions');
      },
      showModal: function showModal(modalString) {
        console.log(modalString);
        Ember.set(this, 'connectedUser.modalName', modalString);
        Ember.set(this, 'connectedUser.showModal', true);
      },
      addAllAccount: function addAllAccount(fund) {
        console.log(fund.id);
        var store = this.get('store');
        this.get('store').query('municipal-account', {
          orderBy: 'municipalYear',
          equalTo: '-LQFmMjAARrGh92rF0iK'
        }).then(function (municipalAccounts) {
          // fund
          //   .get('municipalAccounts')
          //   .addObjects(municipalAccounts);
          // return fund
          //   .save()
          municipalAccounts.forEach(function (account) {
            store.find('municipalAccount', account.id).then(function (accountFound) {
              accountFound.set('municipalFund', fund);
              accountFound.save().catch(function (err) {
                console.log(err);
              });
            }).catch(function (err) {
              console.log(err);
            });
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error while adding all accounts to fund and saving';
          appError.logError(err, specialMessage);
        });
      },
      changeSort: function changeSort() {
        var newSort = this.get('groupValueSort');
        this.set('sortBy', ['' + newSort]);
        this.toggleProperty('showSortByMenu');
      }
    }
  });
});