define('fund-figure-app/models/municipal-pay-period', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstDay: _emberData.default.attr('number'),
    lastDay: _emberData.default.attr('number'),
    payDay: _emberData.default.attr('number'),
    municipalYear: _emberData.default.belongsTo('municipal-year'),
    municipalDb: _emberData.default.belongsTo('municipal-db'),
    municipalPayChecks: _emberData.default.hasMany('municipal-pay-check'),
    manifest: _emberData.default.belongsTo('manifest'),
    municipalTimeCards: _emberData.default.hasMany('municipal-time-card')
  });
});