define('fund-figure-app/components/municipal-handler/municipal-setup-step-one', ['exports', 'ember/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fundInfo: (0, _service.inject)(),
    isLoading: false,
    accounts: null,

    accountSearch: Ember.computed('model', function () {
      var _this = this;
      if (_this.get('isLoading')) {
        return;
      }
      _this.set('isLoading', true);
      return _this.get('fundInfo.allAccounts').then(function (mAccounts) {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('accounts', mAccounts);
      }).finally(function () {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('isLoading', false);
      });
    })
  });
});