define('fund-figure-app/components/reports/ms-434', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),

    getAccounts: Ember.computed('connectedUser.currentFund.municipalAccounts.@each.budget', function () {
      var _this = this,

      //Revenue Accounts
      taxes = Array(),
          lic_fee = Array(),
          fed_gov = Array(),
          state_gov = Array(),
          other_gov = Array(),
          charge_serv = Array(),
          misc_source = Array(),
          inter_in = Array(),
          other_fin = Array(),
          accounts = this.get('connectedUser.currentFund.municipalAccounts').then(function (muni_accounts) {
        var index = 0;
        _this.set('allAccounts', muni_accounts);
        muni_accounts.forEach(function (account) {
          var source = account.get('source');
          switch (source) {
            // Revenue Accounts
            case 'Revenue From Taxes':
              taxes.addObject(account);
              ++index;
              break;
            case 'Revenue From Licenses, Permits and Fees':
              lic_fee.addObject(account);
              ++index;
              break;
            case 'Revenue From the Federal Government':
              fed_gov.addObject(account);
              ++index;
              break;
            case 'Revenue From the State of New Hampshire':
              state_gov.addObject(account);
              ++index;
              break;
            case 'Revenue From Other Governments':
              other_gov.addObject(account);
              ++index;
              break;
            case 'Revenue From Charges For Services':
              charge_serv.addObject(account);
              ++index;
              break;
            case 'Revenue from Miscellaneous Sources':
              misc_source.addObject(account);
              ++index;
              break;
            case 'Interfund Operating Transfers In':
              inter_in.addObject(account);
              ++index;
              break;
            case 'Other Financial Sources':
              other_fin.addObject(account);
              ++index;
              break;
            default:
              // taxes.addObject(account);
              ++index;
              break;
          }
          if (index == muni_accounts.get('length')) {
            console.log('hit the limit');
            //Set Appropriations Info and Revenue Info
            //No revenue accounts here yet!!!!
            _this.setProperties({
              taxesRevenue: taxes,
              licRevenue: lic_fee,
              fedRevenue: fed_gov,
              stateRevenue: state_gov,
              otherGovRevenue: other_gov,
              chargeRevenue: charge_serv,
              miscRevenue: misc_source,
              interRevenue: inter_in,
              otherRevenue: other_fin
            });
          }
        });
      });
    }),

    totalRevenueBudget: Ember.computed('allAccounts', function () {
      var accounts = this.get('allAccounts');
      if (!accounts) {
        return;
      }
      var revenueAccounts = accounts.filterBy('appropriation', false),
          revenueParentAccounts = revenueAccounts.filter(function (a) {
        var b = a.toJSON();
        if (!b.parentAccount) {
          return true;
        } else {
          return false;
        }
      });
      return revenueParentAccounts;
    }),

    moneyFormat: {
      pos: "%s %v",
      neg: "-%s %v",
      zero: "$0"
    }
  });
});