define('fund-figure-app/components/dashboard/payments/payments-index-display', ['exports', 'd3-collection', 'd3-array'], function (exports, _d3Collection, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    apiTransaction: Ember.inject.service(),
    appError: Ember.inject.service(),
    category: "category",
    variable: "amount",
    paymentsArray: [],
    filteredPayments: [],
    donutData: [],
    checkInput: true,
    invoiceInput: false,

    /**
     * Sets original display of payments to all before filtered is applied
     * @param  {Observer} connectedUser.currentFund.municipalChecks watches for changes in checks array
     * @return {NA}        Sets variable paymentsArray and filteredPayments on initial set up
     */
    setPaymentsArray: Ember.computed('connectedUser.currentFund.municipalChecks', {
      get: function get() {
        var _this = this;

        return Ember.get(this, 'connectedUser.currentFund.municipalChecks').then(function (checks) {
          _this.setProperties({ paymentsArray: checks.toArray(), filteredPayments: checks.toArray() });
          return checks.toArray();
        });
      }
    }),

    /**
     * Create data set for visual graphs and displays
     * @param  {Observer}   'filteredPayments.[]'                          watches for changes in filteredArray of payments
     * @param  {Observer}   'connectedUser.currentFund.municipalChecks.[]' watches for changes in municipalChecks
     * @param  {Observer}   'connectedUser.currentFund'                    watches for change in fund
     * @return {NA}                                                        sets visual data variable equal to current filtered data
     */
    // data: computed('category', 'query', 'connectedUser.currentFund.municipalChecks.[]', 'connectedUser.currentFund', {
    //   get() {
    //     console.log('getting payment data');
    //     let array = this.get('filteredPayments'),
    //       _this = this;
    //     if (!array) {
    //       return
    //     }
    //     this.get('filteredPayments')
    //     let returnArray = [],
    //       index = 0;
    //     return array.forEach(promiseCheck => {
    //       let object = {};
    //       object["amount"] = promiseCheck.get('amount');
    //       promiseCheck.get('vendor')
    //         .then(vendor => {
    //           object["category"] = vendor.get('businessCategory');
    //           return object["business"] = vendor.get('businessName');
    //         })
    //         .then(() => {
    //           return returnArray.push(object);
    //         })
    //         .then(() => {
    //           console.log(index, array.length);
    //           if (index == array.length - 1) {
    //             _this.set('donutData', returnArray);
    //             return returnArray;
    //           } else {
    //             return index++;
    //           }
    //         })
    //     })
    //   }
    // }).property('filteredPayments.[]'),

    // combinedDonutData: computed('donutData', 'filteredPayments.[]', 'data', {
    //   get() {
    //     console.log('getting data for combining');

    //     return nest()
    //       .key(function(d) { return d["category"]; })
    //       .rollup(function(leaves) { return { "checks": leaves.length, "total": sum(leaves, function(d) { return parseFloat(d.amount); }) } })
    //       .entries(get(this, 'donutData'))
    //       .map(d => {
    //         return { category: d.key, amount: d.value.total, checks: d.value.checks }
    //       });
    //   },
    //   set(key, value) {
    //     console.log('from combinedDonutData set', value[0], value[1]);
    //     let arrayFilter = get(this, 'donutData').filter(a => {
    //       if (a[value[0]] === value[1]) {
    //         return true;
    //       }
    //       return false
    //     })
    //     set(this, 'category', 'business');      
    //     return nest()
    //       .key(function(d) { return d["business"]; })
    //       .rollup(function(leaves) { return { "checks": leaves.length, "total": sum(leaves, function(d) { return parseFloat(d.amount); }) } })
    //       .entries(arrayFilter)
    //       .map(d => {
    //         return { business: d.key, amount: d.value.total, checks: d.value.checks }
    //       });
    //   }
    // }).property('donutData'),

    actions: {
      // notifyDonut() {
      //   console.log('hey');
      //   this.notifyPropertyChange('donutData');
      // },
      // drillDown(data) {
      //   console.log(data);
      //   set(this, 'combinedDonutData', ["category", data.data["category"]]);
      // },

      // drillUp() {
      //   console.log('drillingUp');
      //   set(this, 'category', 'category');
      //   // get(this, 'combinedDonutData');
      //   this.notifyPropertyChange('donutData');
      // },

      changeDisplay: function changeDisplay(toDisplay) {
        this.setProperties({ checkInput: false, invoiceInput: false });
        Ember.set(this, toDisplay, true);
      },


      /**
       * set variable equal to check we are going to start void process for, opens modal
       * @param  {emberDataModel} check - model of the check 
       * @return {NA}       no return value, only sets a variable from null to check model
       */
      voidCheckModal: function voidCheckModal(check) {
        console.log('setting check');
        Ember.set(this, 'connectedUser.voidCheck', check);
      },
      proceedVoidCheck: function proceedVoidCheck(check) {
        var _this2 = this;

        var appError = Ember.get(this, 'appError');
        if (!check) {
          appError.logError('no check object', 'the check object passed to proceedVoidCheck in payments screen was empty or undefined');
          return;
        }
        console.log(check);
        var invoices = check.get('municipalInvoices'),
            invoiceIndex = 0;
        invoices.forEach(function (invoice) {
          //remove manifest from invoice so it appears in invoice list again
          invoice.set('manifest', null);
          //add void check to a list so we can track it for auditing
          invoice.get('voidChecks').addObject(check);
          invoice.save().catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving invoice after setting check void and adding old check to list of void checks';
            appError.logError(err, specialMessage);
          });
        });
        //reverse transaction when check was paid out
        check.get('p_transaction').then(function (transaction) {
          console.log(transaction);
          //send transaction to api for reversal
          Ember.get(_this2, 'apiTransaction').reverseTransaction(transaction, false, false, check).then(function () {
            console.log('completed void transaction');
            return;
            //may need to notify of property changes to update payments array
          });
        });
      },


      /**
       * set variable equal to check we are going to start void and reissue process for, open modal
       * @param  {emberDataModel} check - model of the check
       * @return {NA}       no return value, only sets a variable form null to check model
       */
      voidCheckReissueModal: function voidCheckReissueModal(check) {
        console.log('setting check');
        Ember.set(this, 'connectedUser.voidReissueCheck', check);
      },
      proceedVoidCheckReissue: function proceedVoidCheckReissue(check) {
        alert('proceed with voiding and reissue of check');
        //will need to set void check.void == true
        //will need to take invoice.municipalCheck for each check.municipalInvoices and add it to invoice.voidChecks      
        //will need to create new check
        //will need to add new check to municipalFund.municipalChecks
        //will need to add new check to each void check.municipalInvoice => invoice.municipalCheck
        //will need to add void check.municipalInvoices to new check.municipalInvoices
        //will need to set void check.new_check to new check
        //will need to set new check.previous_check to void check 
        //will need to track voiding of check in bigBrother
      },


      /**
       * Cancel void process of check
       * @return {NA} no return value, remove check model from variables voidCheck, and voidReissueCheck, closes modal
       */
      cancelVoidCheck: function cancelVoidCheck() {
        Ember.set(this, 'connectedUser.voidCheck', null);
        Ember.set(this, 'connectedUser.voidReissueCheck', null);
      },


      /**
       * Clears payment filter by setting filtered variable equal to original array
       * @return {NA} no return, sets filteredPayments array equal to original array
       */
      clearFilter: function clearFilter() {
        Ember.set(this, 'filteredPayments', Ember.get(this, 'paymentsArray'));
      },


      /**
       * Submit filter and create new array of payments
       * @param  {modelArray} filterArray array of payment models
       * @return {NA}             sets variable filteredPayments array equal to new filter
       */
      submitFilter: function submitFilter(filterArray) {
        var _this3 = this;

        var payments = Ember.get(this, 'paymentsArray');
        filterArray.forEach(function (filter) {
          switch (filter.filter) {
            case 'byAmount':
              var amount = Number(filter.amount);
              if (filter.logic == "amountGreater") {
                var filtered = payments.filter(function (payment) {
                  return payment.get('amount') > amount;
                });
                payments = filtered;
              }
              if (filter.logic == "amountLess") {
                var _filtered = payments.filter(function (payment) {
                  return payment.get('amount') < amount;
                });
                payments = _filtered;
              }
              if (filter.logic == "amountEquals") {
                var _filtered2 = payments.filter(function (payment) {
                  return payment.get('amount') === amount;
                });
                payments = _filtered2;
              }
              break;
            case 'byDate':
              var beginDate = Number(filter.beginDate);
              var endDate = Number(filter.endDate);
              if (filter.logic == "dateGreater") {
                var _filtered3 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate;
                });
                payments = _filtered3;
              }
              if (filter.logic == "dateLess") {
                var _filtered4 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') < beginDate;
                });
                payments = _filtered4;
              }
              if (filter.logic == "dateBetween") {
                var _filtered5 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate && payment.get('date') < endDate;
                });
                payments = _filtered5;
              }
              break;
            default:
              break;
          }
          Ember.set(_this3, 'filteredPayments', payments);
        });
      },


      /**
       * Toggle display of visual graphs
       * @return {NA} toggles property bool value of showVisuals which controls display
       */
      toggleVisuals: function toggleVisuals() {
        this.toggleProperty('showVisuals');
      }
    }
  });
});