define('fund-figure-app/components/dashboard/navigation/top-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    fundInfo: Ember.inject.service(),

    paidFormat: {
      pos: "%s%v",
      neg: "%s%v",
      zero: "%s0.00"
    },

    moneyFormat: {
      pos: "<green>%s%v</green>",
      neg: "<red>%s%v</red>",
      zero: "%s0.00"
    }

  });
});