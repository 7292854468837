define('fund-figure-app/components/dashboard/transactions/received-deposit', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    deposit: null,

    didInsertElement: function didInsertElement() {
      this.createDeposit();
      this.get('transactionArray').clear();
      this.createTransaction();
    },

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },
    get_receivedAccounts: Ember.computed('connectedUser.currentFund.municipalAccounts', {
      get: function get(key) {
        console.log('get accounts');
        var _this = this;
        var currentFund = this.get('connectedUser.currentFund');
        return currentFund.get('municipalAccounts').then(function (accounts) {
          // console.log(accounts);
          return accounts.toArray();
        }).then(function (res) {
          return res.filter(function (a) {
            return a.get('classification') === 'Cash' || a.get('classification') === "Revenue";
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }),
    willDestroyElement: function willDestroyElement() {
      console.log('destroying deposit');
      this.get('deposit').destroyRecord();
      this.get('transactionArray').clear();
      this.get('undoObjects').clear();
    },
    createDeposit: function createDeposit() {
      console.log('making deposit');
      var store = this.get('store'),
          now = moment().format('x'),
          deposit = store.createRecord('municipal-deposit', {
        timestamp: now,
        date_received: moment(now, 'x').subtract(2, 'days').format('MM/DD/YYYY'),
        date: moment(now, 'x').subtract(7, 'days').format('MM/DD/YYYY')
      });
      console.log(deposit);
      this.set('deposit', deposit);
    },

    suggested_deposit_id: Ember.computed('connectedUser.currentFund', 'connectedUser.currentFund.municipalDeposits.[]', 'deposit', {
      get: function get() {
        var deposit = this.get('deposit') || false;
        if (!deposit) {
          return;
        }
        var number = this.get('connectedUser.currentFund.municipalDeposits.length') + 1;
        deposit.set('deposit_number', number);
      }
    }),
    createTransaction: function createTransaction(TransactionCredit, TransactionDebit, entryDate) {
      var store = this.get('store'),
          deposit = this.get('deposit'),
          newTransaction = store.createRecord('transaction', {
        timestamp: moment().unix(),
        t_Revenue: true,
        deposit: deposit
      });
      this.get('transactionArray').addObject(newTransaction);
    },


    actions: {
      open: function open(select, e) {
        console.log('open select');
        select.actions.open();
      },
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('budgetTotal');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        return;
      },
      removeFile: function removeFile(index) {
        var fileInfo = this.get('fileInfo');
        fileInfo.removeAt(index);
      },
      addItem: function addItem() {
        this.createTransaction();
      },
      paymentMethod: function paymentMethod(paymentString) {
        var transaction = this.get('transaction');
        console.log(paymentString);
        console.log(transaction.get('paymentMethod'));
        if (paymentString === transaction.get('paymentMethod')) {
          console.log('reset');
          transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
          return;
        }
        transaction.setProperties({ paymentMethod: null, creditCard: null, checkNumber: null, checkDate: null, cash: null, check: null });
        transaction.set('paymentMethod', paymentString);
        switch (paymentString) {
          case "cash":
            transaction.set('cash', true);
            break;
            return;
          case "check":
            transaction.set('check', true);
            break;
            return;
          default:
            transaction.set('card', true);
            break;
            return;
        }
      }
    }
  });
});