define('fund-figure-app/models/municipal-pay-check', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    employee: _emberData.default.belongsTo('vendor'),
    date: _emberData.default.attr('number'), //date on paycheck  
    transactions: _emberData.default.hasMany('transaction'),
    documents: _emberData.default.hasMany('file', { async: true, inverse: null }),
    manifest: _emberData.default.belongsTo('manifest'), //connecting to manifest object invoice was paid on
    total: _emberData.default.attr('number'),
    // municipalCheck: DS.belongsTo('municipal-check'),  
    timestamp: _emberData.default.attr('number'),
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
    municipality: _emberData.default.belongsTo('municipal-db', { inverse: null }),
    year: _emberData.default.belongsTo('municipal-year', { inverse: null }),
    municipalFund: _emberData.default.belongsTo('municipal-fund', { inverse: null }),
    user: _emberData.default.belongsTo('municipal-user', { async: true, inverse: null }), //who made the entry
    municipalPayPeriod: _emberData.default.belongsTo('municipal-pay-period'), //which pay period is paycheck for
    date_string: Ember.computed('date_received', 'date_due', {
      get: function get() {
        var string = moment(this.get('date_received')).format('MMM') + moment(this.get('date_due')).format('MMM');
        return string;
      }
    })
  });
});