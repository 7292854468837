define('fund-figure-app/components/dashboard/transactions/journal-display/journal-entry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    apiTransaction: Ember.inject.service(),
    classNames: ['list-simple radius-left'],

    didRender: function didRender() {
      var objDiv = document.getElementById("journalWindow");
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    actions: {
      reverseTransaction: function reverseTransaction(transaction, transactionDeposit, transactionInvoice, transactionPayment) {
        var _this = this;

        Ember.get(this, 'apiTransaction').reverseTransaction(transaction, transactionDeposit, transactionInvoice, transactionPayment).then(function () {
          Ember.get(_this, 'checkTransactions')();
        });
      }
    }
  });
});