define('fund-figure-app/routes/dashboard/reconciliation/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    connectedUser: Ember.inject.service(),
    model: function model() {
      var year = this.get('connectedUser.fiscalYear.id');
      console.log(year);
      return this.store.query('reconciled-report', {
        orderBy: 'year',
        equalTo: year
      });
    }
  });
});