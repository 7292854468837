define('fund-figure-app/controllers/dashboard-assets/settings/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    assetInfo: Ember.inject.service(),
    store: Ember.inject.service(),
    undo: Ember.inject.service(),
    appError: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    assetDb: null,

    getAssetDb: Ember.computed('assetInfo.assetDb', {
      get: function get() {
        var _this = this;

        var appError = Ember.get(this, 'appError');
        return Ember.get(this, 'assetInfo.assetDb').then(function (res) {
          Ember.set(_this, 'assetDb', res);
          return res.get('id');
        }).catch(function (err) {
          var specialMessage = 'error getting assetDb from assetInfo service';
          appError.logError(err, specialMessage);
        });
      }
    }),
    actions: {
      saveChanges: function saveChanges(model) {
        var _this2 = this;

        var appError = Ember.get(this, 'appError');
        Ember.get(this, 'undo').createUndo(model, false, null, null, 'Undo change to Capital Improvement Settings', 'adjusted info for assetDb').then(function (res) {
          return res.save();
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving model after undo was done';
          appError.logError(err, specialMessage);
        }).then(function () {
          Ember.get(_this2, 'globalMessaging').actionNotification('Saved Changes', 'success');
        }).catch(function (err) {
          var specialMessage = 'error sending global message';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});