define('fund-figure-app/components/global-messaging', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		globalMessaging: Ember.inject.service(),
		connectionStatus: Ember.inject.service(),

		tempMessage: Ember.computed('globalMessaging.tempMessage', function () {
			var globalMessage = this.get('globalMessaging.tempMessage');
			var globalMessageSuccess = this.get('globalMessaging.tempMessageSuccess');
			return { message: globalMessage, status: globalMessageSuccess };
		})
	});
});