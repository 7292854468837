define('fund-figure-app/models/audit-user-change', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    desc: _emberData.default.attr('string'),
    logDesc: _emberData.default.attr('string'),
    municipalUser: _emberData.default.belongsTo('municipal-user'),
    municipalDb: _emberData.default.belongsTo('municipal-db'),
    modelName: _emberData.default.attr('string'),
    modelId: _emberData.default.attr('string'),
    modelDeleted: _emberData.default.attr('boolean'),
    ts: _emberData.default.attr('number'),
    lines: _emberData.default.attr('number'), //number of lines would take up on report
    changes: _emberData.default.attr(),
    q_year: _emberData.default.attr('string'), //municipal_id-YYYY
    q_date: _emberData.default.attr('string') //municipal_id-YYYY-MM-DD
  });
});