define('fund-figure-app/models/transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    transactionCatagories: _emberData.default.hasMany('transaction-category'),
    transactDebit: _emberData.default.belongsTo('municipal-account', { async: true, inverse: null }),
    transactCredit: _emberData.default.belongsTo('municipal-account', { async: true, inverse: null }),
    documents: _emberData.default.hasMany('file', { async: true }),
    asset: _emberData.default.belongsTo('asset', { inverse: null, defaultValue: null }),
    t_Transfer: _emberData.default.attr('boolean'),
    t_Expense: _emberData.default.attr('boolean'),
    t_Revenue: _emberData.default.attr('boolean'),
    t_Payroll: _emberData.default.attr('boolean'),
    t_warrant: _emberData.default.attr('boolean'),
    function: _emberData.default.belongsTo('municipal-account-function', { inverse: null }),
    functionDesc: _emberData.default.attr('string'),
    invoice: _emberData.default.belongsTo('municipal-invoice', { async: true, inverse: null }),
    municipalCheck: _emberData.default.belongsTo('municipal-check', { async: true }),
    manifest: _emberData.default.belongsTo('manifest', { async: true, inverse: null }), //When manifest is paid total is transacted from cash and accounts payable debited
    deposit: _emberData.default.belongsTo('municipal-deposit', { async: true, inverse: null }),
    reversal: _emberData.default.belongsTo('transaction', { async: true, inverse: null, defaultValue: null }), //connection to reversal transaction
    reversed: _emberData.default.belongsTo('transaction', { async: true, inverse: null, defaultValue: null }), //connection to original transaction being reversed 
    amount: _emberData.default.attr('string'), //amount of transaction line item
    creditCard: _emberData.default.attr('boolean'),
    paymentMethod: _emberData.default.attr('string'),
    check: _emberData.default.attr('boolean'),
    checkNumber: _emberData.default.attr('string'),
    checkDate: _emberData.default.attr('number'), //date on check
    cash: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string'),
    parent_credit_balance_after: _emberData.default.attr('string'),
    parent_debit_balance_after: _emberData.default.attr('string'),
    transact_credit_balance_before: _emberData.default.attr('string'),
    transact_debit_balance_before: _emberData.default.attr('string'),
    transact_debit_balance_after: _emberData.default.attr('string'),
    transact_credit_balance_after: _emberData.default.attr('string'),
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
    municipality: _emberData.default.belongsTo('municipal-db', { inverse: null }),
    year: _emberData.default.belongsTo('municipal-year', { inverse: null }),
    municipalFund: _emberData.default.belongsTo('municipal-fund', { inverse: null }),
    date: _emberData.default.attr('number'), //date of transaction
    date_received: _emberData.default.attr('number'), //date payment was received should match date in most cases
    date_due: _emberData.default.attr('number'),
    timestamp: _emberData.default.attr('number'), //Unix of time of entry
    searchField: Ember.computed('transactDebit.searchField', 'transactCredit.searchField', {
      get: function get() {
        return this.get('transactDebit.searchField') + ' ' + this.get('transactCredit.searchField') + ' ' + this.get('functionDesc') + ' ' + this.get('notes') + ' ' + this.get('amount');
      }
    })
  });
});