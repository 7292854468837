define('fund-figure-app/router', ['exports', 'fund-figure-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('website', function () {
      this.route('home');
    });

    this.route('dashboard', function () {
      this.route('avitar', function () {
        this.route('account-input');
      });
      this.route('accounts', function () {
        this.route('account');
        this.route('add-step-one');
      });
      this.route('state-of-affairs', function () {});
      this.route('transactions', function () {});
      this.route('expenditures');
      this.route('receivables');
      this.route('budget', { path: 'budget/' }, function () {
        this.route('edit-main', { path: 'account' }, function () {
          this.route('sub-accounts', { path: '/:acc_id' });
        });
      });
      this.route('reconciliation', function () {
        this.route('new-report');
        this.route('review-report', { path: 'review-report/:reconciledReport_id' });
        this.route('view-report', { path: 'view-report/:reconciledReport_id' });
      });
      this.route('reports', function () {
        this.route('report', { path: 'report/:report_id' });
        this.route('visuals');
        this.route('visual', { path: 'visual/:report_id' });
        this.route('town-reports', function () {
          this.route('town-report', { path: 'town-report/:report_id' });
        });
        this.route('state-reports', function () {
          this.route('state-report', { path: 'state-report/:report_id' });
        });
        this.route('state-report', { path: 'state-report/:report_id' });
      });
      this.route('ledger', function () {
        this.route('transaction-invoice', { path: 'transaction-invoice/:invoice_id' });
        this.route('transaction-deposit', { path: 'transaction-deposit/:deposit_id' });
        this.route('account-ledger', { path: 'account/:municipal-account_id' });
      });
      this.route('journal', function () {});
      this.route('settings', function () {
        this.route('bank-accounts');
        this.route('vendors');
        this.route('manage-users', function () {
          this.route('add-user', function () {
            this.route('user-role', { path: 'user-role/:municipal-user_id' });
            this.route('user-information', { path: 'user-information/:municipal-user_id' });
            this.route('user-verify', { path: 'user-verify/:municipal-user_id' });
          });
          this.route('edit-user', { path: 'edit-user/:municipal-user_id' });
        });
        this.route('manage-funds', function () {
          this.route('fund-edit');
          this.route('fund-create', function () {
            this.route('step-one');
            this.route('step-two');
            this.route('step-three');
          });
        });
      });

      this.route('setting', function () {});
      this.route('billing', function () {
        this.route('invoices');
      });
      this.route('feedback-messages', function () {
        this.route('feedback-message', { path: '/:feedback_id' });
      });
      this.route('assets', function () {
        this.route('edit-asset');
        this.route('create-asset');
        this.route('asset-list');
        this.route('vehicles');
        this.route('buildings');
        this.route('equipment');
        this.route('infrastructure');
        this.route('land');
        this.route('cip');
        this.route('tax-deed');
      });
      this.route('conversations', function () {
        this.route('conversation', { path: '/:feedback_id' });
      });
      this.route('payments', function () {});
      this.route('invoices-manifests', function () {
        this.route('manifests', function () {
          this.route('manifest', { path: '/:manifest_id' }, function () {
            this.route('edit');
          });
          this.route('create');
        });
        this.route('invoices', function () {
          this.route('invoice', { path: '/:municipalInvoice_id' });
          this.route('add-invoice');
          this.route('edit-invoice', { path: '/:municipalInvoice_id' });
        });
      });
      this.route('personnel', function () {
        this.route('vendors', function () {
          this.route('vendor', { path: '/:vendor_id' }, function () {
            this.route('vendor-edit');
          });
          this.route('create');
        });
        this.route('employees', function () {
          this.route('employee', { path: '/:vendor_id' }, function () {
            this.route('employee-edit');
          });
          this.route('create');
        });

        this.route('employee', function () {});
      });
      this.route('warrant-articles', function () {
        this.route('create-warrant-article', function () {
          this.route('step-two', { path: '/:municipal-article_id' });
        });
      });
      this.route('payroll', function () {
        this.route('statistics');
      });
    });
    this.route('account-handler', function () {
      this.route('account-menu');
      this.route('account-sign-in');
      this.route('account-create-step-one');
      this.route('account-create-step-two', { path: 'account-create-step-two/:user_id' });
      this.route('account-create-step-three', { path: 'account-create-step-three/:municipal-db_id' });
      this.route('account-create-step-four', { path: 'account-create-step-four/:municipal-db_id' });
      this.route('account-create-step-five', { path: 'account-create-step-five/:municipal-db_id' });
      this.route('account-create-step-six', { path: 'account-create-step-six/:municipal-db_id' });
      this.route('add-account-info', { path: 'add-account-info/:municipal-db_id' });

      this.route('vendor-setup', { path: 'vendor-setup/' }, function () {
        this.route('step-one', { path: 'step-one/:municipal-db_id' });
        this.route('step-two', { path: 'step-two/:municipal-db_id' });
      });
      this.route('create-with-pin', function () {
        this.route('pin-step-one', { path: '/1' });
        this.route('pin-step-two', { path: '/2' });
        this.route('pin-step-three', { path: '/3' });
        this.route('pin-step-four', { path: '/4/:municipal-user_id' });
      });
    });
    this.route('municipal-handler', { path: 'municipality/:munic_id' }, function () {
      this.route('municipal-setup-step-one', { path: '/setup-step-one' }, function () {
        this.route('edit-account', { path: '/edit/:acc_id' });
      });
    });
    this.route('loading');
    this.route('test');
    this.route('documentation', function () {
      this.route('api', function () {
        this.route('deposit-item');
        this.route('deposit-create');
      });
    });
    this.route('dashboard-support', function () {
      this.route('feedback-messages', function () {
        this.route('feedback-message', { path: '/:feedback_id' });
      });
      this.route('conversations', function () {
        this.route('user-conversations', { path: '/:municipal-user_id' }, function () {
          this.route('user-conversation', { path: '/:feedback_id' });
        });
      });
      this.route('manage-funds', function () {
        this.route('create');
        this.route('manage-chart-of-accounts', { path: '/:municipal-fund-type_id' }, function () {
          this.route('create');
          this.route('chart-of-account', { path: '/:chart-of-account_id' }, function () {
            this.route('edit');
          });
          this.route('edit');
          this.route('sub-type', function () {
            this.route('create');
            this.route('edit');
          });
        });
      });
      this.route('manage-users', function () {
        this.route('manage-user', { path: "/:user_id" }, function () {
          this.route('municipal-user-add');
          this.route('municipal-user-edit');
        });
      });
      this.route('manage-warrant-articles', function () {
        this.route('manage-warrant-article', function () {});
        this.route('create-warrant-article', { path: '/:state_id/create' });
        this.route('state-warrant-articles', { path: '/:state_id' });
      });
    });
    this.route('dashboard-payroll', function () {
      this.route('payroll');
      this.route('employees');
      this.route('earnings');
      this.route('deductions');
      this.route('taxes');
      this.route('direct-deposit');
    });
    this.route('dashboard-assets', function () {
      this.route('asset', { path: 'asset/:asset_id' }, function () {
        this.route('statistics');
        this.route('depreciation');
        this.route('transactions');
        this.route('cip');
      });
      this.route('capital-improvement-plan', function () {});
      this.route('settings', function () {});
    });
  });

  exports.default = Router;
});