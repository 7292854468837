define('fund-figure-app/routes/dashboard/invoices-manifests/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() /* transition */{
      this.transitionTo('dashboard.invoices-manifests.invoices');
    }
  });
});