define('fund-figure-app/controllers/account-handler/create-with-pin/pin-step-three', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			transitionToStepFour: function transitionToStepFour(municipalUser) {
				this.transitionToRoute('account-handler.create-with-pin.pin-step-four', municipalUser.id);
			}
		}
	});
});