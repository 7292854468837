define('fund-figure-app/components/dashboard/transactions/ledger-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    totalTransaction: null,
    transactions: null,

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s  0.00"
    },

    didInsertElement: function didInsertElement() {
      this.get('get_transactions');
      this.get('transactionFilter');
    },

    endDate: Ember.computed('', function () {
      var now = moment().format("MM/DD/YYYY");
      return now;
    }),
    beginDate: Ember.computed('endDate', function () {
      var now = this.get('endDate');
      var begin = moment(now).subtract(30, 'days').format('MM/DD/YYYY');
      return begin;
    }),
    totalDebitCredit: Ember.computed('connectedUser.currentFund.municipalTransactions.@each.amount', function () {
      var _this = this,
          appError = this.get('appError');
      if (!this.get('connectedUser.currentFund')) {
        return;
      }
      return this.get('connectedUser.currentFund.municipalTransactions').then(function (transactions) {
        return transactions.reduce(function (total, r_account) {
          console.log(r_account);
          if (!r_account.get('amount')) {
            return total;
          }
          return total + Number(r_account.get('amount').replace(/,/g, ''));
        }, 0);
      }).then(function (total) {
        console.log(total);
        _this.set('totalTransaction', total);
        return total;
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'calculating totalDebitCredit total';
        appError.logError(err, specialMessage);
      });
    }),
    get_transactions: Ember.computed('checkTransactions', 'connectedUser.currentFund', 'connectedUser.currentFund.municipalTransactions.[]', 'connectedUser.currentFund.municipalTransactions', function () {
      console.log('fetching transactions');
      var _this = this,
          appError = this.get('appError');
      return this.get('connectedUser.currentFund.municipalTransactions').then(function (res) {
        console.log(res);
        _this.set('transactions', res.toArray());
        return res.toArray();
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'getting transactions';
        appError.logError(err, specialMessage);
      });
    }).property('checkTransactions'),
    transactionFilter: Ember.computed.filter('transactions', function (res) {
      var beginDate = this.get('beginDate'),
          endDate = this.get('endDate');
      if (!beginDate || !endDate) {
        console.log('no dates');
        return false;
      }
      if (!res.get('date')) {
        return false;
      }
      if (moment(beginDate).unix() <= res.get('date') && res.get('date') <= moment(endDate).unix()) {
        return true;
      } else {
        return false;
      }
    }).property('endDate', 'beginDate', 'transactions'),
    sortedTransactions: Ember.computed.sort('transactionFilter', 'sortDefinition'),
    sortBy: 'date',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    actions: {
      toggleDate: function toggleDate() {
        this.toggleProperty('transactionDate');
      },
      reverseTransaction: function reverseTransaction(transaction, transactionDeposit, transactionInvoice) {
        console.log('working');
        var globalMessage = this.get('globalMessaging'),
            _this = this,
            appError = this.get('appError'),
            store = this.get('store'),
            jsonModel = transaction.toJSON(),
            currentFund = this.get('connectedUser.currentFund'),
            transactDebit = transaction.get('transactDebit'),
            transactCredit = transactDebit.then(function () {
          return transaction.get('transactCredit');
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'getting transactCredit';
          appError.logError(err, specialMessage);
          globalMessage.actionNotification(err, 'warning');
        }),
            reverseTransaction = store.createRecord('transaction', jsonModel);
        transactCredit.then(function (t_credit) {
          reverseTransaction.setProperties({ 'transactCredit': transactDebit, 'transactDebit': t_credit, 'reversed': transaction, 'timestamp': moment().unix() });
          reverseTransaction.save().then(function (r_transaction) {
            currentFund.get('municipalTransactions').addObject(r_transaction);
            currentFund.save().then(function (res) {
              _this.notifyPropertyChange('checkTransactions');
              globalMessage.actionNotification('Reversed Transaction', 'success');
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'saving current fund with new transaction added';
              appError.logError(err, specialMessage);
              globalMessage.actionNotification('There was an error, we logged it, may contact you soon', 'warning');
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'saving reverse transaction';
            appError.logError(err, specialMessage);
            globalMessage.actionNotification('There was an error, we logged it, may contact you soon', 'warning');
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'just getting the transactCredit account';
          appError.logError(err, specialMessage);
          globalMessage.actionNotification('There was an error, we logged it, may contact you soon', 'warning');
        });
      },
      displayLedger: function displayLedger(account) {
        $('.list-simple.active').removeClass('active');
        if (Ember.get(this, 'displayAccountLedger.id') === account.id) {
          Ember.set(this, 'displayAccountLedger', null);
          return;
        }
        $('.' + account.id).addClass('active');
        Ember.set(this, 'displayAccountLedger', account);
      },
      closeAccountLedger: function closeAccountLedger() {
        Ember.set(this, 'displayAccountLedger', null);
      }
    }
  });
});