define('fund-figure-app/models/employee-private', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ssn: _emberData.default.attr('string'),
    files: _emberData.default.hasMany('file'),
    allowances: _emberData.default.attr('number'),
    additional_withheld: _emberData.default.attr('number'),
    single: _emberData.default.attr('boolean'),
    married: _emberData.default.attr('boolean'),
    sex_identification_male: _emberData.default.attr('boolean'),
    race_identification: _emberData.default.belongsTo('race-code'),
    married_single: _emberData.default.attr('boolean'),
    payroll_type_hourly: _emberData.default.attr('boolean'),
    payroll_type_salary: _emberData.default.attr('boolean'),
    payroll_amount: _emberData.default.attr('number'), //number that matches to payroll type of hourly, salary etc
    payrollAccounts: _emberData.default.hasMany('municipal-account'), //account payroll will draw from when executed
    payroll_payment_direct: _emberData.default.attr('boolean'), //do they receive payroll via direct deposit  
    payroll_sickpay: _emberData.default.attr('boolean'), //do they earn sickpay per pay period
    payroll_sickpay_amount: _emberData.default.attr('number'), //sick leave earned per pay period
    payroll_vacation: _emberData.default.attr('boolean'), //do they receive vacation days per pay period
    payroll_vacation_amount: _emberData.default.attr('number'), //number of vacation day earned per pay period
    payroll_bonus: _emberData.default.attr('boolean'),
    payroll_bonus_amount: _emberData.default.attr('number')
  });
});