define('fund-figure-app/controllers/dashboard/settings/manage-users/add-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    connectedUser: Ember.inject.service(),

    actions: {
      formSubmit: function formSubmit() {
        // document.getElementById("userInfo").submit()
        $("form").find('[type="submit"]').trigger('click');
      }
    }
  });
});