define('fund-figure-app/components/dashboard/navigation/side-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    helpComponentName: Ember.computed('router.currentURL', {
      get: function get() {
        return 'help-window' + Ember.get(this, 'router.currentURL');
      }
    }),

    // showNewYearButton: computed('connectedUser.currentFund', 'connectedUser.currentFund.municipalDb', 'connectedUser.fiscalYearsAvailable', {
    //   get() {
    //     //If there isnt a fund yet, return
    //     if (get(this, 'connectedUser.currentFund') === null || typeof(get(this, 'connectedUser.currentFund')) == 'undefined') {
    //       return false;
    //     }
    //     if (get(this, 'connectedUser.fiscalYearsAvailable') === null) {
    //       return false;
    //     }
    //     console.log(get(this, 'connectedUser.currentFund'));
    //     //if the fiscal year is already closed return false
    //     if (get(this, 'connectedUser.fiscalYear.closed')) {
    //       return false;
    //     }
    //     console.log('index: ', get(this, 'connectedUser.fiscalYearsAvailable').indexOf(get(this, 'connectedUser.fiscalYear')));
    //     console.log('length: ', get(this, 'connectedUser.fiscalYearsAvailable.length'))
    //     //check if current year is the last year in list
    //     if (get(this, 'connectedUser.fiscalYearsAvailable').indexOf(get(this, 'connectedUser.fiscalYear')) + 1 < get(this, 'connectedUser.fiscalYearsAvailable.length')) {
    //       //current year is not the last year
    //       return false;
    //     }
    //     let now = moment().valueOf(),
    //       year = get(this, 'connectedUser.fiscalYear.year');
    //     return get(this, 'connectedUser.currentFund.municipalDb')
    //       .then(municipalDb => {
    //         if(municipalDb === null){
    //           console.log('no municipalDb', municipalDb);
    //           throw Error('no MunicipalDb');
    //         }
    //         console.log(municipalDb);
    //         return municipalDb.get('info');
    //       })        
    //       .then((municipalInfo, err) => {
    //         if(err){
    //           console.log('in err');
    //           return false;
    //         }          
    //         return municipalInfo.get('fiscalYear') || 'NA';
    //       })
    //       .then((fiscalYear, err) => {
    //         if(err){
    //           console.log('in err');
    //           return false;
    //         }
    //         //should be "January to December" or "July to June"
    //         if (fiscalYear === 'January to December') {
    //           let yearEnd = moment(`09-30-${year}`, 'MM-DD-YYYY').startOf('day').valueOf();
    //           if (now > yearEnd) {
    //             return true;
    //           }
    //         } else if (fiscalYear === "July to June") {
    //           let yearEnd = moment(`03-30-${year}`, 'MM-DD-YYYY').startOf('day').valueOf();
    //           if (now > yearEnd) {
    //             return true;
    //           }
    //         } else {
    //           return false;
    //         }
    //       })
    //   }
    // }),

    // showYearEndClose: computed('connectedUser.currentFund', 'connectedUser.fiscalYearsAvailable', {
    //   get() {
    //     console.log('looking for year end close');
    //     let globalMessage = get(this, 'globalMessaging');
    //     //If there isnt a fund yet, return
    //     if (get(this, 'connectedUser.currentFund') === null) {
    //       console.log('no fund');
    //       return false;
    //     }
    //     if (get(this, 'connectedUser.fiscalYearsAvailable') === null) {
    //       console.log('no fiscaleYearsAvailable');
    //       return false;
    //     }
    //     //if the fiscal year is already closed return false
    //     if (get(this, 'connectedUser.fiscalYear.closed')) {
    //       globalMessage.actionNotification('Year has already been closed', 'warning');
    //       return false;
    //     }
    //     //check if a following year exists
    //     if (get(this, 'connectedUser.fiscalYearsAvailable').objectAt(get(this, 'connectedUser.fiscalYearsAvailable').indexOf(get(this, 'connectedUser.fiscalYear')) + 1) || false) {
    //       if (get(this, 'connectedUser.fiscalYearsAvailable').objectAt(get(this, 'connectedUser.fiscalYearsAvailable').indexOf(get(this, 'connectedUser.fiscalYear')) + 1).toJSON().closed) {
    //         //following year already closed, cannot close this year
    //         console.log('following year closed');
    //         return false;
    //       }
    //       //following year exists and isnt closed so make button available if right date
    //       let now = moment().valueOf(),
    //         year = get(this, 'connectedUser.fiscalYear.year');
    //       return get(this, 'connectedUser.currentFund.municipalDb')
    //         .then(municipalDb => {
    //           console.log('close year', municipalDb);
    //           return municipalDb.get('info');
    //         })
    //         .then(municipalInfo => {
    //           return municipalInfo.get('fiscalYear') || 'NA';
    //         })
    //         .then(fiscalYear => {
    //           //should be "January to December" or "July to June"
    //           if (fiscalYear === 'January to December') {
    //             console.log('in a new year and fiscal year is jan to dec');
    //             let yearEnd = moment(`12-31-${year}`, 'MM-DD-YYYY').startOf('day').valueOf();
    //             console.log(now, yearEnd);
    //             if (now > yearEnd) {
    //               return true;
    //             }
    //           } else if (fiscalYear === "July to June") {
    //             let yearEnd = moment(`06-30-${year}`, 'MM-DD-YYYY').startOf('day').valueOf();
    //             if (now > yearEnd) {
    //               return true;
    //             }
    //           } else {
    //             return false
    //             //no fiscal year given
    //             //may want to add a set term to show a go to settings and indicate fiscal year end
    //           }
    //         })
    //     }
    //     //do not show button
    //     return false;
    //   }
    // }),

    actions: {
      showModal: function showModal(modalString) {
        console.log(modalString);
        Ember.set(this, 'connectedUser.modalName', modalString);
        Ember.set(this, 'connectedUser.showModal', true);
      },
      fixBudget: function fixBudget() {
        // get(this, 'store').findAll('municipalAccount').then(res => {
        //   res.forEach(a => {
        //     if (!a.get('budget')) {
        //       return
        //     }
        //     if (!typeof(a.get('budget')) === 'String') {
        //       return
        //     }
        //     let newBudget = a.get('budget').replace(/,/g, '');
        //     a.set('budget', newBudget);
        //     a.save();
        //   })
        // })
      },
      toggleFinalizeBudgetMenu: function toggleFinalizeBudgetMenu() {
        this.toggleProperty('connectedUser.showFinalizeBudgetMenu');
      },
      toggleWarrantMenu: function toggleWarrantMenu() {
        this.toggleProperty('connectedUser.showFinalizeWarrantMenu');
      },
      toggleNewYearMenu: function toggleNewYearMenu() {
        this.toggleProperty('connectedUser.showNewYearMenu');
      },
      toggleShowReport: function toggleShowReport() {
        this.toggleProperty('connectedUser.showReport');
      },
      toggleShowActivatePayrollMenu: function toggleShowActivatePayrollMenu() {
        this.toggleProperty('connectedUser.showActivatePayrollMenu');
      },
      finalizeBudget: function finalizeBudget(fiscal_year_id) {
        var _this2 = this;

        var session = Ember.get(this, 'session'),
            fund_id = Ember.get(this, 'connectedUser.currentFund.id'),
            _this = this,
            test = Ember.get(this, 'connectedUser.test'),
            instance = 'aacc-603',
            globalMessage = Ember.get(this, 'globalMessaging');
        if (test) {
          instance = 'aacc-603-test';
        }
        session.get('currentUser').getToken(true).then(function (token_string) {
          return token_string;
        }).then(function (token) {
          _this2.set('loading', true);
          $.get({
            url: "https://us-central1-aacc-603.cloudfunctions.net/finalizeMunicipalBudget?fiscal_year_id=" + fiscal_year_id + '&fund_id=' + fund_id + '&token=' + token + '&instance=' + instance,
            json: true
          }).done(function (data) {
            console.log(data);
            if (data.error) {
              globalMessage.actionNotification(error, 'warning');
            } else if (data) {
              globalMessage.actionNotification('Budget Finalized', 'success');
              setTimeout(function () {
                _this.toggleProperty('connectedUser.showFinalizeBudgetMenu');
                _this.setProperties({ loading: false });
              }, 2000);
            }
          }).catch(function (error) {
            console.log(error);
          });
        }).catch(function (error) {
          console.log(error);
        });
      },
      formatAsCurrency: function formatAsCurrency(value, evt) {
        console.log('currency invoked');
        if (value < 1) {
          this.set('warrantAmount', null);
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.set('warrantAmount', formated);
        return;
      },
      createNewYear: function createNewYear(yearDb, years, create) {
        var _this3 = this;

        var globalMessage = this.get('globalMessaging');
        console.log(years, create);
        //Not creating the year yet, just setting up the menu
        if (!create) {
          //If no previous year, set to current year
          if (!years.get('length')) {
            var year = moment().format('YYYY');
            console.log('no previous year create for ', year);
            var newYear = moment(year).add(1, 'year').format('YYYY');
            this.set('newYear', newYear);
            this.toggleProperty('connectedUser.showNewYearMenu');
            return;
            //Has previous year, use last year created to suggest what the following year would be
          } else {
            var lastYearModel = years.get('lastObject'),
                _year = moment(lastYearModel.get('year'), 'YYYY');
            console.log('has previous year ', _year);
            var _newYear = moment(_year).add(1, 'year').format('YYYY');
            this.set('newYear', _newYear);
            this.toggleProperty('connectedUser.showNewYearMenu');
            return;
          }
        }
        //Create new year and there is no previous year
        if (!years.get('length')) {
          console.log('no previous years');
          var store = this.get('store'),
              _lastYearModel = moment(),
              _year2 = this.get('newYear'),
              _this = this,
              yearsModel = store.findRecord('municipalYearsDb', years.get('id')),
              percentChange = 1 + Number(this.get('newBudgetAdjust')) / 100,
              municipality = this.get('connectedUser.currentAccount.municipality');
          this.set('creatingNewYear', true);
          yearsModel.then(function (years_db) {
            var newYearModel = store.createRecord('municipalYear', {
              year: _year2,
              municipality: municipality
            });
            years_db.get('years').addObject(newYearModel);
            newYearModel.save().then(function () {
              return years_db.save();
            }).then(function () {
              globalMessage.actionNotification('This could take a minute or two', 'success');
              _this3.set('creatingNewYear', false);
            }).catch(function (err) {
              console.log(err);
            });
          });
        } else {
          console.log('has previous years');
          var _store = this.get('store'),
              _lastYearModel2 = yearDb.get('years.lastObject'),
              _year3 = this.get('newYear'),
              _this4 = this,
              _yearsModel = _store.findRecord('municipalYearsDb', yearDb.get('id')),
              _percentChange = 1 + Number(this.get('newBudgetAdjust')) / 100,
              _municipality = this.get('connectedUser.currentAccount.municipality');
          this.set('creatingNewYear', true);
          _yearsModel.then(function (years_db) {
            var newYearModel = _store.createRecord('municipalYear', {
              year: _year3,
              municipality: _municipality
            });
            years_db.get('years').addObject(newYearModel);
            newYearModel.save().then(function () {
              return years_db.save();
            }).then(function () {
              return _lastYearModel2.get('municipalFunds');
            }).then(function (municipalFunds) {
              municipalFunds.forEach(function (fund) {
                var fundData = fund.toJSON();
                var newFund = _store.createRecord('municipalFund', {
                  fund_name: fundData.fund_name || null,
                  fund_id: fundData.fund_id || null,
                  municipalFundType: fundData.municipalFundType || null,
                  chartOfAccount: fundData.chartOfAccount || null,
                  municipalDb: fundData.municipalDb || null,
                  municipalYear: newYearModel,
                  previousYear: fund,
                  budgetAdjust: _percentChange,
                  year_voted: fundData.year_voted || null,
                  warrant_article: fundData.warrant_article || null
                });
                newFund.get('stateReports').addObjects(fund.get('stateReports'));
                newFund.get('townReports').addObjects(fund.get('townReports'));
                newFund.get('visualReports').addObjects(fund.get('visualReports'));
                return newFund.save().then(function (savedFund) {
                  newYearModel.get('municipalFunds').addObject(savedFund);
                  newYearModel.save();
                }).catch(function (err) {
                  console.log(err);
                });
              });
            }).then(function () {
              globalMessage.actionNotification('This could take a minute or two', 'success');
              _this3.toggleProperty('connectedUser.showNewYearMenu');
              _this3.set('creatingNewYear', false);
            }).catch(function (err) {
              console.log(err);
            });
          });
        }
      },
      changeFiscalYear: function changeFiscalYear(selection) {
        console.log('changing to year' + selection);
        this.get('connectedUser').getFiscalYear(selection);
      },
      finalizeWarrant: function finalizeWarrant(warrantAmount, warrantDate, fundAccounts) {
        var globalMessage = this.get('globalMessaging');
        if (!warrantAmount || warrantAmount < 1) {
          globalMessage.actionNotification('No warrant amount', 'warning');
          return;
        }
        var uncollectedTaxes = fundAccounts.find(function (a) {
          var json = a.toJSON();
          if (json.uncollected == true) {
            return true;
          }
        }),
            taxesRevenues = fundAccounts.find(function (a) {
          var json = a.toJSON();
          if (json.warrant == true) {
            return true;
          }
        });
        var correctedTotal = Number(warrantAmount.replace(/,/g, '')),
            _this = this,
            store = this.get('store'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            fund = this.get('connectedUser.currentFund'),
            user = this.get('connectedUser.currentAccount'),
            municipal_id = this.get('connectedUser.currentAccount').toJSON().municipality,
            m_year = municipal_id + '-' + moment(warrantDate, 'MMM DD, YYYY').format("YYYY"),
            m_month = m_year + '-' + moment(warrantDate, 'MMM DD, YYYY').format('MM'),
            m_week = m_year + '-' + moment(warrantDate, 'MMM DD, YYYY').format('WW'),
            m_day = m_month + '-' + moment(warrantDate, 'MMM DD, YYYY').format('ddd'),
            m_weekday = m_week + '-' + moment(warrantDate, 'MMM DD, YYYY').format('E'),
            newDeposit = store.createRecord('municipalDeposit', {
          t_warrant: true,
          date_received: moment(warrantDate, 'MMM DD, YYYY').valueOf(), //deposit received date
          date_entered: moment().valueOf(), //date of entry of deposit
          date: moment(warrantDate, 'MMM DD, YYYY').valueOf(), //date on deposit
          total: correctedTotal, //total amount of deposit          
          department: taxesRevenues, //represents the Credit account
          timestamp: moment().valueOf(),
          m_year: m_year, //m_id + year for querying on year
          m_month: m_month, //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
          m_week: m_week, //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
          m_day: m_day, //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
          m_weekday: m_week, //m_id + year + week + day ex: "-Lksdsdsfg-YYYY-WW-E"
          municipality: municipal_id,
          year: fiscalYear,
          municipalFund: fund,
          user: user //who made the entry
        }),
            newTransaction = store.createRecord('transaction', {
          amount: correctedTotal,
          transactDebit: uncollectedTaxes,
          transactCredit: taxesRevenues,
          t_Revenue: true,
          date: moment(warrantDate, 'MMM DD, YYYY').valueOf(), //date on warrant
          notes: 'Warrant ' + fiscalYear.get('year'),
          timestamp: moment().valueOf(),
          m_year: m_year, //m_id + year for querying on year
          m_month: m_month, //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
          m_week: m_week, //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
          m_day: m_day, //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
          m_weekday: m_week, //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
          municipality: municipal_id,
          municipalFund: fund,
          year: fiscalYear,
          user: user //who made the entry
        });
        taxesRevenues.set('balance', 0);
        taxesRevenues.save();
        newDeposit.get('transactions').addObject(newTransaction);
        newDeposit.save().then(function (res) {
          fund.get('warrants').addObject(res);
          fund.get('municipalTransactions').addObject(newTransaction);
          return fund.save();
        }).catch(function (err) {
          globalMessage.actionNotification('Error saving warrant to year: ' + err, 'warning');
          var error = store.createRecord('error', {
            url: window.location.href,
            error: err,
            user: user,
            timestamp: moment().valueOf()
          });
          error.save();
        }).then(function () {
          return newTransaction.save();
        }).catch(function (err) {
          globalMessage.actionNotification('Error saving transaction: ' + err, 'warning');
          var error = store.createRecord('error', {
            url: window.location.href,
            error: err,
            user: user,
            timestamp: moment().valueOf()
          });
          error.save();
          return;
        }).then(function () {
          _this.toggleProperty('connectedUser.showFinalizeWarrantMenu');
          globalMessage.actionNotification('warrantRecorded, may take few seconds for transaction to show', 'success');
        }).catch(function (err) {
          globalMessage.actionNotification(err, 'warning');
          var error = store.createRecord('error', {
            url: window.location.href,
            error: err,
            user: user,
            timestamp: moment().valueOf()
          });
          error.save();
          return;
        });
      },
      resetTown: function resetTown(fiscalYear_id, year) {
        var _this = this,
            globalMessage = this.get('globalMessaging'),
            store = this.get('store'),
            r = confirm('Are you sure you wish to reset all account balances and erase transactions for ' + year + '? Like starting from scratch for a new fiscal year.');
        if (r == true) {
          $.get({
            url: "https://us-central1-aacc-603.cloudfunctions.net/dbMunicipalYearOnRequestReset?fiscalYear=" + fiscalYear_id,
            json: true
          }).done(function (data) {
            console.log(data);
            if (data.error) {
              globalMessage.actionNotification(data.error, 'warning');
            } else if (data.success) {
              globalMessage.actionNotification(data.success, 'success');
            }
          });
        } else {
          console.log('you cancelled');
        }
      },
      yearEndClose: function yearEndClose() {
        // Fund Balance =
        // Unexpended appropriations
        // +
        // Excess Revenues Collected (Cash on Hand)
        // -
        // Uncollected Taxes (All or just from the current years warrants)
        // -
        // Liabilities
        var fundInfo = Ember.get(this, 'fundInfo');
        console.log(fundInfo.totalAppropriations, fundInfo.totalExpendituresFinalized, fundInfo.totalRevenueCollected, fundInfo.totalEstimatedRevenue);
        alert('going to cash ' + (fundInfo.totalAppropriations - fundInfo.totalExpendituresFinalized + (fundInfo.totalRevenueCollected - fundInfo.totalEstimatedRevenue)));
      }
    }
  });
});