define('fund-figure-app/components/municipal-handler/account-info-card', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		classNameBindings: ['isActive'],
		router: Ember.inject.service(),
		isActive: false,
		actions: {
			formatAsCurrency: function formatAsCurrency(attr, value, evt) {
				if (value < 1) {
					this.get('account').set(attr, null);
					return;
				}
				var num = value.replace(/,/g, '');
				var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
				this.get('account').set(attr, formated);
				this.get('account').save();
				return;
			},
			activateAccount: function activateAccount(account) {
				var router = this.get('router');
				var fiscalYear = account.get('municipalYear');
				account.set('activated', true);
				var _this = this;
				account.get('stateAccountNumber').then(function (stateAccount) {
					return stateAccount.get('classification');
				}).then(function (classification) {
					if (classification === "Expense") {
						var subAccount = _this.get('store').createRecord('municipal-account', {
							parentAccount: account,
							stateAccountNumber: account.get('stateAccountNumber'),
							activated: true,
							nickname: 'General Expenses',
							accountNumber: '000',
							municipalYear: account.get('municipalYear'),
							municipality: account.get('municipality')
						});

						account.get('subAccounts').addObject(subAccount);
						account.save().then(function (savedAccount) {
							return subAccount.save();
						}).then(function () {
							_this.notifyPropertyChange(account);
							router.transitionTo('municipal-handler.municipal-setup-step-one.edit-account', account);
						}).catch(function (error) {
							console.log(error);
						});
					} else {
						account.save();
					}
				});
				// this.
			}
		}
	});
});