define('fund-figure-app/components/stripe-checkout', ['exports', 'ember-cli-stripe/components/stripe-checkout'], function (exports, _stripeCheckout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stripeCheckout.default;
    }
  });
});