define('fund-figure-app/models/payroll-db', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    payroll_bi_weekly: _emberData.default.attr('boolean'),
    payroll_weekly: _emberData.default.attr('boolean'),
    payroll_twice_month: _emberData.default.attr('boolean'),
    payroll_monthly: _emberData.default.attr('boolean'),
    payroll_pay_period_end: _emberData.default.attr('number'), //period in which to base all future pay period dates from
    payroll_pay_day: _emberData.default.attr('number'), //date in which to base all future pay days on
    municipality: _emberData.default.belongsTo('municipal-db'),
    municipalPayPeriods: _emberData.default.hasMany('municipal-pay-period'),
    municipalYear: _emberData.default.belongsTo('municipal-year')
  });
});