define('fund-figure-app/helpers/reduce-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reduceArray = reduceArray;
  function reduceArray(params /*, hash*/) {
    var keyForNumber = params[1];

    //check is model present
    if (!params[0]) {
      return;
    }
    var array = params[0].toArray();
    //check if array is empty
    if (array.get('length') < 1 || array.get('length') === null) {
      return 0;
    } else if (array.get('length') === 1 && array.get('firstObject.' + keyForNumber) !== null) {
      var json = array.get('firstObject').toJSON();
      if (json.reversal !== null && json.reversal !== undefined) {
        return 0;
      }
      return Number(String(array.get('firstObject.' + keyForNumber)).replace(/,/g, '')) || 0;
    }
    return array.reduce(function (total, r_account) {
      var json = r_account.toJSON();
      if (!r_account.get(keyForNumber) || typeof json.reversal === 'string' && typeof json.reversed !== 'string' || typeof json.reversal !== 'string' && typeof json.reversed === 'string') {
        return total;
      }
      return total + Number(String(r_account.get(keyForNumber)).replace(/,/g, ''));
    }, 0);
  }

  exports.default = Ember.Helper.helper(reduceArray);
});