define('fund-figure-app/components/dashboard/transactions/bank-reconciliation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    reconciledOnly: false,
    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>(%s %v)</red>",
      zero: "%s  0.00"
    },
    // need to change this to get deposits and invoices
    municipalTransactions: Ember.computed('connectedUser.fiscalYear.municipalTransactions.[]', 'statementDate', 'reconciledOnly', function () {
      var reconciledOnly = this.get('reconciledOnly'),
          statementDate = moment(this.get('statementDate')).valueOf(),
          _this = this,
          year = this.get('connectedUser.fiscalYear').toJSON({ includeId: true }),
          query = '' + year.id,

      // beginDate = moment(statementDate).subtract(60, 'days').valueOf(),
      queryDeposits = this.get('store').query('municipalDeposit', {
        orderBy: 'year',
        equalTo: query
      }).then(function (res) {
        return res.toArray().filter(function (deposit) {
          if (deposit.get('reconciled') == reconciledOnly) {
            return true;
          } else {
            return false;
          }
        });
      }).catch(function (err) {
        console.log(err);
        return;
      }),
          queryInvoices = this.get('store').query('municipalInvoice', {
        orderBy: 'year',
        equalTo: query
      }).then(function (res) {
        return res.toArray().filter(function (invoice) {
          if (invoice.get('reconciled') == reconciledOnly) {
            return true;
          } else {
            return false;
          }
        });
      }).catch(function (err) {
        console.log(err);
        return;
      });

      return Promise.all([queryDeposits, queryInvoices]).then(function (res) {
        console.log(res[0], res[1]);
        _this.set('allTransactions', res[0].concat(res[1]));
        return res[0].concat(res[1]);
      });
    }),
    bankTransactions: Ember.computed('selectedBankAccount.bankAccountTransactions.[]', 'statementDate', function () {
      var statementDate = this.get('statementDate');
      if (!this.get('selectedBankAccount.bankAccountTransactions')) {
        return;
      }
      return this.get('selectedBankAccount.bankAccountTransactions').filter(function (transaction) {
        if (!transaction.get('date') || !statementDate) {
          return false;
        }
        if (moment(transaction.get('date')).unix() <= moment(statementDate).unix()) {
          return true;
        } else {
          return false;
        }
      });
    }),
    sortedBankTransactions: Ember.computed.sort('bankTransactions', 'sortDefinition'),
    sortBy: 'date',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),
    actions: {
      addReconciled: function addReconciled(bankTransaction, ops) {
        var ledgerTransaction = ops.target.ledgerTransaction,
            globalMessaging = this.get('globalMessaging');
        ledgerTransaction.get('reconciledBankTransaction').then(function (alreadyReconciled) {
          if (alreadyReconciled) {
            console.log(ledgerTransaction.get('reconciledBankTransaction'));
            globalMessaging.actionNotification('already has a reconciled bank transaction', 'warning');
            return;
          }
          ledgerTransaction.setProperties({ reconciledBankTransaction: bankTransaction, reconciled: true });
          ledgerTransaction.save().then(function (savedLedgerTransaction) {
            bankTransaction.set('reconciled', true);
            bankTransaction.save();
          }).catch(function (error) {
            console.log(error);
          });
        }).catch(function (error) {
          console.log(error);
        });
      },
      removeReconciled: function removeReconciled(ledger, bank) {
        var globalMessaging = this.get('globalMessaging'),
            bankTransaction = this.get('store').findRecord('bank-account-transaction', bank.get('id'));
        ledger.setProperties({ reconciledBankTransaction: null, reconciled: false });
        bankTransaction.then(function (bankAccount) {
          bankAccount.setProperties({ reconciled: false });
          ledger.save().then(function () {
            bankAccount.save();
            globalMessaging.actionNotification('removed reconciliation', 'success');
            return;
          }).catch(function (error) {
            console.log(error);
          });
        });
      }
    }
  });
});