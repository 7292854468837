define('fund-figure-app/components/dashboard/assets/asset-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    assetsArray: [],
    filteredAssets: [],

    didInsertElement: function didInsertElement() {
      this.createNewAsset();
    },


    setAssetsArray: Ember.computed('connectedUser.fiscalYear.municipalAssets', {
      get: function get() {
        var array = this.get('connectedUser.fiscalYear.municipalAssets');
        this.setProperties({ assetsArray: array.toArray(), filteredAssets: array.toArray() });
        return array;
      }
    }),

    createNewAsset: function createNewAsset() {
      var newAsset = this.get('store').createRecord('asset');
      this.set('newAsset', newAsset);
    },


    costAccounts: Ember.computed('connectedUser.currentFund', {
      get: function get() {
        if (!this.get('connectedUser.currentFund')) {
          return;
        }
        var appError = this.get('appError');
        return this.get('connectedUser.currentFund.municipalAccounts').then(function (municipalAccounts) {
          console.log('municipalAccounts', municipalAccounts.toArray().filter(function (a) {
            return a.get('appropriation') == true;
          }));
          return municipalAccounts.toArray().filter(function (a) {
            return a.get('appropriation') == true;
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error getting municipal Accounts for asset invoices to use';
          appError.logError(err, specialMessage);
        });
      }
    }).property('init'),

    assetTypes: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('assetType');
      }
    }),

    actions: {
      clearFilter: function clearFilter() {
        this.set('filteredAssets', this.get('assetsArray'));
      },
      submitFilter: function submitFilter(filterArray) {
        var _this = this;

        var assets = this.get('assetsArray');
        filterArray.forEach(function (filter) {
          switch (filter.filter) {
            case 'byAmount':
              var amount = Number(filter.amount);
              if (filter.logic == "amountGreater") {
                var filtered = assets.filter(function (asset) {
                  return asset.get('amount') > amount;
                });
                assets = filtered;
              }
              if (filter.logic == "amountLess") {
                var _filtered = assets.filter(function (asset) {
                  return asset.get('amount') < amount;
                });
                assets = _filtered;
              }
              if (filter.logic == "amountEquals") {
                var _filtered2 = assets.filter(function (asset) {
                  return asset.get('amount') === amount;
                });
                assets = _filtered2;
              }
              break;
            case 'byDate':
              var beginDate = Number(filter.beginDate);
              var endDate = Number(filter.endDate);
              if (filter.logic == "dateGreater") {
                var _filtered3 = assets.filter(function (asset) {
                  console.log(asset.get('date'));
                  return asset.get('date') > beginDate;
                });
                assets = _filtered3;
              }
              if (filter.logic == "dateLess") {
                var _filtered4 = assets.filter(function (asset) {
                  console.log(asset.get('date'));
                  return asset.get('date') < beginDate;
                });
                assets = _filtered4;
              }
              if (filter.logic == "dateBetween") {
                var _filtered5 = assets.filter(function (asset) {
                  console.log(asset.get('date'));
                  return asset.get('date') > beginDate && asset.get('date') < endDate;
                });
                assets = _filtered5;
              }
              break;
            default:
              break;
          }
          _this.set('filteredAssets', assets);
        });
      },
      toggleAssetMenu: function toggleAssetMenu() {
        this.toggleProperty('showAddAssetMenu');
      },
      toggleAssetType: function toggleAssetType(type) {
        this.get('newAsset').setProperties({ building: false, equipment: false, infrastructure: false, land: false, vehicle: false, cip: false, taxdeed: false });
        this.get('newAsset').toggleProperty(type);
      }
    }
  });
});