define('fund-figure-app/components/dashboard/transactions/ledger-transaction-invoice/invoice-item', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Component.extend({
      moneyFormat: {
         pos: "<green>%s %v</green>",
         neg: "<red>%s %v</red>",
         zero: "%s  0.00"
      },

      willDestroyElement: function willDestroyElement() {
         var item = this.get('transaction');
         this.get('invoiceItems').removeObject(item);
      },

      updateInvoiceItemArray: Ember.computed('invoiceItems', {
         get: function get(key) {
            var item = this.get('transaction');
            this.get('invoiceItems').addObject(item);
         }
      })
   });
});