define('fund-figure-app/components/account-handler/create-with-pin/pin-step-three', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    newUser: Ember.inject.service(),
    error: false,
    errorMessage: '',
    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: true, haveAccountType: true, havePin: false, haveStaffConfirm: false });
      var user = this.get('connectedUser.user');
      var router = this.get('router');

      // if (!user) {
      //   router.transitionTo('account.user-create-with-pin.step-one');
      // }
    },
    keyDown: function keyDown() {
      $(".new-user").keyup(function () {
        if (this.value.length == this.maxLength) {
          var $next = $(this).parent().next().find('.new-user');
          if ($next.length) {
            $next.focus();
          } else {
            $(this).blur();
          }
        }
      });
    },

    actions: {
      findStaff: function findStaff(pin, pin1, pin2, pin3, pin4, pin5, pin6, pin7, pin8, pin9) {
        console.log('in find staff');
        if (!pin || !pin1 || !pin2 || !pin3 || !pin4 || !pin5 || !pin6 || !pin7 || !pin8 || !pin9) {
          this.set('error', true);
          console.log('error with PINS');
          return;
        }
        var _this = this;
        var key = '' + (pin + pin1 + pin2 + pin3 + pin4 + pin5 + pin6 + pin7 + pin8 + pin9);
        var store = this.get('store');
        var router = this.get('router');
        var municipalUsers = store.query('municipalUser', {
          orderBy: 'pin',
          equalTo: '' + key
        });
        municipalUsers.then(function (res) {
          console.log(res);
          if (res.content.length == 0) {
            _this.set('errorMessage', 'We could find no matching staff account with that pin');
            return;
          }
          if (res.content.length > 1) {
            _this.set('errorMessage', 'There is an error with your pin, please have your admin create another one');
            return;
          } else {
            _this.setProperties({ successMessage: 'Staff file found', errorMessage: '' });
            setTimeout(function () {
              _this.get('transitionToStepFour')(res.toArray().get('firstObject'));
            }, 1000);
          }
        }, function (error) {
          _this.setProperties({ errorMessage: 'There was an error we did not forsee, please notify us at help@sokool.today' });
          console.log('Error : ' + error);
        });
      }
    }
  });
});