define('fund-figure-app/components/dashboard/vendorss/invoices/create-manifest/manifest-preview-detailed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    invoice_efficiency: Ember.computed('invoiceManifest.[]', {
      get: function get(key) {
        var invoice = this.get('invoice'),
            today = moment(),
            date = moment(invoice.get('date'), 'x').endOf('day'),
            date_received = moment(invoice.get('date_received'), 'x').endOf('day'),
            date_due = moment(invoice.get('date_due'), 'x').endOf('day'),
            totalDays = date_due.diff(date, 'days', true),
            elapsedDays = date_due.diff(today, 'days', true);
        return Math.round(elapsedDays / totalDays * 100) + '%';
      }
    })
  });
});