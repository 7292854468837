define('fund-figure-app/components/reports/town-reports/account-balance-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    checkDate: Ember.computed('model.dates', {
      get: function get(key) {
        if (this.get('model.dates')) {
          console.log('model dates is true');
          this.set('connectedUser.dates', true);
        } else {
          this.set('connectedUser.dates', false);
        }
      }
    })

  });
});