define('fund-figure-app/components/helper/file-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appError: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    actions: {
      editView: function editView() {
        this.toggleProperty('edit-view');
      },
      save: function save(model) {
        this.get('saveFileChanges')(model);
        this.toggleProperty('edit-view');
      },
      delete: function _delete(model) {
        this.get('deleteFile')(model);
        this.toggleProperty('edit-view');
      },
      viewFile: function viewFile(documentObject) {
        console.log(documentObject);
        Ember.set(this, 'connectedUser.fileToView', documentObject);
        Ember.set(this, 'connectedUser.modalName', 'modals/view/file-view');
        Ember.set(this, 'connectedUser.showModal', true);
      }
    }
  });
});