define('fund-figure-app/components/dashboard/transactions/pay-invoice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var now = moment().format("MM/DD/YYYY");
      this.set('transaction.transactionDate', now);
    },


    outstandingInvoices: Ember.computed('connectedUser.fiscalYear.municipalTransactions.[]', function () {
      var _this = this;

      return this.get('connectedUser.fiscalYear.municipalTransactions').then(function (transactions) {
        return transactions.filter(function (d) {
          var dd = d.toJSON();
          if (dd.invoice && !dd.p_invoice) {
            return true;
          } else {
            return false;
          }
        });
      }).then(function (outstanding_invoices) {
        console.log(outstanding_invoices);
        _this.set('invoices', outstanding_invoices);
        return outstanding_invoices;
      }).catch(function (err) {
        console.log(err);
      });
    }),
    actions: {
      printCheck: function printCheck(transaction) {
        var store = this.get('store'),
            cashAccount = store.query('municipal-account', {
          orderBy: 'stateAccountNumber',
          equalTo: '-L4RYdcf9FLhiOx5roXP'
        });
        transaction.set('transactDebit', transaction.get('r_invoice.tran'));
        //print check, create pay invoice transaction, save pay invoice to received invoice transaction
        //
        //.1 accounts payable (ie transactCredit from received invoice) is now debit for pay invoice
        transaction.get('r_invoice').then(function (r_invoice) {
          r_invoice.get('transactCredit');
        }).then(function (t_credit) {
          transaction.set('transactDebit', t_credit);
        }).catch(function (err) {
          console.log(err);
        });

        cashAccount.then(function (t_debit) {
          transaction.set('transactCredit', t_debit);
        }).catch(function (err) {
          console.log(err);
        });

        //.2 find towns 1010 account and set as credit account
      }
    }
  });
});