define('fund-figure-app/components/dashboard/budget/parent-account-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['list'],
    editAll: false,

    watchEdit: Ember.observer('editAll', function () {
      var editAll = this.get('editAll');
      if (editAll) {
        return;
      }
      var account = this.get('account');
      account.save().then(function () {}, function (error) {
        console.log('Error : ' + error);
      });
    }),
    budgetTotal: Ember.computed('account.subAccounts.[].budget', 'account.budget', function () {
      var total = 0;
      var _this = this;
      var model = this.get('account');
      var parentAccount = null;
      return model.get('subAccounts').then(function (subAccounts) {
        if (subAccounts.get('length') >= 1) {
          console.log('we have sub accounts');
          return subAccounts.forEach(function (account) {
            if (!account.get('budget')) {
              return;
            }
            if (typeof account.get('budget') === "undefined") {
              return;
            }
            parentAccount = model;
            total = total + Number(account.get('budget').replace(/,/g, ''));
          });
        } else {
          if (!_this.get('model.budget')) {
            return;
          }
          if (typeof _this.get('model.budget') === "undefined") {
            return;
          }
          return total = +Number(_this.get('model.budget').replace(/,/g, ''));
        }
      }).then(function () {
        _this.set('totalBudget', total);
        return total;
      }).then(function (total) {
        if (parentAccount) {
          console.log('parentAccount');
          model.set('budget', total);
          model.save();
        } else {
          model.set('budget', total);
          model.save();
        }
      }).catch(function (error) {
        console.log(error);
      });
    }),
    updateWarrantString: Ember.computed('account.subAccounts.@each.warrantArticle', function () {
      var warrantString = '',
          _this = this;
      return this.get('account.subAccounts').then(function (subAccounts) {
        var index = 0,
            length = subAccounts.get('length');
        console.log(index, length);
        subAccounts.forEach(function (account) {
          warrantString += account.get('warrantArticle') + ' ';
          if (index == length - 1) {
            _this.set('account.warrantArticle', null);
            _this.get('account').save();
            return _this.set('warrantString', warrantString.trim().replace(/ /gi, ", "));
          }
          index++;
        });
      }).then(function () {
        console.log(_this.get('warrantArticle'));
      }).catch(function (err) {
        console.log(err);
      });
    }),
    didInsertElement: function didInsertElement() {
      this.get('editAll_toggle');
    },
    doubleClick: function doubleClick() {
      this.toggleProperty('editParent');
      this.set('subAccounts', true);
      var account = this.get('account'),
          globalMessage = this.get('globalMessaging');
      if (account.hasDirtyAttributes) {
        account.save();
      } else {}
    },

    editAll_toggle: Ember.computed('editAll', function () {
      var editAll = this.get('editAll'),
          account = this.get('account');
      if (editAll) {
        this.set('editParent', true);
        this.set('subAccounts', true);
      } else {
        this.set('editParent', false);
        this.set('subAccounts', false);
        // if(account.hasDirtyAttributes){
        account.save().catch(function (err) {
          console.log(err);
        });
        // }			
      }
    }).property('editAll'),
    actions: {
      toggleSubAccounts: function toggleSubAccounts() {
        this.toggleProperty('subAccounts');
      },
      addSubAccount: function addSubAccount(account) {
        console.log(account);
        this.get('addSub')(account);
      },
      formatAsCurrency: function formatAsCurrency(attr, model, value, evt) {
        if (value < 1) {
          model.set(attr, null);
          this.notifyPropertyChange('account.budget');
          return;
        }
        var num = value.replace(/,/g, '');
        var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        model.set(attr, formated);
        // model.save();
        this.notifyPropertyChange('account.budget');
        // this.notifyPropertyChange('getRevenueAccountBalances');
        return;
      },
      notifyBudget: function notifyBudget() {
        console.log('notify budget parent');
        this.notifyPropertyChange('account.budget');
      }
    }
  });
});