define('fund-figure-app/components/dashboard-support/conversations/user-conversations-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),

    messages: null,

    sortedFeedback: Ember.computed('feedbackAll.@each.addressed', 'messages.@each.addressed', function () {
      if (!this.get('feedbackAll')) {
        return;
      }
      return this.get('feedbackAll').toArray().sort(function (a, b) {
        if (a.get('timestamp') < b.get('timestamp')) {
          return 1;
        }
        if (a.get('timestamp') > b.get('timestamp')) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }).sort(function (a, b) {
        if (a.get('addressed')) {
          return 1;
        } else {
          return -1;
        }
      });
    }),

    actions: {
      newConversation: function newConversation(mUser) {
        var user = this.get('connectedUser.currentAccount'),
            store = this.get('store'),
            municipality_id = this.get('connectedUser.fiscalYear').toJSON().municipality,
            municipality = store.find('municipalDb', municipality_id),
            newFeedback = store.createRecord('feedback', {
          municipalUser: mUser,
          municipality: municipality,
          timestamp: moment().valueOf()
        }),
            newChatRoom = store.createRecord('feedback-chat-room', {
          name: 'Name...'
        });

        newChatRoom.get('members').addObjects([mUser, user]);
        newFeedback.set('feedbackChatRoom', newChatRoom);
        newFeedback.save().then(function (savedFeedback) {
          mUser.get('conversations').addObject(savedFeedback);
          newChatRoom.save().then(function () {
            mUser.save().catch(function (err) {
              console.log(err);
            });
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }

  });
});