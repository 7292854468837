define('fund-figure-app/components/dashboard/payroll/employee-timecard-week-day-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    payroll: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    PTO: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('municipal-pay-time-off-options');
      }
    }),
    actions: {
      addHours: function addHours(timeCard, day, input) {
        // console.log(timeCard, input);
        var store = this.get('store'),
            payPeriod = this.get('payroll.current_Period'),
            newDay = store.createRecord('municipal-time-card-day', {
          municipalTimeCard: timeCard,
          employee: timeCard.get('employee'),
          municipalPayPeriod: payPeriod,
          hoursWorked: input,
          date: day
        });
        timeCard.get('municipalTimeCardDays').addObject(newDay);
      },
      adjustHours: function adjustHours(timeCard, timeCardDay, input, evt) {
        console.log('sending notifications');
        this.get('notifyDayAdded')();
      },
      toggleNotes: function toggleNotes() {
        this.toggleProperty('showNotes');
      },
      openNotes: function openNotes(input, evt) {
        console.log('openNotes', input, evt);
        if (evt.key == "n") {
          evt.preventDefault();
          console.log('open Notes');
          this.toggleProperty('showNotes');
          $(".content-editable").focus();
          // return false;
        }
      },
      addTime: function addTime(timeCard, day) {
        var store = this.get('store'),
            payPeriod = this.get('payroll.current_Period'),
            newDay = store.createRecord('municipal-time-card-day', {
          municipalTimeCard: timeCard,
          employee: timeCard.get('employee'),
          municipalPayPeriod: payPeriod,
          date: day
        });
        timeCard.get('municipalTimeCardDays').addObject(newDay);
      },
      deleteIfZero: function deleteIfZero(timeCard, timeCardDay) {
        if (!timeCardDay) {
          return;
        }
        if (timeCardDay.get('hoursWorked') == "0") {
          timeCard.get('municipalTimeCardDays').removeObject(timeCardDay);
          timeCardDay.destroyRecord();
          this.get('notifyDayAdded')();
        }
      }
    }
  });
});