define('fund-figure-app/components/dashboard-assets/asset/create-asset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["width90"],
    assetInfo: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    assetModel: null,
    undoObjects: [],

    didInsertElement: function didInsertElement() {
      this.set('vehicle', true);
      this.createNewAsset();
    },
    willDestroyElement: function willDestroyElement() {
      // get(this, 'assetModel').deleteRecord();
      // get(this, 'assetModel').save();
      Ember.set(this, 'assetModel', null);
    },

    municipalDepartments: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'store').findAll('municipalDepartment');
      }
    }),
    municipalExpenses: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'fundInfo.appropriations').filter(function (d) {
          if (d.toJSON().parentAccount) {
            return false;
          } else {
            return true;
          }
        });
      }
    }),
    assetDepreciations: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'store').findAll('assetDepreciation');
      }
    }),
    assetModels: Ember.computed('assetModel.depAsset', {
      get: function get() {
        return Ember.get(this, 'assetModel.depAsset').then(function (res) {
          console.log(Object.keys(res.get('assetModel')));
          return Object.keys(res.get('assetModel'));
        });
      }
    }),
    checked: Ember.computed('assetModel', function () {
      console.log('assetModel changed');
    }),
    createNewAsset: function createNewAsset() {
      var store = this.get('store'),
          newAsset = store.createRecord('asset', {
        id: null,
        timestamp: moment().valueOf(),
        fiscalYearAdded: Ember.get(this, 'connectedUser.fiscalYear'),
        municipalFund: Ember.get(this, 'connectedUser.currentFund'),
        municipality: Ember.get(this, 'connectedUser.fiscalYear.municipality'),
        vehicle: Ember.get(this, 'vehicle'),
        land: Ember.get(this, 'land'),
        building: Ember.get(this, 'building'),
        infrastructure: Ember.get(this, 'infrastructure'),
        taxDeeded: Ember.get(this, 'taxDeeded'),
        equipment: Ember.get(this, 'equipment'),
        construction: Ember.get(this, 'construction')
      });
      Ember.set(this, 'assetModel', newAsset);
    },

    actions: {
      toggleAsset: function toggleAsset(asset) {
        var modelChange = this.get('assetModel.' + asset);
        this.setProperties({ vehicle: false, land: false, building: false, infrastructure: false, taxDeeded: false, equipment: false, construction: false });
        this.assetModel.setProperties({ vehicle: false, land: false, building: false, infrastructure: false, taxDeeded: false, equipment: false, construction: false });
        this.set(asset, true);
        Ember.set(this.assetModel, '' + asset, true);
      },
      toggleShowOpps: function toggleShowOpps() {
        this.toggleProperty('showOpps');
      },

      // undo(object, assetModel) {
      //   let undoObjects = this.get('undoObjects');
      //   undoObjects.removeObject(object);
      //   assetModel.addObject(object.model);
      //   if (undoObjects.length < 1) {
      //     this.set('showOpps', false);
      //   }
      // },
      focusAssetCost: function focusAssetCost(value) {
        Ember.set(this, 'focus-' + value, true);
        setTimeout(function () {
          document.getElementById(value).focus();
        }, 500);
      },
      focusAssetValueNew: function focusAssetValueNew(value) {
        Ember.set(this, 'focus-' + value, true);
        setTimeout(function () {
          document.getElementById(value).focus();
        }, 500);
      },
      formatAssetCost: function formatAssetCost(value) {
        Ember.set(this, 'focus-' + value, false);
        console.log('format currency', value);
        // set(this, 'transferAmount', formatMoney(value));
      },
      formatAssetValueNew: function formatAssetValueNew(value) {
        Ember.set(this, 'focus-' + value, false);
        console.log('format currency', value);
        // set(this, 'transferAmount', formatMoney(value));
      },

      // saveFile(fileInfo) {
      //   let _this = this,
      //     appError = this.get('appError'),
      //     model = this.get('deposit');
      //   this.get('documentManager')
      //     .saveDocument(fileInfo, 'deposits', true)
      //     .then(res => {
      //       model.get('documents').addObject(res);
      //       model.save();
      //     })
      //     .catch(err => {
      //       console.log(err);
      //       let specialMessage = `error while saving document`;
      //       appError.logError(err, specialMessage);
      //     })
      // },
      // deleteFile(file) {
      //   let model = this.get('deposit'),
      //     municipalFund = this.get('connectedUser.currentFund'),
      //     appError = this.get('appError'),
      //     deleteDocument = this.get('documentManager'),
      //     _this = this;
      //   model
      //     .get('documents')
      //     .removeObject(file);
      //   model
      //     .save()
      //     .then(() => {
      //       console.log('file deleted from fund list');
      //     })
      //     .catch(err => {
      //       console.log(err);
      //       let specialMessage = `error saving the deposit after removing document`;
      //       appError.logError(err, specialMessage);
      //     })
      // },

      notifyChange: function notifyChange() {
        console.log('assetModel changed in parent');
        this.notifyPropertyChange('assetModel');
      },
      closeModal: function closeModal() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalName', null);
      },
      createAsset: function createAsset() {
        var _this = this;

        Ember.get(this, 'assetInfo').createAsset(Ember.get(this, 'assetModel')).then(function (newAsset) {
          _this.createNewAsset();
        }).catch(function (err) {
          console.log(error);
        });
        // let newAsset = get(this, 'assetModel'),
        //   globalMessage = get(this, 'globalMessaging'),
        //   bigBrother = get(this, 'bigBrother'),
        //   _this = this;
        // newAsset
        //   .save()
        //   .then(savedAsset => {
        //     get(this, 'connectedUser.currentFund.assetDb')
        //       .then(assetDb => {
        //         assetDb.get('assets').addObject(savedAsset);
        //         return assetDb.save()
        //       })
        //     get(this, 'assetInfo.allAssets').addObject(savedAsset);
        //     get(this, 'assetInfo.notifyAssetChanged');
        //     let desc = `Undo changes made to  `, //only used if sending to undo service
        //       logDesc = `added asset ${newAsset.get('description')}`, //description of what changed
        //       undoObject = { model: newAsset, desc: desc, changes: null, logDesc: logDesc };
        //     bigBrother.logChange(undoObject);
        //     globalMessage.actionNotification(`Saved New Asset ${newAsset.get('description')}`, 'success');
        //     this.createNewAsset();
        //   })
      }
    }
  });
});