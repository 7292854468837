define('fund-figure-app/components/dashboard-support/manage-warrant-articles/warrant-article-create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    router: Ember.inject.service(),
    articleModel: null,

    didInsertElement: function didInsertElement() {
      var store = this.get('store'),
          state = this.get('state');
      var newModel = store.createRecord('warrant-article-template', {
        state: state
      });
      this.set('articleModel', newModel);
    },

    actions: {
      save: function save(newArticle, state) {
        var router = this.get('router');
        newArticle.save().then(function (savedArticle) {
          router.transitionTo('dashboard-support.manage-warrant-articles.state-warrant-articles', state.id);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving new article with state info'.appError.logError(err, specialMessage);
        });
      }
    }
  });
});