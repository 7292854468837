define('fund-figure-app/components/dashboard/invoices-manifests/invoices/invoices-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [""],
    invoicesDueNow: [],
    invoicesDueSoon: [],
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    invoices: [],
    invoices_all: true,
    invoicesAll: [],

    outstandingInvoices: Ember.computed('connectedUser.currentFund.municipalTransactions.[]', 'connectedUser.currentFund', function () {
      var _this = this,
          invoicesAll = this.get('invoicesAll'),
          invoices = this.get('invoices');
      invoices.clear();
      invoicesAll.clear();
      var fund = this.get('connectedUser.currentFund').toJSON({ includeId: true }),
          query = '' + fund.id,
          queryInvoices = this.get('store').query('municipalInvoice', {
        orderBy: 'municipalFund',
        equalTo: query
      }).then(function (res) {
        return res.toArray().filter(function (invoice) {
          if (!invoice.toJSON().manifest) {
            return true;
          } else {
            return false;
          }
        }).toArray();
      }).then(function (array) {
        console.log(array);
        _this.set('invoicesAll', array);
        _this.set('invoices', array);
      }).catch(function (err) {
        console.log(err);
        return;
      });
      return Promise.all([queryInvoices]).then(function (res) {
        return res[0];
      });
    }),
    actions: {
      changeInvoices: function changeInvoices(newInvoiceList, btn) {
        this.setProperties({ invoices_all: false, invoices_now: false, invoices_soon: false });
        this.set(btn, true);
        this.set('invoices', newInvoiceList);
      }
    }
  });
});