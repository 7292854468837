define('fund-figure-app/controllers/dashboard-assets/asset/cip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    editFund: false,
    assetInfo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    saveCIP: Ember.computed('model.cipFund', {
      get: function get() {
        var _this = this;

        Ember.get(this, 'model').save().then(function (res) {
          console.log('saved');
          Ember.set(_this, 'editFund', false);
          return res;
        }).catch(function (err) {
          throw new Error(err);
        });
      }
    }),
    // getGanttData: computed('model', {
    //   get() {
    //     get(this, 'assetInfo').singleAssetGanttData(get(this, 'model'));
    //   }
    // }),
    actions: {
      saveToFund: function saveToFund(model, cipFund) {
        console.log(cipFund, model);
        model.set('cipFund', cipFund);
        // model.save();
        // set(this, 'editFund', false);
      },
      saveToCipParentAccount: function saveToCipParentAccount(model, parentAccount) {
        model.set('cipParentAccount', parentAccount);
        // model.save();
        // set(this, 'editFund', false);
      },
      saveToCipSubAccount: function saveToCipSubAccount(model, subAccount) {
        model.set('cipSubAccount', subAccount);
        // model.save();
        // set(this, 'editFund', false);
      },
      saveAsset: function saveAsset(model) {
        var _this2 = this;

        Ember.get(this, 'assetInfo').saveAsset(model).then(function (res) {
          Ember.set(_this2, 'editFund', false);
        }).catch(function (err) {
          model.rollbackAttributes();
        });
      },
      toggleCIPFund: function toggleCIPFund() {
        this.toggleProperty('editFund');
      },
      isParentAccount: function isParentAccount(a) {
        if (a.toJSON().parentAccount) {
          return false;
        } else {
          return true;
        }
      }
    }
  });
});