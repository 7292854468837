define("fund-figure-app/components/reports/ms-636/appropriation-items", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    moneyFormat: {
      pos: "%s %v",
      neg: "-%s %v",
      zero: "$0"
    },

    actions: {
      /**
       * Used for reduce of array of tax impact and article amount
       * @param  {[type]} val  [description]
       * @param  {[type]} val1 [description]
       * @return {[type]}      [description]
       */
      sum: function sum(val, val1) {
        if (!val1 || (typeof val1 === "undefined" ? "undefined" : _typeof(val1)) === undefined || val1 === null) {
          return val;
        }
        return +val + +val1;
      }
    }
  });
});