define('fund-figure-app/components/reports/bubble-graph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    municipalAccounts: null,

    fiscalAccounts: Ember.computed('connectedUser.currentFund.municipalAccounts', function () {
      var currentFund = this.get('connectedUser.currentFund');
      var _this = this;
      if (!currentFund) {
        return;
      }
      return currentFund.get('municipalAccounts').then(function (municipalAccounts) {
        return municipalAccounts.filter(function (account) {
          var json = account.toJSON();
          if (Number(json.budget) < 1 || !json.budget || json.parentAccount) {
            return false;
          }
          if (json.classification !== 'Expense') {
            return false;
          }
          return true;
        });
      }).then(function (expenseAccounts) {
        _this.createDataSet(expenseAccounts);
        return true;
      }).catch(function (error) {
        console.log(error);
      });
    }),
    createDataSet: function createDataSet(dataModels) {
      if (dataModels.length < 1) {
        return;
      }
      var index = 0,
          _this = this,
          array = [];
      dataModels.forEach(function (d) {
        var JSON = d.toJSON();
        d.get('stateAccountNumber').then(function (res) {
          var object = {};
          if (JSON.nickname) {
            object['accountName'] = JSON.nickname;
          } else {
            object['accountName'] = res.toJSON().friendlyName;
          }
          object['source'] = JSON.source;
          object['model'] = d;
          object['budget'] = JSON.budget;
          array.push(object);
          index++;
          if (index == dataModels.length - 1) {
            _this.set('municipalAccounts', array);
            return array;
          }
        });
      });
    },

    actions: {
      nodeClicked: function nodeClicked(d) {
        var municipalAccounts = this.get('municipalAccounts');
        console.log(d.model.toJSON().subAccounts);
        if (d.model.toJSON().subAccounts.length > 1) {
          municipalAccounts.clear();
          // data.push({d});       
          // restart();
          d.model.get('subAccounts').forEach(function (sub) {
            var JSON = sub.toJSON();
            var object = {};
            console.log('sub', JSON);
            if (JSON.nickname) {
              object['accountName'] = JSON.nickname;
            } else {
              object['accountName'] = 'No Name';
            }
            object['model'] = sub;
            object['budget'] = JSON.budget.replace(/,/g, '');
            // subArray.push(object);
            municipalAccounts.push(object);
          });
          if (municipalAccounts.length == d.model.toJSON().subAccounts.length) {
            console.log('restart');
            municipalAccounts.push(d);
            this.notifyPropertyChange('municipalAccounts');
            // _this.set('data', subArray);
            // restart();
          }
        } else {
          return;
        }
      },
      canvasClicked: function canvasClicked(d) {
        console.log(d);
        this.notifyPropertyChange('connectedUser.currentFund.municipalAccounts');
        // this.get('fiscalAccounts');
      }
    }
  });
});