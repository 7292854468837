define('fund-figure-app/components/account-handler/vendor-setup/step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    router: Ember.inject.service(),
    vendors_DB: null,

    vendors: Ember.computed('connectedUser.currentAccount.municipality.municipalSetting.vendor_db_personal', 'vendors_DB.vendors', function () {
      var municipality = null,
          _this = this;
      if (!this.get('connectedUser.currentAccount')) {
        return;
      }
      return this.get('connectedUser.currentAccount.municipality').then(function (muni) {
        municipality = muni;
        return muni.get('municipalSetting');
      }).then(function (muni_setting) {
        var isPersonal = muni_setting.get('vendor_db_personal');
        if (isPersonal) {
          return municipality.get('municipalVendorsDb');
        } else {
          return municipality.get('globalVendorsDb');
        }
      }).then(function (vendor_db) {
        if (!vendor_db) {
          throw 'No Vendor DB for option stated';
        }
        _this.set('vendors_DB', vendor_db);
        return vendor_db.get('vendors');
      }).then(function (vendors) {
        return vendors;
      }).catch(function (err) {
        console.log(err);
      });
    }),

    actions: {
      createVendor: function createVendor(vendorName) {
        var _this = this,
            vendor = _this.get('store').createRecord('vendor', {
          businessName: vendorName
        });
        _this.set('selectedVendor', vendor);
      },
      save: function save(vendor, vendor_db) {
        var globalMessage = this.get('globalMessaging'),
            _this = this;

        return vendor.save().then(function (res) {
          vendor_db.get('vendors').addObject(res);
          return vendor_db.save();
        }).then(function () {
          var string = vendor.get('businessName') + ' has been saved';
          globalMessage.actionNotification(string, 'success');
          _this.set('selectedVendor', null);
          console.log('vendor personal saved');
        }).catch(function (err) {
          console.log(err);
        });
      },
      next: function next(muni) {
        var _this2 = this;

        var user = this.get('connectedUser.currentAccount');

        user.set('is_new', 'account-handler.account-create-step-six/' + muni.get('id'));
        return user.save().then(function () {
          return _this2.get('router').transitionTo('account-handler.account-create-step-six', muni);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});