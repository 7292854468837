define('fund-figure-app/components/dashboard/settings/manage-users/add-user/user-role', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    appError: Ember.inject.service(),
    initial: true,
    specialSetup: false,

    transition: function transition(model) {
      if (this.get('specialSetup')) {
        console.log('specialTransition');
        return this.get('saveNewUser')();
      }
      this.get('connectedUser').setProperties({
        step_one: true,
        step_two: true,
        step_three: true,
        // nextRoute: 'dashboard.settings.manage-users.add-user.user-role',
        previousRoute: 'dashboard.settings.manage-users.add-user.user-role'
      });
      this.get('router').transitionTo('dashboard.settings.manage-users.add-user.user-verify', model);
    },


    actions: {
      roleChange: function roleChange(role) {
        var _this = this;
        _this.setProperties({ initial: false, support_staff: false, dept_heads: false, finance_staff: false, avitar: false });
        _this.setProperties({ budget_committee: false, dept_assistant: false, admin_assistant: false });
        _this.setProperties({ select_board: false, dept_police: false, dept_fire: false, dept_highway: false, dept_assessing: false });
        _this.setProperties({ administrator: false, accountant: false });
        _this.setProperties({ super_user: false, avitar_support_staff: false });
        _this.set(role, true);
      },
      subRole: function subRole(_subRole) {
        var _this = this;
        _this.setProperties({ budget_committee: false, dept_assistant: false, admin_assistant: false });
        _this.setProperties({ select_board: false, dept_police: false, dept_fire: false, dept_highway: false, dept_assessing: false });
        _this.setProperties({ administrator: false, accountant: false });
        _this.setProperties({ super_user: false, avitar_support_staff: false });
        _this.set(_subRole, true);
      },
      saveStaffRole: function saveStaffRole(model, support_staff, budget_committee, dept_assistant, admin_assistant, dept_heads, select_board, assistant, dept_police, dept_fire, dept_highway, dept_assessing, finance_staff, administrator, accountant, avitar, avitar_support_staff, super_user) {
        var _this = this,
            appError = this.get('appError'),
            specialSetup = this.get('specialSetup'),
            text = "",
            possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 10; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        if (specialSetup) {
          console.log('specialSetup');
          console.log(model);
          console.log(avitar, super_user);
          if (support_staff) {
            if (budget_committee) {
              model.setProperties({ role: 49, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (dept_assistant) {
              model.setProperties({ role: 48, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (admin_assistant) {
              model.setProperties({ role: 47, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            }
          } else if (dept_heads) {
            if (select_board) {
              model.setProperties({ role: 50, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (dept_police) {
              model.setProperties({ role: 51, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (dept_fire) {
              model.setProperties({ role: 52, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (dept_highway) {
              model.setProperties({ role: 53, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (dept_assessing) {
              model.setProperties({ role: 54, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            }
          } else if (finance_staff) {
            if (administrator) {
              model.setProperties({ role: 68, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (accountant) {
              model.setProperties({ role: 67, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            }
          } else if (avitar) {
            console.log('avitar');
            if (super_user) {
              model.setProperties({ role: 99, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            } else if (avitar_support_staff) {
              model.setProperties({ role: 90, pin: text });
              model.save().then(function () {
                _this.transition();
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving newUser model with role info';
                appError.logError(err, specialMessage);
              });
            }
          }
        } else {
          this.get('connectedUser.currentAccount.municipality').then(function (municipality) {
            if (support_staff) {
              if (budget_committee) {
                model.setProperties({ municipality: municipality, role: 49, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (dept_assistant) {
                model.setProperties({ municipality: municipality, role: 48, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (admin_assistant) {
                model.setProperties({ municipality: municipality, role: 47, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              }
            } else if (dept_heads) {
              if (select_board) {
                model.setProperties({ municipality: municipality, role: 50, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (dept_police) {
                model.setProperties({ municipality: municipality, role: 51, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (dept_fire) {
                model.setProperties({ municipality: municipality, role: 52, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (dept_highway) {
                model.setProperties({ municipality: municipality, role: 53, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (dept_assessing) {
                model.setProperties({ municipality: municipality, role: 54, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              }
            } else if (finance_staff) {
              if (administrator) {
                model.setProperties({ municipality: municipality, role: 68, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (accountant) {
                model.setProperties({ municipality: municipality, role: 67, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              }
            } else if (avitar) {
              if (super_user) {
                model.setProperties({ municipality: municipality, role: 99, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              } else if (avitar_support_staff) {
                model.setProperties({ municipality: municipality, role: 90, pin: text });
                model.save().then(function (savedStaff) {
                  municipality.get('municipalUsers').addObject(savedStaff);
                  municipality.save().then(function () {
                    _this.transition(savedStaff);
                  });
                });
              }
            }
          });
        }
      }
    }
  });
});