define('fund-figure-app/routes/dashboard/settings/manage-funds/fund-create', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return this.store.createRecord('municipal-fund');
		}
	});
});