define('fund-figure-app/components/dashboard/personnel/vendors/vendor/vendor-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    actions: {
      save: function save(vendor) {
        this.get('save')(vendor);
      },
      delete: function _delete(vendor) {
        this.get('delete')(vendor);
      },
      cancel: function cancel() {
        this.get('cancel')();
      }
    }
  });
});