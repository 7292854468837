define('fund-figure-app/components/dashboard-assets/asset/edit-asset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["width90"],
    assetInfo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    fundInfo: Ember.inject.service(),
    store: Ember.inject.service(),
    municipalDepartments: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'store').findAll('municipalDepartment');
      }
    }),
    municipalExpenses: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'fundInfo.appropriations').filter(function (d) {
          if (!d.toJSON().parentAccount) {
            return true;
          } else {
            return false;
          }
        });
      }
    }),
    assetDepreciations: Ember.computed('', {
      get: function get() {
        return Ember.get(this, 'store').findAll('assetDepreciation');
      }
    }),
    assetModels: Ember.computed('assetInfo.currentAsset.depAsset', {
      get: function get() {
        return Ember.get(this, 'assetInfo.currentAsset.depAsset').then(function (res) {
          console.log(res);
          console.log(Object.keys(res.get('assetModel')));
          return Object.keys(res.get('assetModel'));
        });
      }
    }),
    assetModel: Ember.computed('assetInfo.currentAsset', {
      get: function get() {
        return Ember.get(this, 'assetInfo.currentAsset');
      }
    }),
    actions: {
      closeModal: function closeModal() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalName', null);
      },
      saveAsset: function saveAsset(asset) {
        var _this = this;

        return Ember.get(this, 'assetInfo').saveAsset(asset).then(function (res) {
          Ember.set(_this, 'assetModel', res);
          return res;
        });
      }
    }
  });
});