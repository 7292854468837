define('fund-figure-app/components/dashboard-support/dashboard-index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		connectedUser: Ember.inject.service(),
		store: Ember.inject.service(),
		userList: [],

		feedbackSent: Ember.computed('connectedUser.currentAccount', 'connectedUser.loading', {
			get: function get(key) {
				return this.get('store').findAll('feedback', { include: 'feedbackChatRoom' });
			}
		}),
		queryUsers: Ember.computed('connectedUser.currentAccount', 'userList', 'usersFound', {
			get: function get() {
				var _this = this;
				var promise1 = this.get('store').findAll('municipal-user', { include: 'user' }).then(function (res) {
					return res.toArray();
				}).catch(function (err) {
					console.log(err);
				});

				return Promise.all([promise1]).then(function (res) {
					_this.set('usersFound', res[0]);
					return res[0];
				}).catch(function (err) {
					console.log(err);
				});
			}
		}),

		actions: {
			getUsers: function getUsers() {
				console.log(this.get('municipalUsers'));
			}
		}

	});
});