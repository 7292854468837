define('fund-figure-app/components/helper/date-range-global', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    classNames: ['relative'],
    showDates: false,
    foo: null,
    range: { start: null, end: null },

    // setRange: computed('connectedUser.beginDate', 'connectedUser.endDate', {
    //   get() {}
    // }).property('init', 'connectedUser.endDate'),

    didInsertElement: function didInsertElement() {
      var beginDate = this.get('connectedUser.beginDate'),
          endDate = this.get('connectedUser.endDate');
      this.setProperties({ range: { start: moment(beginDate).toDate(), end: moment(endDate).toDate() }, foo: moment(beginDate).toDate() });
      console.log('from date global', moment(beginDate).toDate());
    },


    actions: {
      showDates: function showDates() {
        this.toggleProperty('showDates');
      },
      selectDate: function selectDate(date) {
        console.log(date);
        var beginDate = date.start,
            endDate = date.end,
            range = this.get('range'),
            connectedUser = this.get('connectedUser');
        connectedUser.setProperties({ beginDate: beginDate, endDate: endDate });
        this.setProperties({ range: { start: beginDate, end: endDate }, foo: beginDate, thisMonth: false, threeMonthsToDate: false, yearToDate: false });
      },
      thisMonth: function thisMonth() {
        var beginDate = moment().startOf('month'),
            endDate = moment().endOf('month'),
            range = this.get('range'),
            connectedUser = this.get('connectedUser');
        connectedUser.setProperties({ beginDate: beginDate, endDate: endDate });
        this.setProperties({ range: { start: beginDate, end: endDate }, foo: beginDate, thisMonth: true, threeMonthsToDate: false, yearToDate: false });
        this.toggleProperty('showDates');
      },
      threeMonthsToDate: function threeMonthsToDate() {
        var beginDate = moment().subtract(3, 'months').startOf('month'),
            endDate = moment(),
            range = this.get('range'),
            connectedUser = this.get('connectedUser');
        connectedUser.setProperties({ beginDate: beginDate, endDate: endDate });
        this.setProperties({ range: { start: beginDate, end: endDate }, foo: beginDate, thisMonth: false, threeMonthsToDate: true, yearToDate: false });
        this.toggleProperty('showDates');
      },
      yearToDate: function yearToDate() {
        var beginDate = moment(Ember.get(this, 'connectedUser.fiscalYear.year'), 'YYYY').startOf('year'),
            endDate = moment(),
            range = this.get('range'),
            connectedUser = this.get('connectedUser');
        connectedUser.setProperties({ beginDate: beginDate, endDate: endDate });
        this.setProperties({ range: { start: beginDate, end: endDate }, foo: beginDate, thisMonth: false, threeMonthsToDate: false, yearToDate: true });
        this.toggleProperty('showDates');
      },
      showPreviousYear: function showPreviousYear() {
        this.toggleProperty('showPreviousYear');
      }
    }
  });
});