define('fund-figure-app/components/account-handler/add-account-info', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		accountYearsLastObject: null,
		currentAccount: null,
		accountIndex: 0,
		message: 'Creating current year and adding accounts',

		accountYears: Ember.computed('municipality', function () {
			var _this = this;
			return this.get('municipality.municipalYearsDb').then(function (mYearDb) {
				return mYearDb.get('years');
			}).then(function (years) {
				_this.set('accountYearsLastObject', years.get('lastObject'));
				return years.toArray();
			}).catch(function (error) {
				console.log(error);
			});
		}),

		municipalAccountForYearByIndex: Ember.computed('accountYearsLastObject', 'accountIndex', function () {
			var _this = this;
			var index = this.get('accountIndex');
			var accountYear = this.get('accountYearsLastObject');
			if (!accountYear) {
				return;
			}
			return accountYear.get('municipalAccounts').then(function (mAccounts) {
				_this.set('currentAccount', mAccounts.objectAt(index));
				return mAccounts.objectAt(index);
			}).catch(function (error) {
				console.log(error);
			});
		}),
		actions: {
			formatAsCurrency: function formatAsCurrency(attr, value, evt) {
				if (value < 1) {
					this.get('currentAccount').set(attr, null);
					return;
				}
				var num = value.replace(/,/g, '');
				var formated = Number(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
				this.get('currentAccount').set(attr, formated);
				return;
			},
			previousAccount: function previousAccount(currentAccount) {
				var _this2 = this;

				if (this.get('accountIndex') === 0) {
					return;
				}
				currentAccount.save().then(function () {
					_this2.decrementProperty('accountIndex');
				}).catch(function (error) {
					console.log(error);
				});
			},
			nextAccount: function nextAccount(currentAccount) {
				var _this3 = this;

				currentAccount.save().then(function () {
					_this3.incrementProperty('accountIndex');
				}).catch(function (error) {
					console.log(error);
				});
			}
		}

	});
});