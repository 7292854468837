define('fund-figure-app/models/manifest', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    municipality: _emberData.default.belongsTo('municipal-db'),
    municipalYear: _emberData.default.belongsTo('municipal-year'),
    municipalInvoices: _emberData.default.hasMany('municipal-invoice'),
    municipalPayPeriod: _emberData.default.belongsTo('municipal-pay-period'),
    municipalChecks: _emberData.default.hasMany('municipal-check'),
    date_created: _emberData.default.attr('number'), //Date when someone first created manifest
    date_finalized: _emberData.default.attr('number'), //Date when someone locked in invoices to manifest
    checks_printed: _emberData.default.attr('number'), //Date when checks were printed
    date_paid: _emberData.default.attr('number'), //Date when checks were marked as signed and sent
    total: _emberData.default.attr('number'), //Total of all invoices  
    start_check: _emberData.default.attr('number'), //Number of first check issued
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string') //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
  });
});