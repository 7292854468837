define('fund-figure-app/components/banks/bank-with-accounts-info/account-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    moneyFormatTransactions: {
      neg: "<green>%s %v</green>",
      pos: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    unreconciledTransactions: Ember.computed.filter('account.bankAccountTransactions.@each.reconciled', function (transaction, index, array) {
      return !transaction.get('reconciled');
    }),
    sortedTransactions: Ember.computed.sort('unreconciledTransactions', 'sortBy'),
    actions: {
      showTransactions: function showTransactions() {
        this.toggleProperty('showTransactions');
      }
    }
  });
});