define('fund-figure-app/components/dashboard/modals/subscription/expanded-subscription-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["width100"],
    connectedUser: Ember.inject.service(),
    actions: {
      closeExpandedSubscriptionInfo: function closeExpandedSubscriptionInfo() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalName', null);
        Ember.set(this, 'connectedUser.modalModel', null);
      },
      totalYearlyCost: function totalYearlyCost(subscription) {
        //need to map quantity, amount and interval
        console.log('subscription', subscription);
        var itemsArray = subscription.get('items.data');
        console.log('itemsArray', itemsArray);
        var subTotalsArray = itemsArray.map(function (a) {
          if (a.plan.interval === 'month') {
            return a.quantity * (a.plan.amount / 100) * 12;
          } else if (a.plan.interval === 'year') {
            return a.quantity * (a.plan.amount / 100);
          } else {
            return 0;
          }
        });
        var reducer = function reducer(accumulator, currentValue) {
          return accumulator + currentValue;
        };
        return subTotalsArray.reduce(reducer, 0);
      }
    }
  });
});