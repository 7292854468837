define('fund-figure-app/components/dashboard/warrant-articles/create-warrant-article-step-two', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    appError: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      resetArticle: function resetArticle(article, templateLanguage) {
        return article.set('language', templateLanguage);
      },
      saveArticle: function saveArticle(article, account, fund) {
        var appError = Ember.get(this, 'appError'),
            router = Ember.get(this, 'router'),
            store = Ember.get(this, 'store');
        article.save().catch(function (err) {
          console.log(err);
          var specialMessage = 'error saving new article ' + article.id + ' to db';
          appError.logError(err, specialMessage);
        }).then(function (savedArticle) {
          if (!article.toJSON().municipalAccount) {
            return savedArticle;
          }
          console.log(account);
          return store.findRecord('municipal-account', account.get('id')).then(function (accountModel) {
            accountModel.get('municipalArticles').addObject(savedArticle);
            return accountModel.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving municipal accountModel ' + accountModel.id + ' after adding new warrant article ' + savedArticle.id;
              appError.logError(err, specialMessage);
            }).then(function () {
              return savedArticle;
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error finding the account given ' + account;
            return appError.logError(err, specialMessage);
          });
        }).then(function (savedArticle) {
          return store.findRecord('municipal-fund', fund.get('id')).then(function (fundModel) {
            fundModel.get('municipalArticles').addObject(savedArticle);
            return fundModel.save().catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving municipal fundModel ' + fundModel.id + ' after adding new warrant article ' + savedArticle.id;
              appError.logError(err, specialMessage);
            }).then(function () {
              router.transitionTo('dashboard.warrant-articles');
            });
          });
        });
      }
    }
  });
});