define('fund-figure-app/components/dashboard-support/conversations/user-conversations/user-conversation-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    didRender: function didRender() {
      var objDiv = document.getElementById("chatWindow");
      objDiv.scrollTop = objDiv.scrollHeight;
    },


    actions: {
      sendComment: function sendComment(chatRoom, message) {
        var _this = this,
            user = Ember.get(this, 'connectedUser.userAccount'),
            m_user = Ember.get(this, 'connectedUser.currentAccount'),
            feedback = Ember.get(this, 'feedback'),

        // feedbackChat = get(this,'feedback.feedbackChatRoom'),
        newFeedback = Ember.get(this, 'store').createRecord('feedbackChat', {
          owner: user,
          municipalUser: m_user,
          feedbackChatRoom: chatRoom,
          timestamp: moment().format('x'),
          message: message
        }),
            feedbackChatRoom = Ember.get(this, 'store').find('feedbackChatRoom', chatRoom),
            globalMessage = Ember.get(this, 'globalMessaging');
        feedback.set('last_chat', moment().valueOf());
        feedbackChatRoom.then(function (resultRoom) {
          //add message to chatroom
          resultRoom.get('feedbackChats').addObject(newFeedback);
          //add notification to each member of chat (new way)
          resultRoom.get('members').forEach(function (member) {
            member.get('responses').addObject(feedback);
            member.save();
          });
          //add all members to be notified (old way)
          resultRoom.get('messageNotifications').addObjects(resultRoom.get('members'));
          //remove person who generated message
          resultRoom.get('messageNotifications').removeObject(feedback.get('municipalUser'));
          if (user.get('avitar')) {
            feedback.setProperties({
              addressed: true,
              addressedBy: user,
              addressedOn: moment().valueOf()
            });
            feedback.save();
          }
          return resultRoom.save();
        }).then(function () {
          return newFeedback.save();
        }).then(function () {
          _this.setProperties({
            message: null
          });
        }).catch(function (err) {
          console.log(err);
          globalMessage.actionNotification(err, 'warning');
        });
      },
      markClosed: function markClosed(feedback) {
        var ts = moment().valueOf(),
            user = Ember.get(this, 'connectedUser.userAccount');
        feedback.setProperties({ closed: ts, closed_by: user });
        feedback.save().catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});