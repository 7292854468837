define('fund-figure-app/controllers/dashboard-assets/asset/transactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    transactionContent: [],
    connectedUser: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      Ember.set(this, 'transactionContent', []);
    },


    filterByDate: Ember.computed('connectedUser.beginDate', 'connectedUser.endDate', {
      get: function get() {
        var beginDate = moment(Ember.get(this, 'connectedUser.beginDate'), 'MM-DD-YYYY').valueOf() || false,
            endDate = moment(Ember.get(this, 'connectedUser.endDate'), 'MM-DD-YYYY').valueOf() || false,
            transactions = Ember.get(this, 'model.transactions'),
            filtered = transactions.filter(function (a) {
          if (!beginDate || !endDate) {
            return true;
          }
          if (a.get('date') < beginDate || a.get('date') > endDate) {
            return false;
          } else {
            return true;
          }
        });
        Ember.set(this, 'transactionContent', filtered);
      }
    })
  });
});