define('fund-figure-app/models/municipalities', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    county: _emberData.default.attr('string'),
    population: _emberData.default.attr('string'),
    municipality: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr('string'),
    ein: _emberData.default.attr('string'),
    state: _emberData.default.belongsTo('state', { async: true, inverse: null }),
    billCycle: _emberData.default.attr('string'),
    fiscalYear: _emberData.default.attr('string') //January to December or July to June
  });
});