define('fund-figure-app/models/town-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    desc: _emberData.default.attr('string'),
    componentName: _emberData.default.attr('string'),
    nameLong: _emberData.default.attr('string'),
    dates: _emberData.default.attr('boolean'),
    options: _emberData.default.attr('string'),
    role: _emberData.default.attr('number'), //used to limit some reports to certain users
    lines_per_page: _emberData.default.attr('number')
  });
});