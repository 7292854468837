define('fund-figure-app/components/banks/bank-with-accounts-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    gettingPublicKey: false,
    receivedPublicKey: false,
    publicKey: null,

    moneyFormat: {
      pos: "<green>%s %v</green>",
      neg: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    moneyFormatTransactions: {
      neg: "<green>%s %v</green>",
      pos: "<red>%s %v</red>",
      zero: "%s 0.00"
    },

    actions: {
      requestPublicKey: function requestPublicKey(bankId) {
        var sendMessage = this.get('globalMessaging'),
            _this = this;
        this.set('gettingPublicKey', true);
        console.log(bankId);
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_GeneratePublicKey?bank_id=" + JSON.stringify(bankId),
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            _this.set('gettingPublicKey', false);
            sendMessage.actionNotification(data.error, false);
          } else if (data.success) {
            _this.setProperties({ gettingPublicKey: false, publicKey: data.success.public_token, receivedPublicKey: true });
            sendMessage.actionNotification(data.success, true);
          }
        });
      },
      bankTransactionWarning: function bankTransactionWarning() {
        this.toggleProperty('show_transaction_warning');
      },
      requestBankTransactionUpdate: function requestBankTransactionUpdate(bank) {
        $.get({
          url: "https://us-central1-aacc-603.cloudfunctions.net/plaid_updateWebhook?bank_account_id=" + JSON.stringify(bank.id),
          json: true
        }).done(function (data) {
          console.log(data);
          if (data.error) {
            console.log(data.error);
          } else if (data.success) {
            console.log(data.success);
          }
        });
      }
    }
  });
});