define('fund-figure-app/services/global-messaging', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    tempMessage: null,
    tempMessageSuccess: null,
    actionNotification: function actionNotification(message, success) {
      var _this = this;
      this.setProperties({ tempMessage: message, tempMessageSuccess: success });
      setTimeout(function () {
        _this.setProperties({ tempMessage: null, tempMessageSuccess: null });
      }, 5000);
    }
  });
});