define('fund-figure-app/models/chart-of-account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fund_name: _emberData.default.attr('string'),
    state: _emberData.default.belongsTo('state'),
    fund_id: _emberData.default.attr('string'),
    accountNumbers: _emberData.default.hasMany('account-number'),
    stateReports: _emberData.default.hasMany('report'), //links to state reports specific to fund type
    townReports: _emberData.default.hasMany('town-report'), //links to town reports for specific fund types
    visualReports: _emberData.default.hasMany('visual-report'), //links to reports specific to fund types
    description: _emberData.default.attr('string'),
    documents: _emberData.default.hasMany('file'),
    fundType: _emberData.default.belongsTo('municipal-fund-type', { async: true }),
    fundSubType: _emberData.default.belongsTo('municipal-fund-type-sub', { async: true }),
    lawLink: _emberData.default.attr('string'), //link to state law
    rev: _emberData.default.attr('string'),
    subRev: _emberData.default.attr('string')
  });
});