define('fund-figure-app/models/municipal-board-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    termStart: _emberData.default.attr('number'),
    termEnd: _emberData.default.attr('number'),
    postion: _emberData.default.attr('string', { defaultValue: 'Selectboard Member' })
  });
});