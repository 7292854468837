define('fund-figure-app/components/account-new-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalMessaging: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    accountType: ['Revenue', 'Expense', 'Cash'],
    // accountsSorting: ['id:desc'],
    // sortedAccounts: Ember.computed.sort('accounts', 'accountsSorting'),

    actions: {
      submitAccount: function submitAccount(number, desc, source, type, rev, subrev) {
        var messageService = this.get('globalMessaging');
        var _this = this;
        if (!number || !desc) {
          messageService.actionNotification('both account number and description need to be present', false);
          return;
        }
        var store = this.get('store');
        store.createRecord('account-number', {
          accountNumber: number,
          description: desc,
          source: source,
          classification: type,
          rev: rev,
          subRev: subrev
        }).save().then(function (savedAccount) {
          var message = 'successfully saved account ' + savedAccount.get('accountNumber');
          _this.setProperties({ accountNumber: null, description: null });
          messageService.actionNotification(message, true);
        }).catch(function (error) {
          messageService.actionNotification(error, false);
        });
      },
      myMistake: function myMistake() {
        var store = this.get('store');
        store.findAll('account-number').then(function (accounts) {
          accounts.forEach(function (account) {
            account.set('update', true);
            account.save().catch(function (err) {
              console.log(err);
            });
          });
        });

        // this.get('connectedUser.fiscalYear.municipalAccounts')
        //   .then(accounts => {
        //     return accounts.forEach(account => {           
        //       account.get('stateAccountNumber')
        //         .then(stateAccountNumber => {            
        //             stateAccountNumber.set('update', true);
        //             stateAccountNumber.save().catch(error=>{
        //               console.log(error);
        //             });               
        //         })
        //         .catch(error => {
        //           console.log(error);
        //         });
        //     })
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
      }
    }
  });
});