define('fund-figure-app/components/dashboard/feedback-messages/feedback-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),

    didRender: function didRender() {
      var objDiv = document.getElementById("chatWindow");
      objDiv.scrollTop = objDiv.scrollHeight;
      // this.get('messageNotifications');
    },
    didInsertElement: function didInsertElement() {
      var appError = Ember.get(this, 'appError'),
          muni_user = Ember.get(this, 'connectedUser.currentAccount');
      console.log('should be updating user responses');
      muni_user.get('responses').removeObject(Ember.get(this, 'feedback'));
      muni_user.save().catch(function (err) {
        console.log(err);
        var specialMessage = 'error saving municipal user account after removing notification they had response';
        appError.logError(err, specialMessage);
      });
    },


    actions: {
      sendComment: function sendComment(chatRoom, message) {
        var _this = this,
            user = this.get('connectedUser.userAccount'),
            m_user = this.get('connectedUser.currentAccount'),
            feedback = this.get('feedback'),

        // feedbackChat = this.get('feedback.feedbackChatRoom'),
        newFeedback = this.get('store').createRecord('feedbackChat', {
          owner: user,
          municipalUser: m_user,
          feedbackChatRoom: chatRoom,
          timestamp: moment().format('x'),
          message: message
        }),
            feedbackChatRoom = this.get('store').find('feedbackChatRoom', chatRoom),
            globalMessage = this.get('globalMessaging');
        feedback.set('last_chat', moment().valueOf());
        feedbackChatRoom.then(function (resultRoom) {
          resultRoom.get('feedbackChats').addObject(newFeedback);
          resultRoom.get('messageNotification').addObjects(resultRoom.get('members'));
          resultRoom.get('messageNotification').removeObject(feedback.get('municipalUser'));
          if (user.get('avitar')) {
            feedback.setProperties({
              addressed: true,
              addressedBy: user,
              addressedOn: moment().valueOf()
            });
            feedback.save();
          }
          return resultRoom.save();
        }).then(function () {
          return newFeedback.save();
        }).then(function () {
          _this.setProperties({
            message: null
          });
          _this.get('connectedUser').getMessages();
        }).catch(function (err) {
          console.log(err);
          globalMessage.actionNotification(err, 'warning');
        });
      },
      toggleEditName: function toggleEditName() {
        this.toggleProperty('editName');
      },
      focusOutInput: function focusOutInput(em) {
        var _this = this;
        this.get('feedback.feedbackChatRoom').then(function (chatRoom) {
          chatRoom.set('name', em);
          chatRoom.save().then(function () {
            _this.toggleProperty('editName');
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});