define('fund-figure-app/models/municipal-deposit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    date_received: _emberData.default.attr('number'), //deposit received date
    deposit_number: _emberData.default.attr('string'), //id of deposit
    date: _emberData.default.attr('number'), //date on deposit
    t_warrant: _emberData.default.attr('boolean'),
    documents: _emberData.default.hasMany('file', { async: true, inverse: null }),
    total: _emberData.default.attr('number'), //total amount of deposit
    transactions: _emberData.default.hasMany('transaction'), //connection to transactions of deposit
    department: _emberData.default.belongsTo('municipal-account', { async: true }), //represents the Credit account
    timestamp: _emberData.default.attr('number'),
    reconciledBankTransaction: _emberData.default.belongsTo('bank-account-transaction', { async: true, inverse: null }),
    reconciled: _emberData.default.attr('boolean', { defaultValue: false }), //this could be deleted if we use reconciledReport as indicator
    reconciledReport: _emberData.default.belongsTo('reconciled-report', { async: true, inverse: null }),
    m_year: _emberData.default.attr('string'), //m_id + year for querying on year
    m_month: _emberData.default.attr('string'), //m_id + year + month for querying ex: "-Lksdsdsfg-YYYY-M"
    m_week: _emberData.default.attr('string'), //m_id + year + month + week of month ex: "-Lksdsdsfg-YYYY-WW"
    m_day: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-M-DD"
    m_weekday: _emberData.default.attr('string'), //m_id + year + month + day ex: "-Lksdsdsfg-YYYY-WW-E"
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: null }),
    year: _emberData.default.belongsTo('municipal-year', { async: true, inverse: null }),
    municipalFund: _emberData.default.belongsTo('municipal-fund', { inverse: null }),
    user: _emberData.default.belongsTo('municipal-user', { async: true, inverse: null }) //who made the entry
  });
});