define('fund-figure-app/components/dashboard/invoices-manifests/manifests/manifest/manifest-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      payManifest: function payManifest(manifest) {
        var _this2 = this;

        var store = this.get('store'),
            globalMessage = this.get('globalMessaging'),
            _this = this,
            fund = this.get('connectedUser.currentFund');
        if (manifest.get('date_paid')) {
          return globalMessage.actionNotification('Checks from manifest already paid on ' + moment(manifest.get('date_paid')).format('MMM DD, YYYY'), 'warning');
        }
        this.set('paying', true);
        this.get('connectedUser.currentFund.municipalAccounts').then(function (municipalAccounts) {
          var accountsPayable = municipalAccounts.find(function (account) {
            return account.toJSON().stateName === 'Accounts Payable';
          });
          var cash = municipalAccounts.find(function (account) {
            return account.toJSON().stateName === "Cash On Hand";
          });
          return [cash, accountsPayable];
        }).then(function (accounts) {
          // create transaction for above, let backend perform transaction
          return _this.get('connectedUser.fiscalYear.municipality').then(function (municipalDb) {
            var municipal_id = municipalDb.id,
                m_year = municipal_id + '-' + moment().format("YYYY"),
                m_month = m_year + '-' + moment().format('MM'),
                m_week = m_year + '-' + moment().format('WW'),
                m_day = m_month + '-' + moment().format('ddd'),
                m_weekday = m_week + '-' + moment().format('E');
            return manifest.get('municipalChecks').then(function (checks) {
              var index = 0,
                  total_checks_printed = checks.length - 1;
              checks.forEach(function (check) {
                console.log(index, total_checks_printed);
                var newTransaction = store.createRecord('transaction', {
                  transactDebit: accounts[1],
                  transactCredit: accounts[0],
                  t_Expense: true,
                  municipalCheck: check,
                  manifest: manifest,
                  amount: '' + check.get('amount'),
                  m_year: m_year,
                  m_month: m_month,
                  m_week: m_week,
                  m_day: m_day,
                  m_weekday: m_weekday,
                  municipality: municipalDb,
                  year: _this2.get('connectedUser.fiscalYear'),
                  municipalFund: fund,
                  date: check.get('date'),
                  timestamp: moment().valueOf()
                });
                return newTransaction.save().then(function (savedTransaction) {
                  fund.get('municipalTransactions').addObject(savedTransaction);
                  fund.save().catch(function (err) {
                    console.log(err);
                  });
                  check.set('p_transactions', savedTransaction);
                  return check.save().then(function () {
                    if (index == total_checks_printed) {
                      _this.set('paying', false);
                      manifest.set('date_paid', moment().valueOf());
                      return manifest.save().then(function () {
                        globalMessage.actionNotification('Checks Paid', 'success');
                        return;
                      });
                    }
                    index++;
                  }).catch(function (err) {
                    console.log(err);
                  });
                }).catch(function (err) {
                  console.log(err);
                });
              });
            }).catch(function (err) {
              console.log(err);
            });
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});