define('fund-figure-app/models/municipal-banks-db', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // municipality: DS.belongsTo('municipal-db', {async:true, inverse:'municipalBanksDb'}),
    municipalFund: _emberData.default.belongsTo('municipal-fund'),
    municipalBanks: _emberData.default.hasMany('municipal-bank', { inverse: null })
  });
});