define('fund-figure-app/components/helper/reduce-array-promises', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    reducedArray: null,
    getReducedArray: Ember.computed('array', {
      get: function get() {
        var _this = this;

        var promiseRequests = Ember.get(this, 'promiseString').split('.'),
            array = Ember.get(this, 'array');
        if (!array[0]) {
          return;
        }

        function methodThatReturnsAPromise(object) {
          return new Promise(function (resolve, reject) {
            object.get(promiseRequests[0]).then(function (res) {
              resolve(res);
            });
          });
        }

        var result = array.reduce(function (total, nextObject) {
          return total.then(function (res) {
            return methodThatReturnsAPromise(nextObject).then(function (currentResult) {
              if (currentResult) {
                return +res + +currentResult.get(promiseRequests[1]);
              } else {
                return res;
              }
            });
          });
        }, Promise.resolve(0));

        return Promise.resolve(result).then(function (res) {
          Ember.set(_this, 'reducedArray', res);
          return res;
        });
      }
    })
  });
});