define('fund-figure-app/components/dashboard/budget/edit-main', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    cardView: true,
    lineView: false,
    actions: {
      toggleView: function toggleView(view) {
        console.log(view);
        this.setProperties({ cardView: false, lineView: false });
        this.toggleProperty(view);
      },
      toggleEditAll: function toggleEditAll() {
        this.toggleProperty('editAll');
      },
      addSubAccount: function addSubAccount(account) {
        var subAccountNumber = String(account.get('subAccounts.length')).padStart(3, "0"),
            municipalFund = Ember.get(this, 'connectedUser.currentFund'),
            _this = this;
        account.get('stateAccountNumber').then(function (stateAccount) {
          return stateAccount.get('classification');
        }).then(function (classification) {
          if (classification === "Expense") {
            var _this$get$createRecor;

            var _subAccountNumber = account.get('subAccount.length');
            var subAccount = _this.get('store').createRecord('municipal-account', (_this$get$createRecor = {
              parentAccount: account,
              stateAccountNumber: account.get('stateAccountNumber'),
              activated: true,
              classification: account.get('classification'),
              appropriation: account.get('appropriation'),
              accountNumber: String(account.get('subAccounts.length')).padStart(3, "0"),
              municipalYear: account.get('municipalYear'),
              municipalFund: account.get('municipalFund'),
              stateName: account.get('stateName'),
              stateNumber: account.get('stateNumber'),
              municipality: account.get('municipality'),
              transactDebit: account.get('transactDebit'),
              transactCredit: account.get('transactCredit')
            }, _defineProperty(_this$get$createRecor, 'stateName', account.get('stateName')), _defineProperty(_this$get$createRecor, 'stateNumber', account.get('stateNumber')), _this$get$createRecor));

            account.get('subAccounts').addObject(subAccount);
            municipalFund.get('municipalAccounts').addObject(subAccount);
            account.save().then(function (savedAccount) {
              municipalFund.save();
              return subAccount.save();
            }).catch(function (error) {
              console.log(error);
            });
          } else {
            account.save();
          }
        });
      }
    }
  });
});