define('fund-figure-app/controllers/dashboard-assets/asset/statistics', ['exports', 'd3-collection', 'd3-array'], function (exports, _d3Collection, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    data: Array(),
    date_keys: ["YYYY", "MMM YY", "MMM Do"],
    date_key_index: 0,
    filter: null,
    graphExpanded: true,

    willTransition: function willTransition() {
      Ember.set(this, 'data', Array());
    },

    width: Ember.computed('didInsertElement', {
      get: function get() {
        return document.querySelector('#graphArea').clientWidth;
      },
      set: function set(value) {
        return value;
      }
    }),
    height: Ember.computed('didInsertElement', {
      get: function get() {
        return null;
      },
      set: function set(value) {
        return value;
      }
    }),
    graphData: Ember.computed('model.transactions', 'date_key_index', {
      get: function get() {
        var _this = this;

        //get transaction data      
        return Ember.get(this, 'model.transactions').then(function (assetTransactions) {
          if (!assetTransactions || assetTransactions.length === 0) {
            console.log('no transactions to analyze');
            Ember.set(_this, 'data', []);
            return;
          }
          console.log('we have transactions to analyze');
          var mappedAssetExpenses = Promise.resolve(assetTransactions.map(function (assetTransaction) {
            var object = {};
            object["filter"] = moment(assetTransaction.get('date')).format(Ember.get(_this, 'date_keys')[Ember.get(_this, 'date_key_index') - 1]);
            object["query"] = moment(assetTransaction.get('date')).format(Ember.get(_this, 'date_keys')[Ember.get(_this, 'date_key_index')]);
            object["Category"] = assetTransaction.get('functionDesc') || 'Unknown';
            object["Amount"] = assetTransaction.get('amount');
            return object;
          }).sort(function (a, b) {
            return moment(a.query, Ember.get(_this, 'date_keys')[Ember.get(_this, 'date_key_index')]).format('YYYYMMDD') - moment(b.query, Ember.get(_this, 'date_keys')[Ember.get(_this, 'date_key_index')]).format('YYYYMMDD');
          }));
          return mappedAssetExpenses.then(function (res) {
            Ember.set(_this, 'data', res);
          });
        });
      }
    }),
    currentData: Ember.computed('data', {
      get: function get() {
        var currentYear = Ember.get(this, 'connectedUser').get('fiscalYear.year'),
            filter = Ember.get(this, 'filter'),
            dataFilter = null;
        if (filter) {
          dataFilter = Ember.get(this, 'data').filter(function (transaction) {
            if (transaction.filter == filter) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          dataFilter = Ember.get(this, 'data');
        }
        // get(this, 'currentTotals').set('', dataFilter);
        Ember.set(this, 'currentTotals', dataFilter);
        return (0, _d3Collection.nest)().key(function (d) {
          return d.query;
        }).key(function (d) {
          return d.Category;
        }).sortKeys(_d3Array.descending).rollup(function (v) {
          return (0, _d3Array.sum)(v, function (d) {
            return Number(d.Amount);
          });
        }).object(dataFilter);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    currentTotals: Ember.computed('currentData', {
      get: function get(key) {
        return key;
      },
      set: function set(key, value) {
        console.log('inside totals', key, value);
        if (value) {
          console.log('setting total');
          console.log((0, _d3Collection.nest)().key(function (d) {
            return d.Category;
          }).sortKeys(_d3Array.descending).rollup(function (v) {
            return (0, _d3Array.sum)(v, function (d) {
              return Number(d.Amount);
            });
          }).entries(value));
          return (0, _d3Collection.nest)().key(function (d) {
            return d.Category;
          }).sortKeys(_d3Array.descending).rollup(function (v) {
            return (0, _d3Array.sum)(v, function (d) {
              return Number(d.Amount);
            });
          }).entries(value);
        }
      }
    }),

    actions: {
      groupClicked: function groupClicked(d) {
        var _this2 = this;

        console.log('data clicked');
        return new Promise(function (resolve, reject) {
          var keys = Ember.get(_this2, 'date_keys').length - 1,
              currentIndex = Ember.get(_this2, 'date_key_index');
          console.log(keys, currentIndex);
          if (currentIndex == keys) {
            Ember.set(_this2, 'filter', null);
            Ember.set(_this2, 'date_key_index', 0);
            resolve('done');
          } else {
            Ember.set(_this2, 'filter', d[0]);
            Ember.set(_this2, 'date_key_index', currentIndex + 1);
            resolve('done');
          }
        });
      },
      backgroundClicked: function backgroundClicked() {
        var _this3 = this;

        console.log('background clicked');
        return new Promise(function (resolve, reject) {
          var currentIndex = Ember.get(_this3, 'date_key_index');
          // keys = get(this, 'date_keys').length - 1;
          console.log(currentIndex);
          if (currentIndex == 0) {
            resolve(false);
          } else {
            console.log('change index');
            Ember.set(_this3, 'filter', null);
            Ember.set(_this3, 'date_key_index', currentIndex - 1);
            resolve(true);
          }
        });
      },
      toggleAttr: function toggleAttr(attr) {
        this.toggleProperty(attr);
      }
    }
  });
});