define('fund-figure-app/components/dashboard/personnel/vendors/vendor-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    documentManager: Ember.inject.service(),
    store: Ember.inject.service(),
    undo: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    appError: Ember.inject.service(),

    willDestroyElement: function willDestroyElement() {
      var model = this.get('model'),
          undo = this.get('undo'),
          appError = this.get('appError'),
          commonTasks = this.get('commonTasks'),
          bigBrother = this.get('bigBrother'),
          privateDataChanges = undo.getChanges(model);
      console.log(privateDataChanges);
      if (!commonTasks.isEmpty(privateDataChanges)) {
        var desc = 'Undo changes made to ' + model.get('businessName'),
            logDesc = '' + model.get('businessName'),
            undoObject = { model: model, desc: desc, changes: privateDataChanges, logDesc: logDesc };
        bigBrother.logChange(undoObject);
        undo.get('undoObjects').addObject(undoObject);
      }
      return model.save().catch(function (err) {
        console.log(err);
        var specialMessage = 'error saving vendor data changes during destruction of element';
        appError.logError(err, specialMessage);
      });
    },

    actions: {
      saveFile: function saveFile(fileInfo) {
        var _this = this,
            appError = this.get('appError'),
            model = this.get('model');
        this.get('documentManager').saveDocument(fileInfo, 'vendor document').then(function (res) {
          model.get('files').addObject(res);
          model.save();
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      deleteFile: function deleteFile(file) {
        var model = this.get('model'),
            fiscalYear = this.get('connectedUser.fiscalYear'),
            appError = this.get('appError'),
            _this = this;
        model.get('documents').removeObject(file);
        model.save().then(function () {
          fiscalYear.get('municipalDocuments').removeObject(file);
          fiscalYear.save().then(function () {
            file.destroyRecord();
          }).catch(function (error) {
            appError.logError(error);
            console.log(error);
          });
        }).catch(function (error) {
          appError.logError(error);
          console.log(error);
        });
      },
      downloadFile: function downloadFile(file) {
        dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href", file.get('fileDownload'));
        dlAnchorElem.setAttribute("download", '' + file.get('name'));
        dlAnchorElem.click();
      }
    }
  });
});