define('fund-figure-app/controllers/dashboard-assets/asset/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    assetInfo: Ember.inject.service(),
    data: null,

    getGanttData: Ember.computed('model', 'assetInfo.allAssets.[]', {
      get: function get() {
        Ember.get(this, 'assetInfo').singleAssetGanttData(Ember.get(this, 'model'));
        // console.log('data changed');
        // let object = {},
        //   model = get(this, 'model');
        // object["name"] = model.get('name') || null;
        // object["model"] = model.get('model.name') || null;
        // object["modelYear"] = model.get('modelYear') || null;
        // if (model.get('vehicle') || model.get('equipment')) {
        //   if (!model.get('depModel')) {
        //     object["depModel"] = model.get('name');
        //   } else {
        //     object["depModel"] = model.get('depModel').replace(/_/g, ' ').replace(
        //       /\w*\S*/g,
        //       function(txt) {
        //         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        //       }
        //     ) || null;
        //   }
        // } else if (model.get('building')) {
        //   object["depModel"] = model.get('name') || null;
        // } else if (model.get('land') || model.get('taxDeeded')) {
        //   object["depModel"] = model.get('propertyLoc') || null;
        // } else {
        //   object["depModel"] = model.get('name');
        // }
        // object["lifeSpan"] = model.get('lifeSpan') || null;
        // object["cost"] = model.get('cost') || null;
        // object["cipFund"] = model.get('cipFund.fund_name') || null;
        // object["department"] = model.get('municipal-department.name') || null;
        // object["condition"] = model.get('condition') || null;
        // object["acquired"] = model.get('acquired') || null;
        // object["disposed"] = model.get('disposed') || null;
        // object["replacementYear"] = model.get('replacementYear').valueOf() || null;
        // object["cip_total_save"] = model.get('cip_total_save') || null;
        // object["cip_cost_per_year"] = model.get('cip_cost_per_year') || null;

        // set(this, 'data', [object]);
        // this.notifyPropertyChange('data');
        // return get(this, 'data');
      }
    }).property('model')
  });
});