define('fund-figure-app/components/dashboard/warrant-articles/article-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      deleteArticle: function deleteArticle() {
        Ember.get(this, 'deleteArticle')();
      },
      clickEdit: function clickEdit() {
        Ember.get(this, 'clickEdit')();
      }
    }
  });
});