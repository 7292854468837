define('fund-figure-app/components/reports/town-reports/budget-line-item-detail', ['exports', 'd3-collection'], function (exports, _d3Collection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    byVariable: 'accountDesc',
    organizedData: [],
    loading: false,

    didInsertElement: function didInsertElement() {
      this.get('getBudgetData');
    },
    toggleLoading: function toggleLoading(value) {
      return new Promise(function (resolve, reject) {
        if (value) {
          $('#loadingScreen').removeClass('hidden');
          $('#reportScreen').addClass('hidden');
          setTimeout(function () {
            resolve('creating report');
          }, 100);
        } else {
          $('#loadingScreen').addClass('hidden');
          $('#reportScreen').removeClass('hidden');
          setTimeout(function () {
            resolve('report done');
          }, 100);
        }
      });
    },


    checkDate: Ember.computed('model.dates', {
      get: function get(key) {
        if (this.get('model.dates')) {
          console.log('model dates is true');
          this.set('connectedUser.dates', true);
        } else {
          this.set('connectedUser.dates', false);
        }
      }
    }),

    getBudgetData: Ember.computed('byVariable.[]', 'connectedUser.currentFund', 'connectedUser.currentFund.municipalAccounts.[]', {
      get: function get() {
        var _this2 = this;

        Ember.get(this, 'store').findAll('municipalAccountFunction');
        console.log('getting accounts');
        var byVariable = Ember.get(this, 'byVariable');
        Ember.get(this, 'organizedData').clear();
        return Ember.get(this, 'connectedUser.currentFund.municipalAccounts').then(function (municipalAccounts) {
          var subAccounts = municipalAccounts.filter(function (a) {
            if (a.toJSON().parentAccount) {
              return true;
            } else {
              return false;
            }
          });
          var organizedData = (0, _d3Collection.nest)().key(function (d) {
            return d.get('stateNumber') + ' - ' + d.get('stateName');
          }).key(function (d) {
            return d.get(byVariable);
          }).entries(subAccounts.toArray());
          Ember.set(_this2, 'organizedData', organizedData);
          console.log('organizedData', organizedData);
          return organizedData;
        });
      }
    }).property('byVariable'),

    exportData: function exportData() {
      console.log('starting report generation');
      var _this = this;
      var CSV = '',
          year = this.get('connectedUser.fiscalYear.year'),
          arrayOfRows = $('.tablerow');
      for (var i = 0; i < arrayOfRows.length; i++) {
        var row = "";
        for (var index in $('.tablerow').eq(i).children('.tablecolumn')) {
          if ($('.tablerow').eq(i).children('.tablecolumn').eq(index).html()) {
            row += '"' + $('.tablerow').eq(i).children('.tablecolumn').eq(index).text().replace(/[\n\r\s\t]+/g, ' ') + '",';
          }
        }
        row.slice(0, row.length - 1);
        //add a line break after each row      
        CSV += row + '\r\n';
      }

      if (CSV == '') {
        this.set('loading', false);
        alert("Invalid data");
        return;
      }
      //Initialize file format you want csv or xls
      this.toggleLoading(false).then(function (res) {
        console.log(res);
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV),
            dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href", uri);
        dlAnchorElem.setAttribute("download", 'budget_line_item_detail_' + year + '.csv');
        dlAnchorElem.click();
      });
    },


    generatePageContent: Ember.computed('organizedData', {
      //generate pages based on line limit for report
      get: function get() {
        console.log('gen page content');
        var lines = this.get('model.lines_per_page'),
            _this = this,
            contentArray = this.get('organizedData'),
            changesLength = contentArray.length,
            page_info = [],
            lastIndex = 0,
            line_count = 0;
        if (contentArray.length < 1) {
          return;
        }
        contentArray.forEach(function (account, index) {
          var totalLines = 0;
          for (var i = account.values.length - 1; i >= 0; i--) {
            //2 for parent account, 4 each for category, 1.25 for each for sub account                   
            totalLines += 4 + account.values.length * 4 + account.values[i].values.length * 1.5;
          }
          if (line_count + totalLines >= lines) {
            var object = {};
            object["startAt"] = lastIndex;
            object["endAt"] = index - 1;
            page_info.push(object);
            line_count = 0;
            lastIndex = index;
          }
          line_count += totalLines;
          if (changesLength == index + 1) {
            var _object = {};
            _object["startAt"] = lastIndex;
            _object["endAt"] = index;
            page_info.push(_object);
            _this.set('pageInfo', page_info);
          }
        });
      }
    }),

    actions: {
      exportData: function exportData() {
        var _this3 = this;

        this.toggleLoading(true).then(function (res) {
          console.log(res);
          _this3.exportData();
        });
      },
      byFunction: function byFunction() {
        console.log('setting byVariable');
        Ember.set(this, 'byVariable', 'function.desc');
        Ember.get(this, 'getBudgetData');
      },
      byDescription: function byDescription() {
        console.log('setting byVariable');
        Ember.set(this, 'byVariable', 'accountDesc');
        Ember.get(this, 'getBudgetData');
      },
      fixAllAccounts: function fixAllAccounts() {
        Ember.get(this, 'store').query('municipalAccount', {
          orderBy: 'municipality',
          equalTo: '-LZVi1mj9oPNBc90LWXW'
        }).then(function (res) {
          res.forEach(function (account) {
            console.log(account.get('budget'), _typeof(account.get('budget')));
            // if (typeof(account.budget) == 'string') {
            //   console.log(account.budget.replace(/,/g, ''));
            // }
          });
        });
      }
    }
  });
});