define('fund-figure-app/components/dashboard/billing/plan-info/plan-info-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    actions: {
      expandSubscriptionInfo: function expandSubscriptionInfo(subscription) {
        Ember.set(this, 'connectedUser.modalName', 'dashboard/modals/subscription/expanded-subscription-info');
        Ember.set(this, 'connectedUser.modalModel', subscription);
        Ember.set(this, 'connectedUser.showModal', true);
      },
      totalYearlyCost: function totalYearlyCost(subscription) {
        //need to map quantity, amount and interval
        var subscriptionData = subscription.get('items.data');
        console.log('itemsArray', subscriptionData);
        if (!subscriptionData) {
          return 0;
        }
        if (subscriptionData.length) {
          //already an array
          var subTotalsArray = subscriptionData.map(function (a) {
            if (a.plan.interval === 'month') {
              return 0;
            } else if (a.plan.interval === 'year') {
              return a.quantity * (a.plan.amount / 100);
            } else {
              return 0;
            }
          });
          var reducer = function reducer(accumulator, currentValue) {
            return accumulator + currentValue;
          };
          return subTotalsArray.reduce(reducer, 0);
        } else {
          // not an array, so one object
          var quantity = +subscriptionData.get('quantity'),
              amount = +subscriptionData.get('plan.amount') / 100,
              interval = subscriptionData.get('plan.interval');

          if (interval === "month") {
            return 0;
          } else {
            console.log(quantity, amount);
            return quantity * amount;
          }
        }
      },
      totalMonthlyCost: function totalMonthlyCost(subscription) {
        //need to map quantity, amount and interval
        var subscriptionData = subscription.get('items.data');
        console.log('itemsArray', subscriptionData);
        if (!subscriptionData) {
          return 0;
        }
        if (subscriptionData.length) {
          //already an array
          var subTotalsArray = subscriptionData.map(function (a) {
            if (a.plan.interval === 'month') {
              return a.quantity * (a.plan.amount / 100);
            } else if (a.plan.interval === 'year') {
              return 0;
            } else {
              return 0;
            }
          });
          var reducer = function reducer(accumulator, currentValue) {
            return accumulator + currentValue;
          };
          return subTotalsArray.reduce(reducer, 0);
        } else {
          // not an array, so one object
          var quantity = +subscriptionData.get('quantity'),
              amount = +subscriptionData.get('plan.amount') / 100,
              interval = subscriptionData.get('plan.interval');

          if (interval === "month") {
            return quantity * amount;
          } else {
            return 0;
          }
        }
      }
    }
  });
});