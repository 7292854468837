define('fund-figure-app/models/municipal-setting', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		vendor_db_personal: _emberData.default.attr('boolean', { defaultValue: true }),
		municipality: _emberData.default.belongsTo('municipal-db')
	});
});