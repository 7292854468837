define('fund-figure-app/components/dashboard-support/manage-funds/manage-chart-of-accounts/chart-of-accounts-create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    states: Ember.computed('', {
      get: function get() {
        return this.get('store').findAll('state');
      }
    }).property('init'),
    actions: {
      save: function save(model) {
        var newChart = model.newChart;
        newChart.save().then(function (savedModel) {
          savedModel.get('fundType').then(function (fundType) {
            fundType.get('chartOfAccounts').addObject(savedModel);
            fundType.save();
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});