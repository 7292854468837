define('fund-figure-app/models/error', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		timestamp: _emberData.default.attr('number'),
		error: _emberData.default.attr('string'),
		user: _emberData.default.belongsTo('municipal-user'),
		url: _emberData.default.attr('string'),
		specialMessage: _emberData.default.attr('string')
	});
});