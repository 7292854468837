define('fund-figure-app/components/dashboard/settings/manage-funds/fund-create/step-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      if (!this.get('model.municipalFundType')) {
        return this.get('router').transitionTo('dashboard.settings.manage-funds.fund-create');
      }
    },

    actions: {
      setFund: function setFund(fund) {
        var model = this.get('model'),
            router = this.get('router');
        model.set('chartOfAccount', fund);
        model.get('stateReports').addObjects(fund.get('stateReports'));
        model.get('townReports').addObjects(fund.get('townReports'));
        model.get('visualReports').addObjects(fund.get('visualReports'));
        router.transitionTo('dashboard.settings.manage-funds.fund-create.step-three');
      }
    }
  });
});