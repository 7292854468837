define('fund-figure-app/components/dashboard/settings/manage-users/add-user/user-information', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.get('connectedUser').setProperties({
        step_one: true,
        step_two: false,
        step_three: false,
        nextRoute: 'dashboard.settings.manage-users.add-user.user-role',
        previousRoute: 'dashboard.settings.manage-users'
      });
      // this.get('connectedUser.currentAccount.municipality')
      //   .then(municipality => {
      //     let model = this.get('store').createRecord('municipalUser', {
      //       municipality:municipality
      //     });
      //     _this.set('model', model);
      //   })
      //   .catch(err=>{
      //     console.log(err);
      //   })
    },

    actions: {
      saveUserInfo: function saveUserInfo(model) {
        this.get('connectedUser').setProperties({
          step_one: true,
          step_two: true,
          step_three: false,
          nextRoute: 'dashboard.settings.manage-users.add-user.user-verify',
          previousRoute: 'dashboard.settings.manage-users.add-user.user-information'
        });
        this.get('router').transitionTo('dashboard.settings.manage-users.add-user.user-role', model);
      }
    }
  });
});