define('fund-figure-app/components/account-handler/create-with-pin/pin-step-four', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    newUser: Ember.inject.service(),
    error: false,
    errorMessage: '',
    didInsertElement: function didInsertElement() {
      var newUser = this.get('newUser');
      newUser.setProperties({ hasUser: true, haveAccountType: true, havePin: true, haveStaffConfirm: false });
      var user = this.get('connectedUser.user');
      var router = this.get('router');
      if (!user) {
        router.transitionTo('account-hander.user-create-with-pin.pin-step-one');
      }
    },

    actions: {
      combineWithStaff: function combineWithStaff(m_user) {
        var _this = this;
        var router = this.get('router');
        var store = this.get('store');
        var municipalDb = m_user.get('municipality');
        var municipalModel = store.findRecord('municipal-db', municipalDb.get('id'));
        var user = this.get('connectedUser.user');
        user.then(function (theUser) {
          municipalModel.then(function (municipality) {
            theUser.get('municipalUsers').addObject(m_user);
            theUser.setProperties({
              defaultAccount: m_user,
              is_new: null,
              name_first: m_user.get('name_first'),
              name_last: m_user.get('name_last')
            });
            theUser.save().then(function (savedUser) {
              m_user.setProperties({ user: savedUser, is_new: null });
              m_user.save().then(function () {
                _this.setProperties({ successMessage: 'Staff file found', errorMessage: '' });
                setTimeout(function () {
                  router.transitionTo('dashboard.state-of-affairs');
                }, 1000);
              }, function (error) {
                console.log('Error : ' + error);
              });
            }, function (error) {
              theUser.rollbackAttributes().save();
              m_user.rollbackAttributes().save();
              console.log('Error : ' + error);
            });
          }, function (error) {
            console.log('Error : ' + error);
          });
        }, function (error) {
          console.log('Error : ' + error);
        });
      }
    }
  });
});