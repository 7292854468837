define('fund-figure-app/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name_first: _emberData.default.attr('string'),
    name_last: _emberData.default.attr('string'),
    avitar: _emberData.default.attr('number'), //Avitar Employee and can view management dashboard
    // paymentInfo: DS.belongsTo('payment-info'), //Link to stripe info
    role: _emberData.default.attr('number'), //Used to govern what a user can do in the application
    pin: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone_mobile: _emberData.default.attr('string'),
    phone_work: _emberData.default.attr('string'),
    phone_home: _emberData.default.attr('string'),
    defaultAccount: _emberData.default.belongsTo('municipal-user', { async: true, inverse: 'user' }),
    municipalUsers: _emberData.default.hasMany('municipal-user'),
    is_new: _emberData.default.attr('string'),
    avatar: _emberData.default.belongsTo('avatar', { inverse: null })
  });
});