define('fund-figure-app/services/api-transaction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    globalMessaging: Ember.inject.service(),
    undo: Ember.inject.service(),
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),

    /**
     * reverse the transaction to essentially delete or make a zero change
     * @param  {emberModel} transaction        model of transaction to reverse
     * @param  {emberModel} transactionDeposit model of deposit tied to transaction if it exists
     * @param  {emberModel} transactionInvoice model of invoice tied to transaction if it exists
     * @param  {emberModel} transactionPayment model of municipalCheck tied to transaction if it exists
     * @return {bool}                    success or fail
     */
    reverseTransaction: function reverseTransaction(transaction, transactionDeposit, transactionInvoice, transactionPayment) {
      // console.log('working');
      var globalMessage = Ember.get(this, 'globalMessaging'),
          appError = Ember.get(this, 'appError'),
          bigBrother = Ember.get(this, 'bigBrother'),
          _this = this,
          store = Ember.get(this, 'store'),
          now = moment().format('x'),
          jsonModel = transaction.toJSON(),
          fiscalYear = Ember.get(this, 'connectedUser.fiscalYear'),
          currentFund = Ember.get(this, 'connectedUser.currentFund'),
          transactDebit = transaction.get('transactDebit'),
          transactCredit = transactDebit.then(function () {
        return transaction.get('transactCredit');
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error while getting transactCredit information';
        appError.logError(err, specialMessage);
      }),
          reverseTransaction = store.createRecord('transaction', jsonModel),
          ready = Promise.all([transactionDeposit, transactionInvoice, transactionPayment, transactDebit, transactCredit]);

      ready.then(function (res) {
        // If transaction was deposit
        if (res[0]) {
          reverseTransaction.setProperties({ 'transactCredit': res[3], 'transactDebit': res[4], 'reversed': transaction, 'timestamp': now, 'date': now, 'year': fiscalYear, 'municipalFund': currentFund });
          reverseTransaction.set('deposit', res[0]);
          return reverseTransaction.save().then(function (r_transaction) {
            transaction.set('reversal', r_transaction);
            transaction.save();
            res[0].get('transactions').addObject(r_transaction);
            res[0].save();
            currentFund.get('municipalTransactions').addObject(r_transaction);
            currentFund.save().then(function (res) {
              var desc = 'Undo changes made to  ',
                  //only used if sending to undo service
              logDesc = '' + transaction.get('id'),
                  //description of what changed
              undoObject = { model: transaction, desc: desc, changes: { "transaction": { new: 'Reversal Transaction: ' + r_transaction.get('id'), old: 'Reversed Transaction: ' + transaction.get('id') } }, logDesc: logDesc };
              bigBrother.logChange(undoObject);
              return globalMessage.actionNotification('Reversed Transaction', 'success');
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving fund with new deposit reversed transaction added';
              appError.logError(err, specialMessage);
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error after saving new reveresed deposit transaction before adding it to a fund';
            appError.logError(err, specialMessage);
          });
        }
        // If transaction was invoice
        else if (res[1]) {
            reverseTransaction.setProperties({ 'transactCredit': res[3], 'transactDebit': res[4], 'reversed': transaction, 'timestamp': now, 'date': now, 'year': fiscalYear, 'municipalFund': currentFund });
            reverseTransaction.set('invoice', res[1]);
            return reverseTransaction.save().then(function (r_transaction) {
              transaction.set('reversal', r_transaction);
              transaction.save();
              res[1].get('transactions').addObject(r_transaction);
              res[1].save();
              currentFund.get('municipalTransactions').addObject(r_transaction);
              currentFund.save().then(function (res) {
                var desc = 'Undo changes made to  ',
                    //only used if sending to undo service
                logDesc = '' + transaction.get('id'),
                    //description of what changed
                undoObject = { model: transaction, desc: desc, changes: { "transaction": { new: 'Reversal Transaction: ' + r_transaction.get('id'), old: 'Reversed Transaction: ' + transaction.get('id') } }, logDesc: logDesc };
                bigBrother.logChange(undoObject);
                return globalMessage.actionNotification('Reversed Transaction', 'success');
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error saving fund with new invoice reversed transaction added';
                appError.logError(err, specialMessage);
              });
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error after saving new reveresed invoice transaction before adding it to a fund';
              appError.logError(err, specialMessage);
            });
          }
          // If transaction was payment (municipalCheck)
          else if (res[2]) {
              reverseTransaction.setProperties({ 'transactCredit': res[3], 'transactDebit': res[4], 'reversed': transaction, 'timestamp': now, 'date': now, 'year': fiscalYear, 'municipalFund': currentFund });
              reverseTransaction.set('municipalCheck', res[2]);
              return reverseTransaction.save().then(function (r_transaction) {
                transaction.set('reversal', r_transaction);
                transaction.save();
                res[2].set('void', true);
                res[2].save();
                currentFund.get('municipalTransactions').addObject(r_transaction);
                currentFund.save().then(function (res) {
                  var desc = 'Undo changes made to  ',
                      //only used if sending to undo service
                  logDesc = '' + transaction.get('id'),
                      //description of what changed
                  undoObject = { model: transaction, desc: desc, changes: { "transaction": { new: 'Reversal Transaction: ' + r_transaction.get('id'), old: 'Voided Transaction: ' + transaction.get('id') } }, logDesc: logDesc };
                  bigBrother.logChange(undoObject);
                  return globalMessage.actionNotification('Reversed Transaction', 'success');
                }).catch(function (err) {
                  console.log(err);
                  var specialMessage = 'error saving fund with new invoice reversed transaction added';
                  appError.logError(err, specialMessage);
                });
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error after saving new reveresed invoice transaction before adding it to a fund';
                appError.logError(err, specialMessage);
              });
            } else {
              appError.logError('transaction was part of an error', 'Something went wrong with reversing transaction');
            }
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error waiting for promises to resolve for transactDebit and transactCredit';
        appError.logError(err, specialMessage);
      });
      return Promise.all([ready]).then(function (res) {
        return true;
      });
    },
    editInvoice: function editInvoice(invoice) {
      var _this2 = this;

      // check if dates change, need to roll down changes
      var changeList = invoice.changedAttributes();
      if (changeList['date']) {
        // the date was changed need to change all transaction dates
        this.updateTransactionsFromChangeList(invoice, changeList, "date");
      }
      if (changeList['date_received']) {
        // date received was changed need to update transactions
        this.updateTransactionsFromChangeList(invoice, changeList, "date_received");
      }
      if (changeList['date_due']) {
        // date received was changed need to update transactions
        this.updateTransactionsFromChangeList(invoice, changeList, "date_due");
      }
      var logDesc = 'Invoice edited',
          changes = Ember.get(this, 'undo').getChanges(invoice, false),
          bigBrotherObject = { model: invoice, desc: null, changes: changes, modelDeleted: false, secondaryModels: null, logDesc: logDesc };
      Ember.get(this, 'bigBrother').logChange(bigBrotherObject);
      return invoice.save().then(function (res) {
        Ember.get(_this2, 'globalMessaging').actionNotification('Invoice saved', 'success');
      });
    },
    updateTransactionsFromChangeList: function updateTransactionsFromChangeList(model, changeList, attribute) {
      model.get('transactions').then(function (transactions) {
        transactions.forEach(function (transaction) {
          transaction.set(attribute, changeList[attribute][1]);
          transaction.save();
        });
      });
    }
  });
});