define('fund-figure-app/components/reports/print/manifest-checks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    showReport: false,
    loadingReport: true,
    store: Ember.inject.service(),

    printConfirm: function printConfirm() {
      var r = confirm('Did you print the checks?'),
          store = this.get('store'),
          commonTasks = Ember.get(this, 'commonTasks'),
          model = this.get('model'),
          year = this.get('connectedUser.fiscalYear'),
          fund = this.get('connectedUser.currentFund'),
          _this = this;

      if (r == true) {
        //checks have been printed create checks to save to manifest  
        this.get('connectedUser.fiscalYear.municipality').then(function (municipalDb) {
          if (model.get('checks_printed')) {
            return _this.get('globalMessaging').actionNotification('Checks from manifest already printed on ' + moment(model.get('checks_printed')).format('MMM DD, YYYY'), 'warning');
          }
          model.set('checks_printed', moment().valueOf());
          model.get('municipalInvoices').then(function (invoices) {
            var newInvoices = invoices.toArray().reverse();
            console.log(newInvoices);
            var invoiceIndex = 0,
                vendorInfo = [],
                //list of objects with vendor:{checkNumber:number} info
            length = invoices.length,
                start_check = model.get('start_check');
            newInvoices.forEach(function (invoice) {
              console.log(invoiceIndex, length);
              invoice.get('vendor').then(function (vendor) {
                var vendorCheck = vendorInfo.filter(function (a_vendor) {
                  return a_vendor.vendorId === vendor.id;
                });
                if (vendorCheck.length > 0) {
                  //vendor has already had check created, so add check number to this invoice
                  console.log('vendor already received a check number');
                  var check = vendorCheck[0].check,
                      checkAmount = check.get('amount'),
                      newAmount = commonTasks.preciseRound(invoice.get('total') + checkAmount, 2);
                  check.get('municipalInvoices').addObject(invoice);
                  check.set('amount', newAmount);
                  check.save().then(function (savedCheck) {
                    invoice.set('municipalCheck', savedCheck);
                    invoice.save().catch(function (err) {
                      console.log(err);
                    });
                  });
                  invoice.save().then(function () {
                    check.save().catch(function (err) {
                      console.log(err);
                    });
                  }).catch(function (err) {
                    console.log(err);
                  });
                  if (invoiceIndex === length) {
                    console.log('complete');
                  }
                  return invoiceIndex++;
                } else {
                  console.log('new vendor------', 'vendorInfo:', vendorInfo);
                  var municipal_id = municipalDb.id,
                      previous_check = invoice.get('voidChecks.lastObject') || invoice.get('voidChecks.firstObject') || null,
                      m_year = municipal_id + '-' + moment().format("YYYY"),
                      m_month = m_year + '-' + moment().format('MM'),
                      m_week = m_year + '-' + moment().format('WW'),
                      m_day = m_month + '-' + moment().format('ddd'),
                      m_weekday = m_week + '-' + moment().format('E');
                  console.log('previous_check', previous_check);
                  var newCheck = store.createRecord('municipalCheck', {
                    manifest: model,
                    vendor: vendor,
                    check_number: start_check,
                    date: moment().valueOf(),
                    municipalDb: municipalDb,
                    previous_check: previous_check,
                    amount: invoice.get('total'),
                    municipalYear: year,
                    municipalFund: fund,
                    m_year: m_year,
                    m_month: m_month,
                    m_week: m_week,
                    m_day: m_day,
                    m_weekday: m_weekday
                  });
                  newCheck.get('municipalInvoices').addObject(invoice);
                  newCheck.save().then(function (savedCheck) {
                    invoice.set('municipalCheck', savedCheck);
                    model.get('municipalChecks').addObject(savedCheck);
                    fund.get('municipalChecks').addObject(savedCheck);
                    invoice.save().catch(function (err) {
                      console.log(err);
                    });
                    model.save().catch(function (err) {
                      console.log(err);
                    });
                    year.save().catch(function (err) {
                      console.log(err);
                    });
                  });
                  // //create object to put in vendor info with check_id
                  vendorInfo.push({ vendorId: vendor.id, check: newCheck });
                  // manifest.ref.child(`municipalChecks/${newCheckRef.key}`).set(true);
                  if (invoiceIndex === length) {
                    console.log('complete');
                  }
                  start_check++;
                  return invoiceIndex++;
                }
              }).catch(function (err) {
                console.log(err);
              });
            });
          });
        });
      } else {
        console.log('liar!');
      }
    },
    willDestroy: function willDestroy() {
      $('#iframe').remove();
    },


    actions: {
      previewPDF: function previewPDF() {
        var component = this.get('componentId') || "export",
            model = this.get('model'),
            _this = this,
            prom = prompt('First Check Number? ', ''),
            currentFund = this.get('connectedUser.currentFund');
        if (prom <= 0) {
          return;
        }
        model.set('start_check', prom);
        model.save();
        currentFund.set('currentCheckNumber', prom);
        currentFund.save();
        $('#' + component).printThis({
          debug: true,
          afterPrint: function afterPrint() {
            _this.printConfirm();
          }
        });
      },

      // printRequest() {
      //   alert('Did you print?');
      // },
      closePreview: function closePreview() {
        this.set('showReport', false);
      }
    }
  });
});