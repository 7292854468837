define('fund-figure-app/services/app-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    store: Ember.inject.service(),

    logError: function logError(error, specialMessage) {
      var user = this.get('connectedUser.currentAccount') || 'signing in',
          url = window.location.href,
          globalMessaging = this.get('globalMessaging');
      this.get('store').createRecord('error', {
        timestamp: moment().valueOf(),
        error: error,
        user: user,
        url: url,
        specialMessage: specialMessage
      }).save().then(function (res) {
        console.log(String(error));
        if (String(error).includes('unknown mixin')) {
          globalMessaging.actionNotification('Permission denied', 'warning');
          console.log(error);
        } else {
          globalMessaging.actionNotification('' + error, 'warning');
          console.log(error);
        }
      }).catch(function (err) {
        console.log('this would suck');
      });
    }
  });
});