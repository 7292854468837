define('fund-figure-app/components/account-handler/create-step-six', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      transitionToAddAccountInfo: function transitionToAddAccountInfo(muni) {
        var _this = this;

        var user = this.get('connectedUser.currentAccount');
        user.set('is_new', 'account-handler.add-account-info');
        return user.save().then(function () {
          return _this.get('router').transitionTo('account-handler.add-account-info', muni);
        }).catch(function (error) {
          console.log(error);
        });
      },
      transitionToDashboard: function transitionToDashboard(fiscalYear) {
        var _this2 = this;

        var user = this.get('connectedUser.currentAccount');
        this.get('connectedUser').getFiscalYear();
        console.log(user);
        user.set('is_new', null);
        user.save().then(function () {
          return _this2.get('router').transitionTo('dashboard.state-of-affairs');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});