define('fund-figure-app/models/municipal-years-db', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    municipality: _emberData.default.belongsTo('municipal-db', { async: true, inverse: 'municipalYearsDb' }),
    years: _emberData.default.hasMany('municipal-year')
  });
});