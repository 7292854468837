define('fund-figure-app/services/document-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    connectedUser: Ember.inject.service(),
    store: Ember.inject.service(),
    appError: Ember.inject.service(),
    bigBrother: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),

    /**
     * Saves documents
     * @param  {[array]} files      [Array of files to save]
     * @param  {[string]} folder     [Name of folder to save to]
     * @param {boolean} [isPublic] [should file be public or private]
     * @param  {[array]} modelArray [Array of models to save file model to once saved]
     * @return {[type]}            [description]
     */
    saveDocument: function saveDocument(file, folder, isPublic, additionalModels) {
      var user = this.get('connectedUser.currentAccount'),
          appError = this.get('appError'),
          municipalYear = this.get('connectedUser.fiscalYear'),
          fund = this.get('connectedUser.currentFund'),
          store = this.get('store'),
          storage = this.get('firebaseApp').storage(),
          _this = this;

      return user.get('municipality').then(function (municipality) {
        console.log(file, folder);
        var m_name = municipality.get('id'),
            storageRef = storage.ref('' + m_name),
            metadata = {
          name: file.metadata.name,
          size: file.metadata.size,
          lastModified: file.metadata.lastModified,
          filePDF: file.metadata.filePDF || null,
          fileJPG: file.metadata.fileJPG || null,
          fileXSL: file.metadata.fileXSL || null,
          fileDOC: file.metadata.fileDOC || null,
          fileSVG: file.metadata.fileSVG || null
        };

        return storageRef.child(file.metadata.name).put(file.file, file.metadata).then(function (fileShot) {
          console.log(fileShot);
          var path = fileShot.metadata.fullPath,
              downloadUrl = fileShot.metadata.downloadURLs[0],
              fileModel = store.createRecord('file', {
            name: fileShot.metadata.name,
            filePath: path,
            fileDownload: downloadUrl,
            metadata: metadata,
            saveDate: moment().valueOf(),
            municipality: municipality,
            municipalYear: municipalYear,
            municipalFund: fund,
            folder: folder
          });
          if (isPublic) {
            fileModel.setProperties({
              query_public_municipality: m_name + '-public', //string of {municipal_id}-public
              query_public_municipalFund: fund.get('id') + '-public' //string of {municipalFund_id}-public     
            });
          } else {
            fileModel.setProperties({
              query_private_municipality: m_name + '-private', //string of {municipal_id}-private
              query_private_municipalFund: fund.get('id') + '-private' //string of {municipalFund_id}-private
            });
          }
          return fileModel.save().then(function (fileSaved) {
            fund.get('documents').addObject(fileSaved);
            return fund.save().then(function () {
              return fileSaved;
            }).catch(function (err) {
              console.log(err);
              var specialMessage = 'error saving muniFund after adding file reference';
              appError.logError(err, specialMessage);
            });
          }).catch(function (err) {
            console.log(err);
            var specialMessage = 'error saving file after creating the new model';
            appError.logError(err, specialMessage);
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error puting new file ref to storage on firebase';
          appError.logError(err, specialMessage);
        });
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error getting municipality db reference to save new file';
        appError.logError(err, specialMessage);
      });
    },
    removeDocument: function removeDocument(file) {
      console.log(file);
      var refPath = file.get('filePath'),
          appError = this.get('appError');
      console.log(refPath);
      var promise = this.get('firebaseApp').storage().ref('' + refPath).delete().then(function (res) {
        return true;
      }).catch(function (err) {
        console.log(err);
        var specialMessage = 'error deleting file reference in firebase storage for ' + refPath;
        appError.logError(err, specialMessage);
      });
      return Promise.all([promise]).then(function (res) {
        console.log(res);
        if (!res[0].error) {
          return file;
        } else {
          return res[0];
        }
      });
    },
    removeDocuments: function removeDocuments(files) {
      var _this2 = this;

      var index = 0,
          length = files.length,
          errors = [];
      return files.forEach(function (file) {
        var refPath = file.get('filePath');
        console.log(refPath);
        var promise = _this2.get('firebaseApp').storage().ref('' + refPath).delete().then(function (res) {
          return true;
        }).catch(function (err) {
          console.log(err);
          return { error: err };
        });
        return Promise.all([promise]).then(function (res) {
          console.log(index, length);
          if (index === length) {
            console.log(res);
            if (!errors) {
              return file;
            } else {
              return { error: errors };
            }
          }
          if (res[0].error) {
            errors.push('' + res[0].error);
          }
          index++;
        });
      });
    },
    updateDocument: function updateDocument(file) {
      //If this is supported will need to download old file, resave with new name etc then delete old file
    }
  });
});