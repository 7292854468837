define('fund-figure-app/routes/account-handler/account-create-step-six', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		// model(params){
		// 	return this.store.findRecord('municipal-db', params.munic_id);
		// }
	});
});