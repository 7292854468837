define('fund-figure-app/components/dashboard/modals/payroll/submit-payroll-standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    commonTasks: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    appError: Ember.inject.service(),
    store: Ember.inject.service(),
    employeeWarnings: null,
    employeePayrollWarnings: null,
    submittingPayroll: false,

    // employeePayrollCheck: computed('', {
    //   get() {
    //     let municipal_id = get(this, 'connectedUser.currentAccount').toJSON().municipality;
    //     return get(this, 'store').query('employeePayrollInfo', {
    //         orderBy: 'municipality',
    //         equalTo: `${municipal_id}`
    //       })
    //       .then(result => {
    //         console.log('result', result);
    //         return result.filter(a => {
    //           return Number(a.get('gross_pay')) == 0;
    //         });
    //       })
    //       .then(filter => {
    //         set(this, 'employeeWarnings', filter);
    //         return filter.toArray();
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         let specialMessage = `error querying and filtering employee payroll info in submit module`;
    //         appError.logError(err, specialMessage);
    //       })
    //   }
    // }),

    employeePayrollCheck: Ember.computed('', {
      get: function get() {
        var _this = this;

        var municipal_id = Ember.get(this, 'connectedUser.currentAccount').toJSON().municipality,
            appError = Ember.get(this, 'appError');
        return Ember.get(this, 'store').query('employeePayrollInfo', {
          orderBy: 'municipality',
          equalTo: '' + municipal_id
        }).then(function (result) {
          return result.filter(function (a) {
            return a.toJSON().gross_pay_account == null;
          });
        }).then(function (filter) {
          Ember.set(_this, 'employeePayrollWarnings', filter);
          return filter.toArray();
        }).then(function () {
          var warnings = Ember.get(_this, 'connectedUser.employees').filter(function (a) {
            return Number(a.get('total_pay')) == 0;
          });
          Ember.set(_this, 'employeeWarnings', warnings);
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error querying and filtering employee payroll info in submit module';
          appError.logError(err, specialMessage);
        });
      }
    }),

    actions: {
      hideSubmitPayrollStandard: function hideSubmitPayrollStandard() {
        Ember.set(this, 'connectedUser.showModal', false);
        Ember.set(this, 'connectedUser.modalName', null);
      },
      ignorePayrollZeroToggle: function ignorePayrollZeroToggle() {
        this.toggleProperty('ignorePayrollZero');
      },

      /**
       * generate municipal-invoice and transactions for each employee-payroll-info
       * @param  {boolean} withChecks true if checks should be printed with other invoice checks
       * @return {class}            submittal class showing result of submitting from server
       */
      submitPayroll: function submitPayroll(withChecks, checkDate) {
        var _this2 = this;

        Ember.set(this, 'submittingPayroll', true);
        var municipal_id = Ember.get(this, 'connectedUser.currentAccount').toJSON().municipality,
            globalMessage = Ember.get(this, 'globalMessaging'),
            appError = Ember.get(this, 'appError'),
            commonTasks = Ember.get(this, 'commonTasks'),

        //check to see if municipality manages payroll tax payments to government or if third party does
        managesPayrollTaxes = Ember.get(this, 'connectedUser.fiscalYear.municipality').then(function (municipality) {
          return municipality.get('managesPayrollTaxes');
        });
        if (!checkDate) {
          globalMessage.actionNotification('Must have check date', 'warning');
          Ember.set(this, 'submittingPayroll', false);
          return;
        }
        return Ember.get(this, 'store').query('employeePayrollInfo', {
          orderBy: 'municipality',
          equalTo: '' + municipal_id
        }).then(function (result) {
          return result.filter(function (a) {
            return Number(a.get('gross_pay') || 0) > 0;
          });
        }).then(function (employeesToPay) {
          console.log(employeesToPay);
          var length = employeesToPay.length,
              municipal_id = Ember.get(_this2, 'connectedUser.currentAccount').toJSON().municipality,
              fiscalYear = Ember.get(_this2, 'connectedUser.fiscalYear'),
              currentFund = Ember.get(_this2, 'connectedUser.currentFund'),
              user = Ember.get(_this2, 'connectedUser.currentAccount'),
              index = 0,
              store = Ember.get(_this2, 'store'),
              m_year = municipal_id + '-' + moment().format("YYYY"),
              m_month = m_year + '-' + moment().format('MM'),
              m_week = m_year + '-' + moment().format('WW'),
              m_day = m_month + '-' + moment().format('ddd'),
              m_weekday = m_week + '-' + moment().format('E'),
              getPayrollAccounts = Ember.get(_this2, 'connectedUser.currentFund.municipalAccounts').then(function (accounts) {
            return accounts.filter(function (a) {
              return a.get('stateNumber') == '2020' || a.get('stateNumber') == '4155';
            });
          });
          getPayrollAccounts.then(function (payrollAccounts) {
            var net_pay = payrollAccounts.find(function (a) {
              return a.toJSON().function == 1250;
            }),
                health_care = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2100;
            }),
                fica_ss_employee = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2200 && a.toJSON().stateNumber == '2020';
            }),
                fica_ss_employer_credit = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2201 && a.toJSON().stateNumber == '2020';
            }),
                fica_ss_employer_debit = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2201 && a.toJSON().stateNumber == '4155';
            }),
                fica_med_employee = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2210 && a.toJSON().stateNumber == '2020';
            }),
                fica_med_employer_credit = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2211 && a.toJSON().stateNumber == '2020';
            }),
                fica_med_employer_debit = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2211 && a.toJSON().stateNumber == '4155';
            }),
                nhrs_retirement = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2310;
            }),
                employee_retirement = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2320;
            }),
                fed_tax = payrollAccounts.find(function (a) {
              return a.toJSON().function == 2700;
            });

            employeesToPay.forEach(function (payroll) {
              store.findRecord('vendor', payroll.toJSON().employee).then(function (employee) {
                //create invoice to attach each transaction to
                var invoice = store.createRecord('municipalInvoice', {
                  date: moment(checkDate).valueOf(),
                  total: payroll.get('gross_pay'),
                  vendor: payroll.get('employee'),
                  timestamp: moment().valueOf(),
                  pay_check: true,
                  net_pay: commonTasks.preciseRound(payroll.get('net_pay'), 2),
                  m_year: m_year,
                  m_month: m_month,
                  m_week: m_week,
                  m_day: m_day,
                  m_weekday: m_weekday,
                  municipality: municipal_id,
                  year: fiscalYear,
                  municipalFund: currentFund,
                  user: user
                });
                //FICA SS
                var promise1 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('fica_oasdi_tax')) > 0) {
                    //create fica_ss_employee transaction in default account
                    var fica_ss_employee_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: fica_ss_employee,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('fica_oasdi_tax'), 2),
                      notes: 'SS contribution',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    fica_ss_employee_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving fica_ss_employee portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error in fica_ss_employee (promise1)');
                    });
                  } else {
                    resolve();
                  }
                });
                //FICA Medicare employee
                var promise2 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('fica_medicare_tax')) > 0) {
                    //create fica_med_employee transaction in default account
                    var fica_medicare_employee_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: fica_med_employee,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('fica_medicare_tax'), 2),
                      notes: 'Medicare contribution',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    fica_medicare_employee_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving fica_medicare_employee portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error in fica_medicare (promise2)');
                    });
                  } else {
                    resolve();
                  }
                });
                //FICA Medicare additional employee
                var promise3 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('fica_additional_medicare')) > 0) {
                    //create fica_med_employee transaction in default account
                    var fica_additoinal_medicare_employee_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: fica_med_employee,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('fica_additional_medicare'), 2),
                      notes: '+Medicare contribution',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    fica_additoinal_medicare_employee_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving fica_additional_medicare_employee portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error saving fica_med_additional (promise3)');
                    });
                  } else {
                    resolve();
                  }
                });
                //NHRS employee
                var promise4 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('retirement')) > 0) {
                    //create nhrs_retirement transaction in default account
                    var state_retirement_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: nhrs_retirement,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('retirement'), 2),
                      notes: 'Retirement contribution',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    state_retirement_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving state_retirement portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error saving nhrs_retirement (promise4)');
                    });
                  } else {
                    resolve();
                  }
                });
                //Federal Tax
                var promise5 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('federal_tax')) > 0) {
                    //create fed_tax transaction in default account
                    var fed_tax_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: fed_tax,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('federal_tax'), 2),
                      notes: 'Federal Tax contribution',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    fed_tax_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving fed_tax portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error saving fed_tax promise5');
                    });
                  } else {
                    resolve();
                  }
                });
                //Health Care
                var promise6 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('health_care')) > 0) {
                    //create net_pay transaction in default account
                    var health_care_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: health_care,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('health_care'), 2),
                      notes: 'Health Care',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    health_care_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving health_care portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error saving healt_care promise6');
                    });
                  } else {
                    resolve();
                  }
                });
                //Net Pay
                var promise7 = new Promise(function (resolve, reject) {
                  if (Number(payroll.get('net_pay')) > 0) {
                    //create net_pay transaction in default account
                    var net_pay_PayTransaction = store.createRecord('transaction', {
                      transactDebit: payroll.get('gross_pay_account'),
                      transactCredit: net_pay,
                      t_Expense: true,
                      t_Payroll: true,
                      invoice: invoice,
                      amount: commonTasks.preciseRound(payroll.get('net_pay'), 2),
                      notes: 'Net pay',
                      m_year: m_year,
                      m_month: m_month,
                      m_week: m_week,
                      m_day: m_day,
                      m_weekday: m_weekday,
                      municipality: municipal_id,
                      year: fiscalYear,
                      municipalFund: currentFund,
                      timestamp: moment().valueOf(),
                      date: moment().valueOf()
                    });
                    net_pay_PayTransaction.save().then(function (saved) {
                      invoice.get('transactions').addObject(saved);
                      currentFund.get('municipalTransactions').addObject(saved);
                      fiscalYear.get('municipalTransactions').addObject(saved);
                      resolve();
                    }).catch(function (err) {
                      console.log(err);
                      var specialMessage = 'error saving net_pay portion of payroll for ' + payroll.get('employee.id');
                      appError.logError(err, specialMessage);
                      reject('error saving net_pay promise7');
                    });
                  } else {
                    resolve();
                  }
                });
                //Municipal SS Payroll Burden Pay
                var promise8 = new Promise(function (resolve, reject) {
                  return managesPayrollTaxes.then(function (result) {
                    if (result) {
                      //town manages payroll taxes put correct amount in accounts
                      var town_ss = store.createRecord('transaction', {
                        transactDebit: fica_ss_employer_debit,
                        transactCredit: fica_ss_employer_credit,
                        t_Expense: true,
                        t_Payroll: true,
                        invoice: invoice,
                        amount: commonTasks.preciseRound(payroll.get('fica_oasdi_tax'), 2),
                        notes: 'SS contribution',
                        m_year: m_year,
                        m_month: m_month,
                        m_week: m_week,
                        m_day: m_day,
                        m_weekday: m_weekday,
                        municipality: municipal_id,
                        year: fiscalYear,
                        municipalFund: currentFund,
                        timestamp: moment().valueOf(),
                        date: moment().valueOf()
                      });
                      town_ss.save().then(function (saved) {
                        invoice.get('transactions').addObject(saved);
                        currentFund.get('municipalTransactions').addObject(saved);
                        fiscalYear.get('municipalTransactions').addObject(saved);
                        resolve();
                      }).catch(function (err) {
                        console.log(err);
                        var specialMessage = 'error saving fica_ss_employer_credit portion of payroll for ' + payroll.get('employee.id');
                        appError.logError(err, specialMessage);
                        reject('error saving fica_ss_employer_credit promise8');
                      });
                    } else {
                      resolve();
                    }
                  });
                });
                //Municipal Med Payroll Burden Pay
                var promise9 = new Promise(function (resolve, reject) {
                  return managesPayrollTaxes.then(function (result) {
                    if (result) {
                      //town manages payroll taxes put correct amount in accounts
                      var town_med = store.createRecord('transaction', {
                        transactDebit: fica_med_employer_debit,
                        transactCredit: fica_med_employer_credit,
                        t_Expense: true,
                        t_Payroll: true,
                        invoice: invoice,
                        amount: commonTasks.preciseRound(payroll.get('fica_medicare_tax'), 2),
                        notes: 'Medicare contribution',
                        m_year: m_year,
                        m_month: m_month,
                        m_week: m_week,
                        m_day: m_day,
                        m_weekday: m_weekday,
                        municipality: municipal_id,
                        year: fiscalYear,
                        municipalFund: currentFund,
                        timestamp: moment().valueOf(),
                        date: moment().valueOf()
                      });
                      town_med.save().then(function (saved) {
                        invoice.get('transactions').addObject(saved);
                        currentFund.get('municipalTransactions').addObject(saved);
                        fiscalYear.get('municipalTransactions').addObject(saved);
                        resolve();
                      }).catch(function (err) {
                        console.log(err);
                        var specialMessage = 'error saving fica_med_employer_credit portion of payroll for ' + payroll.get('employee.id');
                        appError.logError(err, specialMessage);
                        reject('error saving fica_med_employer_credit promise9');
                      });
                    } else {
                      resolve();
                    }
                  });
                });

                Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9]).then(function () {
                  invoice.save().then(function (saved) {
                    currentFund.get('municipalInvoices').addObject(saved);
                    currentFund.save();
                    fiscalYear.save();
                    employee.get('municipalInvoices').addObject(saved);
                    employee.get('employee_payroll_infos').removeObject(payroll);
                    employee.save();
                    payroll.destroyRecord();
                    index++;
                    console.log(index, length);
                    if (index == length) {
                      Ember.set(_this2, 'connectedUser.showModal', false);
                      Ember.set(_this2, 'connectedUser.modalName', null);
                      Ember.set(_this2, 'submittingPayroll', false);
                      globalMessage.actionNotification('submitted ' + index + ' payrolls', 'success');
                    }
                  }).catch(function (err) {
                    console.log(err);
                    var specialMessage = 'error saving invoice after creating transactions for each payroll';
                    appError.logError(err, specialMessage);
                  });
                }).catch(function (err) {
                  console.log(err);
                  var specialMessage = 'error saving after all 1-7 promises resolve';
                  appError.logError(err, specialMessage);
                });
              }).catch(function (err) {
                console.log(err);
                var specialMessage = 'error finding employee connected to payroll';
                appError.logError(err, specialMessage);
              });
            });
          });
        }).catch(function (err) {
          console.log(err);
          var specialMessage = 'error querying and filtering employee payroll info in submit module';
          appError.logError(err, specialMessage);
        });
      }
    }
  });
});