define('fund-figure-app/components/dashboard/payments/payments-index-display/index-display-filter', ['exports', 'd3-collection', 'd3-array'], function (exports, _d3Collection, _d3Array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    globalMessaging: Ember.inject.service(),
    apiTransaction: Ember.inject.service(),
    appError: Ember.inject.service(),
    category: "category",
    variable: "amount",
    donutData: [],
    showVisuals: false,
    checkInput: true,
    invoiceInput: false,

    /**
     * Create data set for visual graphs and displays
     * @param  {Observer}   'filteredPayments.[]'                          watches for changes in filteredArray of payments
     * @param  {Observer}   'connectedUser.currentFund.municipalChecks.[]' watches for changes in municipalChecks
     * @param  {Observer}   'connectedUser.currentFund'                    watches for change in fund
     * @return {NA}                                                        sets visual data variable equal to current filtered data
     */
    data: Ember.computed('category', 'superFilteredPayments.[]', {
      get: function get() {
        console.log('getting payment data');
        var array = this.get('superFilteredPayments').filter(function (a) {
          if (a.get('void')) {
            return false;
          }
          return true;
        }),
            _this = this;
        if (!array) {
          return;
        }
        this.get('superFilteredPayments');
        var returnArray = [],
            index = 0;
        return array.forEach(function (promiseCheck) {
          var object = {};
          object["amount"] = promiseCheck.get('amount');
          promiseCheck.get('vendor').then(function (vendor) {
            object["category"] = vendor.get('businessCategory');
            return object["business"] = vendor.get('businessName');
          }).then(function () {
            return returnArray.push(object);
          }).then(function () {
            console.log(index, array.length);
            if (index == array.length - 1) {
              _this.set('donutData', returnArray);
              return returnArray;
            } else {
              return index++;
            }
          });
        });
      }
    }).property('superFilteredPayments.[]'),

    combinedDonutData: Ember.computed('donutData', 'data', {
      get: function get() {
        console.log('getting data for combining');
        return (0, _d3Collection.nest)().key(function (d) {
          return d["category"];
        }).rollup(function (leaves) {
          return { "checks": leaves.length, "total": (0, _d3Array.sum)(leaves, function (d) {
              return parseFloat(d.amount);
            }) };
        }).entries(Ember.get(this, 'donutData')).map(function (d) {
          return { category: d.key, amount: d.value.total, checks: d.value.checks };
        });
      },
      set: function set(key, value) {
        console.log('from combinedDonutData set', value[0], value[1]);
        var arrayFilter = Ember.get(this, 'donutData').filter(function (a) {
          if (a[value[0]] === value[1]) {
            return true;
          }
          return false;
        });
        Ember.set(this, 'category', 'business');
        return (0, _d3Collection.nest)().key(function (d) {
          return d["business"];
        }).rollup(function (leaves) {
          return { "checks": leaves.length, "total": (0, _d3Array.sum)(leaves, function (d) {
              return parseFloat(d.amount);
            }) };
        }).entries(arrayFilter).map(function (d) {
          return { business: d.key, amount: d.value.total, checks: d.value.checks };
        });
      }
    }).property('donutData'),

    actions: {
      notifyDonut: function notifyDonut() {
        console.log('hey');
        this.notifyPropertyChange('donutData');
      },
      drillDown: function drillDown(data) {
        console.log(data);
        Ember.set(this, 'combinedDonutData', ["category", data.data["category"]]);
      },
      drillUp: function drillUp() {
        console.log('drillingUp');
        Ember.set(this, 'category', 'category');
        // get(this, 'combinedDonutData');
        this.notifyPropertyChange('donutData');
      },
      changeDisplay: function changeDisplay(toDisplay) {
        this.setProperties({ checkInput: false, invoiceInput: false });
        Ember.set(this, toDisplay, true);
      },


      /**
       * set variable equal to check we are going to start void process for, opens modal
       * @param  {emberDataModel} check - model of the check 
       * @return {NA}       no return value, only sets a variable from null to check model
       */
      voidCheckModal: function voidCheckModal(check) {
        console.log('setting check');
        // set(this, 'connectedUser.voidCheck', check);
        Ember.get(this, 'voidCheckModal')(check);
      },


      /**
       * set variable equal to check we are going to start void and reissue process for, open modal
       * @param  {emberDataModel} check - model of the check
       * @return {NA}       no return value, only sets a variable form null to check model
       */
      voidCheckReissueModal: function voidCheckReissueModal(check) {
        Ember.get(this, 'voidCheckReissueModal')(check);
      },


      /**
       * Clears payment filter by setting filtered variable equal to original array
       * @return {NA} no return, sets filteredPayments array equal to original array
       */
      clearFilter: function clearFilter() {
        Ember.set(this, 'filteredPayments', Ember.get(this, 'paymentsArray'));
      },


      /**
       * Submit filter and create new array of payments
       * @param  {modelArray} filterArray array of payment models
       * @return {NA}             sets variable filteredPayments array equal to new filter
       */
      submitFilter: function submitFilter(filterArray) {
        var _this2 = this;

        var payments = Ember.get(this, 'paymentsArray');
        filterArray.forEach(function (filter) {
          switch (filter.filter) {
            case 'byAmount':
              var amount = Number(filter.amount);
              if (filter.logic == "amountGreater") {
                var filtered = payments.filter(function (payment) {
                  return payment.get('amount') > amount;
                });
                payments = filtered;
              }
              if (filter.logic == "amountLess") {
                var _filtered = payments.filter(function (payment) {
                  return payment.get('amount') < amount;
                });
                payments = _filtered;
              }
              if (filter.logic == "amountEquals") {
                var _filtered2 = payments.filter(function (payment) {
                  return payment.get('amount') === amount;
                });
                payments = _filtered2;
              }
              break;
            case 'byDate':
              var beginDate = Number(filter.beginDate);
              var endDate = Number(filter.endDate);
              if (filter.logic == "dateGreater") {
                var _filtered3 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate;
                });
                payments = _filtered3;
              }
              if (filter.logic == "dateLess") {
                var _filtered4 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') < beginDate;
                });
                payments = _filtered4;
              }
              if (filter.logic == "dateBetween") {
                var _filtered5 = payments.filter(function (payment) {
                  console.log(payment.get('date'));
                  return payment.get('date') > beginDate && payment.get('date') < endDate;
                });
                payments = _filtered5;
              }
              break;
            default:
              break;
          }
          Ember.set(_this2, 'filteredPayments', payments);
        });
      },


      /**
       * Toggle display of visual graphs
       * @return {NA} toggles property bool value of showVisuals which controls display
       */
      toggleVisuals: function toggleVisuals() {
        if (Ember.get(this, 'donutData').length == 0) {
          Ember.set(this, 'showVisuals', false);
          Ember.get(this, 'globalMessaging').actionNotification('There are only void payments', 'warning');
          return;
        }
        this.toggleProperty('showVisuals');
      }
    }
  });
});