define('fund-figure-app/components/helper/filter-account-transactions-by-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filteredTransactions: null,

    transactions: Ember.computed('account', 'beginDate', 'endDate', {
      get: function get(key) {
        var _this = this,
            beginDate = moment(this.get('beginDate')) || false,
            endDate = moment(this.get('endDate')) || false,
            account = this.get('account'),
            dateLocation = this.get('dateLocation'),
            transactionArray = Array(),
            removePeriod = this.get('removePeriod') || false,
            transactions = account.get('transactions');
        return transactions.then(function (allTransactions) {
          if (!beginDate || !endDate) {
            transactionArray = allTransactions;
            _this.set('filteredTransactions', allTransactions);
          } else {
            if (removePeriod) {
              switch (removePeriod) {
                case 'Year':
                  beginDate.subtract(1, 'years');
                  endDate.subtract(1, 'years');
                  break;
              }
            }
            var filteredTransactionArray = allTransactions.filter(function (transaction) {
              // console.log(beginDate.valueOf(), transaction.get(dateLocation), endDate.valueOf());
              if (transaction.get(dateLocation) >= beginDate.valueOf() && transaction.get(dateLocation) <= endDate.valueOf()) {
                return true;
              } else {
                return false;
              }
            });
            transactionArray = filteredTransactionArray;
            _this.set('filteredTransactions', filteredTransactionArray);
            // console.log(filteredTransactionArray)
          }
          return transactionArray;
        });
      }
    })
  });
});