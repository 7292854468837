define('fund-figure-app/components/dashboard/vendorss/manifests-display/view-manifest', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    connectedUser: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),

    didUpdateAttrs: function didUpdateAttrs() {
      console.log('inserting!');
      $('#manifest.y-scroll').scrollTop(0);
    }
  });
});