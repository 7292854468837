define('fund-figure-app/components/number-to-spanish', ['exports', 'ember-number-to-words/components/number-to-spanish'], function (exports, _numberToSpanish) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _numberToSpanish.default;
    }
  });
});